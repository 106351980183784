import { LetterType } from "../../../../types/patients";
import { DialogV2 } from "saga-library/src";
import React, { useEffect, useRef, useState } from "react";
import { LetterForm } from "./LetterForm";
import { DeleteLetterConfirmation } from "./LetterFormMain";
import { Permission, PermissionType } from "../../../../types/settings/Permission";
import { letterDefaults } from "./LetterFormDefaultValues";
import { schema } from "./LetterFormValidationSchema";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { PrintIconButton } from "saga-library/src";
import PermissionButton from "../../../../components/PermissionButton";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { useParams } from "react-router-dom";
import { useLetterContext } from "../../providers/LetterProvider";

export const LETTER_FORM_NAME = "letter_form"

interface LetterModalProps {
  open: boolean
  onModalClose: () => void
  letter?: LetterType | null
}
export const LetterModal = ({ letter, open, onModalClose}: LetterModalProps) => {
  const { updateLetter, deleteLetter, createLetter } = useLetterContext()

  const editorRef = useRef<any>(null)

  const { patient_id } = useParams()
  const { enableNavigationPrompt } = usePrompt()
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)

  const formMethods = useForm<LetterType>({
    defaultValues: letterDefaults,
    schema: schema,
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset,
  } = formMethods

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        onModalClose()
        reset(letterDefaults)
      }
    }
  }

  useEffect(() => {
    if(open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, LETTER_FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, LETTER_FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      onModalClose()
    }
  }

  const onSubmitCreate = handleSubmit(
    async (data) => {
      data.patientId = patient_id!
      await createLetter(data, onModalClose)
    },
    (errors) => {
      console.error("errors: ", errors)
    }
  )

  const onSubmitUpdate = handleSubmit(
    async (data) => {
      data.patientId = patient_id!
      await updateLetter(data, onModalClose)
    },
    (errors) => {
      console.error("errors: ", errors)
    }
  )

  const onDelete = async(letterId) => {
    await deleteLetter(letterId, onModalClose)
  }

  return (
    <DialogV2
      title={letter ? letter.title : "New Letter"}
      size={"lg"}
      open={open}
      onClose={onCancel}
      primaryAction={()=>null}
      overridePrimaryComponent={
        <PermissionButton
          name={"saveLetter"}
          type={"submit"}
          form={LETTER_FORM_NAME}
          requiredType={PermissionType.Chart}
          requiredPermission={Permission.READWRITE}
          loading={isSubmitting}
          dataTestId={"newLetter-modal-save-button"}
        >
          Save
        </PermissionButton>
      }
      headerActions={ letter ?
        [
          <DeleteLetterConfirmation onDelete={() => onDelete(letter.id)} />,
          <PrintIconButton onClick={() => editorRef?.current?.editor?.print?.run()} dataTestId={"letter-modal"} />
        ]
        : []
      }
      preventScroll={true}
      contentSx={{
        display: 'flex',
        flexDirection: 'column',
        paddingRight: 0
      }}
      dataTestId={"newLetter-modal"}
    >
      <FormProvider {...formMethods}>
        <LetterForm
          formName={LETTER_FORM_NAME}
          onSubmit={letter ? onSubmitUpdate : onSubmitCreate}
          letter={letter}
          editorRef={editorRef}
          dataTestId={"letterForm-modal"}
        />
      </FormProvider>
    </DialogV2>
  )
}