import { PrintButton, PrintIconButton } from 'saga-library/src'
import React, { createRef, useRef, useState } from 'react'
import { useActivityTracking, UserActivityObjectType } from '../../../../hooks/useActivityTracking'
import { AbLabResult } from '../../../../types/patients'
import { FileType } from '../../../../types/tasks'
import { useDocumentContext } from '../../../../providers/DocumentProvider'
import { PDFUI, PDFViewer } from '../../../../components/FoxitPDFViewer/FoxitTypes'
import { FoxitPDFViewer } from '../../../../components/FoxitPDFViewer'
import { PrintOnly } from 'components/PrintOnly'
import { useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { GET_LAB_AND_INVESTIGATION } from '../../../../graphql-definitions'
import _get from 'lodash/get'

interface LabAndInvestigationPrintButtonProps {
  file?: FileType | null,
  labAndInvestigation?: AbLabResult | null,
  printComponentRef: React.RefObject<any>,
  patientId: string | undefined,
  onComplete?: () => void,
  onBeforeGetContent?: () => void,
}

export const LabAndInvestigationPrintButton = ({
  file,
  labAndInvestigation,
  printComponentRef,
  patientId,
  onComplete,
  onBeforeGetContent,
}: LabAndInvestigationPrintButtonProps) => {
  const { tenant_id } = useParams()
  const [loading, setLoading] = useState(false)
  const { getFileTypeUriWithToken } = useDocumentContext()
  const printPdfUiRef = useRef<PDFUI | null>(null)
  const printPdfViewerRef = useRef<PDFViewer | null>(null)
  const activityTracking = useActivityTracking()

  const imageRefs = useRef(new Map())
  let firstFile = labAndInvestigation?.abLabResultObservationResults[0]?.file
  let otherLabs = labAndInvestigation?.abLabResultObservationResults
    ? [...labAndInvestigation.abLabResultObservationResults.slice(1)]
    : []
  let labsWithImages = new Map(
    labAndInvestigation?.abLabResultObservationResults
      .filter(result => result.file?.originalFileName.split('.').pop() === 'jpg')
      .map(result => [result, imageRefs.current.get(result) || React.createRef()])
  )

  const [getLabAndInvestigation, { data: labData }] = useLazyQuery(GET_LAB_AND_INVESTIGATION, {
    fetchPolicy: 'cache-and-network',
    onCompleted: async data => {
      labAndInvestigation = _get(labData, 'tenant.patient.labAndInvestigation.get')
      firstFile = labAndInvestigation?.abLabResultObservationResults[0]?.file
      otherLabs = labAndInvestigation?.abLabResultObservationResults
        ? [...labAndInvestigation.abLabResultObservationResults.slice(1)]
        : []
      labsWithImages = (new Map(
        labAndInvestigation?.abLabResultObservationResults
          .filter(result => result.file?.originalFileName.split('.').pop() === 'jpg')
          .map(result => [result, imageRefs.current.get(result) || React.createRef()])
      ))

      await printPdfViewers()
    },
    onError: error => {
      console.error(JSON.stringify(error, null, 2))
    }
  })

  const printPdfCallback = (data) => {
    if(data.state !== 'end') return
    if(!patientId) return
    activityTracking.logPrintActivity(UserActivityObjectType.PATIENT, patientId)
    setLoading(false)
  }

  const printPdfViewers = async () => {
    setLoading(true)
    if (!printPdfUiRef.current || !firstFile) {
      setLoading(false)
      return
    }

    let printPdfDoc
    await getFileTypeUriWithToken(firstFile)
      .then(async (fileUri) => {
        if (!fileUri) return
        if (!printPdfUiRef.current) return
        printPdfDoc = await printPdfUiRef.current.openPDFByHttpRangeRequest({
          range: {
            url: fileUri
          }
        })
      })

    let pageCount
    await Promise.all(otherLabs.map(async (result) => {
      if (!result?.file) return
      try {
        const fileUri = await getFileTypeUriWithToken(result.file)
        if (!fileUri) return
        const response = await fetch(fileUri)
        let blob = await response.blob()
        if (result.file?.originalFileName.split('.').pop() === 'jpg') {
          await Promise.all(Array.from(labsWithImages).map(async ([result, ref], index) => {
            if (!ref.current) return
            await ref.current.convertImageToPDFDoc(blob, fileUri, '', '')
              .then(async doc => {
                await ref.current.renderDoc(doc, '100')
                await ref.current.printCurrentView()
              })
          }))
        } else {
          await printPdfDoc.insertPages({ destIndex: 0, file: blob, startIndex: 0, endIndex: -1 })
        }
      } catch (error) {
        console.error('Error processing file:', result.file, error)
      } finally {
        setLoading(false)
      }
    }))

    pageCount = printPdfDoc.getPageCount()
    await printPdfViewerRef?.current?.printEx({ type: 0, pageRange: `1-${pageCount}`, progress: true }, (data) => {
      printPdfCallback(data)
    })
  }

  if ((!!file && !!labAndInvestigation) || !labAndInvestigation?.abLabResultObservationRequest) {
    const handlePrint = async () => {
      if (!firstFile && !file) {
        try {
          await getLabAndInvestigation({
            variables: {
              tenantId: tenant_id,
              patientId: patientId,
              id: labAndInvestigation?.id
            }
          })
          return
        } catch (error) {
          console.log(error)
          return
        }
      }

      await printPdfViewers()
    }

    return (
      <>
        <PrintIconButton
          disabled={loading}
          onClick={handlePrint}
          dataTestId={'lab-and-investigation'}
        />
        <PrintOnly componentKey={`${labAndInvestigation?.id}-print-only-area`}>
          {
            <FoxitPDFViewer
              key={'print-pdf-viewer'}
              pdfuiRef={printPdfUiRef}
              pdfViewerRef={printPdfViewerRef}
              loading={loading}
              selectedFile={undefined}
              variant={'labs'}
            />
          }
          {
            Array.from(labsWithImages.entries()).map(([result, ref], index) => (
              <FoxitPDFViewer
                key={`${result.id}-viewer-${index}`}
                pdfuiRef={createRef()}
                pdfViewerRef={ref}
                loading={loading}
                selectedFile={undefined}
                variant={'labs'}
              />
            ))
          }
        </PrintOnly>
      </>
    )
  } else {
    return (
      <PrintButton
        type={'icon'}
        componentRef={printComponentRef}
        documentTitle={'Labs and investigations'}
        dataTestId={'labs-and-investigations'}
        name={'labs-and-investigations'}
        objectType={UserActivityObjectType.PATIENT}
        objectId={patientId}
        onComplete={onComplete}
        onBeforeGetContent={onBeforeGetContent}
      />
    )
  }
}
