import { Audit } from './Audit'

export type FamilyHistoryType = {
  id?: string,
  condition: string,
  relationship: string,
  notes?: string,
  diagnosticCodeId?: string,
  audit?: Audit
}

export enum ExtendedFamilyRelationship {
  AUNT = 'AUNT',
  AUNT_MATERNAL = 'AUNT_MATERNAL',
  AUNT_PATERNAL = 'AUNT_PATERNAL',
  BROTHER = 'BROTHER',
  COUSIN = 'COUSIN',
  COUSIN_MATERNAL = 'COUSIN_MATERNAL',
  COUSIN_PATERNAL = 'COUSIN_PATERNAL',
  DAUGHTER = 'DAUGHTER',
  FATHER = 'FATHER',
  GRANDDAUGHTER = 'GRANDDAUGHTER',
  GRANDFATHER = 'GRANDFATHER',
  GRANDFATHER_MATERNAL = 'GRANDFATHER_MATERNAL',
  GRANDFATHER_PATERNAL = 'GRANDFATHER_PATERNAL',
  GRANDMOTHER = 'GRANDMOTHER',
  GRANDMOTHER_MATERNAL = 'GRANDMOTHER_MATERNAL',
  GRANDMOTHER_PATERNAL = 'GRANDMOTHER_PATERNAL',
  GRANDSON = 'GRANDSON',
  MOTHER = 'MOTHER',
  NEPHEW = 'NEPHEW',
  NIECE = 'NIECE',
  OTHER = 'OTHER',
  SISTER = 'SISTER',
  SON = 'SON',
  UNCLE = 'UNCLE',
  UNCLE_MATERNAL = 'UNCLE_MATERNAL',
  UNCLE_PATERNAL = 'UNCLE_PATERNAL',
}

export const formatEnumToSentenceCase = (value: string): string => {
  if (!value) return ''
  return value.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())
}