import _get from "lodash/get";
import { ControlledSearch } from "saga-library/src";
import {
  ControlledSearchControlProps
} from "saga-library/src/components/SearchControl/ControlledSearchControl";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { GET_RECENT_DIAGNOSTICCODES, GET_SEARCH_DIAGNOSTICCODES } from "../../../graphql-definitions";
import { AbClaimDiagnosticCode } from "../../../types/billing";
import DiagnosticCodeResult from "./DiagnosticCodeResult";
import { Chip } from "@mui/material";

export interface DiagnosticCodeSearchProps<
  T = AbClaimDiagnosticCode,
  Multiple extends boolean = boolean,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
  > extends Omit<ControlledSearchControlProps<
  T,
  Multiple,
  DisableClearable,
  FreeSolo
  >, 'queries'|'render'|'transform'|'isOptionEqualToValue'> {
  serviceDate?: Date
  practitionerId?: string
  disabled?: boolean
  allowMultiple?: boolean
  dataTestId?: string
}

export const DiagnosticCodeSearch = ({
  name = 'diagnosticCodeIds',
  label = 'Diagnostics',
  serviceDate = new Date(),
  practitionerId = '',
  disabled = false,
  allowMultiple = true,
  dataTestId,
  ...props
} :DiagnosticCodeSearchProps) => {

  const { tenant_id } = useParams()

  const searchQueryVariables = useMemo(() => ({
    tenantId: tenant_id,
    serviceDate: serviceDate
  }), [tenant_id, serviceDate])

  const initialQueryVariables = useMemo(() => ({
    tenantId: tenant_id,
    searchPractitionerId: practitionerId,
    serviceDate: serviceDate
  }), [tenant_id, practitionerId, serviceDate])

  return (
    <ControlledSearch<AbClaimDiagnosticCode, typeof allowMultiple, false, false>
      {...props}
      dataTestId={dataTestId}
      multiple={allowMultiple}
      name={name}
      label={label}
      disabled={disabled}
      queries={{
        search: {
          gql: GET_SEARCH_DIAGNOSTICCODES,
          get: (data) => _get(data, 'tenant.search.abClaim.diagnosticCodes', [] as AbClaimDiagnosticCode[]),
          variables: searchQueryVariables,
          fetchPolicy: 'network-only'
        },
        initial: {
          gql: GET_RECENT_DIAGNOSTICCODES,
          get: (data) => _get(data, 'tenant.search.abClaim.recentDiagnosticCodes', []) as AbClaimDiagnosticCode[],
          variables: initialQueryVariables,
          fetchPolicy: 'cache-first'
        }
      }}
      render={{
        value: (code) => code.diagnosticCode,
        option: (code, state) => (
          <DiagnosticCodeResult
            diagnosticCode={code}
            searchText={state.inputValue}
          />
        )
      }}
      renderTags={(codes, getTagProps) =>
        codes.map((code, index) => (
          <Chip
            {...getTagProps({ index })}
            data-testid={`${dataTestId}-chip-${index}`}
            label={code.diagnosticCode}
            size={'small'}
          />
        ))
      }
    />
  )
}
