import React from 'react'
import { Button } from "saga-library/src";
import { Box } from "@mui/material";
import { ResubmitOptionsList } from "./ResubmitOptionsList";

export const AdjustedClaimHeader = ({
                                      onAcceptButtonClick,
                                      onResubmitButtonClick,
                                      onReassessOptionClick,
                                      onDeleteOptionClick,
                                      onChangeOptionClick,
                                      expand
                                   }) => {

  return (
    <Box sx={{display: 'flex', flexDirection: 'row'}}>
      <Button
        name="accept"
        variant={'outlined'}
        onClick={onAcceptButtonClick}
        sx={{
          m: 'auto',
          mr: 1,
        }}
      >
        ACCEPT
      </Button>
      <ResubmitOptionsList
        onResubmitButtonClick={onResubmitButtonClick}
        onReassessOptionClick={onReassessOptionClick}
        onDeleteOptionClick={onDeleteOptionClick}
        onChangeOptionClick={onChangeOptionClick}
        expand={expand}
      />
    </Box>
  )
}