import { Box } from "@mui/material";
import MuiRadioGroup from "@mui/material/RadioGroup";
import { Radio, TableList } from "saga-library/src";
import { UnsubmittedClaimRow } from "./UnsubmittedClaimRow";
import React, { useState } from "react";
import { AbClaimProblemSeverity } from "../../../utils/ABClaimConstants";
import { UnsubmittedClaimColumns} from "./UnsubmittedClaimList";
import { useParams } from "react-router-dom";
import { useAccountContext } from "../../../providers/AccountContext";
import { PermissionType, Permission } from "../../../types/settings/Permission";

export const AllUnsubmittedClaimsListView = ({
  allUnsubmittedClaims,
  isAllSelected,
  selectAll,
  onCheckboxClick,
  onRowClick,
  loading,
  selectedClaims,
  onRowSelectionChange,
  clearSelectedRows
}) => {
  const [filter, setFilter] = useState('*')
  const { tenant_id } = useParams()
  const { userHasPermission } = useAccountContext()

  const hasEditPermission = (tenant_id && userHasPermission(tenant_id, PermissionType.Billing, Permission.READWRITE))

  const claimStates = {
    all: '*',
    readyToSubmit: 'READY',
    held: 'HOLD',
    containsErrors: 'ERROR',
  }

  const filterClaimsByStatus = (claim) => {
    if (filter === '*') {
      return true
    } else if (filter !== 'ERROR') {
      return claim.claimStatus === filter
    } else {
      return claim.problems.some(p => p.severity === AbClaimProblemSeverity.ERROR)
    }
  }

  const selectAllChecked = selectedClaims.length && allUnsubmittedClaims.filter(filterClaimsByStatus).length === selectedClaims.length

  const rowsChecked = allUnsubmittedClaims.map((uc, index) => {
    if (selectedClaims.some(sc => sc.id === uc.id)) return index
  }).filter(index => index !== undefined)

  return <>
    <Box
      data-testid={"unsubmittedClaimList"}
      display={'flex'}
      flexDirection={'row'}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <MuiRadioGroup
        row={true}
        name={'filter'}
        data-testid={'filterTestId'}
        onChange={(event) => setFilter(claimStates[event.target.value])}
        defaultValue={'all'}
      >
        <Radio label={'All'} value={'all'} />
        <Radio label={'Ready to submit'} value={'readyToSubmit'} />
        <Radio label={'Held'} value={'held'} />
        <Radio label={'Contains errors'} value={'containsErrors'} />
      </MuiRadioGroup>
    </Box>
    <TableList
      size={'small'}
      loading={loading}
      showCheckbox={!!hasEditPermission}
      onSelectAllClick={(e) => {
        selectAll(e, allUnsubmittedClaims.filter(filterClaimsByStatus))
      }}
      emptyListComponent={ { message: "Unsubmitted claims appear here once saved." } }
      columns={UnsubmittedClaimColumns}
      clearSelectedRows={clearSelectedRows}
      rows={[
        ...allUnsubmittedClaims
          .filter(filterClaimsByStatus)
          .map((claim) => {
            return (
              UnsubmittedClaimRow(claim, onRowClick, onRowSelectionChange)
            )
          })
      ]}
    />
  </>
}