import React from 'react'
import {
  ResultOptionsWrapper
} from "saga-library/src";
import {
  EntryTitle,
  IdentifierRow
} from "saga-library/src";
import { PractitionerSearchResult } from "../../../types/settings/Practitioner";
import { practitionerDisplayName } from "saga-library/src/util/formatting";

interface PractitionerResultProps {
  practitioner: PractitionerSearchResult
  searchText: string
  openInTab?: (ref: PractitionerSearchResult) => void
  dataTestId: string
}

export const PractitionerResult = ({
  practitioner,
  searchText,
  openInTab,
  dataTestId,
}: PractitionerResultProps) => {
  return (
    <ResultOptionsWrapper
      dataTestId={dataTestId}
      options={{
        onOpenInNewTab: () => {
          if(openInTab) {
            openInTab(practitioner)
          }
        }
      }}
    >
      <EntryTitle
        title={practitionerDisplayName(practitioner.firstName, practitioner.lastName)}
        searchText={searchText}
        dataTestId={`${dataTestId}-name`}
      />
      <IdentifierRow
        label={'ID'}
        identifier={practitioner.primaryIdentifier || 'None'}
        searchText={searchText}
        dataTestId={`${dataTestId}-identifier`}
      />
    </ResultOptionsWrapper>
  )
}
