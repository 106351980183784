import { Controller, useFormContext } from "saga-library/src/components/Form";
import { Autocomplete } from "saga-library/src";
import React from "react";
import { findOption } from "../../../../../utils/findOption";
import { SxProps } from "@mui/material";
import { PitTerminologyType } from "../../../../../types/PitTerminology";

interface DictionarySelectProps {
  name: string;
  label: string;
  dictionary: {
    [id: number]: PitTerminologyType;
  };
  onChange?: (v) => void;
  sx?: SxProps
  disabled?: boolean,
  sortOptions?: 'asc' | 'desc',
  freeSolo?: boolean
  dataTestId?: string
}

export const DictionarySelect = ({
  name,
  label,
  dictionary,
  onChange,
  sx,
  disabled,
  sortOptions,
  freeSolo = false,
  dataTestId
}: DictionarySelectProps) => {
  const { control } = useFormContext();

  const options: { label: string, value: number }[] = []

  Object.entries(dictionary).forEach(value => {
    if(typeof value[1] === "string") {
      options.push({ label: value[1], value: parseInt(value[0]) })
    }
    else {
      options.push({ label: value[1].display, value: parseInt(value[0]) })
    }
  })
  if (sortOptions) {
    options.sort((a, b) => {
      if (sortOptions === 'asc') {
        return a.label.localeCompare(b.label)
      } else {
        return b.label.localeCompare(a.label)
      }
    })
  }

  return <Controller
    name={name}
    control={control}
    render={({ field: { onChange: controllerOnChange, value = "" }, fieldState: { error } }) => {

      return <Autocomplete
        dataTestId={dataTestId}
        name={name}
        label={label}
        options={options}
        onChange={(e, newValue) => {
          if (onChange) {
            onChange(freeSolo ? newValue : newValue?.value)
          }

          if (freeSolo)
          {
            // This state happens when Tab is used to select an option.
            if(options.find(o => o.value === newValue?.value))
            {
              controllerOnChange(newValue?.label)
            }
            else
            {
              // This state happens when the user types in a value.
              controllerOnChange(newValue)
            }
          }
          else
          {
            controllerOnChange(newValue?.value)
          }
        }}
        getOptionLabel={(option) => option?.label || option || ""}
        isOptionEqualToValue={(option, value) => {
          return option?.value === value?.value
        }}
        value={freeSolo ? value : findOption(value, options)}
        sx={sx}
        freeSolo={freeSolo}
        autoSelect={true}
        disabled={disabled}
        error={error}
        helperText={error?.message}
      />
    }}
  />
}