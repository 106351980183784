import React, { useEffect } from "react"
import { Route, Routes } from 'react-router-dom'
import { Billing as BillingSection } from '../apps/billing/Billing'
import UnsubmittedForm from '../apps/billing/components/UnsubmittedForm'
import AssessedClaims from "../apps/billing/components/AssessedClaims";
import { usePageTitleContext } from "../providers/PageTitleContextProvider"

export const Billing = () => {
  const { setAreaTitle } = usePageTitleContext()

  useEffect(() => {
    setAreaTitle('Billing')
  }, [setAreaTitle])

  return (
    <Routes>
      <Route path={'/'} element={<BillingSection />}>
        <Route
          path={'c/:claim_id'}
          element={<UnsubmittedForm />}
        />

        <Route path={'assessed'}>
          <Route
            path={'c/:claim_id'}
            element={
              <AssessedClaims />
            }
          />
          <Route
            index
            element={
              <AssessedClaims />
            }
          />
        </Route>

        <Route index element={<UnsubmittedForm />} />
      </Route>
    </Routes>
  )
}
