import React, { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'saga-library/src/components/Form'
import { EncounterNotesForm } from './EncounterNotesForm'
import { EncounterNoteInput } from '../../../../types/patients'
import { encounterNotesFormDefaultValues } from './EncounterNotesFormDefaultValues'
import { schema } from './EncounterNotesFormValidationSchema'
import { PatientSectionHeader } from '../PatientSectionHeader'
import { Box, useTheme } from '@mui/material'
import { ConfirmationDialog, LoadingButton, RemoveButton, Section, Typography } from 'saga-library/src'
import { useNavigate, useParams } from 'react-router-dom'
import { useAccountContext } from '../../../../providers/AccountContext'
import { usePrompt } from '../../../../providers/NavigationPrompt'
import { useEncounterNoteContext } from "../../providers/EncounterNoteProvider";
import { cleanEncounterNoteInput, convertEncounterNoteToInput } from "./EncounterNoteUtil";
import { flushSync } from "react-dom";
import { Permission, PermissionType } from "../../../../types/settings/Permission";
import { useQuery } from "@apollo/client";
import _get from "lodash/get";
import { SignedIcon } from "../ChartIcons";
import { GET_ENCOUNTER_NOTE } from "../../../../graphql-definitions";
import { LoadingOverlay } from "../../../../components/LoadingScreen";
import { HasPermissionTemplate } from '../../../../components/HasPermissionTemplate'
import { useFormattedDate } from '../../../../hooks/FormattedDate'
import PermissionButton from '../../../../components/PermissionButton'

const ENCOUNTER_NOTES_FORM_NAME = "encounter_notes_form"

export const EncounterNotesEditor = () => {
  const theme = useTheme()
  const { enableNavigationPrompt, clearNavigationPrompt } = usePrompt()
  const navigate = useNavigate()
  const { buildTenantRoute, userHasPermission, userId } = useAccountContext()
  const { patient_id, tenant_id, encounterNote_id} = useParams()
  const [isSigning, setIsSigning] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const { createEncounterNote, updateEncounterNote, deleteEncounterNote } = useEncounterNoteContext()

  const hasChartPermissions = (tenant_id && userHasPermission(tenant_id, PermissionType.Chart, Permission.READWRITE))

  const formMethods = useForm<EncounterNoteInput>({
    defaultValues: encounterNotesFormDefaultValues,
    schema: schema
  })

  const {
    formState: { dirtyFields, isSubmitting },
    handleSubmit,
    setValue,
    reset
  } = formMethods

  const { data, loading } = useQuery(GET_ENCOUNTER_NOTE, {
    skip: !encounterNote_id || isDeleting,
    variables: {
      tenantId: tenant_id,
      patientId: patient_id,
      id: encounterNote_id,
    },
    fetchPolicy: 'cache-and-network',
    onError: error => {
      console.error(JSON.stringify(error, null, 2))
    }
  })

  const encounterNote = _get(data, 'tenant.patient.encounterNote.get', null)

  const isSigned = !!encounterNote?.signOffUser?.id
  const signedDate = useFormattedDate(encounterNote?.signOffPit, true)

  useEffect(() => {
    if (!encounterNote) {
      return
    }

    const encounterNoteInput = convertEncounterNoteToInput(encounterNote)

    reset(encounterNoteInput)
  }, [encounterNote])

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, ENCOUNTER_NOTES_FORM_NAME)
    return () => enableNavigationPrompt(false, ENCOUNTER_NOTES_FORM_NAME)
  }, [Object.keys(dirtyFields).length]);

  const onSuccess = useCallback((encounterNoteResult)=> {
    setIsSigning(false)
    flushSync(() => {
      clearNavigationPrompt(ENCOUNTER_NOTES_FORM_NAME)
    })
    navigate(buildTenantRoute(`patients/p/${patient_id}/encounter-notes/e/${encounterNoteResult.id}`, tenant_id), {replace: true})
  }, [patient_id])

  const onSubmitCreate = handleSubmit(async (data) => {
    const encounterNoteData = cleanEncounterNoteInput(data)

    await createEncounterNote(encounterNoteData, onSuccess)
  })

  const onSubmitEdit = handleSubmit(async (data) => {
    const encounterNoteData = cleanEncounterNoteInput(data)

    await updateEncounterNote(encounterNoteData, () => {
      reset({}, { keepValues: true })
    })
  })

  const onSign = handleSubmit(async (data) => {
    setIsSigning(true)

    const message = "Encounter note has been saved and signed"

    let encounterNoteData = cleanEncounterNoteInput(data)
    encounterNoteData.signOffUserId = userId

    if (!encounterNote) {
      await createEncounterNote(encounterNoteData, onSuccess, message)
    } else {
      await updateEncounterNote(encounterNoteData, () => {
        reset({}, { keepValues: true })
        setIsSigning(false)
      }, message)
    }
  })

  const onUnsign = handleSubmit(async (data) => {
    setValue("signOffUserId", null)

    let encounterNoteData = cleanEncounterNoteInput(data)
    encounterNoteData.signOffUserId = null

    await updateEncounterNote(encounterNoteData, () => {
      reset({}, { keepValues: true })
    }, "Encounter note can now be edited")
  })

  const onDelete = async(encounterNoteId) => {
    setIsDeleting(true)
    await deleteEncounterNote(encounterNoteId, () => {
      flushSync(() => {
        clearNavigationPrompt(ENCOUNTER_NOTES_FORM_NAME)
      })
      navigate(buildTenantRoute(`patients/p/${patient_id}/encounter-notes`, tenant_id))
    }, () => {
      setIsDeleting(false)
    })
  }

  const getHeaderActions = () => {
    if (isSigned) {
      return (
        <Box display={"flex"} alignItems={"center"} flex={"0 0 auto"} gap={3}>
          <Box display={"flex"} alignItems={"center"} flexShrink={0} gap={1}>
            <SignedIcon sx={{ color: theme.palette.greys.medium }} />
            <Typography
              color={theme.palette.greys.medium}
              fontWeight={"bold"}
              lineHeight={"16px"}
              textTransform={"uppercase"}
            >
              Signed on {signedDate}
            </Typography>
          </Box>
          <EditEncounterNoteConfirmation onEdit={onUnsign} isSubmitting={isSubmitting} />
        </Box>
      )
    }

    return (
      <>
        {encounterNote && (
          <DeleteEncounterNoteConfirmation onDelete={() => onDelete(encounterNote.id)} />
        )}
        <SignButton
          onSign={onSign}
          isSubmitting={isSubmitting}
          isSigning={isSigning}
        />
      </>
    )
  }

  const onBack = () => {
    navigate(buildTenantRoute(`patients/p/${patient_id}/encounter-notes`, tenant_id), {replace: true})
  }

  return (
    <Section.Column
      sx={{ flex: "1 1 100%" }}
      header={
        <PatientSectionHeader
          dataTestId={'encounter-note-section-header'}
          sectionLabel={!!encounterNote || loading
            ? (encounterNote?.template?.name || encounterNote?.description)
            : "New encounter note"
          }
          showBackButton={true}
          onBack={onBack}
          formName={ENCOUNTER_NOTES_FORM_NAME}
          showSave={!!hasChartPermissions && !isSigned}
          disableSave={isSigning}
          submitting={isSubmitting}
          actions={getHeaderActions()}
        />
      }>
      <FormProvider {...formMethods}>
        <LoadingOverlay loading={loading} message={null} />
        <EncounterNotesForm
          onSubmit={encounterNote ? onSubmitEdit : onSubmitCreate}
          formName={ENCOUNTER_NOTES_FORM_NAME}
          formDisabled={!hasChartPermissions || isSigned}
          encounterNote={encounterNote}
          sx={{ 'paddingRight': '8px' }}
          dataTestId={"encounterNotesForm"}
        />
      </FormProvider>
    </Section.Column>
  )
}

export const SignButton = ({ onSign, isSubmitting, isSigning }) => {
  return (
    <PermissionButton
      variant={"outlined"}
      display={"inline-flex"}
      startIcon={<SignedIcon/>}
      name={"encounter-note-sign"}
      requiredType={PermissionType.Chart}
      requiredPermission={Permission.READWRITE}
      loading={isSubmitting && isSigning}
      disabled={isSubmitting}
      onClick={onSign}
      dataTestId={"encounter-note-sign-button"}
    >
      Sign
    </PermissionButton>
  )
}

export const DeleteEncounterNoteConfirmation = ({onDelete}) => {
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)

  return (
    <HasPermissionTemplate requiredType={PermissionType.Chart} requiredPermission={Permission.READWRITE}>
      <RemoveButton
        onClick={() => setOpenDeleteConfirmation(true)}
        dataTestId={'encounter-note'}
      />
      <ConfirmationDialog
        open={openDeleteConfirmation}
        title={'Delete encounter note?'}
        message={"This action cannot be undone."}
        primaryAction={() => {
          setOpenDeleteConfirmation(false)
          onDelete()
        }}
        primaryLabel={'delete'}
        onClose={() => setOpenDeleteConfirmation(false)}
        dataTestId={'delete-encounter-note-confirmation'}
      />
    </HasPermissionTemplate>
  )
}

export const EditEncounterNoteConfirmation = ({ onEdit, isSubmitting }) => {
  const [openEditConfirmation, setOpenEditConfirmation] = useState(false)

  return (
    <HasPermissionTemplate requiredType={PermissionType.Chart} requiredPermission={Permission.READWRITE}>
      <LoadingButton
        variant={'outlined'}
        name={'encounter-note-edit'}
        loading={isSubmitting}
        onClick={() => setOpenEditConfirmation(true)}
        dataTestId={'encounter-note-edit-button'}
      >
        Edit
      </LoadingButton>
      <ConfirmationDialog
        open={openEditConfirmation}
        title={'Edit signed encounter note?'}
        message={'This encounter note will no longer be signed.'}
        primaryAction={() => {
          setOpenEditConfirmation(false)
          onEdit()
        }}
        primaryLabel={'edit'}
        onClose={() => setOpenEditConfirmation(false)}
        dataTestId={'edit-encounter-note-confirmation'}
      />
    </HasPermissionTemplate>
  )
}