import { gql } from "@apollo/client";


export const ALLERGY_FRAGMENT = gql`
  fragment AllergyFragment on Allergy {
    id
    allergen
    reactionType
    onsetDate
    reactionDescription
    severity
    version
    patientId
    audit {
      crePit
    }
  }
`

export const LIST_ALLERGIES = gql`
  ${ALLERGY_FRAGMENT}
  query ListAllergies($tenantId: ID!){
    tenant(id: $tenantId){
      allergy{
        list{
          ...AllergyFragment
        }
      }
    }
  }
`

export const GET_PATIENT_ALLERGIES = gql`
  ${ALLERGY_FRAGMENT}
  query GetPatientAllergies($patientId: ID!, $tenantId: ID!) {
    tenant(id: $tenantId) {
      patient(id: $patientId) {
        allergy {
          list {
            ...AllergyFragment
          }
        }
      }
    }
  }
`


export const CREATE_ALLERGY = gql`
  ${ALLERGY_FRAGMENT}
  mutation CreateAllergy($tenantId: ID!, $input: AllergyInput!) {
    tenant(id: $tenantId) {
      patient {
        allergy {
          create(input: $input) {
            ...AllergyFragment
          }
        }
      }
    }
  }
`

export const UPDATE_ALLERGY = gql`
  ${ALLERGY_FRAGMENT}
  mutation UpdateAllergy($tenantId: ID!, $id: ID!, $input: AllergyInput!) {
    tenant(id: $tenantId) {
      patient{
        allergy {
          update(allergyId: $id, input: $input) {
            ...AllergyFragment
          }
        }
      }
    }
  }
`

export const DELETE_ALLERGY = gql`
  mutation DeleteAllergy($tenantId: ID!, $id: ID!) {
    tenant(id: $tenantId) {
      patient{
        allergy {
          delete(allergyId: $id)
          }
        }  
      }    
    }
`