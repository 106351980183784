import { FormType } from "../../../../../types/patients";
import DialogV2 from "saga-library/src/components/Dialog/DialogV2";
import { FormsForm } from "./FormsForm";
import React, { useEffect, useState } from "react";
import PermissionButton from "../../../../../components/PermissionButton";
import { Permission, PermissionType } from "../../../../../types/settings/Permission";
import { FormsStateProvider } from "./FormsStateProvider";
import { DeleteFormConfirmation } from "../FormsEditor";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { formsFormDefaultValues } from "./FormsFormDefaultValues";
import { schema } from "./FormsFormValidationSchema";
import { usePrompt } from "../../../../../providers/NavigationPrompt";
import { usePatientFormContext } from "../../../providers/PatientFormProvider";

const FORM_NAME = "patient_form_modal"

interface FormModalProps {
  open: boolean
  onModalClose: () => void
  form?: FormType|null
}
export const FormModal = ({ form, open, onModalClose}: FormModalProps) => {
  const { createForm, updateForm, deleteForm } = usePatientFormContext()
  const { enableNavigationPrompt } = usePrompt()
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)

  const formMethods = useForm<FormType>({
    defaultValues: formsFormDefaultValues,
    schema: schema,
  })

  const {
    formState: { dirtyFields, isSubmitting },
    reset,
  } = formMethods

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        onClose()
      }
    }
  }

  useEffect(() => {
    if(open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onClose = () => {
    onModalClose()
    reset(formsFormDefaultValues)
  }

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      onClose()
    }
  }

  const onSuccess = () => {
    enableNavigationPrompt(false, FORM_NAME)
    onClose()
  }

  const onSubmitCreate = async (data) => {
    await createForm(data, onSuccess)
  }

  const onSubmitEdit = async (data) => {
    await updateForm(data, onSuccess)
  }

  const onDelete = async (formId) => {
    await deleteForm(formId, onSuccess)
  }

  return (
    <DialogV2
      title={form ? form.name : "New form"}
      size={"lg"}
      open={open}
      onClose={onCancel}
      primaryAction={()=>null}
      overridePrimaryComponent={
        <PermissionButton
          name={"saveForm"}
          type={"submit"}
          form={FORM_NAME}
          requiredType={PermissionType.Chart}
          requiredPermission={Permission.READWRITE}
          loading={isSubmitting}
          dataTestId={"formsModal-save-button"}
        >
          Save
        </PermissionButton>
      }
      headerActions={form ?
        [<DeleteFormConfirmation onDelete={() => onDelete(form.id)} />]
        : []
      }
      dataTestId={"formsModal"}
      preventScroll={true}
      contentSx={{
        display: "flex",
        flexDirection: "column",
        paddingRight: 0,
        flex: "1 1 850px"
      }}
    >
      <FormProvider {...formMethods}>
        <FormsStateProvider>
          <FormsForm
            onSubmit={form ? onSubmitEdit : onSubmitCreate}
            formName={FORM_NAME}
            form={form}
          />
        </FormsStateProvider>
      </FormProvider>
    </DialogV2>
  )
}