import _get from "lodash/get";
import { ControlledSearch } from "saga-library/src";
import {
  ControlledSearchControlProps
} from "saga-library/src/components/SearchControl/ControlledSearchControl";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { FacilityResult } from "./FacilityResult";
import { GET_RECENT_FACILITY, GET_SEARCH_FACILITY } from "../../../graphql-definitions";
import { Facility } from "../../../types/billing";

export interface FacilitySearchProps<
  T = Facility,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
  > extends Omit<ControlledSearchControlProps<
  T,
  Multiple,
  DisableClearable,
  FreeSolo
  >, 'queries'|'render'|'transform'|'isOptionEqualToValue'> {
  serviceDate?: Date,
  practitionerId?: string
  disabled?: boolean
}

export const FacilitySearch = ({
  name = 'facilityId',
  label = 'Facility',
  serviceDate = new Date(),
  practitionerId = '',
  disabled = false,
  ...props
} :FacilitySearchProps) => {
  const { tenant_id } = useParams()

  const searchQueryVariables = useMemo(() => ({
    tenantId: tenant_id,
    serviceDate: serviceDate
  }), [tenant_id, serviceDate])

  const initialQueryVariables = useMemo(() => ({
    tenantId: tenant_id,
    searchPractitionerId: practitionerId,
    serviceDate: serviceDate
  }), [tenant_id, practitionerId, serviceDate])

  return (
    <ControlledSearch<Facility, false, false, false>
      {...props}
      name={name}
      label={label}
      disabled={disabled}
      queries={{
        search: {
          gql: GET_SEARCH_FACILITY,
          get: (data) => _get(data, 'tenant.search.abClaim.facilities', []) as Facility[],
          variables: searchQueryVariables,
          fetchPolicy: 'network-only'
        },
        initial: {
          gql: GET_RECENT_FACILITY,
          get: (data) => _get(data, 'tenant.search.abClaim.recentFacilities', []) as Facility[],
          variables: initialQueryVariables,
          fetchPolicy: 'cache-first'
        }
      }}
      render={{
        value: (facility) => facility.description,
        option: (facility, state) => (
          <FacilityResult
            facility={facility}
            searchText={state.inputValue}
            dataTestId={props.dataTestId}
          />
        )
      }}
    />
  )
}
