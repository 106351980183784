import React, { createRef, useRef } from "react";
import { PDFDoc } from "../../../../../components/FoxitPDFViewer/FoxitTypes";
import { SharedFile } from "support-client/src/types/SharedFile";
import { DocumentProvider } from "../../../../../providers/DocumentProvider";

interface PatientFormStateContextType {
  selectedFile: SharedFile | null,
  setSelectedFile: (file: SharedFile | null) => void,
  fileModified: boolean,
  setFileModified: (modified: boolean) => void,
  pdfDocRef?: React.MutableRefObject<PDFDoc | null>,
  loadingFile: boolean,
  setLoadingFile: (loading: boolean) => void,
}

const defaultPatientFormContext: PatientFormStateContextType = {
  selectedFile: null,
  setSelectedFile: () => {},
  fileModified: false,
  setFileModified: () => {},
  pdfDocRef: createRef(),
  loadingFile: false,
  setLoadingFile: () => {},
}

const PatientFormStateContext = React.createContext(defaultPatientFormContext)

export const FormsStateProvider = ({ children }) => {
  const [selectedFile, setSelectedFile] = React.useState<SharedFile | null>(null)
  const [fileModified, setFileModified] = React.useState<boolean>(false);
  const pdfDocRef = useRef<PDFDoc | null>(null)
  const [loadingFile, setLoadingFile] = React.useState<boolean>(false)

  const providerValue : PatientFormStateContextType = {
    selectedFile,
    setSelectedFile,
    fileModified,
    setFileModified,
    pdfDocRef,
    loadingFile,
    setLoadingFile,
  }

  return (
    <PatientFormStateContext.Provider value={providerValue}>
      <DocumentProvider>
        {children}
      </DocumentProvider>
    </PatientFormStateContext.Provider>
  )
}

export const usePatientFormStateContext = () => {
  return React.useContext(PatientFormStateContext)
}
