import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  MenuItem,
  useTheme
} from "@mui/material";
import { FormProvider, useForm, useWatch } from "saga-library/src/components/Form";
import { DialogV2, Select, Typography } from "saga-library/src";
import { BlockType, ScheduleLength } from "../../../types/schedule/Schedule";
import { useParams } from "react-router-dom";
import { APPLY_TEMPLATE, GET_TEMPLATE, LIST_TEMPLATES, REMOVE_TEMPLATE } from "../../../graphql-definitions";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Eventcalendar, formatDate,
  MbscCalendarColor,
  MbscCalendarEvent,
  MbscCalendarEventData,
  momentTimezone
} from "@mobiscroll/react";
import _get from "lodash/get";
import moment from "moment-timezone";
import { parseTime, processEvents } from "../util/scheduleFunctions";
import { appendEvent, getDayBlockedTimes, transformToMbscCalendarColor } from "../util/templateFunctions";
import { Event } from "./ScheduleCalendar/ScheduleBlockEvent";
import { useScheduleContext } from "../../../providers/ScheduleContextProvider";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { Permission, PermissionType } from "../../../types/settings/Permission";
import { useAccountContext } from "../../../providers/AccountContext";
import PermissionButton from "../../../components/PermissionButton";
import { useTenantContext } from "../../../providers/TenantContextProvider";
import { CONDENSED_ROW_HEIGHT } from "../../../utils/ScheduleConstants";
import { addDurationToDate } from "saga-library/src/util";

type EventCalendarData = {
  events: MbscCalendarEvent[]
  bookingPreferences: MbscCalendarColor[]
  closedTimes: MbscCalendarColor[]
}

interface ApplyTemplateDialogProps {
  open: boolean
  handleClose: () => void
  scheduleDate: string
  onComplete: () => void
  selectedTemplateId?: number
}

export const ApplyTemplateDialog = ({
  open,
  handleClose,
  scheduleDate,
  onComplete,
  selectedTemplateId
}: ApplyTemplateDialogProps) => {
  const { tenant_id } = useParams()
  const { showSuccessAlert, showErrorAlert } = useAlerts()
  const { selectedScheduleIds, scheduleView } = useScheduleContext()
  const theme = useTheme();
  const { userHasPermission } = useAccountContext()
  const hasReadWrite = tenant_id && userHasPermission(tenant_id, PermissionType.Schedule, Permission.READWRITE)
  const { getTenantSettings, tenantSettingsKeys } = useTenantContext();
  const { timezone } = getTenantSettings([tenantSettingsKeys.TIMEZONE])

  const noTemplate = { id: 'noTemplate', name: 'No template', templateDays: [] }
  const [showNoTemplateNote, setShowNoTemplateNote] = useState<boolean>(false)
  type ApplyTemplateType = {
    length: ScheduleLength
    template: string
  }
  const [applyTemplateDefaults, setApplyTemplateDefaults] = useState<ApplyTemplateType>({length: ScheduleLength.DAY, template: ''})
  const [appliedDate, setAppliedDate] = useState<string>(moment(scheduleDate).format('MMMM D, YYYY'))

  const formatAppliedDate = (viewLength) => {
    if (viewLength === ScheduleLength.WEEK) {
      setAppliedDate(moment(scheduleDate).day(0).format('MMMM D, YYYY') + ' - ' + moment(scheduleDate).day(6).format('MMMM D, YYYY'))
      setApplyTemplateDefaults({length: ScheduleLength.WEEK, template: ''})
    } else {
      setAppliedDate(moment(scheduleDate).format('MMMM D, YYYY'))
      setApplyTemplateDefaults({length: ScheduleLength.DAY, template: ''})
    }
  }
  
  const [ templates, setTemplates ] = useState<any[]>([])
  const [ allTemplates, setAllTemplates ] = useState<any[]>([])
  const [ eventCalendarData, setEventCalendarData ] = useState<EventCalendarData>({
    events: [],
    bookingPreferences: [],
    closedTimes: []
  })

  const {loading}  = useQuery(LIST_TEMPLATES, {
    variables: { tenantId: tenant_id },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
    onCompleted: (data) => {
      setAllTemplates(_get(data, 'tenant.schedule.listTemplates'))
    },
    fetchPolicy: 'cache-and-network'
  })

  const filteredTemplates = () => {
    if (allTemplates.length > 0) {
      let templateList = allTemplates.filter(t => t.templateDays.length === 7)
      if (scheduleView.view === ScheduleLength.DAY) {
        templateList = allTemplates.filter(t => t.templateDays.length === 1)
      }
      if (selectedTemplateId && !templateList.some(t => t.id === selectedTemplateId) && allTemplates.some(t => t.id === selectedTemplateId)) {
        setTemplates([noTemplate, ...templateList, allTemplates.find(t => t.id === selectedTemplateId)])
      } else {
        setTemplates([noTemplate, ...templateList])
      }
    }
  }

  const formMethods = useForm<ApplyTemplateType>({
    defaultValues: applyTemplateDefaults
  })

  const { control, handleSubmit, reset, setValue, formState: {isSubmitting} } = formMethods

  const templateId = useWatch({
    control,
    name: 'template'
  })

  const templateLength = useWatch({
    control,
    name: 'length'
  })

  const safeClose = () => {
    reset(applyTemplateDefaults)
    setShowNoTemplateNote(false)
    setEventCalendarData({
      events: [],
      bookingPreferences: [],
      closedTimes: []
    })
    handleClose()
  }

  useEffect(() => {
    if(allTemplates.length > 0) {
      filteredTemplates()
    }
  }, [allTemplates])

  useEffect(() => {
    if (scheduleView.view) {
      if (scheduleView.view !== formMethods.getValues().length) {
        setValue('length', scheduleView.view)
        filteredTemplates()
      }
    }
  }, [scheduleView.view])

  const [ getTemplateDefault ] = useLazyQuery(GET_TEMPLATE, {
    onError: error => {
      console.error(JSON.stringify(error, null, 2))
    },
    onCompleted: data => {
      const templateData = _get(data, 'tenant.schedule.template', null)
      if (templateData.templateDays.length === 1) {
        reset({template: templateData.id, length: ScheduleLength.DAY})
      } else {
        reset({template: templateData.id, length: ScheduleLength.WEEK})
      }
    }
  })

  const [ getTemplate ] = useLazyQuery(GET_TEMPLATE, {
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
    onCompleted: (data) => {
      const template = _get(data, 'tenant.schedule.template', null)
      if (template) {

        let templateDays = template?.templateDays?.flat()
        const templateBlocks = templateDays?.map((templateDay) => templateDay.templateBlocks).flat()

        let bookingPreferences : MbscCalendarColor[] = []
        let closedTimes : MbscCalendarColor[] = []
        let events : MbscCalendarEvent[] = []

        templateDays?.forEach((templateDay) => {
          closedTimes.push(...getDayBlockedTimes(templateDay, applyTemplateDefaults.length, timezone as string))
        })

        if (template.templateDays.length !== 1) {
          // Week implementation
          templateDays?.forEach((templateDay) => {
            let bookingPreferenceTemplateBlocks = templateDay.templateBlocks?.filter((templateBlock) => templateBlock.blockType === BlockType.BOOKING_PREFERENCE)
            const day = templateDay.day
            bookingPreferenceTemplateBlocks?.forEach((bookingPreference) => {
              const today = moment()
              const date = today.day(day)

              let startTime = moment(parseTime(bookingPreference.startTimeSpan, date))

              let start = startTime.toDate()
              let end = addDurationToDate(moment.duration(bookingPreference.length, 'minutes'), startTime).toDate()
              bookingPreferences.push(transformToMbscCalendarColor(bookingPreference, start, end))
            })

            let eventTemplateBlocks = templateDay.templateBlocks?.filter((templateBlock) => templateBlock.blockType === BlockType.EVENT)
            eventTemplateBlocks?.forEach((event) => {
              const today = moment()
              const date = today.day(day)

              let startTime = moment(parseTime(event.startTimeSpan, date))

              let start = startTime.toDate()
              let end = addDurationToDate(moment.duration(event.length, 'minutes'), startTime).toDate()
              appendEvent(events, event, start, end, day)
            })
          })

        } else {
          const bookingPreferenceTemplateBlocks = templateBlocks?.filter((templateBlock) => templateBlock.blockType === BlockType.BOOKING_PREFERENCE)
          bookingPreferenceTemplateBlocks?.forEach((bookingPreference) => {
            let startTime = moment(parseTime(bookingPreference.startTimeSpan, new Date()))

            let start = startTime.toDate()
            let end = addDurationToDate(moment.duration(bookingPreference.length, 'minutes'), startTime).toDate()

            bookingPreferences.push(transformToMbscCalendarColor(bookingPreference, start, end))
          })

          const eventTemplateBlocks = templateBlocks?.filter((templateBlock) => templateBlock.blockType === BlockType.EVENT)
          eventTemplateBlocks?.forEach((event) => {
            let startTime = moment(parseTime(event.startTimeSpan, new Date()))

            let start = startTime.toDate()
            let end = addDurationToDate(moment.duration(event.length, 'minutes'), startTime).toDate()
            appendEvent(events, event, start, end, 0)
          })
        }

        setEventCalendarData({
          bookingPreferences: bookingPreferences,
          events: events,
          closedTimes: closedTimes
        })
      }
    }
  })

  useEffect(() => {
    if (selectedTemplateId && allTemplates.some(t => t.id === selectedTemplateId)) {
      getTemplateDefault({
        variables: {
          tenantId: tenant_id,
          templateId: selectedTemplateId
        }
      })
    }
  }, [selectedTemplateId, allTemplates])

  useEffect(() => {
    if(templateId && templates.length > 0) {
      if (templateId === noTemplate.id) {
        setEventCalendarData({
          events: [],
          bookingPreferences: [],
          closedTimes:[]
        })
        setShowNoTemplateNote(true)
      } else {
        setValue('length', templates.find(t => t.id === templateId)?.templateDays.length === 1 ? ScheduleLength.DAY : ScheduleLength.WEEK)
        getTemplate({
          variables: {
            tenantId: tenant_id,
            templateId: templateId
          }
        })
        setShowNoTemplateNote(false)
      }
    }
  }, [templateId, templates])

  useEffect(() => {
    if (templateLength === ScheduleLength.DAY) {
      formatAppliedDate(ScheduleLength.DAY)
    } else if (scheduleView.view === 'day') {
      // even if ScheduleLength is a week, change will only occur on the specific day
      formatAppliedDate(ScheduleLength.DAY)
    } else {
      formatAppliedDate(ScheduleLength.WEEK)
    }
  }, [templateLength, scheduleDate])

  const scheduleEvent = (data: MbscCalendarEventData) => {
    const eventData = data.original

    switch(eventData?.blockType) {
      case BlockType.EVENT:
        return <Event eventData={data}/>
      default:
        return null
    }
  }

  const [ applyTemplate, {loading: submittingApplyTemplate} ]  = useMutation(APPLY_TEMPLATE, {
    onCompleted: (data) => {
      onComplete()
      safeClose()
      showSuccessAlert('Template has been applied.')
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert("Template couldn't be applied.")
    }
  })

  const [ removeTemplate ]  = useMutation(REMOVE_TEMPLATE, {
    onCompleted: (data) => {
      onComplete()
      safeClose()
      showSuccessAlert('Template has been removed.')
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert('Failed to remove template.')
    }
  })

  const onSubmit = handleSubmit(async (data) => {
    if(isSubmitting || submittingApplyTemplate) {
      return
    }
    if (templateId === noTemplate.id) {
      // if schedule view is day, only remove from that day (no matter if it is a week template)
      let startDate = scheduleDate
      let endDate = scheduleDate
      // if schedule view is week, only remove from entire week if it is a week template
      if (scheduleView.view === 'week' && templateLength === ScheduleLength.WEEK) {
        startDate = moment(scheduleDate).day(0).format('MMMM D, YYYY')
        endDate = moment(scheduleDate).day(6).format('MMMM D, YYYY')
      }
      await removeTemplate({
        variables: {
          tenantId: tenant_id,
          scheduleId: selectedScheduleIds[0],
          startDate: startDate,
          endDate: endDate
        }
      })
    } else {
      await applyTemplate({
          variables: {
          tenantId: tenant_id,
          scheduleId: selectedScheduleIds[0],
          templateId: templateId,
          scheduleDate: scheduleDate
        }
      })
    }
  })

  const displayNote = () => {
    let phrase = "This template will be applied to "
    let backgroundColor = `rgba(32, 41, 49, 0.08)`
    let icon = <InfoOutlinedIcon sx={{ color: `rgba(32, 41, 49, 0.64)` }}/>
    let note = <Typography variant={'p4'}>Applying a template will remove existing booking preferences.</Typography>
    if (showNoTemplateNote) {
      phrase = "Default start and end times will be applied to "
      backgroundColor = theme.palette.warning.main
      icon = <WarningAmberIcon sx={{ color: 'white' }}/>
      note = <Typography variant={'p4'} sx={{ color: 'white', fontWeight: '400' }}>Applying <b>No template</b> will remove all templates and booking preferences for the listed days.</Typography>
    }
    return (
      <>
        <Typography variant={'h4'}>{phrase}<b>{appliedDate}</b></Typography>
        <Box
          display={'flex'}
          gap={1}
          sx={{
            p:1,
            alignItems: 'center',
            width: 'fit-content',
            borderRadius: '8px',
            backgroundColor: backgroundColor,
            margin: '8px',
          }}
        >
          {icon}
          {note}
        </Box>
      </>
    )
  }

  const dayHeader = (args) => {
    const {date} = args

    let bgColor = 'transparent'
    let labelColor = theme.palette.greys.medium
    let labelWeight = 'default'

    return (
      <Box>
        <Box bgcolor={ bgColor } borderRadius={2}>
          <Typography
            color={ labelColor }
            fontWeight={ labelWeight }
          >
            {`${formatDate('DDDD', date)}`}
          </Typography>
        </Box>
      </Box>
    )
  }

  const ApplyTemplateForm = () => {
    return (
      <Box
        display={'flex'}
        flexDirection={'column'}
      >
        <Select
          dataTestId={"applyTemplate-selectTemplate"}
          label={'Template'}
          name={'template'}
          sx={{width: '284px'}}
          disabled={!hasReadWrite}
          >
          {loading && (
            <MenuItem>
              <CircularProgress />
            </MenuItem>
          )}
          {templates && templates.map(({id, name}, index) =>
            <MenuItem data-testid={`applyTemplate-selectTemplate-menuItem-${index}`} key={id} value={id}>{name}</MenuItem>
          )}
        </Select>
        <Box
          display={'flex'}
          flexDirection={'column'}
          sx={{
            alignItems: 'center'
          }}
        >
          { displayNote() }
        </Box>
        <Box
          sx={{
            width: '100%',
            ...(scheduleView.view === 'day' && {
              '& .mbsc-schedule-header': {
                display: 'none'
              },
            }),
            '& .mbsc-calendar-controls': {
              display: 'none'
            },
            "& .mbsc-schedule-time-wrapper":{
              height: CONDENSED_ROW_HEIGHT
            },
            "& .mbsc-schedule-item":{
              height: CONDENSED_ROW_HEIGHT
            },
            overflow: 'hidden'
          }}
        >
        <Eventcalendar
          data={processEvents(eventCalendarData?.events)}
          colors={[...eventCalendarData?.closedTimes, ...eventCalendarData?.bookingPreferences] ?? []}
          renderScheduleEvent={scheduleEvent}
          showEventTooltip={false}
          renderDay={dayHeader}
          view={{
            schedule: {
              type: formMethods.getValues().length,
            }
          }}
          theme={'material'}
          themeVariant={'light'}
          dataTimezone={'utc'}
          displayTimezone={timezone as string}
          timezonePlugin={momentTimezone}
        />
        </Box>
      </Box>
    )
  }

  return (
    <DialogV2
      open={open}
      onClose={safeClose}
      title={`Apply ${scheduleView.view} template`}
      overridePrimaryComponent={
        <PermissionButton
          dataTestId={"applyTemplate-save"}
          name={'save'}
          onClick={onSubmit}
          requiredType={PermissionType.Schedule}
          requiredPermission={Permission.READWRITE}
          loading={isSubmitting || submittingApplyTemplate}
          type={'submit'}
        >
          SAVE
        </PermissionButton>
      }
    >
      <FormProvider {...formMethods}>
        <ApplyTemplateForm/>
      </FormProvider>
    </DialogV2>
  )
}
