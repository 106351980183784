import React, { useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_APPOINTMENT_REPORT,
  CREATE_BILLING_REPORT,
  CREATE_PATIENT_REPORT,
  LIST_REPORTS,
  LIST_BILLING_REPORTS,
  LIST_PATIENT_AND_APPOINTMENT_REPORTS,
  UPDATE_APPOINTMENT_REPORT,
  UPDATE_BILLING_REPORT,
  UPDATE_PATIENT_REPORT,
  DELETE_APPOINTMENT_REPORT,
  DELETE_BILLING_REPORT,
  DELETE_PATIENT_REPORT
} from '../graphql-definitions'
import { useParams } from 'react-router-dom'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { ReportCategoryType, ReportInputDataType, ReportInputType, ReportType } from '../types/Report'
import { convertColumnInputsToColumns, convertParameterInputsToParameters } from '../apps/reports/components/ReportUtil'
import _get from 'lodash/get'
import { useAccountContext } from './AccountContext'
import { Permission, PermissionType } from '../types/settings/Permission'

const reportQueryCategories = {
  "all": {
    listReportQuery: LIST_REPORTS,
    listUrl: 'tenant.report.list'
  },
  "billing": {
    listReportQuery: LIST_BILLING_REPORTS,
    listUrl: 'tenant.report.listBilling'
  },
  "patientAndAppointment": {
    listReportQuery: LIST_PATIENT_AND_APPOINTMENT_REPORTS,
    listUrl: 'tenant.report.listPatientAndAppointment'
  }
}

interface ReportContextInterface {
  reports: ReportType[]
  createReport: (inputReport: ReportInputType, onSuccess: (returnData: ReportType) => void) => Promise<void>
  updateReport: (inputReport: ReportInputType, onSuccess: (returnData: ReportType)  => void) => Promise<void>
  deleteReport: (reportId: string, category: ReportCategoryType, onSuccess: () => void) => Promise<void>
}

const defaultReportContext: ReportContextInterface = {
  reports: [],
  createReport: (inputReport: ReportInputType, onSuccess: (returnData: ReportType) => void) => Promise.resolve(),
  updateReport: (inputReport: ReportInputType, onSuccess: (returnData: ReportType) => void) => Promise.resolve(),
  deleteReport: (reportId: string, category: ReportCategoryType, onSuccess: () => void) => Promise.resolve()
}

const ReportContext = React.createContext<ReportContextInterface>(defaultReportContext)

export const ReportProvider = ({children}) => {
  const { tenant_id } = useParams()
  const { userHasPermission } = useAccountContext()
  const { showErrorAlert, showSuccessAlert } = useAlerts()

  const billingPermission = userHasPermission(tenant_id || "", PermissionType.ReportingBilling, Permission.READONLY)
  const patientPermission = userHasPermission(tenant_id || "", PermissionType.ReportingPatientAndAppointment, Permission.READONLY)

  const { listReportQuery, listUrl } = useMemo(() => {
    if (billingPermission && !patientPermission) {
      return reportQueryCategories["billing"]
    } else if (!billingPermission && patientPermission) {
      return reportQueryCategories["patientAndAppointment"]
    }
    return reportQueryCategories["all"]
  }, [billingPermission, patientPermission])

  const [ createAppointmentReportMutation ] = useMutation(CREATE_APPOINTMENT_REPORT)
  const [ createBillingReportMutation ] = useMutation(CREATE_BILLING_REPORT)
  const [ createPatientReportMutation ] = useMutation(CREATE_PATIENT_REPORT)
  const [ updateAppointmentReportMutation ] = useMutation(UPDATE_APPOINTMENT_REPORT)
  const [ updateBillingReportMutation ] = useMutation(UPDATE_BILLING_REPORT)
  const [ updatePatientReportMutation ] = useMutation(UPDATE_PATIENT_REPORT)
  const [ deleteAppointmentReportMutation ] = useMutation(DELETE_APPOINTMENT_REPORT)
  const [ deleteBillingReportMutation ] = useMutation(DELETE_BILLING_REPORT)
  const [ deletePatientReportMutation ] = useMutation(DELETE_PATIENT_REPORT)

  const { loading, data } = useQuery(listReportQuery, {
    skip: !billingPermission && !patientPermission,
    variables: { tenantId: tenant_id },
    fetchPolicy: 'cache-first',
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert("Reports couldn't be retrieved.")
    }
  })

  const reports = _get(data, listUrl, [])

  const createReport = async (inputReport: ReportInputType, onSuccess) => {
    let input: ReportInputDataType = {
      ...inputReport,
      columns: convertColumnInputsToColumns(inputReport.columns),
      parameters: convertParameterInputsToParameters(inputReport.parameters)
    }

    if (!input.category) {
      return
    }

    const reportMutationCategories = {
      [ReportCategoryType.APPOINTMENT]: {
        createReportMutation: createAppointmentReportMutation,
        resultUrl: 'tenant.report.createAppointment'
      },
      [ReportCategoryType.BILLING]: {
        createReportMutation: createBillingReportMutation,
        resultUrl: 'tenant.report.createBilling'
      },
      [ReportCategoryType.PATIENT]: {
        createReportMutation: createPatientReportMutation,
        resultUrl: 'tenant.report.createPatient'
      }
    }

    const { createReportMutation, resultUrl } = reportMutationCategories[input.category]

    await createReportMutation({
      variables: {
        tenantId: tenant_id,
        input: input
      },
      onCompleted: (data) => {
        const reportResult = _get(data, resultUrl, null)
        showSuccessAlert("Report has been saved")
        onSuccess(reportResult)
      },
      onError: () => {
        showErrorAlert("Report couldn't be created")
      },
      update: (cache, { data }) => {
        const newReport = _get(data, resultUrl, null)
        updateReportCache(cache, newReport, tenant_id, listUrl)
      }
    })
  }

  const updateReport = async (inputReport: ReportInputType, onSuccess) => {
    let input: ReportInputDataType = {
      ...inputReport,
      columns: convertColumnInputsToColumns(inputReport.columns),
      parameters: convertParameterInputsToParameters(inputReport.parameters)
    }

    if (!input.category) {
      return
    }

    const reportMutationCategories = {
      [ReportCategoryType.APPOINTMENT]: {
        updateReportMutation: updateAppointmentReportMutation,
        resultUrl: 'tenant.report.updateAppointment'
      },
      [ReportCategoryType.BILLING]: {
        updateReportMutation: updateBillingReportMutation,
        resultUrl: 'tenant.report.updateBilling'
      },
      [ReportCategoryType.PATIENT]: {
        updateReportMutation: updatePatientReportMutation,
        resultUrl: 'tenant.report.updatePatient'
      }
    }

    const { updateReportMutation, resultUrl } = reportMutationCategories[input.category]

    const reportId = input.id
    delete input.id

    await updateReportMutation({
      variables: {
        tenantId: tenant_id,
        id: reportId,
        input: input
      },
      onCompleted: (data) => {
        const reportResult = _get(data, resultUrl, null)
        showSuccessAlert("Report has been saved")
        onSuccess(reportResult)
      },
      onError: () => {
        showErrorAlert("Report couldn't be saved")
      }
    })
  }

  const deleteReport = async (reportId: string, category: ReportCategoryType, onSuccess) => {
    const reportMutationCategories = {
      [ReportCategoryType.APPOINTMENT]: deleteAppointmentReportMutation,
      [ReportCategoryType.BILLING]: deleteBillingReportMutation,
      [ReportCategoryType.PATIENT]: deletePatientReportMutation
    }

    const deleteReportMutation = reportMutationCategories[category]

    await deleteReportMutation({
      variables: {
        tenantId: tenant_id,
        id: reportId
      },
      onCompleted: () => {
        showSuccessAlert("Report has been deleted")
        onSuccess()
      },
      onError: () => {
        showErrorAlert("Report couldn't be deleted")
      },
      update: (cache) => {
        const normalizedId = cache.identify({
          id: reportId,
          __typename: "Report",
          isReference: false
        })
        cache.evict({ id: normalizedId })
        cache.gc()
      }
    })
  }

  const providerValues = {
    reports,
    createReport,
    updateReport,
    deleteReport
  }

  return (
    <ReportContext.Provider value={providerValues}>
      {children}
    </ReportContext.Provider>
  )
}

export const useReportContext = () => {
  return React.useContext(ReportContext)
}

const updateReportCache = async(cache, newReport, tenantId, listUrl) => {
  await cache.updateQuery({
    query: LIST_REPORTS,
    variables: {
      tenantId
    }
  }, (data) => {
    const existingReports = _get(data, listUrl, []).filter(r => r.id !== newReport.id)

    return {
      tenant: {
        report: {
          list: [...existingReports, newReport]
        }
      }
    }
  })
}