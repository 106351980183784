import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { GET_TEMPLATE, LIST_TEMPLATES, UPDATE_TEMPLATE } from "../../../graphql-definitions";
import _get from "lodash/get";
import { TemplateForm } from "./TemplateForm";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { LoadingSpinner } from "../../../components/LoadingScreen";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { useAccountContext } from "../../../providers/AccountContext";
import { TemplateInput, TemplateMbscCalendarColor, TemplateMbscCalendarEvent } from "../../../types/schedule/Template";
import { omit } from "lodash";
import { BlockType, ScheduleLength } from "../../../types/schedule/Schedule";
import { MbscCalendarEvent } from "@mobiscroll/react";
import {
  cleanTemplateDayForInput,
  getDayBlockedTimes,
  getTemplateBookingPreference,
  getTemplateEvent
} from "../util/templateFunctions";
import omitDeep from "omit-deep-lodash";
import { schema } from "../util/templateValidation";
import { Form } from 'saga-library/src'
import { useTenantContext } from "../../../providers/TenantContextProvider";
import { usePrompt } from "../../../providers/NavigationPrompt";
import { flushSync } from "react-dom";

export interface TemplateDataProps {
  events:TemplateMbscCalendarEvent[],
  bookingPreferences:TemplateMbscCalendarColor[],
  length:ScheduleLength,
  blockedTimes:MbscCalendarEvent[]
}

const defaultDailyHours = {
  startTime: "PT8H",
  endTime: "PT17H"
}

const FORM_NAME = 'edit_schedule_template_form'

export const EditTemplate = () => {
  const { getTenantSettings, tenantSettingsKeys } = useTenantContext()
  const { timezone } = getTenantSettings([tenantSettingsKeys.TIMEZONE])
  const { tenant_id, template_id } = useParams()
  const { showSuccessAlert, showErrorAlert } = useAlerts()
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()
  const { enableNavigationPrompt, clearNavigationPrompt } = usePrompt()
  const [initialTemplateData, setInitialTemplateData] = useState<TemplateDataProps>({
    bookingPreferences: [],
    events: [],
    length: ScheduleLength.DAY,
    blockedTimes: []
  })

  const templateDefaults = {
    name: '',
    version: "0",
    isDeleted: false,
    templateDays: [
      {
        ...defaultDailyHours,
        day: 0,
        templateBlocks: []
      }
    ],
  }

  const formMethods = useForm<TemplateInput>({
    defaultValues: templateDefaults,
    schema: schema
  })

  const {
    handleSubmit,
    formState: { isSubmitSuccessful, dirtyFields },
    reset
  } = formMethods

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true, keepDefaultValues: true })
    }
  }, [isSubmitSuccessful])

  const [updateTemplate, { loading: loadingUpdate }] =
    useMutation(UPDATE_TEMPLATE, {
      onCompleted: (data) => {
        flushSync(() => {
          clearNavigationPrompt(FORM_NAME)
        })
        const updatedTemplate = _get(data, 'tenant.schedule.updateTemplate', null)
        processTemplateData(updatedTemplate)
        showSuccessAlert('Template has been saved.')
        navigate(buildTenantRoute(`schedule/templates/t/${updatedTemplate.id}`, tenant_id))
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Changes couldn\'t be saved.')
      }
    })

  const { loading: loadingTemplate, data: templateData } = useQuery(GET_TEMPLATE, {
    variables: {
      tenantId: tenant_id,
      templateId: template_id
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert("Template couldn't be loaded.")
    },
    onCompleted: (data) => {
      const template = _get(data, 'tenant.schedule.template', null)
      if (template) {
        processTemplateData(template)
      }
    },
    fetchPolicy: 'cache-and-network'
  })

  const processTemplateData = (initialTemplateDatax) => {
    if (initialTemplateDatax) {
      if (initialTemplateDatax.templateDays.length === 0) {
        console.error("Template does not contain appropriate number of days. Number of days expect is 1 or 7. Number of days received: " + initialTemplateDatax.templateDays.length)
        showErrorAlert("Template couldn't be loaded.")
        return
      }

      let cleanedData = omitDeep(
        omit(initialTemplateDatax, 'id', 'version'),
        '__typename'
      )
      const tempTemplateLength = (cleanedData.templateDays.length > 1) ? ScheduleLength.WEEK : ScheduleLength.DAY
      const events: TemplateMbscCalendarEvent[] = []
      const bookingPreferences: TemplateMbscCalendarColor[] = []
      const tempBlockedTimes: MbscCalendarEvent[] = []

      cleanedData.templateDays.forEach(templateDay => {
        templateDay.isOpen = (!!templateDay.startTime || !!templateDay.endTime)
        if (!templateDay.isOpen) {
          templateDay.startTime = defaultDailyHours.startTime
          templateDay.endTime = defaultDailyHours.endTime
        }

        tempBlockedTimes.push(...getDayBlockedTimes(templateDay, tempTemplateLength, timezone as string))

        templateDay.templateBlocks.forEach(block => {
          block.localId = crypto.randomUUID()

          if (block.blockType === BlockType.EVENT) {
            events.push(getTemplateEvent(block, tempTemplateLength))
          } else if (block.blockType === BlockType.BOOKING_PREFERENCE) {
            bookingPreferences.push(getTemplateBookingPreference(block, tempTemplateLength))
          }
        })
      })

      setInitialTemplateData({
        events: events,
        bookingPreferences: bookingPreferences,
        length: tempTemplateLength,
        blockedTimes: tempBlockedTimes
      })
      reset(cleanedData)
    }
  }

  const template = _get(templateData, 'tenant.schedule.template', null)

  const onSubmit = handleSubmit(async (data, event) => {
    if (event && event.target.name !== FORM_NAME) {
      return
    }

    data.version = template.version + 1
    data.isDeleted = false
    data.templateDays.forEach(templateDay => {
      cleanTemplateDayForInput(templateDay)
    })

    await updateTemplate({
      variables: {
        tenantId: tenant_id,
        id: template_id,
        templateInput: data
      },
      refetchQueries: [
        {
          query: LIST_TEMPLATES,
          variables: {
            tenantId: tenant_id,
          },
        },
      ],
    })
  })

  if (loadingTemplate || loadingUpdate) {
    return <LoadingSpinner />
  }

  return (
    <FormProvider {...formMethods}>
      <Form
        id={FORM_NAME}
        name={FORM_NAME}
        style={{
          height: '100%',
        }}
        onSubmit={onSubmit}
      >
        <TemplateForm
          formName={FORM_NAME}
          title={`Edit template (${template?.name})`}
          templateData={initialTemplateData}
        />
      </Form>
    </FormProvider>
  )
}

