import React, { useState } from 'react'
import { ConfirmationDialog, Radio, SimpleRadioGroup, DialogV2 } from "saga-library/src";
import { AbClaimAcceptCode, ABClaimActionCode } from 'utils/ABClaimConstants';


interface DialogProps {
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  accept: (any?) => void,
}

interface DialogActionsProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  accept: () => void,
  confirmActionLabel: string,
  cancelMessage?: string
}

interface AssessedClaimDialogProps {
  writeOffProps: DialogProps
  reassessmentWarningProps: DialogProps
  deletionWarningProps: DialogProps
  cancelResubmissionProps: DialogProps
  cancelWriteOffProps: DialogProps
  batchMode: boolean
}

export const AssessedClaimDialogs = ({
  writeOffProps,
  reassessmentWarningProps,
  deletionWarningProps,
  cancelResubmissionProps,
  cancelWriteOffProps,
  batchMode,
} : AssessedClaimDialogProps) => {

  const writeoffStyling = {
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      lineHeight: '24px',
    }
  }
  const [writeoff, setWriteoff] = useState(AbClaimAcceptCode.WRONG_DOCTOR_OR_PATIENT)

  return (
    <>
      <DialogV2
        open={writeOffProps.open}
        title={batchMode ? 'Write off claims' : 'Write off claim'}
        onClose={() => writeOffProps.setOpen(false)}
        primaryAction={() => writeOffProps.accept(writeoff)}
        primaryLabel={'Write off'}
      >
        <SimpleRadioGroup
          row={false}
          label={batchMode ? 'Select a reason for writing off these claims:' : 'Select a reason for writing off this claim:'}
          name={''}
          value={writeoff}
          onChange={(e) => setWriteoff(e.currentTarget.value)}
        >
          <Radio label={'Wrong practitioner or patient'} value={AbClaimAcceptCode.WRONG_DOCTOR_OR_PATIENT} sx={writeoffStyling}/>
          <Radio label={'Previously paid or included in another province'} value={AbClaimAcceptCode.PREVIOUSLY_PAID_OR_INCLUDED_IN_ANOTHER_SERVICE} sx={writeoffStyling}/>
          <Radio label={'Bill directly to patient or third party'} value={AbClaimAcceptCode.BILLED_TO_PATIENT_OR_THIRD_PARTY} sx={writeoffStyling}/>
          <Radio label={'Billed to alternate BA number'} value={AbClaimAcceptCode.BILLED_TO_ALTERNATE_BA_NUMBER} sx={writeoffStyling}/>
          <Radio label={'Write-off: uncollectable'} value={AbClaimAcceptCode.WRITE_OFF_OR_UNCOLLECTABLE} sx={writeoffStyling}/>
        </SimpleRadioGroup>
      </DialogV2>

      <ConfirmationDialog
        open={reassessmentWarningProps.open}
        onClose={() => reassessmentWarningProps.setOpen(false)}
        title={'Resubmitting a claim for reassessment'}
        message={'When resubmitting a claim for reassessment, only text is updated. All other claim fields that have been modified will not be updated.'}
        primaryAction={() => {
          reassessmentWarningProps.setOpen(false)
          reassessmentWarningProps.accept()
        }}
        primaryLabel={'Resubmit'}
      />
      <ConfirmationDialog
        open={deletionWarningProps.open}
        onClose={() => deletionWarningProps.setOpen(false)}
        title={'Resubmitting a claim for deletion'}
        message={'When resubmitting a claim for deletion, no fields are updated. All claim fields that have been modified will not be  updated.'}
        primaryAction={() => {
          deletionWarningProps.setOpen(false)
          deletionWarningProps.accept()
        }}
        primaryLabel={'Resubmit'}
      />
      <ConfirmationDialog
        open={cancelResubmissionProps.open}
        onClose={() => cancelResubmissionProps.setOpen(false)}
        closeLabel={'No'}
        title={'Cancel resubmission?'}
        message={'This claim will not be resubmitted to ACHIP and will revert back to its original assessed state, including all fields that have been modified.'}
        primaryAction={() => {
          cancelResubmissionProps.setOpen(false)
          cancelResubmissionProps.accept()
        }}
        primaryLabel={'Cancel resubmission'}
      />
      {!batchMode &&
        <ConfirmationDialog
          open={cancelWriteOffProps.open}
          onClose={() => cancelWriteOffProps.setOpen(false)}
          closeLabel={'No'}
          title={'Cancel write off?'}
          message={'You will be able to resubmit this claim to AHCIP again.'}
          primaryAction={() => {
            cancelResubmissionProps.setOpen(false)
            cancelWriteOffProps.accept()
          }}
          primaryLabel={'Cancel write off'}
        />
      }
    </>
  )
}