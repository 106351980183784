import React, { useCallback, useMemo } from 'react'
import _get from "lodash/get";
import { useParams } from "react-router-dom";
import { SearchControl } from "saga-library/src";
import { GET_SEARCH_DIAGNOSTICCODES, LIST_RECENT_CONDITION_AND_PROCEDURES } from "../../../graphql-definitions";
import { AbClaimDiagnosticCode } from "../../../types/billing";
import { ConditionAndProcedureType } from "../../../types/ConditionAndProcedure";
import { SxProps } from '@mui/material'
import { Theme } from '@mui/system'
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid'
import {
  ConditionAndProcedureRenderer,
  DescriptionEqual
} from './ConditionAndProcedureDescriptionSearch'

export interface ConditionAndProcedureDescriptionGridSearchProps {
  name: string
  dataTestId: string
  placeholderText?: string
  label?: string
  sx?: SxProps<Theme>
  params: GridRenderEditCellParams
}

export const ConditionAndProcedureDescriptionGridSearch = ({
  name,
  dataTestId,
  placeholderText='',
  label = '',
  sx,
  params
} :ConditionAndProcedureDescriptionGridSearchProps) => {

  const { tenant_id } = useParams()
  const serviceDate = new Date()

  const searchQueryVariables = useMemo(() => ({
    tenantId: tenant_id,
    serviceDate: serviceDate
  }), [tenant_id])

  const initialQueryVariables = useMemo(() => ({
    tenantId: tenant_id
  }), [tenant_id])

  const apiRef = useGridApiContext();
  const handleChange = useCallback((event: React.SyntheticEvent<Element, Event>, newValue: any) => {
    event.stopPropagation();
    apiRef.current.setEditCellValue({ id: params.id, field: params.field, value: newValue });
  }, [params.id, params.field]);

  const getValue = useCallback(() => {
    if (params.value)
      return params.value;
    return null;
  }, [params.value]);

  return (
    <SearchControl<AbClaimDiagnosticCode|string, false, false, true>
      freeSolo
      autoSelect
      dataTestId={dataTestId}
      name={name}
      label={label}
      placeholder={placeholderText}
      value={getValue()}
      error={false}
      onChange={handleChange}
      onInputChange={(event, value) => (event) && handleChange(event, value)}
      queries={{
        search: {
          gql: GET_SEARCH_DIAGNOSTICCODES,
          get: (data) => _get(data, 'tenant.search.abClaim.diagnosticCodes', [] as AbClaimDiagnosticCode[]),
          variables: searchQueryVariables,
          fetchPolicy: 'network-only'
        },
        initial: {
          gql: LIST_RECENT_CONDITION_AND_PROCEDURES,
          get: (data) => {
            let recentConditions = _get(data, "tenant.recentConditionAndProcedures", []) as ConditionAndProcedureType[];
            return recentConditions.map((condition) => {
              return {
                id: condition.id,
                description: condition.description,
                diagnosticCode: condition.diagnosticCode?.diagnosticCode
              } as AbClaimDiagnosticCode
            })
          },
          variables: initialQueryVariables,
          fetchPolicy: 'cache-and-network'
        }
      }}
      render={ConditionAndProcedureRenderer}
      isOptionEqualToValue={DescriptionEqual}
      sx={sx}
    />
  )
}
