import React from "react";
import { useParams } from "react-router-dom";
import { QueryReference, useBackgroundQuery } from "@apollo/client";
import { GET_PATIENT_APPOINTMENTS } from "../../../graphql-definitions/tenant/patient/AppointmentQueries";
import _get from "lodash/get";
import { PatientAppointment } from "../../../types/patients";

const getAppointmentQueryResults = (data) => {
  return [..._get(data, 'tenant.patient.appointment.list', []) as Array<PatientAppointment>]
}

interface AppointmentContextInterface {
  appointmentQueryRef: QueryReference
  getAppointmentQueryResults: (any) => PatientAppointment[]
}

const defaultAppointmentContext: AppointmentContextInterface = {
  appointmentQueryRef: {} as QueryReference,
  getAppointmentQueryResults: getAppointmentQueryResults
}


const AppointmentContext = React.createContext(defaultAppointmentContext)

export const AppointmentProvider = ({ children }) => {
  const { tenant_id, patient_id } = useParams()

  const [appointmentQueryRef] = useBackgroundQuery(GET_PATIENT_APPOINTMENTS, {
    variables: {
      patientId: patient_id,
      tenantId: tenant_id
    }
  })

  const providerValues = {
    appointmentQueryRef,
    getAppointmentQueryResults
  }

  return (
    <AppointmentContext.Provider value={providerValues}>
      { children }
    </AppointmentContext.Provider>
  )
}

export const useAppointmentContext = () => {
  return React.useContext(AppointmentContext)
}