import { Menu, MenuItem, SxProps, useTheme } from "@mui/material";
import React, { useState } from "react";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Button from "../Button/Button";
import { Theme } from "@mui/system";

interface ButtonDropdownOption {
  name: string
  onClick: () => void
}

interface ButtonDropdownProps {
  title: string
  options: ButtonDropdownOption[]
  variant?: 'outlined' | 'contained'
  sx?: SxProps<Theme>
  dataTestId?: string
}

export const ButtonDropdown = ({title, options, variant='contained', sx, dataTestId} : ButtonDropdownProps) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selected, setSelected] = useState<ButtonDropdownOption | null>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return <>
    <Button
      dataTestId={dataTestId}
      name={title}
      aria-controls={open ? 'customized-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      variant={variant}
      onClick={handleClick}
      sx={{
        paddingRight: '10px',
        paddingLeft: '16px',
        ...sx
      }}
    >
      {title}
      <ExpandMore data-testid={`${dataTestId}-expandMore`}/>
    </Button>
    <Menu
      data-testid={`${dataTestId}-menu`}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      MenuListProps={{
        'aria-labelledby': 'customized-button',
        disablePadding: true
      }}
      sx={{
        "& .Mui-selected": {
          backgroundColor: `${theme.palette.backgrounds.selected} !important`
        },
        "& .Mui-selected:hover": {
          backgroundColor: `${theme.palette.backgrounds.selectedHover} !important`
        },
      }}
    >
      {options.map((option, index) => {
        return (
          <MenuItem
            data-testid={`${dataTestId}-menuItem-${index}`}
            selected={option.name === selected?.name}
            onClick={() => {
              setSelected(option)
              option.onClick()
              handleClose()
            }}
            key={option.name}
            sx={{
              "&:hover": {
                backgroundColor: theme.palette.backgrounds.hover,
              },
              justifyContent: "center",
            }}
          >
            {option.name}
          </MenuItem>
        )
      })}
    </Menu>
  </>
}

export default ButtonDropdown