import { gql } from "@apollo/client";

export const GET_BILLING_DATA = gql`
  query GetBillingData ($tenantId: ID!, $patientId: ID!){
    tenant(id: $tenantId){
      patient(id: $patientId){
        get{
          id
          firstName
          lastName
          diagnosticCodes{
            diagnosticCode
            id
          }
          serviceCode{
            description
            serviceCode
            id
          }
        }
      }
    }
  }
`

export const ADD_NEW_PATIENT = gql`
  mutation AddNewPatient($tenantId: ID!, $patientData: PatientInput!) {
    tenant(id: $tenantId) {
      patient {
        createPatient(patientData: $patientData)
      }
    }
  }
`

const LABEL_ADDRESS_DETAILS_FRAGMENT = gql`
  fragment PatientResultAddressDetails on PatientResult {
    street
    street2
    street3
    city
    province
    postalCode
    country
  }
`

const LABEL_PERSONAL_DETAILS_FRAGMENT = gql`
  fragment PatientResultPersonalDetails on PatientResult {
    lastName
    firstName
    middleName
    gender {
      name
    }
    dob
  }
`

export const GET_PATIENT_LABEL = gql`
    ${LABEL_PERSONAL_DETAILS_FRAGMENT}
    ${LABEL_ADDRESS_DETAILS_FRAGMENT}
    query GetPatientLabel($tenantId: ID!, $patientId: ID!) {
        tenant(id: $tenantId) {
            patient(id: $patientId) {
                label {
                    id
                    primaryIdentifier
                    primaryIdentifierLabel
                    primaryPhoneNumber
                    email
                    patientNotes {
                      id
                      noteText
                    }
                    ...PatientResultPersonalDetails
                    ...PatientResultAddressDetails
                }
            }
        }
    }
`

export const GET_PATIENT_SCHEDULE_LABEL = gql`
    ${LABEL_PERSONAL_DETAILS_FRAGMENT}
    ${LABEL_ADDRESS_DETAILS_FRAGMENT}
    query GetPatientScheduleLabel($tenantId: ID!, $patientId: ID!) {
        tenant(id: $tenantId) {
            patient(id: $patientId) {
                label {
                    id
                    primaryIdentifier
                    primaryIdentifierLabel
                    primaryPractitioner {
                      firstName
                      lastName
                    }
                    email
                    phones {
                      id
                      number
                      extension
                      type
                      isPrimary
                      notes
                    }
                    ...PatientResultPersonalDetails
                    ...PatientResultAddressDetails
                }
            }
        }
    }
`