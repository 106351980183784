import React, { useState } from 'react'
import { useSagaAuthentication } from '../providers/Auth'
import Avatar from '@mui/material/Avatar'
import { Divider, useTheme } from "@mui/material";
import {
  AppBar,
  TenantSelectionDialog,
  MenuSection,
  MenuFooter,
  PrivacyAndTerms,
  LoadingButton,
  Typography,
  MenuItem,
  Button,
} from "saga-library/src";
import logo from '../assets/SagaHealth-Icon-White.png'
import Box from '@mui/material/Box'
import { useAccountContext } from '../providers/AccountContext'
import { useParams, useNavigate, NavLink } from "react-router-dom";
import { GlobalSearchBar } from './GlobalSearchBar'
import { MainAppBarMenuRow } from "saga-library/src/components/AppBarMenu/AppBarMenu";
import { getTenantStorageKey } from "../config/LocalStorageKeys";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import ScheduleIcon from "@mui/icons-material/CalendarMonthOutlined";
import { HasPermissionTemplate } from "./HasPermissionTemplate";
import { Permission, PermissionType } from "../types/settings/Permission";
import AnalyticsIcon from "@mui/icons-material/AnalyticsOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import ReceiptLong from "@mui/icons-material/ReceiptLongOutlined";
import InboxOutlinedIcon from "@mui/icons-material/InboxOutlined";
import { AccountModal } from "../apps/account/AccountModal";

interface MainAppBarProps {
  rightMenu?: Boolean
  centerMenu?: Boolean
  search?: Boolean
  displayName?: Boolean
  clinicName?: Boolean
}

export const MainAppBar = ({
  rightMenu = false,
  centerMenu = false,
  search = false,
  displayName,
  clinicName,
}: MainAppBarProps) => {
  const { performSignOut, isAuthenticated } = useSagaAuthentication()
  const navigate = useNavigate()
  const [tenantDialogOpen, setTenantDialogOpen] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [accountModalOpen, setAccountModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { buildTenantRoute, tenants, userHasTenant, getTenantName, userId, userFirstName, userLastName, isSupportUser } =
    useAccountContext()
  const { tenant_id } = useParams()
  const tenantName = getTenantName(tenant_id)
  const theme = useTheme()

  const userDisplayName = isAuthenticated
    ? userLastName + ', ' + userFirstName
    : ''

  const handleSignout = () => {
    setLoading(true)
    performSignOut()
      .then(() => {
        setLoading(false)
        navigate("/signin")
      })
  }

  const SettingsRoute = () => {
    return (
      <Box
        sx={{
          display: 'block',
          mr: 1,
          ml: 1,
          mt: '4px'
        }}
        key={'settings-route'}
      >
        <NavLink to={buildTenantRoute('settings', tenant_id)}>
          <SettingsIcon sx={{ color: "white" }} data-testid={'settings-icon'}/>
        </NavLink>
      </Box>
    )
  }

  const RightMenu = () => {
    return (
      <Box width={"280px"}>
        <MenuSection sx={{mt:0}}>
          <MainAppBarMenuRow sx={{mb: 2}}>
            <Avatar sx={{ m: 'auto', height: "72px", width: "72px", mb:"10px" }} />
            <MainAppBarMenuRow
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {userDisplayName}
            </MainAppBarMenuRow>
          </MainAppBarMenuRow>
          <MainAppBarMenuRow>
            <Button
              name={'account_button'}
              data-testid={'manage-account-button'}
              variant={'outlined'}
              onClick={() => {
                setAccountModalOpen(true)
                setMenuOpen(false)
              }}
            >
              Manage your Account
            </Button>
            <AccountModal
              title={userDisplayName}
              onClose={()=>setAccountModalOpen(false)}
              open={accountModalOpen}
              dataTestId={"accountDialog"}
            />
          </MainAppBarMenuRow>
        </MenuSection>
        <Divider />

        {userHasTenant(tenant_id) && (
          <>
            <MenuSection>
              <MainAppBarMenuRow>
                <Box sx={{ color: theme.palette.greys.medium, fontWeight: 500, fontSize: '14px' }}>
                  You're currently accessing
                </Box>
              </MainAppBarMenuRow>
              <MainAppBarMenuRow sx={{ fontWeight: 700 }}>
                {tenantName ? tenantName : tenant_id}
              </MainAppBarMenuRow>
              {tenants.length > 1 && !isSupportUser && (
                <MainAppBarMenuRow>
                  <Button
                    name={'switch-clinics-button'}
                    variant={'outlined'}
                    dataTestId={'switch-clinics-button'}
                    onClick={() => {
                      setMenuOpen(false)
                      setTenantDialogOpen(true)
                    }}
                  >
                    Switch clinics
                  </Button>
                </MainAppBarMenuRow>
              )}
            </MenuSection>
            <Divider />
          </>
        )}
        <MenuFooter>
          <MainAppBarMenuRow>
            <LoadingButton
              dataTestId={'sign-out-button'}
              name={'SignOutButton'}
              loading={loading}
              variant={'outlined'}
              onClick={handleSignout}>
              Sign Out
            </LoadingButton>
          </MainAppBarMenuRow>
          <PrivacyAndTerms>
            <Box data-testid={'privacy-policy'} onClick={() => navigate('/privacy-policy')}>
              <Typography variant={'body2'} >
                Privacy policy
              </Typography>
            </Box>
            <Box data-testid={'terms-of-service'} onClick={() => navigate('/terms-of-service')}>
              <Typography variant={'body2'} >
                Terms of service
              </Typography>
            </Box>
          </PrivacyAndTerms>
        </MenuFooter>
      </Box>
    )
  }

  return (
    <>
      <AppBar
        displayName={displayName ? userDisplayName : undefined}
        clinicName={clinicName ? tenantName : undefined}
        logo={logo}
        logoNav={() => navigate('/')}
        menuOpen={menuOpen}
        setMenuOpen={(open) => setMenuOpen(open)}
        searchBar={search && <GlobalSearchBar />}
        rightMenu={rightMenu && <RightMenu />}
        settingsRoute={<SettingsRoute />}
        routes={ centerMenu && [
          <MenuRoute
            dataTestId={'side-menu-dashboard'}
            text={'Dashboard'}
            Icon={HomeIcon}
            route={buildTenantRoute('', tenant_id)}
          />,
          <MenuRoute
            dataTestId={'side-menu-schedule'}
            text={'Schedule'}
            Icon={ScheduleIcon}
            route={buildTenantRoute('schedule', tenant_id)}
          />,
          <MenuRoute
            dataTestId={'side-menu-tasks'}
            text={'Inbox'}
            Icon={InboxOutlinedIcon}
            route={buildTenantRoute('inbox', tenant_id)}
          />,
          <HasPermissionTemplate requiredType={PermissionType.Billing} requiredPermission={Permission.READONLY} >
            <MenuRoute
              dataTestId={'side-menu-billing'}
              text={'Billing'}
              Icon={ReceiptLong}
              route={buildTenantRoute('billing', tenant_id)}
            />
          </HasPermissionTemplate>,
          <HasPermissionTemplate
            requiredType={[PermissionType.ReportingBilling, PermissionType.ReportingPatientAndAppointment]}
            requiredPermission={Permission.READONLY}
          >
            <MenuRoute
              dataTestId={'side-menu-reports'}
              text={'Reports'}
              Icon={AnalyticsIcon}
              route={buildTenantRoute('reports', tenant_id)}
            />
          </HasPermissionTemplate>,
        ]}
      />
      <TenantSelectionDialog
        dataTestId={'tenantSelectionDialog'}
        tenants={tenants}
        activeTenant={tenant_id ? tenant_id : null}
        open={tenantDialogOpen}
        onClose={() => setTenantDialogOpen(false)}
        selectionHandler={(id) => {
          localStorage.setItem(getTenantStorageKey(userId), id)
          navigate(buildTenantRoute('', id))
        }}
      />
    </>
  )
}

const MenuRoute = ({ route, text, Icon, dataTestId }) => {
  return (
    <div className={'menu-route'}>
      <NavLink
        to={route}
        style={{
          display: 'block',
          textDecoration: 'none',
        }}
      >
        {({ isActive }) => (
          <MenuItem dataTestId={dataTestId} isActive={isActive} Icon={Icon} text={text} />
        )}
      </NavLink>
    </div>
  )
}