import React from 'react'
import { TableListRowConfig } from 'saga-library/src'
import { useTheme } from "@mui/material";
import { AbClaimProblemSeverity } from "../../../utils/ABClaimConstants";
import { ErrorIcon, HoldIcon, ReadyIcon } from "./ClaimStatusIcons";
import { AbClaim } from '../../../types/billing';
import { useFormattedDate } from "../../../hooks/FormattedDate";
import { Typography } from "saga-library/src";
import { NameAndIdentifier } from "saga-library/src/components/TableList/CellBlocks/NameAndIdentifier";
import { patientDisplayName } from "saga-library/src/util";
import { practitionerDisplayName } from "saga-library/src/util/formatting";

export const UnsubmittedClaimRow = (
  claim: AbClaim,
  onRowClick: (claim:AbClaim) => void,
  onRowSelectionChange?: (claim: AbClaim) => void
): TableListRowConfig => {

  const theme = useTheme()

  const statusIcons: Record<string, React.ReactNode> = {
    READY: <ReadyIcon theme={theme} />,
    HOLD: <HoldIcon theme={theme} />,
    ERROR: <ErrorIcon theme={theme} />,
  }

  const renderStatusIcon = (claim) => {
    if (claim.problems?.some(p => p.severity === AbClaimProblemSeverity.ERROR)) {
      return statusIcons['ERROR']
    }
    return statusIcons[claim.claimStatus]
  }

  const claimRow = [
    {
      children: renderStatusIcon(claim),
    },
    {
      children: (
        <NameAndIdentifier
          name={patientDisplayName(claim.patient?.firstName!, claim.patient?.lastName!)}
          identifier={claim.patient?.primaryIdentifier}
        />
      )
    },
    {
      children: (
        <Typography variant={'body2'}>{useFormattedDate(claim.serviceDate)}</Typography>
      )
    },
    {
      children: (
        <Typography variant={'body2'}>{claim.serviceCode?.serviceCode}</Typography>
      )
    },
    {
      children: (
        <Typography variant={'body2'}>{claim.calls}</Typography>
      )
    },
    {
      children: (
        <Typography variant={'body2'}>{claim.diagnosticCodes?.map((dc) => {
          return dc?.diagnosticCode
        }).join(', ')}</Typography>
      )
    },
    {
      children:(
        <NameAndIdentifier
          name={practitionerDisplayName(claim.billingProfile?.practitionerFirstName, claim.billingProfile?.practitionerLastName)}
          identifier={claim.billingProfile?.name}
        />
      )
    },
    {
      children: (
        <NameAndIdentifier
          name={`${claim.facility?.description}`}
          identifier={claim.functionalCenter?.functionalCenterCode}
        />
      )
    },
    {
      children: (
        <Typography variant={'body2'} align={'right'} >{claim.claimAmount != null? `$${claim.claimAmount.toFixed(2)}` : ''}</Typography>
      )
    }
  ]

  return {
    onClick: () => { onRowClick(claim) },
    onRowSelectionChange: () => {
      if (onRowSelectionChange) {
        onRowSelectionChange(claim)
      }},
    rowData: claimRow,
    key: claim.id
  }
}
