import { DialogV2 } from "saga-library/src";
import {
  PrintPrescriptionButton,
  DeletePrescriptionButton,
  SavePrescriptionButton,
  CreateFavouriteButton
} from "../../prescriptions/components/PrescriptionActions";
import React, { useEffect, useState } from "react";
import { usePrompt } from "../../../../../providers/NavigationPrompt";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { PrescriptionStatus, PrescriptionType } from "../../../../../types/Prescription";
import { prescriptionFormDefaultValues } from "../../prescriptions/components/PrescriptionFormDefaultValues";
import { schema, requiredSchema } from "../../prescriptions/components/PrescriptionFormValidationsSchema";
import { usePrescriptionUtils } from "../../prescriptions/components/PrescriptionUtil";
import { PrescriptionForm } from "../../prescriptions/components/PrescriptionForm";
import { PrescriptionStatusTag } from "../../prescriptions/components/PrescriptionStatusTag";
import omitDeep from 'omit-deep-lodash'
import { RenewBanner } from "../../prescriptions/components/RenewBanner";
import { useParams } from "react-router-dom";
import { useAccountContext } from "../../../../../providers/AccountContext";
import { Permission, PermissionType } from "../../../../../types/settings/Permission";
import { usePrescriptionContext } from "../../../providers/PrescriptionProvider";

const FORM_NAME = "edit_prescription_form_dialog"

const EditPrescriptionDialog = ({ prescription, setPrescription , onNew: parentOnNew}) => {
  const { tenant_id } = useParams()
  const { deleteDraftPrescription, renewPrescription, inactivatePrescription } = usePrescriptionContext()
  const { enableNavigationPrompt } = usePrompt()
  const { userHasPermission } = useAccountContext()
  const { setAsHistorical, saveAsActive, saveAsDraft } = usePrescriptionUtils()

  const hasEditPermission = (tenant_id && userHasPermission(tenant_id, PermissionType.Chart, Permission.READWRITE))

  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean | string>(false)

  const formMethods = useForm<PrescriptionType>({
    defaultValues: prescriptionFormDefaultValues(),
    schema: schema,
    requiredSchema: requiredSchema
  })

  const {
    handleSubmit,
    formState: { dirtyFields },
    reset,
    getValues,
    setValue,
  } = formMethods

  useEffect(() => {
    if(prescription){
      reset(prescription)
    }
  }, [prescription]);

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      if (discard) {
        reset()
        setPrescription(null)
        if(typeof openNavigationPrompt === 'string' && openNavigationPrompt === 'new'){
          parentOnNew()
        }
      }
      setOpenNavigationPrompt(false)
    }
  }

  useEffect(() => {
    if (prescription) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, !!openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [prescription, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onDelete = (prescriptionId) => {
    deleteDraftPrescription(prescriptionId, ()=>null)
    setPrescription(null)
  }

  const onSaveAsActive = (onComplete=()=>null) => {
    saveAsActive(
      setValue,
      handleSubmit,
      (updatedPrescription) => {
        enableNavigationPrompt(false, FORM_NAME)
        reset({},{keepValues: true})
        onComplete()
        setPrescription(null)
      }
    )
  }

  const onSubmit = (e) => {
    e.preventDefault()
    onSaveAsActive()
  }

  const onSaveAsDraft = (onComplete=()=>null) => {
    saveAsDraft(
      handleSubmit,
      (updatedPrescription) => {
        onComplete()
        enableNavigationPrompt(false, FORM_NAME)
        reset({},{keepValues: true})
        setPrescription(null)
      }
    )
  }

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      reset()
      setPrescription(null)
    }
  }
  const onNew = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt("new")
    } else {
      parentOnNew()
    }
  }

  const onRenew = (prescriptionId) => {
    renewPrescription(
      prescriptionId,
      (newPrescription)=> {
        setPrescription(newPrescription)
      }
    )
  }

  const onDeactivate = (prescription) => {
    inactivatePrescription(prescription, (prescription)=> {
      const inactivePrescription = omitDeep(prescription, '__typename')
      setPrescription(inactivePrescription)
    })
  }

  const onSetAsHistorical = () => {
    setAsHistorical(
      handleSubmit,
      (updatedPrescription) => {
        enableNavigationPrompt(false, FORM_NAME)
        reset({}, {keepValues: true})
        setPrescription(updatedPrescription)
      }
    )
  }

  const formDisabled = prescription?.status === PrescriptionStatus.ACTIVE || prescription?.status === PrescriptionStatus.INACTIVE

  if(!prescription) return null

  return <DialogV2
    dataTestId={'edit-prescription'}
    title={prescription.drug.name}
    open={!!prescription}
    onClose={onCancel}
    primaryAction={()=>null}
    secondaryLabel={hasEditPermission ? 'new' : undefined}
    secondaryAction={onNew}
    overridePrimaryComponent = {
      <SavePrescriptionButton
        prescription={prescription}
        formName={FORM_NAME}
        onSaveAsDraft={onSaveAsDraft}
        getValues={getValues}
        onSetAsInactive={onDeactivate}
        onSetAsHistorical={onSetAsHistorical}
      />
    }
    chips={[<PrescriptionStatusTag prescriptionStatus={prescription.status} />]}
    headerActions={[
      <CreateFavouriteButton prescription={prescription} handleSubmit={handleSubmit}/>,
      //TODO: Print needs to display Print dialog
      <PrintPrescriptionButton prescription={prescription} onSaveAsActive={onSaveAsActive} onPrint={()=>null}/>,
      <DeletePrescriptionButton prescription={prescription} onDelete={()=>onDelete(prescription.id)}/>
    ]}
  >
    <RenewBanner prescription={prescription} onRenew={ ()=>onRenew(prescription?.id)} />
    <FormProvider {...formMethods}>
      <PrescriptionForm
        formName={FORM_NAME}
        onSubmit={onSubmit} formDisabled={formDisabled}
        autoFocus={false}
      />
    </FormProvider>
  </DialogV2>
}

export default EditPrescriptionDialog