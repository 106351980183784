import React, { useState } from "react";
import { Box } from "@mui/material";
import { IconButton, Typography } from "saga-library/src";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { PatientNotesDialog } from "./PatientNotesDialog";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ClearIcon from "@mui/icons-material/Clear";
import { PatientSearchResult } from "../../../types/patients";
import { useFormContext } from "saga-library/src/components/Form";
import { useParams } from "react-router-dom";
import { useAccountContext } from "../../../providers/AccountContext";
import { patientDisplayName } from "saga-library/src/util";
import { IdentifierGroup } from "saga-library/src/components/SearchControl/blocks";
import { SxProps, Theme } from "@mui/material";
import { useFormattedDate} from '../../../hooks/FormattedDate'
import { NO_DATE_OF_BIRTH } from '../../FormattedAge'

const PatientEndAdornments = ({ patient, openPatientInNewTab, disabled, clearOnClick, refresh, dataTestId } ) => {
  const [notesOpen, setNotesOpen] = useState<boolean>(false)
  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'flex-end'}
    >
      {
        patient && patient.patientNotes?.length > 0 &&
        <>
          <IconButton
            dataTestId={`${dataTestId}-notes`}
            onClick={() => setNotesOpen(true)}
            icon={<DescriptionOutlinedIcon />}
          />
          <PatientNotesDialog
            patient={patient}
            open={notesOpen}
            setOpen={setNotesOpen}
          />
        </>
      }
      {
        patient &&
        <IconButton
          dataTestId={`${dataTestId}-open`}
          onClick={() => openPatientInNewTab(patient)}
          icon={<OpenInNewIcon />}
        />
      }
      { !disabled &&
        <IconButton
          dataTestId={`${dataTestId}-clear`}
          onClick={clearOnClick}
          icon={<ClearIcon />}
        />
      }
      { refresh }
    </Box>
  )
}

interface PatientLabelProps {
  patient: PatientSearchResult
  name: string
  disabled: boolean
  variant?: 'md' | 'sm'
  refresh?: React.ReactNode
  dataTestId?: string
  sx?: SxProps<Theme>
}


interface PatientLabelSizingProps {
  title: 'body1' | 'p2'
  identifier: 'p2' | 'p3'
  flexDirection: 'row' | 'column'
  alignItems?: 'center' | "normal"
  gap: string
  height: string
}

export const PatientLabel = ({patient, name, disabled, variant = 'md', refresh, dataTestId, sx = {} }: PatientLabelProps) => {
  const { setValue } = useFormContext()
  const { tenant_id } = useParams()
  const { buildTenantRoute } = useAccountContext()
  const formattedDob = useFormattedDate(patient.dob)
  const openPatientInNewTab = (patient) => {
    window.open(buildTenantRoute(
      `patients/p/${patient.id}`, tenant_id
    ))
  }

  let sizing: PatientLabelSizingProps = {
    title: 'body1',
    identifier: 'p2',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',
    height: '20px'
  }
  if (variant === 'sm') {
    sizing = {
      title: 'p2',
      identifier: 'p3',
      alignItems: 'normal',
      flexDirection: 'column',
      gap: '2px',
      height: '16px'
    }
  }

  return (
    <Box
      data-testid={dataTestId}
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{
        padding: '0px 8px 0px 16px',
        borderRadius: '8px',
        border: '1px solid rgba(32, 41, 49, 0.32)',
        height: '56px',
        flexShrink: 0,
        alignSelf: 'stretch',
        mb: 1,
        ...sx,
      }}
    >
      <>
        <Box>
          <Typography variant={sizing.title} lineclamp={1} sx={{fontWeight:500}} >{patientDisplayName(patient.firstName, patient.lastName)}</Typography>
          <Box
            display={'flex'}
            flexDirection={ sizing.flexDirection }
            sx={{ gap: sizing.gap }}
            alignItems={sizing.alignItems}
          >
            <Typography dataTestId={`${dataTestId}-identifier`} variant={sizing.identifier} color={'greys.dark'} lineclamp={1}>{formattedDob || NO_DATE_OF_BIRTH}</Typography>
            <IdentifierGroup dataTestId={`${dataTestId}-id`} variant={sizing.identifier} height={sizing.height} label={patient.primaryIdentifierLabel || 'ID'} identifier={patient.primaryIdentifier || 'None'} />
          </Box>
        </Box>
        <PatientEndAdornments
          dataTestId={dataTestId}
          patient={patient}
          openPatientInNewTab={openPatientInNewTab}
          disabled={disabled}
          clearOnClick={() => setValue(name, null)}
          refresh={refresh}
        />
      </>
    </Box>
  )
}