import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { TableListHeaderConfig } from 'saga-library/src'
import { AllUnsubmittedClaimsListView } from "./AllUnsubmittedClaimsListView";

export const UnsubmittedClaimList = ({ loading, allUnsubmittedClaims, onCheckboxClick, onRowClick, selectAll, selectedClaims, onRowSelectionChange, clearSelectedRows }) => {
  const [allSelected, setAllSelected] = useState(false)

  useEffect(() => {
    if (!loading && selectedClaims.length === allUnsubmittedClaims.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [selectedClaims])

  return (
    <Box display={'flex'} flexDirection={'column'} height={'100%'}>
      <AllUnsubmittedClaimsListView
        loading={loading}
        allUnsubmittedClaims = { allUnsubmittedClaims }
        isAllSelected = { allSelected }
        onCheckboxClick = { onCheckboxClick }
        onRowClick = { onRowClick }
        selectAll = { selectAll }
        selectedClaims={selectedClaims}
        onRowSelectionChange={onRowSelectionChange}
        clearSelectedRows={clearSelectedRows}
      />
    </Box>
  )
}

export default UnsubmittedClaimList
export const UnsubmittedClaimColumns: TableListHeaderConfig[] = [
  { name: '' },
  { name: 'Patient' },
  { name: 'Service date' },
  { name: 'Service' },
  { name: 'Calls' },
  { name: 'Diagnoses' },
  { name: 'Practitioner' },
  { name: 'Facility' },
  { name: 'Claimed' },
]