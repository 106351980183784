import React from 'react'
import { Section } from "saga-library/src"
import { SectionColumnProps } from "saga-library/src/components/Section/Section";

const FormColumn = ({children, ...remainingProps}: SectionColumnProps) => {
  return <Section.Column
    width={'40%'}
    {...remainingProps}
    sx={{
      flex: "0 0 40%",
      minWidth: "650px",
      maxWidth: "715px",
      mt: 0,
      ...remainingProps.sx
    }}
    rightPadding={1}
  >
    { children }
  </Section.Column>
}

export default FormColumn