export enum PhoneEnumType {
  HOME = 'HOME',
  MOBILE = 'MOBILE',
  BUSINESS = 'BUSINESS',
  OTHER = 'OTHER',
  NEW = '',
}

export type PhoneType = {
  id?: string | null
  type: PhoneEnumType
  number: string
  notes?: string
  extension: string
  isPrimary: boolean
}

export type Phones = {
  phones: PhoneType[]
}