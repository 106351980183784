import React from 'react'
import { ComingSoonPlaceholder, Section } from 'saga-library/src'

export const ReportFormCriteria = () => {
  return (
    <Section.Container
      sx={{
        border: "1px solid rgba(32, 41, 49, 0.32)",
        borderRadius: "8px",
        flex: "1 1 auto",
        height: "100%"
      }}
    >
      <Section.ColumnNoElevation>
        <ComingSoonPlaceholder message={"Criteria"} />
      </Section.ColumnNoElevation>
    </Section.Container>
  )
}