import { gql } from "@apollo/client";

export const LIST_TENANT_LETTER_TEMPLATES = gql`
    query ListTenantLetterTemplates($tenantId: ID!) {
        tenant(id: $tenantId) {
            letterTemplate {
                list {
                    id
                    name
                    title
                    content
                    version
                    practitionerId
                    practitioner {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
    }
`

export const CREATE_TENANT_LETTER_TEMPLATE = gql`
    mutation CreateTenantLetterTemplate($tenantId: ID!, $input: LetterTemplateInput!) {
        tenant(id: $tenantId) {
            letterTemplate {
                create(input: $input) {
                    id
                    name
                    title
                    content
                    version
                    practitioner {
                        id
                        firstName
                        lastName
                    }
                    practitionerId
                }
            }
        }
    }
`

export const UPDATE_TENANT_LETTER_TEMPLATE = gql`
    mutation UpdateTenantLetterTemplate($tenantId: ID!, $letterTemplateId: ID!, $input: LetterTemplateInput!) {
        tenant(id: $tenantId) {
            letterTemplate {
                update(letterTemplateId: $letterTemplateId, input: $input) {
                    id
                    name
                    title
                    content
                    version
                    practitioner {
                        id
                        firstName
                        lastName
                    }
                    practitionerId
                }
            }
        }
    }
`

export const DELETE_TENANT_LETTER_TEMPLATE = gql`
    mutation DeleteTenantLetterTemplate($tenantId: ID!, $letterTemplateId: ID!) {
        tenant(id: $tenantId) {
            letterTemplate {
                delete(letterTemplateId: $letterTemplateId)
            }
        }
    }
`