import React from "react";
import { AppointmentSlotType } from "../types/schedule/Appointment";

export enum ScheduleDialogActions{
  CREATE="CREATE",
  EDIT="EDIT",
}
export enum ConfirmationDialogEnum{
  INVALID = "INVALID",
  OVERLAP = "OVERLAP",
  OUTSIDE = "OUTSIDE",
  PAST = "PAST",
}

export const useAppointmentSlot = (goto:(appointmentSlot: AppointmentSlotType)=>void):
  {
    slotInfo: AppointmentSlotType | null,
    gotoSlot: (appointmentSlot: AppointmentSlotType)=>void,
    clearSlot:()=>void
  } => {
  const [appointmentSlot, setAppointmentSlot] = React.useState<AppointmentSlotType | null>(null)

  const gotoSlot = (appointmentSlot: AppointmentSlotType) => {
    setAppointmentSlot(appointmentSlot)
    goto(appointmentSlot)
  }

  const clearSlot = () => setAppointmentSlot(null)

  return {slotInfo:appointmentSlot, gotoSlot, clearSlot}
}

export const useShuttleBay = ():{isOccupied: boolean, setOccupied: ()=>void, setUnoccupied: ()=>void} => {
  const [isOccupied, setIsOccupied] = React.useState(false)

  const set = () => setIsOccupied(true)
  const clear = () => setIsOccupied(false)

  return {isOccupied, setOccupied: set, setUnoccupied: clear}
}

export enum TempEventActionType {
  DRAG = "DRAG",
  HOVER = "HOVER",
  REBOOK = "REBOOK",
  BOOK = "BOOK",
  QUICKBOOK = "QUICKBOOK",
}
export interface TempEventType{
  event: any,
  action: string|null
}

export interface TempEventHandlerType {
  tempEvent: TempEventType|null
  setHoverEvent: (tempEvent: any)=>void
  setDragEvent: (tempEvent: any)=>void
  setRebookEvent: (tempEvent: any)=>void
  setBookEvent: (tempEvent: any)=>void
  setQuickBookEvent: (tempEvent: any)=>void
  clearTempEvent: ()=>void
}

export const tempEventDefaults = {
  tempEvent: null,
  setHoverEvent: (tempEvent)=>null,
  setDragEvent: (tempEvent)=>null,
  setRebookEvent: (tempEvent)=>null,
  setBookEvent: (tempEvent)=>null,
  setQuickBookEvent: (tempEvent)=>null,
  clearTempEvent: ()=>null
}

export const useTempEvent = () => {
  const [tempEvent, setTempEvent] = React.useState<TempEventType | null>(null)

  const clear = () => {
    setTempEvent(null)
  }

  return {
    tempEvent,
    setHoverEvent: (tempEvent: TempEventType) => setTempEvent({ event: tempEvent, action: TempEventActionType.HOVER }),
    setDragEvent: (tempEvent: TempEventType) => setTempEvent({ event: tempEvent, action: TempEventActionType.DRAG }),
    setRebookEvent: (tempEvent: TempEventType) => setTempEvent({ event: tempEvent, action: TempEventActionType.REBOOK }),
    setBookEvent: (tempEvent: TempEventType) => setTempEvent({ event: tempEvent, action: TempEventActionType.BOOK }),
    setQuickBookEvent: (tempEvent: TempEventType) => setTempEvent({ event: tempEvent, action: TempEventActionType.QUICKBOOK }),
    clearTempEvent: clear
  }
}
