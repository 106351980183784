import { SettingsSectionColumn } from "../components/SettingsSectionColumn";
import SettingsHeader from "../components/SettingsHeader";
import React, { useState } from "react";
import { Permission, PermissionType } from "../../../types/settings/Permission";
import PermissionButton from "../../../components/PermissionButton";
import { LetterTemplateType } from "../../../types/patients/Letters";
import { NewLetterTemplateModal } from "./LetterTemplates/NewLetterTemplateModal";
import { EditLetterTemplateModal } from "./LetterTemplates/EditLetterTemplateModal";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { LIST_TENANT_LETTER_TEMPLATES } from "../../../graphql-definitions";
import _get from "lodash/get";
import { TableList, TableListHeaderConfig } from "saga-library/src";
import { LetterTemplateRow } from "./LetterTemplates/LetterTemplateRow";


const columns: TableListHeaderConfig[] = [
  { name: "Template name" },
  { name: "Title" },
  { name: "Practitioner" }
];

export const LetterTemplates = () => {
  const [newLetterTemplateOpen, setNewLetterTemplateOpen] = useState(false)
  const [editLetterTemplateOpen, setEditLetterTemplateOpen] = useState<LetterTemplateType | null>(null)

  const { tenant_id } = useParams();

  const { loading, error, data } = useQuery(LIST_TENANT_LETTER_TEMPLATES, {
    variables: { tenantId: tenant_id },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2));
    }
  });

  const letterTemplates = _get(data, 'tenant.letterTemplate.list', [])

  return (
    <SettingsSectionColumn header={
      <SettingsHeader
        title={'Letter templates'}
        actions={
          <PermissionButton
            name={"new"}
            requiredType={PermissionType.Chart}
            requiredPermission={Permission.READWRITE}
            onClick={() => setNewLetterTemplateOpen(true)}
            dataTestId={"letterTemplates-new-button"}
          >
            New
          </PermissionButton>
        }
        dataTestId={'letterTemplates'}
      />
    }>
      <TableList
        columns={columns}
        rows={letterTemplates?.map(letter => LetterTemplateRow(letter, setEditLetterTemplateOpen))}
        sx={{ height: "100%" }}
        emptyListComponent={{ message: "Letter Templates appear here once added." }}
        loading={loading}
        enableSorting={false}
        dataTestId={"letterTemplates-tableList"}
        leftAlignColumns={true}
      />
      { newLetterTemplateOpen &&
        <NewLetterTemplateModal
          open={newLetterTemplateOpen}
          setOpen={setNewLetterTemplateOpen}/>
      }
      { editLetterTemplateOpen &&
        <EditLetterTemplateModal
          open={!!editLetterTemplateOpen}
          letterTemplate={editLetterTemplateOpen}
          onClose={() => setEditLetterTemplateOpen(null)}
        />
      }
    </SettingsSectionColumn>
  )
}