import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import moment from "moment-timezone";
import { RemoveButton } from "saga-library/src";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import _get from "lodash/get";
import { EventForm } from "./EventForm";
import { schema } from "../../util/eventValidation";
import { ScheduleEventInput } from "../../../../types/schedule";
import { GET_SCHEDULE_ITEMS } from "../../../../graphql-definitions";
import { ConfirmationDialog, DialogV2 } from "saga-library/src";
import { useScheduleContext } from "../../../../providers/ScheduleContextProvider";
import { DELETE_EVENT, UPDATE_EVENT } from "../../../../graphql-definitions";
import { useTenantContext } from "../../../../providers/TenantContextProvider";
import { formatTime, getScheduleViewDates } from "../../util/scheduleFunctions";
import { getAppointmentTitle } from '../../util/appointmentDialogFunctions'

const DATA_TEST_ID = "editEvent"
const FORM_NAME = "edit_event_form"

const eventDefaults = (eventData, scheduleId, timezone): ScheduleEventInput => {
  return {
    version: eventData.version,
    scheduleId: scheduleId,
    start: moment(eventData.start).tz(timezone),
    end: moment(eventData.end).tz(timezone),
    title: eventData.title,
    itemDate: moment(eventData?.start).format('YYYY-MM-DD'),
    allDay: eventData.allDay
  }
}

interface EditEventProps {
  open: boolean
  eventData: any
  scheduleId: string
  onClose: () => void
  practitionerName?: string
}

export const EditEvent = ({
  open,
  scheduleId,
  onClose,
  practitionerName,
  eventData
}: EditEventProps) => {
  const { tenant_id } = useParams()
  const { showSuccessAlert, showErrorAlert } = useAlerts()
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)
  const { enableNavigationPrompt } = usePrompt()
  const { selectedScheduleIds, scheduleView, displayScheduleError } = useScheduleContext()
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const { getTenantSettings, tenantSettingsKeys } = useTenantContext()

  const tenantSettings = getTenantSettings([tenantSettingsKeys.TIMEZONE])

  const formMethods = useForm<ScheduleEventInput>({
    defaultValues: eventDefaults(eventData, scheduleId, tenantSettings[tenantSettingsKeys.TIMEZONE.name]),
    schema: schema
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset,
  } = formMethods

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      reset()
      onClose()
    }
  }

  const handleNavigationPromptDiscard = (discard : boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        reset()
        onClose()
      }
    }
  }

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length, openNavigationPrompt]);

  const [updateEvent] = useMutation(UPDATE_EVENT, {
    onCompleted: (data) => {
      reset()
      onClose()
      showSuccessAlert('Event updated.')
    },
    onError: (error) => {
      displayScheduleError(error, "Event", "updated")
    }
  })

  useEffect(()=>{
    if(eventData){
      reset(eventDefaults(eventData, scheduleId, tenantSettings[tenantSettingsKeys.TIMEZONE.name]))
    }
  }, [reset, eventData, scheduleId])

  const onSubmit = handleSubmit(async (data) => {
    data.start = moment(data.itemDate + ' ' + formatTime(data.start), 'YYYY-MM-DD h:mm A')
    data.end =  moment(data.itemDate + ' ' + formatTime(data.end), 'YYYY-MM-DD h:mm A')

    await updateEvent({
      variables: {
        tenantId: tenant_id,
        id: eventData.id,
        input: data
      },
      update(cache, returnedData) {
        let updatedEvent = _get(returnedData, 'data.tenant.schedule.updateEvent')
        const scheduleDateRange = getScheduleViewDates(scheduleView.date, scheduleView.view)

        cache.writeQuery({
          query: GET_SCHEDULE_ITEMS,
          data: {
            tenant: {
              schedule: {
                scheduleItems: [
                  {
                    key: scheduleId,
                    value: [updatedEvent],
                  }
                ],
                scheduleTemplateDays: [],
              }
            }
          },
          variables: {
            tenantId: tenant_id,
            scheduleIdList: selectedScheduleIds,
            start: moment(scheduleDateRange.start).utc().toISOString(),
            end: moment(scheduleDateRange.end).utc().toISOString
          }
        })
      }
    })
  })

  const [deleteEvent] = useMutation(DELETE_EVENT, {
    onCompleted: (data) => {
      reset()
      onClose()
      showSuccessAlert('Event has been deleted.')
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert("Event couldn't be deleted.")
    }
  })

  const handleDeleteEvent = handleSubmit(async data => {
    console.log("Deleting event")
    console.log(eventData)
    await deleteEvent({
      variables: {
        tenantId: tenant_id,
        id: eventData.id
      },
      update(cache) {
        const normalizedId = cache.identify({
          id:eventData.id,
          __typename: 'ScheduleEvent'
        })
        cache.evict({id:normalizedId})
        cache.gc()
      }
    })
  })

  return (
    <>
      <DialogV2
        open={open}
        onClose={onCancel}
        title={getAppointmentTitle({
          start: eventData?.start,
          end: eventData?.end,
          practitionerName: practitionerName
        })}
        headerActions={[
          <RemoveButton onClick={() => setOpenDeleteDialog(true)} dataTestId={DATA_TEST_ID} />
        ]}
        formName={FORM_NAME}
        submitting={isSubmitting}
        dataTestId={DATA_TEST_ID}
      >
        <FormProvider {...formMethods}>
          <EventForm
            formName={FORM_NAME}
            onSubmit={onSubmit}
            dataTestId={DATA_TEST_ID}
          />
        </FormProvider>
      </DialogV2>
      <ConfirmationDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        title={'Delete event?'}
        message={'This action cannot be undone.'}
        primaryAction={handleDeleteEvent}
        primaryLabel={'Delete'}
        isSubmitting={isSubmitting}
        dataTestId={`${DATA_TEST_ID}-deleteDialog`}
      />
    </>
  )
}
