import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import UpdateIcon from '@mui/icons-material/Update'
import ReceiptLong from "@mui/icons-material/ReceiptLongOutlined";
import SelectAllIcon from '@mui/icons-material/SelectAll'
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ActionComponent } from "./UnsubmittedMultipleSelected";
import { styled } from "@mui/system";
import { Typography, IconButton } from "saga-library/src";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { AssessedClaimDialogs } from "./AssessedClaimDialogs";
import { ACCEPT_ADJUSTMENTS, WRITEOFF_CLAIMS } from "../../../graphql-definitions";

const BillingsIcon = styled(ReceiptLong)(({ theme }) => ({
  color: theme.palette.greys.light,
  fontSize: '100px',
}))

export const AssessedMultipleSelected = ( {selectedClaims, refetch, clearSelected, setUpdateBatch, allClaims, setSelectedClaims} ) => {
  const { tenant_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const theme = useTheme()
  const [acceptAdjustments, { error: adjustmentsError }] = useMutation(ACCEPT_ADJUSTMENTS)
  const [writeOffClaims, { error: writeOffError }] = useMutation(WRITEOFF_CLAIMS)
  const [openWriteoffDialog, setOpenWriteoffDialog] = useState(false)
  const [openReassessmentWarningDialog, setOpenReassessmentWarningDialog] = useState(false)
  const [openDeletionWarningDialog, setOpenDeletionWarningDialog] = useState(false)
  const [openCancelResubmissionDialog, setOpenCancelResubmissionDialog] = useState(false)
  const [openCancelWriteoffDialog, setOpenCancelWriteoffDialog] = useState(false)

  const uniqueOutcomeCheck = selectedClaims.every(sc => sc.assessmentOutcome === selectedClaims[0].assessmentOutcome)
  if (!uniqueOutcomeCheck) throw new Error('Unsupported application state: Different assessment outcomes for selected claims.')

  const claimStatus = selectedClaims[0].assessmentOutcome === 'APPLIED' ? 'adjusted'
    : selectedClaims[0].assessmentOutcome === 'REFUSED' ? 'refused'
    : selectedClaims[0].assessmentOutcome === 'HELD' ? 'held' : ''

  const handleSelectAll = () => {
    setSelectedClaims(allClaims)
  }

  const handleBulkAccept = () => {
    acceptAdjustments({
      variables: {
        tenantId: tenant_id,
        ids: selectedClaims.map(sc => sc.id)
      },
      onCompleted: () => {
        refetch()
        showSuccessAlert(`${selectedClaims.length} ${selectedClaims.length === 1 ? 'claim has' : 'claims have'} been accepted.`)
        clearSelected()
      },
      onError: () => {
        console.error(JSON.stringify(adjustmentsError, null, 2))
        showErrorAlert('Claims couldn\'t be accepted.')
      }
    })
  }

  const handleBulkWriteOff = () => {
    setOpenWriteoffDialog(true)
  }

  const handleWriteOffConfirmed = (code) => {
    writeOffClaims({
      variables: {
        tenantId: tenant_id,
        ids: selectedClaims.map(sc => sc.id),
        code: code
      },
      onCompleted: () => {
        refetch()
        showSuccessAlert(`${selectedClaims.length} ${selectedClaims.length === 1 ? 'claim has' : 'claims have'} been written off.`)
        clearSelected()
      },
      onError: () => {
        console.error(JSON.stringify(writeOffError, null, 2))
        showErrorAlert('Claims couldn\'t be written off.')
      }
    })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 6,
      }}>
      <AssessedClaimDialogs
        writeOffProps={{open: openWriteoffDialog, setOpen: setOpenWriteoffDialog, accept: handleWriteOffConfirmed}}
        reassessmentWarningProps={{open: openReassessmentWarningDialog, setOpen: setOpenReassessmentWarningDialog, accept: () => console.warn("Function NYI")}}
        deletionWarningProps={{open: openDeletionWarningDialog, setOpen: setOpenDeletionWarningDialog, accept: () => console.warn("Function NYI")}}
        cancelResubmissionProps={{open: openCancelResubmissionDialog, setOpen: setOpenCancelResubmissionDialog, accept: () => console.warn("Function NYI")}}
        cancelWriteOffProps={{open: openCancelWriteoffDialog, setOpen: setOpenCancelWriteoffDialog, accept: () => console.warn("Function NYI")}}
        batchMode={true}
      />
      <BillingsIcon />
      <Typography variant={'h2'}>{selectedClaims.length} {selectedClaims.length === 1 ? 'claim' : 'claims'} selected</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          color: theme.palette.primary.main,
          marginTop: '20px',
        }}
      >
        <IconButton onClick={handleSelectAll} icon={<SelectAllIcon/>} />
        <Typography fontWeight={'bold'} sx={{ marginLeft: '8px' }}>Select all {claimStatus} claims</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 4,
          color: theme.palette.primary.main,
        }}
      >
        <ActionComponent
          icon={<UpdateIcon/>}
          text={'UPDATE'}
          onClick={() => setUpdateBatch(true)}
        />
        {
          claimStatus === 'refused' &&
          <ActionComponent
            icon={<MoneyOffIcon />}
            text={'WRITE OFF'}
            onClick={handleBulkWriteOff}
          />
        }
        {claimStatus === 'adjusted' &&
          <ActionComponent
            icon={<CheckCircleOutlineIcon/>}
            text={'ACCEPT'}
            onClick={handleBulkAccept}
          />
        }
        <hr
          style={{
            alignItems: 'center',
            color: theme.palette.greys.light,
            width: '120px',
            marginTop: '32px',
          }}
        />

        <ActionComponent
          icon={<ClearOutlinedIcon/>}
          text={'CANCEL'}
          onClick={() => clearSelected()}
        />
      </Box>
    </Box>
  )
}

