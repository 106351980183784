import { EmptyList, IconButton, Section, Typography } from "saga-library/src";
import { Box } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { practitionerDisplayName, patientDisplayName } from "saga-library/src/util/formatting";
import LogoutIcon from '@mui/icons-material/Logout';
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  REMOVE_PATIENT_FROM_ROOM,
  ASSIGN_APPOINTMENT_TO_ROOM,
  LIST_APPOINTMENT_STATES
} from "../../../../graphql-definitions";
import { Dropcontainer } from "@mobiscroll/react";
import { useScheduleContext } from "../../../../providers/ScheduleContextProvider";
import { RoomType } from "../../../../types/schedule";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { DraggableEvent } from "../DraggableEvent";

const canMove = (draggedEvent, location, practitioner) => {
  return draggedEvent
    && (draggedEvent.action === "move" || (draggedEvent.action === "create" && draggedEvent.resource === undefined))
    && !(draggedEvent.event.__typename?.includes('Template'))
    && !(isFromShuttleBay(draggedEvent))
    && ( (!location && !practitioner)
         || (canEnterExamRoom(draggedEvent) && canMoveToRoom(draggedEvent, location, practitioner)))
}

const canEnterExamRoom = (draggedEvent) => {
  return draggedEvent.event.state?.canEnterExamRoom
}

const canMoveToRoom = (draggedEvent, location, practitioner) => {
  return (!location || draggedEvent.event.location?.id === location?.id)
    && (!practitioner || draggedEvent.event.practitioner?.id === practitioner?.id)
}

const isFromShuttleBay = (draggedEvent) => {
  return draggedEvent.event.shuttleBay
}

export const droppableBorder = (draggedEvent, location, practitioner) => {
  const approvedToDrag = canMove(draggedEvent, location, practitioner)
  return {
    borderRadius: 1,
    borderWidth: "4px",
    borderStyle: approvedToDrag ? "dashed" : "solid",
    borderColor: approvedToDrag ? theme => theme.palette.primary.main : 'rgba(220, 223, 229, 0)',
    ':hover': {
      backgroundColor: approvedToDrag ? theme => theme.palette.backgrounds.hover : 'none'
    },
  }
}
interface RoomListProps {
  onRoomWithAppointmentClick: (room: RoomType) => void
}

export const RoomList = memo(({ onRoomWithAppointmentClick }: RoomListProps) => {
  const { tenant_id } = useParams()
  const { schedulesData:{resources, rooms} } = useScheduleContext()
  const { showWarningAlert } = useAlerts()
  const hasMultipleLocations = resources && !resources.every(resource => resource.location === (resources ? resources[0].location : null))

  const { data: appointmentStateList, loading } = useQuery(LIST_APPOINTMENT_STATES, {
    variables: { tenantId: tenant_id },
    fetchPolicy: 'cache-first',
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
  })

  const [assignAppointmentToRoom] = useMutation(ASSIGN_APPOINTMENT_TO_ROOM, {
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    }
  })

  const assignToRoom = async (event, room) => {
    if (isFromShuttleBay(event)) {
      return
    }

    if (!canEnterExamRoom(event)) {
      showWarningAlert('Appointments in this state can\'t be placed in an exam room.')
      return
    }
    if (!canMoveToRoom(event, room.location, room.practitioner)) {
      showWarningAlert('Please select a room at the appointment\'s location.')
      return
    }

    var optimisticResponse = {
      tenant:{
        schedule:{
          room:{
            assignAppointmentToRoom:{
              ...room,
              appointment: {
                ...event.event
              }
            },
            __typename:"AppointmentRoomMutations"
          },
          __typename:"ScheduleMutations"
        },
        __typename:"TenantMutations"
      }
    }

    await assignAppointmentToRoom({
      variables: {
        tenantId: tenant_id,
        roomId: room.id,
        appointmentId: event.event.id,
      },
      optimisticResponse: optimisticResponse,
      update: (cache,{ data }) => {
        cache.modify({
          id: cache.identify({
            id:event.event.draggedFromRoom,
            __typename:"AppointmentRoom",
          }),
          fields:{
            appointment(appt){
              return null
            }
          }
        })
      }
    })
  }

  if (loading) {
    return <></>
  }

  if (!rooms || rooms.length === 0) {
    return <EmptyList
        message={"Rooms appear here once added."}
      />
  }

  return (
    <>
      <Typography variant={'h3'}>Rooms</Typography>
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{ mt: 3, pr: 1}}
        component={"div"}
      >
        {rooms.map((room, index) => {
          if (room.appointment) {
            return (
              <AppointmentRoom
                key={index}
                room={room}
                onRoomWithAppointmentClick={onRoomWithAppointmentClick}
                hasMultipleLocations={hasMultipleLocations}
                leftClinicState={appointmentStateList.tenant.schedule.state.listStates.find(state => state.name === 'Left clinic')}
              />
            )
          } else {
            return (
              <EmptyRoom
                key={index}
                room={room}
                assignToRoom={assignToRoom}
                hasMultipleLocations={hasMultipleLocations}
              />
            )
          }
        }
        )}
      </Box>
    </>
  )
})


const EmptyRoom = ({ room, assignToRoom, hasMultipleLocations }) => {

  const [ refElem, setRefElem ] = useState<HTMLDivElement | null>(null)
  const { tempEventHandler } = useScheduleContext()
  const { tempEvent } = tempEventHandler

  const setDropContainer = (container) => {
    setRefElem(container)
  }

  const handleDropFromScheduler = (event) => {
    assignToRoom({ event: event.data }, room)
  }

  return (
    <Section.FormSection refElement={setDropContainer}>
      <RoomHeader room={room} hasMultipleLocations={hasMultipleLocations} />
      <Box
        display={'flex'}
        sx={{
          mt: '4px',
          justifyContent: 'center',
          alignItems: 'center',
          height: '34px',
          backgroundColor: 'rgba(220, 223, 229, 0.38)',
          ...droppableBorder(tempEvent?.event, room.location, room.practitioner)
        }}
        data-testid={`scheduler-emptyRoom-${room.name}`}
      >
        <Dropcontainer onItemDrop={handleDropFromScheduler} element={refElem}>
          <Typography variant={'p3'}>EMPTY</Typography>
        </Dropcontainer>
      </Box>
    </Section.FormSection>
  )
}


const AppointmentRoom = ({ room, onRoomWithAppointmentClick, hasMultipleLocations, leftClinicState }) => {
  const { tenant_id } = useParams()
  const { tempEventHandler } = useScheduleContext()

  const { clearTempEvent } = tempEventHandler
  const { id, name, appointment } = room

  const [removePatientFromRoom] = useMutation(REMOVE_PATIENT_FROM_ROOM, {
    variables: { tenantId: tenant_id, roomId: id },
    onCompleted: () => {
      clearTempEvent()
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
  })

  const removePatient = () => {
    removePatientFromRoom({
      update(cache) {
        cache.modify({
          id: cache.identify(appointment),
          fields: {
            state(cachedState) {
              return leftClinicState
            }
          }
        })
      }
    })
  }

  return (
    <Section.FormSection>
      <RoomHeader room={room} hasMultipleLocations={hasMultipleLocations} />
      <DraggableEvent dragData={{...appointment, draggedFromRoom: room.id}} dataTestId={`scheduler-appointmentRoom-${name}`}>
        <Box
          title={appointment.reasonForVisit}
          onClick={() => onRoomWithAppointmentClick(appointment)}
          display={'flex'}
          sx={theme => ({
            mt: '4px',
            height: '38px',
            borderRadius: 1,
            background: appointment.state.color,
            color: theme.palette.greys.dark,
            opacity: 1
          })}
        >
          <Box sx={theme => ({
            height: '100%',
            width: '8px',
            background: appointment?.type ? appointment?.type?.color : "rgba(255,255,255,0)",
            border: appointment?.type ? "none" : `1px solid ${theme.palette.greys.extraLight}`,
            flex: '0 0 auto',
            boxSizing: "border-box"
          })} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              height: '100%',
              width: '100%',
              px: 1,
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: 'rgba(255,255,255,0)',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: '1 1 auto' }}>
              <Typography variant={'p3'} fontWeight={'bold'}>
                {patientDisplayName(appointment.patient.firstName, appointment.patient.lastName)}
              </Typography>
              <Typography variant={'p3'} >
                {appointment.type?.name}
              </Typography>
            </Box>
            <IconButton
              dataTestId={`scheduler-appointmentRoom-${name}-removePatient`}
              sx={{ height: '100%' }}
              onClick={(event) => {
                event.stopPropagation()
                removePatient()
              }}
              icon={<LogoutIcon />}
            />
          </Box>
        </Box>
      </DraggableEvent>
    </Section.FormSection>
  )
}

const RoomHeader = ({ room, hasMultipleLocations }) => {
  const { name, practitioner, location } = room

  return <>
    <Typography variant={'p3'} fontWeight={'bold'}>
      {name.toUpperCase()}
      {practitioner && ` (${practitionerDisplayName(practitioner.firstName.toUpperCase(), practitioner.lastName.toUpperCase())})`}
    </Typography>
    {
      hasMultipleLocations &&
      <Typography variant={'p3'}>
        {location.name.toUpperCase()}
      </Typography>
    }
  </>
}




