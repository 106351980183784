import _get from "lodash/get";
import { ControlledSearch } from "saga-library/src";
import {
  ControlledSearchControlProps
} from "saga-library/src/components/SearchControl/ControlledSearchControl";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { AbClaimServiceCode } from '../../../types/billing';
import { ServiceCodeResult } from "./ServiceCodeResult";
import { GET_RECENT_SERVICECODES, GET_SEARCH_SERVICECODES } from "../../../graphql-definitions";

export interface ServiceCodeSearchProps<
  T = AbClaimServiceCode,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
  > extends Omit<ControlledSearchControlProps<
  T,
  Multiple,
  DisableClearable,
  FreeSolo
  >, 'queries'|'render'|'transform'|'isOptionEqualToValue'> {
  serviceDate?: Date,
  practitionerId?: string,
  disabled?: boolean
}

export const ServiceCodeSearch = ({
  name = 'serviceCodeId',
  label = 'Service Code',
  serviceDate = new Date(),
  practitionerId = '',
  disabled = false,
  ...props
} :ServiceCodeSearchProps) => {
  const { tenant_id } = useParams()

  const searchQueryVariables = useMemo(() => ({
    tenantId: tenant_id,
    serviceDate: serviceDate
  }), [tenant_id, serviceDate])

  const initialQueryVariables = useMemo(() => ({
    tenantId: tenant_id,
    searchPractitionerId: practitionerId,
    serviceDate: serviceDate
  }), [tenant_id, practitionerId, serviceDate])

  return (
    <ControlledSearch<AbClaimServiceCode, false, false, false>
      {...props}
      name={name}
      label={label}
      disabled={disabled}
      queries={{
        search: {
          gql: GET_SEARCH_SERVICECODES,
          get: (data) => _get(data, 'tenant.search.abClaim.serviceCodes', []) as AbClaimServiceCode[],
          variables: searchQueryVariables,
          fetchPolicy: 'network-only'
        },
        initial: {
          gql: GET_RECENT_SERVICECODES,
          get: (data) => _get(data, 'tenant.search.abClaim.recentServiceCodes', []) as AbClaimServiceCode[],
          variables: initialQueryVariables,
          fetchPolicy: 'cache-first'
        }
      }}
      render={{
        value: (serviceCode) => serviceCode.serviceCode,
        option: (serviceCode, state) => (
          <ServiceCodeResult
            serviceCode={serviceCode}
            searchText={state.inputValue}
          />
        )
      }}
    />
  )
}
