export interface ReportType {
  id: string,
  name: string,
  description: string,
  category: ReportCategoryType,
  columns: ReportColumn[],
  parameters: ReportParameter[],
  lastRun: Date | null,
  isReference: boolean,
  version: string
}

interface ReportInputBaseType {
  id?: string,
  name?: string,
  description?: string,
  category?: ReportCategoryType | null,
  queries?: ReportQueryType[],
  version?: string
}

export interface ReportInputType extends ReportInputBaseType {
  columns?: ReportColumnInput[]
  parameters?: ReportParameterInput[]
}

export interface ReportInputDataType extends ReportInputBaseType {
  columns?: ReportColumn[]
  parameters?: ReportParameter[]
}

export enum ReportCategoryType {
  BILLING = 'BILLING',
  APPOINTMENT = 'APPOINTMENT',
  PATIENT = 'PATIENT'
}

export const REPORT_CATEGORIES: { [category: string]: ReportCategoryType } = {
  "appointment": ReportCategoryType.APPOINTMENT,
  "billing": ReportCategoryType.BILLING,
  "patient": ReportCategoryType.PATIENT
}

export enum ReportColumnType {
  DATE = 'DATE',
  NOTES = 'NOTES',
  PRACTITIONER_BILLING_PROFILE = 'PRACTITIONER_BILLING_PROFILE',
  STATE = 'STATE',
  TYPE = 'TYPE',
  CALLS = 'CALLS',
  CLAIMED = 'CLAIMED',
  DIAGNOSES = 'DIAGNOSES',
  FACILITY = 'FACILITY',
  OUTCOME = 'OUTCOME',
  PAID = 'PAID',
  SERVICE_CODE = 'SERVICE_CODE',
  SERVICE_DATE = 'SERVICE_DATE',
  LAB_RESULT_DATE = 'LAB_RESULT_DATE',
  LAB_RESULT_DESCRIPTION = 'LAB_RESULT_DESCRIPTION',
  LINKED_DOCUMENT_CATEGORY = 'LINKED_DOCUMENT_CATEGORY',
  LINKED_DOCUMENT_DESCRIPTION = 'LINKED_DOCUMENT_DESCRIPTION',
  PRESCRIPTION_DATE = 'PRESCRIPTION_DATE',
  PRESCRIPTION = 'PRESCRIPTION',
  PRESCRIPTION_STATUS = 'PRESCRIPTION_STATUS',
  PATIENT_AGE = 'PATIENT_AGE',
  PATIENT_DATE_OF_BIRTH = 'PATIENT_DATE_OF_BIRTH',
  PATIENT_GENDER = 'PATIENT_GENDER',
  PATIENT_IDENTIFIER = 'PATIENT_IDENTIFIER',
  PATIENT_IDENTIFIER_TYPE = 'PATIENT_IDENTIFIER_TYPE',
  PATIENT_LAST_APPOINTMENT_DATE = 'PATIENT_LAST_APPOINTMENT_DATE',
  PATIENT_NAME = 'PATIENT_NAME',
  PATIENT = 'PATIENT',
  PATIENT_POSTAL_CODE = 'PATIENT_POSTAL_CODE',
  PRACTITIONER = 'PRACTITIONER'
}

export interface ReportColumnInput {
  id?: string
  name: string
  label: string
  version?: string
}

export interface ReportColumn {
  id?: string
  column: string
  order: number
  version?: string
}

export enum ReportColumnDisplayFormatter {
  DATE = "DATE"
}

export const REPORT_COLUMNS: { [key in ReportColumnType]: {label:string, selectedLabel?:string, formatter?:ReportColumnDisplayFormatter} } = {
  [ReportColumnType.DATE]: {label:"Date", selectedLabel:"Appointment date", formatter:ReportColumnDisplayFormatter.DATE},
  [ReportColumnType.NOTES]: {label:"Notes", selectedLabel:"Appointment notes"},
  [ReportColumnType.PRACTITIONER_BILLING_PROFILE]: {label:"Practitioner billing profile"},
  [ReportColumnType.STATE]: {label:"State", selectedLabel:"Appointment state"},
  [ReportColumnType.TYPE]: {label:"Type",  selectedLabel:"Appointment type"},
  [ReportColumnType.CALLS]: {label:"Calls"},
  [ReportColumnType.CLAIMED]: {label:"Claimed"},
  [ReportColumnType.DIAGNOSES]: {label:"Diagnoses"},
  [ReportColumnType.FACILITY]: {label:"Facility"},
  [ReportColumnType.OUTCOME]: {label:"Outcome"},
  [ReportColumnType.PAID]: {label:"Paid"},
  [ReportColumnType.SERVICE_CODE]: {label:"Service code"},
  [ReportColumnType.SERVICE_DATE]: {label:"Service date", formatter:ReportColumnDisplayFormatter.DATE},
  [ReportColumnType.LAB_RESULT_DATE]: {label:"Date",  selectedLabel:"Lab date", formatter:ReportColumnDisplayFormatter.DATE},
  [ReportColumnType.LAB_RESULT_DESCRIPTION]: {label:"Test description"},
  [ReportColumnType.LINKED_DOCUMENT_CATEGORY]: {label:"Category",  selectedLabel:"Document category"},
  [ReportColumnType.LINKED_DOCUMENT_DESCRIPTION]: {label:"Description",  selectedLabel:"Document description"},
  [ReportColumnType.PRESCRIPTION_DATE]: {label:"Date",  selectedLabel:"Prescription date", formatter:ReportColumnDisplayFormatter.DATE},
  [ReportColumnType.PRESCRIPTION]: {label:"Prescription"},
  [ReportColumnType.PRESCRIPTION_STATUS]: {label:"Status",  selectedLabel:"Prescription status"},
  [ReportColumnType.PATIENT_AGE]: {label:"Age"},
  [ReportColumnType.PATIENT_DATE_OF_BIRTH]: {label:"Date of birth", formatter:ReportColumnDisplayFormatter.DATE},
  [ReportColumnType.PATIENT_GENDER]: {label:"Gender"},
  [ReportColumnType.PATIENT_IDENTIFIER]: {label:"Identifier"},
  [ReportColumnType.PATIENT_IDENTIFIER_TYPE]: {label:"Identifier type"},
  [ReportColumnType.PATIENT_LAST_APPOINTMENT_DATE]: {label:"Last appointment date", formatter:ReportColumnDisplayFormatter.DATE},
  [ReportColumnType.PATIENT_NAME]: {label:"Name"},
  [ReportColumnType.PATIENT]: {label:"Patient"},
  [ReportColumnType.PATIENT_POSTAL_CODE]: {label:"Postal code"},
  [ReportColumnType.PRACTITIONER]: {label:"Practitioner"}
}

export enum ReportParameterType {
  APPOINTMENT_DATE_RANGE = 'APPOINTMENT_DATE_RANGE',
  APPOINTMENT_PRACTITIONERS = 'APPOINTMENT_PRACTITIONERS',
  SERVICE_DATE_RANGE = 'SERVICE_DATE_RANGE',
  PAYMENT_DATE_RANGE = 'PAYMENT_DATE_RANGE',
  CLAIM_PRACTITIONERS = 'CLAIM_PRACTITIONERS',
  PRIMARY_PRACTITIONER = 'PRIMARY_PRACTITIONER'
}

export interface ReportParameterInput {
  id?: string
  name: string
  label: string
  version?: string
}

export interface ReportParameter {
  id?: string
  parameter: string
  version?: string
}

export const REPORT_PARAMETERS: { [key in ReportParameterType]: string } = {
  [ReportParameterType.APPOINTMENT_DATE_RANGE]: "Appointment date range",
  [ReportParameterType.APPOINTMENT_PRACTITIONERS]: "Appointment practitioner(s)",
  [ReportParameterType.SERVICE_DATE_RANGE]: "Service date range",
  [ReportParameterType.PAYMENT_DATE_RANGE]: "Payment date range",
  [ReportParameterType.CLAIM_PRACTITIONERS]: "Claim practitioner(s)",
  [ReportParameterType.PRIMARY_PRACTITIONER]: "Primary practitioner"
}

export interface ReportResult {
  columns: ReportColumnResult[]
}

export interface ReportColumnResult {
  column: ReportColumnType
  result: string | null
}

export interface ReportQueryType {}
