import React, { useEffect, useMemo, useRef, useState } from "react";
import { EmptyList, Section } from "saga-library/src";
import { TimelineIcon } from "../ChartIcons";
import { PatientSectionHeader } from "../PatientSectionHeader";
import { useApolloClient, useReadQuery } from "@apollo/client";
import Timeline from "../timeline/Timeline";
import { useTimelineUtils } from "../../util/TimelineUtil";
import { TimelineCacheMappings, TimelineEventType } from "../../../../types/patients/Timeline";
import { ChartPanelHOC } from "../../util/ChartPanelHOC";
import { useAllergyContext } from "../../providers/AllergyProvider";
import { useAppointmentContext } from "../../providers/AppointmentProvider";
import { useConditionAndProcedureContext } from "../../providers/ConditionAndProcedureProvider";
import { usePatientDocumentContext } from "../../providers/PatientDocumentProvider";
import { useEncounterNoteContext } from "../../providers/EncounterNoteProvider";
import { usePatientFormContext } from "../../providers/PatientFormProvider";
import { useLabAndInvestigationContext } from "../../providers/LabAndInvestigationProvider";
import { useLetterContext } from "../../providers/LetterProvider";
import { usePrescriptionContext } from "../../providers/PrescriptionProvider";
import EditAllergyDialog from "../allergies/EditAllergyDialog";
import { OpenAppointmentOnSchedule } from "../PatientAppointmentRow";
import EditConditionAndProcedureDialog from "../conditionAndProcedures/EditConditionAndProcedureDialog";
import { LetterModal } from "../letters/LetterModal";
import EditPrescriptionDialog from "../widgets/components/EditPrescriptionDialog";
import { FormModal } from "../forms/components/FormsModal";
import NewPrescriptionDialog from "../widgets/components/NewPrescriptionDialog";
import { LetterDocumentModal } from "../letters/LetterDocumentModal";
import { FormDocumentModal } from "../forms/components/FormsDocumentModal";
import { EncounterNotesDocumentModal } from "../encounterNotes/EncounterNotesDocumentModal";
import { EncounterNotesModal } from "../encounterNotes/EncounterNotesModal";
import { OtherDocumentsModal } from "../other-documents/components/OtherDocumentsModal";
import { LabAndInvestigationDocumentModal } from "../labAndInvestigations/LabAndInvestigationDocumentModal";
import { LabAndInvestigationModal } from "../labAndInvestigations/LabAndInvestigationModal";
import { TIMELINE_SCROLL_KEY, useUserInteraction } from "../../../../providers/UserInteractionContext";

const SectionTitle = "Timeline"

export const PatientTimeline = ChartPanelHOC(SectionTitle, (props) => {
  const {
    convertAllergyToTimelineEvent,
    convertAppointmentToTimelineEvent,
    convertConditionAndProcedureToTimelineEvent,
    convertEncounterNoteToTimelineEvent,
    convertFormToTimelineEvent,
    convertLabAndInvestigationToTimelineEvent,
    convertLetterToTimelineEvent,
    convertOtherDocumentToTimelineEvent,
    convertPrescriptionToTimelineEvent,
    groupTimelineEvents
  } = useTimelineUtils()

  const { allergyQueryRef, getAllergyQueryResults } = useAllergyContext()
  const { data: allergyData } = useReadQuery(allergyQueryRef!)
  const allergies = useMemo(() => {
    return getAllergyQueryResults(allergyData).map(a => convertAllergyToTimelineEvent(a))
  }, [allergyData, getAllergyQueryResults, convertAllergyToTimelineEvent])

  const { appointmentQueryRef, getAppointmentQueryResults } = useAppointmentContext()
  const { data: appointmentData } = useReadQuery(appointmentQueryRef!)
  const appointments = useMemo(() => {
    return getAppointmentQueryResults(appointmentData)
      .filter(a => !a.isDeleted)
      .map(a => convertAppointmentToTimelineEvent(a))
  }, [appointmentData, getAppointmentQueryResults, convertAppointmentToTimelineEvent])

  const { conditionAndProcedureQueryRef, getConditionAndProcedureQueryResults } = useConditionAndProcedureContext()
  const { data: conditionAndProcedureData } = useReadQuery(conditionAndProcedureQueryRef!)
  const conditionAndProcedures = useMemo(() => {
    return getConditionAndProcedureQueryResults(conditionAndProcedureData).map(cp => convertConditionAndProcedureToTimelineEvent(cp))
  }, [conditionAndProcedureData, getConditionAndProcedureQueryResults, convertConditionAndProcedureToTimelineEvent])

  const { encounterNoteQueryRef, getEncounterNoteQueryResults } = useEncounterNoteContext()
  const { data: encounterNoteData } = useReadQuery(encounterNoteQueryRef!)
  const encounterNotes = useMemo(() => {
    return getEncounterNoteQueryResults(encounterNoteData).map(en => convertEncounterNoteToTimelineEvent(en))
  }, [encounterNoteData, getEncounterNoteQueryResults, convertEncounterNoteToTimelineEvent])

  const { patientFormQueryRef, getFormQueryResults } = usePatientFormContext()
  const { data: formData } = useReadQuery(patientFormQueryRef!)
  const forms = useMemo(() => {
    return getFormQueryResults(formData).map(f => convertFormToTimelineEvent(f))
  }, [formData, getFormQueryResults, convertFormToTimelineEvent])

  const { labAndInvestigationQueryRef, getLabAndInvestigationQueryResults } = useLabAndInvestigationContext()
  const { data: labAndInvestigationData } = useReadQuery(labAndInvestigationQueryRef!)
  const labAndInvestigations = useMemo(() => {
    return getLabAndInvestigationQueryResults(labAndInvestigationData).map(li => convertLabAndInvestigationToTimelineEvent(li))
  }, [labAndInvestigationData, getLabAndInvestigationQueryResults, convertLabAndInvestigationToTimelineEvent])

  const { letterQueryRef, getLetterQueryResults } = useLetterContext()
  const { data: letterData } = useReadQuery(letterQueryRef!)
  const letters = useMemo(() => {
    return getLetterQueryResults(letterData).map(l => convertLetterToTimelineEvent(l))
  }, [letterData, getLetterQueryResults, convertLetterToTimelineEvent])

  const { documentQueryRef, getLinkedDocumentQueryResults } = usePatientDocumentContext()
  const { data: otherDocumentData } = useReadQuery(documentQueryRef!)
  const otherDocuments = useMemo(() => {
    return getLinkedDocumentQueryResults(otherDocumentData).map(ld => convertOtherDocumentToTimelineEvent(ld))
  }, [otherDocumentData, getLinkedDocumentQueryResults, convertOtherDocumentToTimelineEvent])

  const { prescriptionsQueryRef, getPrescriptionQueryResults } = usePrescriptionContext()
  const { data: prescriptionData } = useReadQuery(prescriptionsQueryRef!)
  const prescriptions = useMemo(() => {
    return getPrescriptionQueryResults(prescriptionData).map(p => convertPrescriptionToTimelineEvent(p))
  }, [prescriptionData, getPrescriptionQueryResults, convertPrescriptionToTimelineEvent])

  const allEvents = [
    ...allergies,
    ...appointments,
    ...conditionAndProcedures,
    ...encounterNotes,
    ...forms,
    ...labAndInvestigations,
    ...letters,
    ...otherDocuments,
    ...prescriptions
  ]

  const timeline = groupTimelineEvents(allEvents)

  return <PatientTimelinePanel timeline={timeline} {...props} />
})

const PatientTimelinePanel = ({ timeline }) => {
  const timelineRef = useRef<HTMLDivElement>(null)
  const { getScrollPosition, saveScrollPosition } = useUserInteraction()
  const [selectedEvent, setSelectedEvent] = useState<TimelineEventType | null>(null)

  useEffect(() => {
    requestAnimationFrame(() => {
      if (timelineRef?.current) {
        timelineRef.current.scrollTop = getScrollPosition(TIMELINE_SCROLL_KEY)
      }
    })
  }, [TIMELINE_SCROLL_KEY, getScrollPosition])

  const onEventClick = (event: TimelineEventType) => {
    if (timelineRef?.current) {
      saveScrollPosition(TIMELINE_SCROLL_KEY, timelineRef.current.scrollTop)
    }
    setSelectedEvent(event)
  }

  const view = () => {
    const timelineIsEmpty = !timeline || !Object.keys(timeline).find((g) => timeline[g].length !== 0)

    if (timelineIsEmpty) {
      return (
        <EmptyList
          icon={TimelineIcon}
          message={"Timeline entries appear here once chart items are created for this patient."}
          sx={{ maxWidth: "335px" }}
        />
      )
    }

    return <Timeline timeline={timeline} onEventClick={onEventClick} />
  }

  return (
    <Section.Column
      innerRefElement={timelineRef}
      sx={{ flex: "1 1 100%" }}
      header={
        <PatientSectionHeader dataTestId={"patient-timeline"} sectionLabel={SectionTitle} showSave={false} />
      }
    >
      {view()}
      <PatientTimelineModal selectedEvent={selectedEvent} onClose={() => setSelectedEvent(null)} />
    </Section.Column>
  )
}

const PatientTimelineModal = ({ selectedEvent, onClose }: { selectedEvent: TimelineEventType | null, onClose: () => void }) => {
  const client = useApolloClient()
  const [event, setEvent] = useState<any>(null)
  const [onOpenNew, setOnOpenNew] = useState<boolean>(false)

  useEffect(() => {
    if (!selectedEvent) {
      setEvent(null)
      return
    }

    const cacheId = client.cache.identify({
      id: selectedEvent.id,
      isLinkedDocument: !!selectedEvent.isLinkedDocument,
      __typename: TimelineCacheMappings.get(selectedEvent.type)!.typename
    })
    const cachedEvent = client.readFragment({
      id: cacheId,
      fragment: TimelineCacheMappings.get(selectedEvent.type)!.fragment,
      fragmentName: TimelineCacheMappings.get(selectedEvent.type)!.fragmentName
    })

    setEvent(cachedEvent)
  }, [client, selectedEvent])

  const onNew = () => {
    setOnOpenNew(true)
  }

  useEffect(() => {
    if(!onOpenNew){
      onClose()
    }
  }, [onOpenNew])

  const onUpdateEvent = (updatedEvent) => {
    if (!updatedEvent) {
      onClose()
    }
    setEvent(updatedEvent)
  }

  if (!event) {
    return <></>
  }

  switch (selectedEvent?.type) {
    case "ALLERGY":
      return <EditAllergyDialog allergy={event} setAllergy={onUpdateEvent} />

    case "APPOINTMENT":
      return <AppointmentModal appointment={event} onClose={onClose} />

    case "CONDITION_AND_PROCEDURE":
      return <EditConditionAndProcedureDialog conditionAndProcedure={event} setConditionAndProcedure={onUpdateEvent} />

    case "ENCOUNTER_NOTE":
      if (event.isLinkedDocument) {
        return <EncounterNotesDocumentModal encounterNote={event} onModalClose={onClose} />
      }
      return <EncounterNotesModal openDialog={true} selectedEncounterNote={event} onClose={onClose} />

    case "FORM":
      if (event.isLinkedDocument) {
        return <FormDocumentModal form={event} onModalClose={onClose} />
      }
      return <FormModal open={true} form={event} onModalClose={onClose} />

    case "LAB_AND_INVESTIGATION":
      if (event.isLinkedDocument) {
        return <LabAndInvestigationDocumentModal document={event.linkedDocument} onModalClose={onClose} />
      }
      return <LabAndInvestigationModal labId={event.id} open={true} onModalClose={onClose} />

    case "LETTER":
      if (event.isLinkedDocument) {
        return <LetterDocumentModal letter={event} onModalClose={onClose} />
      }
      return <LetterModal open={true} letter={event} onModalClose={onClose} />

    case "OTHER_DOCUMENT":
      return <OtherDocumentsModal document={event} onModalClose={onClose} />

    case "PRESCRIPTION":
      if (onOpenNew) {
        return <NewPrescriptionDialog open={onOpenNew} setOpen={setOnOpenNew} onSaveAndNew={onNew} />
      }
      return <EditPrescriptionDialog prescription={event} setPrescription={onUpdateEvent} onNew={onNew} />

    default:
      return <></>
  }
}

const AppointmentModal = ({ appointment, onClose }) => {
  const onOpen = OpenAppointmentOnSchedule(appointment, onClose)
  useEffect(() => {
    onOpen()
  }, []);

  return <></>
}