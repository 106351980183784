import React, { useEffect, useState } from "react";
import { ConfirmationDialog, DeleteButton, SaveButton } from "saga-library/src";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import _get from "lodash/get";
import omitDeep from 'omit-deep-lodash'
import { omit } from "lodash";
import { useForm, FormProvider } from "saga-library/src/components/Form";
import { pharmacyDetailsDefaults, PharmacyDetailsType } from "./PharmacyNew";
import { schema } from "./util/validation";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { LoadingSpinner } from "../../../components/LoadingScreen";
import { PharmacyForm } from "./PharmacyForm";
import { PharmacyInput } from "../../../types";
import {
  GET_PHARMACY,
  UPDATE_PHARMACY,
  DELETE_PHARMACY,
} from "../../../graphql-definitions";
import SettingsHeader from "../components/SettingsHeader";
import { SettingsSectionColumn } from "../components/SettingsSectionColumn";
import { usePrompt } from "../../../providers/NavigationPrompt";

const FORM_NAME = 'update_pharmacy_form'

export const PharmacyUpdate = () => {
  const navigate = useNavigate()
  const { tenant_id, pharmacy_id } = useParams()
  const [ title, setTitle ] = useState('')
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const [ openDeleteDialog, setOpenDeleteDialog ] = useState(false)
  const { enableNavigationPrompt } = usePrompt()

  const formMethods = useForm<PharmacyDetailsType>({
    defaultValues: pharmacyDetailsDefaults,
    schema: schema,
  })
  const {
    reset,
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
  } = formMethods

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length]);

  const {
    loading: initialLoading,
    data: initialData,
  } = useQuery(GET_PHARMACY, {
    variables: {
      pharmacyId: pharmacy_id,
      tenantId: tenant_id
    },
    onCompleted: (data) => {
      const pharmacy = _get(data, 'tenant.pharmacy.get', null)
      if (pharmacy) {
        setValues(pharmacy)
      }
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert('Pharmacy couldn\'t be retrieved.')
    },
    fetchPolicy: 'cache-and-network'
  })

  const [updatePharmacy, { data: updateData }] =
    useMutation(UPDATE_PHARMACY, {
      onCompleted: (updateData) => {
        const data = _get(updateData, 'tenant.pharmacy.update', null)
        if (data.id === pharmacy_id) {
          setValues(data)
        }
        showSuccessAlert(`Pharmacy has been updated.`)
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert("Pharmacy couldn't be updated.")
      }
    })

  const [deletePharmacy] =
    useMutation(DELETE_PHARMACY, {
      onCompleted: (data) => {
        showSuccessAlert('Pharmacy has been deleted.')
        navigate(`../`)
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert("Pharmacy couldn't be deleted.")
      }
    })

  const setValues = (data) => {
    let cleanedData = omitDeep(
      omit(data, 'id'),
      '__typename',
      'street1'
    )
    cleanedData = {
      ...cleanedData,
      street: data.street1
    }
    reset(cleanedData)
    setTitle(data.name)
  }

  const onSubmit = handleSubmit( async (data) => {
    const { street, ...rest } = data
    const pharmacy: PharmacyInput = {
      ...rest,
      street1: street
    }

    await updatePharmacy({
      variables: {
        tenantId: tenant_id,
        input: pharmacy,
        id: pharmacy_id
      }
    })
  })

  const onDelete = () => {
    deletePharmacy({
      variables: {
        tenantId: tenant_id,
        id: pharmacy_id
      }
    })
  }

  const getHeader = () => {
    if(initialData) {
      return <SettingsHeader
        title={title}
        onBack={() => navigate(`../`)}
        dataTestId={"pharmacyUpdate"}
        actions={
          <>
            <DeleteButton
              onClick={()=>setOpenDeleteDialog(true)}
              dataTestId={"pharmacyUpdate-delete-button"}
            />
            <SaveButton
              form={FORM_NAME}
              dataTestId={"pharmacyUpdate"}
              submitting={isSubmitting}
            />
          </>
        }
      />
    }
  }

  return (
    <FormProvider {...formMethods}>
      <SettingsSectionColumn header={getHeader()} >
        {initialLoading && (
          <LoadingSpinner />
        )}
        {(initialData || updateData) && (
          <PharmacyForm formName={FORM_NAME} onSubmit={onSubmit} width={'30%'}/>
        )}
        <ConfirmationDialog
          open={openDeleteDialog}
          onClose={()=> setOpenDeleteDialog(false)}
          title={"Delete pharmacy?"}
          message={"This pharmacy will be removed from any patients to which it’s been linked. This action cannot be undone."}
          primaryAction={onDelete}
          primaryLabel={"Delete"}
          dataTestId={"pharmacyUpdate-delete-dialog"}
        />
      </SettingsSectionColumn>
    </FormProvider>
  )
}