import { Controller, useFormContext } from "saga-library/src/components/Form";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Autocomplete } from "saga-library/src";
import { Box, Divider, SxProps, Theme, createFilterOptions, useTheme } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GET_TENANT_USERS } from "../../graphql-definitions";
import _get from "lodash/get";
import { userDisplayName } from "saga-library/src/util/formatting";

interface AssignedToProps {
  label: string
  name: string
  includeRoles?: boolean
  disabled?: boolean
  dataTestId: string
  sx?: SxProps<Theme>
}

interface AssignedToOptionType {
  type: "role" | "user" | "divider"
  value: string
  label: string
}

const DIVIDER_OPTION: AssignedToOptionType = { type: "divider", value: '-', label: '' }

export const AssignedTo = ({
  label = 'Assigned to',
  name,
  includeRoles = false,
  disabled = false,
  dataTestId,
  sx
}: AssignedToProps) => {
  const { tenant_id } = useParams()
  const { control } = useFormContext()

  const { loading, error, data } = useQuery(GET_TENANT_USERS, {
    variables: { tenantId: tenant_id },
  })

  const [options, setOptions] = useState<AssignedToOptionType[]>([])

  const filterOptions = createFilterOptions()

  useEffect(() => {
    if (error) {
      console.error(JSON.stringify(error, null, 2))
    } else {
      let availableOptions: AssignedToOptionType[] = []

      const users = _get(data, 'tenant.user.allTenantUsers', [])
      const userOptions = users.map(user => {
        return {
          type: "user",
          value: user.id,
          label: userDisplayName(user.firstName, user.lastName)
        }
      })
      availableOptions = [...userOptions]

      if (includeRoles) {
        const roles = _get(data, 'tenant.role.tenantRoles', [])
        const roleOptions = roles.map(role => {
          return {
            type: "role",
            value: role.id,
            label: role.name
          }
        })
        availableOptions = [...roleOptions, ...userOptions]
      }

      setOptions(availableOptions)
    }
  }, [includeRoles, data, error])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value = null }, fieldState: { error } }) => {
        if (value === '') {
          value = null
        }
        return (
          <Autocomplete
            name={name}
            label={label}
            loading={loading}
            options={options}
            onChange={(e, newValue) => {
              if (includeRoles) {
                onChange(newValue ?? null)
              } else {
                onChange(newValue?.value ?? null)
              }
            }}
            OptionComponent={AssignedToOption}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return options.find(o => o.value === option)?.label || ""
              }
              return option?.label || ""
            }}
            filterOptions={(options, state) => {
              const results: AssignedToOptionType[] = filterOptions(options, state) as AssignedToOptionType[]
              if (!includeRoles) {
                return results
              }

              if (!!results.find(r => r.type === "role") && !!results.find(r => r.type === "user")) {
                const startOfUsersIndex = results.findIndex(r => r.type === "user")
                return [...results.slice(0, startOfUsersIndex), DIVIDER_OPTION, ...results.slice(startOfUsersIndex)]
              }
              return results
            }}
            isOptionEqualToValue={(option, value) => {
              if (includeRoles) {
                return option?.type === value?.type && option?.value === value?.value
              }
              return option?.value === value
            }}
            value={value}
            sx={{
              flex: '1 1 100%',
              ...sx
            }}
            optionSx={{ px: 1 }}
            disabled={disabled}
            onInputChange={(e, value, reason) => {
              if(reason === 'clear'){
                onChange('')
              }
            }}
            error={error}
            helperText={error?.message}
            dataTestId={`assignedTo-${dataTestId}`}
          />
        )
      }}
    />
  )
}

const AssignedToOption = ({ props, data, dataTestId = "" }) => {
  const theme = useTheme()

  if (data.type === DIVIDER_OPTION.type) {
    return (
      <Divider
        key={DIVIDER_OPTION.type}
        sx={{ borderColor: theme.palette.greys.light, mx: 2, my: 1 }}
      />
    )
  }

  return (
    <Box
      component={"li"}
      {...props}
      data-testid={dataTestId}
    >
      {data.label}
    </Box>
  )
}