import { gql } from "@apollo/client";


export const UPDATE_BOOKING_PREFERENCE = gql`
  mutation UpdateBookingPreference($tenantId: ID!, $scheduleBlockId: ID!, $scheduleBlockInput: ScheduleBlockInput!) {
    tenant(id: $tenantId) {
      schedule {
        updateBookingPreference(id: $scheduleBlockId, input: $scheduleBlockInput) {
          id
          blockType
          end
          itemDate
          scheduleId
          start
          title
          version
          resource: scheduleId
          appointmentTypes {
            id
            name
          }
        }
      }
    }
  }
`

export const DELETE_BOOKING_PREFERENCE = gql`
  mutation DeleteBookingPreference($tenantId: ID!, $scheduleBlockId: ID!) {
    tenant(id: $tenantId) {
      schedule {
       deleteBookingPreference(id: $scheduleBlockId) 
       }
    }
  }
`


export const ADD_NEW_BOOKING_PREFERENCE = gql`
  mutation CreateBookingPreference($tenantId: ID!, $input: ScheduleBlockInput!) {
    tenant(id: $tenantId) {
      schedule {
        createBookingPreference(input: $input)
      }
    }
  }
`
