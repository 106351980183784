import * as yup from "yup";

export const schema = yup.object({
  name: yup.string().required("Required"),
  description: yup.string().notRequired(),
  category: yup.string().required("Required"),
  columns: yup.array().of(yup.object()),
  parameters: yup.array().of(yup.object()),
  queries: yup.array().of(yup.object()),
  version: yup.string().required("Required")
})
