import { ConditionAndProcedureType, ConditionStatus } from "../../../../../types/ConditionAndProcedure";

export const conditionAndProcedureDefaults: ConditionAndProcedureType = {
  description: null,
  diagnosticCode: null,
  diagnosticCodeId: '',
  status: ConditionStatus.ACTIVE,
  severity: null,
  startDate: null,
  endDate: null,
  notes: ''
}