import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AbLabResult, AbLabResultUpdate } from '../../../../types/patients'
import { PatientSectionHeader } from '../PatientSectionHeader'
import { useForm } from 'saga-library/src/components/Form'
import { Section } from 'saga-library/src'
import { Permission, PermissionType } from '../../../../types/settings/Permission'
import { useAccountContext } from '../../../../providers/AccountContext'
import { usePrompt } from '../../../../providers/NavigationPrompt'
import { LabAndInvestigationForm } from './LabAndInvestigationForm'
import { FormProvider } from 'react-hook-form'
import { schema } from './LabAndInvestigationFormValidationSchema'
import { LabAndInvestigationLabel, LabAndInvestigationPatientLabel } from './components/LabAndInvestigationLabel'
import { useQuery } from '@apollo/client'
import { GET_LAB_AND_INVESTIGATION } from '../../../../graphql-definitions'
import _get from 'lodash/get'
import { FileType } from '../../../../types/tasks'
import { LabAndInvestigationFileNavigation } from './components/LabAndInvestigationFileNavigation'
import { LoadingSpinner } from '../../../../components/LoadingScreen'
import { LabAndInvestigationPrintButton } from './LabAndInvestigationsPrintButton'
import { PrintOnly } from '../../../../components/PrintOnly'

const FORM_NAME = 'labs_and_investigations_form'

interface LabAndInvestigationEditorContentProps {
  files: FileType[],
  file: FileType | null,
  setFile: (file: FileType | null) => void,
  labAndInvestigation: AbLabResult | null,
  isSubmitting?: boolean,
  formMethods: any,
  resetFormState: (lab: AbLabResult) => void,
  loading?: boolean,
  dataTestId?: string
}

export const LabAndInvestigationEditorContent = ({
  files,
  file,
  setFile,
  labAndInvestigation,
  isSubmitting,
  formMethods,
  resetFormState,
  loading,
  dataTestId
}: LabAndInvestigationEditorContentProps) => {
  const navigate = useNavigate()
  const { tenant_id, patient_id } = useParams()
  const { userHasPermission } = useAccountContext()
  const hasEditPermission = (tenant_id && userHasPermission(tenant_id, PermissionType.Chart, Permission.READWRITE))

  const printComponentRef = useRef<any>(null)

  const headerActions = () => {
    return (
      <>
        {
          files.length > 1 &&
          <LabAndInvestigationFileNavigation
            files={files}
            selectedFile={file}
            setSelectedFile={setFile}
            dataTestId={'editLabAndInvestigation'}
          />
        }
        <LabAndInvestigationPrintButton
          labAndInvestigation={labAndInvestigation}
          file={file}
          printComponentRef={printComponentRef}
          patientId={patient_id}
        />
      </>
    )
  }

  return (
    <Section.Column
      refElement={printComponentRef}
      sx={{ flex: '1 1 100%' }}
      preventScroll={true}
      header={
        <PatientSectionHeader
          dataTestId={'editLabAndInvestigation'}
          sectionLabel={labAndInvestigation ?
            <>
              <LabAndInvestigationLabel labAndInvestigation={labAndInvestigation} />
              <PrintOnly componentKey={labAndInvestigation.id}>
                <LabAndInvestigationPatientLabel
                  labAndInvestigation={labAndInvestigation}
                  dataTestId={'lab-patient-label'}
                />
              </PrintOnly>
            </> : ''}
          showBackButton={true}
          onBack={() => navigate(-1)}
          formName={FORM_NAME}
          showSave={!!hasEditPermission as boolean}
          submitting={isSubmitting}
          actions={headerActions()}
        />
      }
    >
      <FormProvider {...formMethods}>
        {loading
          ? <LoadingSpinner />
          : <LabAndInvestigationForm
            formName={FORM_NAME}
            key={dataTestId}
            file={file}
            labAndInvestigation={labAndInvestigation}
            dataTestId={'editLabAndInvestigation'}
            onSuccess={(updatedLab) => resetFormState(updatedLab)}
          />
        }
      </FormProvider>
    </Section.Column>
  )
}

export const LabAndInvestigationEditor = () => {
  const { tenant_id, patient_id, lab_id } = useParams()
  const { enableNavigationPrompt } = usePrompt()

  const [labAndInvestigation, setLabAndInvestigation] = useState<AbLabResult | null>(null)
  const [files, setFiles] = useState<FileType[]>([])
  const [file, setFile] = useState<FileType | null>(null)

  const formMethods = useForm<AbLabResultUpdate>({
    schema: schema
  })

  const {
    reset,
    formState: { dirtyFields, isSubmitting }
  } = formMethods

  const resetFormState = (lab: AbLabResult) => {
    reset({
      id: lab.id,
      notes: lab.notes,
      version: lab.version
    })
  }

  const { loading } = useQuery(GET_LAB_AND_INVESTIGATION, {
    variables: {
      tenantId: tenant_id,
      patientId: patient_id,
      id: lab_id
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: async (data) => {
      const lab: AbLabResult = _get(data, 'tenant.patient.labAndInvestigation.get', null)

      if (lab) {
        const resultsWithEmbeddedFile = lab?.abLabResultObservationResults
          ?.filter(r => r.file)
          ?.map(r => r.file!)

        setFiles(resultsWithEmbeddedFile)
        resultsWithEmbeddedFile.length > 0 ? setFile(resultsWithEmbeddedFile[0]) : setFile(null)

        resetFormState(lab)
      }

      setLabAndInvestigation(lab)
    },
    onError: error => {
      console.error(JSON.stringify(error, null, 2))
    }
  })

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, `labs-investigations/`)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length])

  return (
    <LabAndInvestigationEditorContent
      files={files}
      file={file}
      setFile={setFile}
      labAndInvestigation={labAndInvestigation}
      isSubmitting={isSubmitting}
      formMethods={formMethods}
      resetFormState={resetFormState}
      loading={loading}
    />
  )
}