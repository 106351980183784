import { ErrorBoundary, FilterSelect, IconButton, Section, SubsectionMenu, SubsectionMenuItem, Typography } from "saga-library/src";
import React, { Suspense, useState, useEffect, useCallback } from "react";
import { Box, SvgIconProps } from "@mui/material";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import {ReactComponent as LabsImagingIcon} from "../../assets/LabsImagingIcon.svg";
import ForumIcon from '@mui/icons-material/ForumOutlined'
import MailIcon from '@mui/icons-material/MailOutlined'
import ArticleIcon from '@mui/icons-material/ArticleOutlined'
import ChecklistIcon from '@mui/icons-material/ChecklistOutlined'
import UploadFileIcon from '@mui/icons-material/UploadFileOutlined'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { useSuspenseQuery } from "@apollo/client";
import { GET_TENANT_USERS } from "../../graphql-definitions";
import _get from "lodash/get";
import { useParams } from "react-router-dom";
import { useAccountContext } from "../../providers/AccountContext"
import { ImportedDocuments } from "./documents/ImportedDocuments"
import { LoadingSpinner } from "../../components/LoadingScreen";

const MENU_OPEN_WIDTH = '289px'
const MENU_COLLAPSED_WIDTH = '56px'

type TaskMenuItemData = {
  text: string,
  icon: React.ReactElement<SvgIconProps>
}

const taskMenuItems: TaskMenuItemData[] = [
  {text: "All", icon: <AssignmentTurnedInIcon />},
  {text: "Labs/Imaging", icon: <LabsImagingIcon />},
  {text: "Referrals", icon: <ForumIcon />},
  {text: "Letters", icon: <MailIcon />},
  {text: "Forms", icon: <ArticleIcon />},
  {text: "Other tasks", icon: <ChecklistIcon />},
  {text: "Imported documents", icon: <UploadFileIcon />}
]

const FallbackMenu = ({ children }) => {
  return (
    <Section.Column
      rightPadding={1}
      width={MENU_OPEN_WIDTH}
    >
      {children}
    </Section.Column>
  )
}

export const Tasks = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>(taskMenuItems[0].text)

  const displaySelection = useCallback(() => {
    if (selectedMenuItem === "Imported documents") return <ImportedDocuments />

    return (
      <Section.Column sx={{ flex: "1 1 auto"}}>
        <Typography variant={'h2'}>Placeholder for section display</Typography>
      </Section.Column>
    )
  }, [selectedMenuItem])

  return (
    <Section.Container>
      <ErrorBoundary
        fallback={
          <FallbackMenu>
            <Box color={(theme) => theme.palette.error.main}>Failed to load</Box>
          </FallbackMenu>
        }
      >
        <Suspense
          fallback={
            <FallbackMenu>
              <LoadingSpinner label={null} />
            </FallbackMenu>
          }
        >
          <TasksMenu
            selectedMenuItem={selectedMenuItem}
            setSelectedMenuItem={setSelectedMenuItem}
          />
        </Suspense>
      </ErrorBoundary>
      {displaySelection()}
    </Section.Container>
  )
}

const TasksMenu = ({ selectedMenuItem, setSelectedMenuItem }) => {
  const { tenant_id } = useParams()
  const { userId} = useAccountContext()
  const [menuIsCollapsed, setMenuIsCollapsed] = useState<boolean>(false)
  const [importedDocumentsIsOpen, setImportedDocumentsIsOpen] = useState<boolean>(false)

  const { data } = useSuspenseQuery(GET_TENANT_USERS, {
    variables: { tenantId: tenant_id },
    fetchPolicy: 'cache-and-network'
  })

  const userList = _get(data, 'tenant.user.allTenantUsers', [])

  useEffect(() => {
    if (selectedMenuItem === "Imported documents") {
      setMenuIsCollapsed(true)
      setImportedDocumentsIsOpen(true)
    } else {
      setImportedDocumentsIsOpen(false)
    }
  }, [selectedMenuItem])

  const getUserOptions = (userList) => userList.map(user => {
    return {label: `${user.lastName}, ${user.firstName}`, value: user.id}
  })

  return (
    <SubsectionMenu
      dataTestId={"tasks-menu"}
      width={menuIsCollapsed ? MENU_COLLAPSED_WIDTH : MENU_OPEN_WIDTH}
      sx={{minWidth: MENU_COLLAPSED_WIDTH, flex: "0 0 auto", pl: 1}}
      header={
        <Box display={'flex'} flexDirection={'row'}>
          {!menuIsCollapsed &&
            <Box flex={'1 1 auto'} display={'flex'}>
              <FilterSelect
                dataTestId={"tasks-userSelect"}
                options={userList ? getUserOptions(userList) : []}
                onSelect={() => {}}
                defaultSelectedValue={userId}
                size={'large'}
                sx={{justifyContent: 'left'}}
              />
            </Box>
          }
          <IconButton
            dataTestId={"tasks-toggleMenuOpen"}
            sx={{padding: 1, mr:-1}}
            onClick={() => setMenuIsCollapsed(current => !current)}
            icon={menuIsCollapsed ? <ArrowForwardIosIcon/> : <ArrowBackIosNewIcon/>}
          />
        </Box>
      }
      routes={taskMenuItems.map((menuItem, index) => {
        return <SubsectionMenuItem
          dataTestId={`tasks-taskMenu-menuItem-${index}`}
          isActive={selectedMenuItem === menuItem.text}
          startIcon={menuItem.icon}
          primaryText={menuIsCollapsed ? "" : menuItem.text }
          onClick={() => setSelectedMenuItem(menuItem.text)}
        />
      })}
    />
  )
}