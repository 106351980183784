import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'saga-library/src/components/Form'
import { DialogV2 } from 'saga-library/src'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { usePrompt } from '../../../../providers/NavigationPrompt'
import { useMutation } from '@apollo/client'
import { CREATE_TASK_TYPE, LIST_TASK_TYPES } from '../../../../graphql-definitions'
import { TaskTypeInput } from '../../../../types/tasks/TaskType'
import { TaskTypeForm } from './TaskTypeForm'
import { taskTypeDefaults } from './TaskTypeDefaultValues'
import { schema } from './TaskTypeValidationsSchema'
import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'

const FORM_NAME = "new_task_type_form"

export const NewTaskTypeDialog = ({ open, setOpen }) => {
  const { tenant_id } = useParams()
  const { showSuccessAlert, showErrorAlert } = useAlerts()
  const { enableNavigationPrompt } = usePrompt()

  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)

  const [createTaskType] = useMutation(CREATE_TASK_TYPE)

  const formMethods = useForm<TaskTypeInput>({
    defaultValues: taskTypeDefaults,
    schema: schema,
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset,
  } = formMethods

  const onClose = () => {
    setOpen(false)
    reset(taskTypeDefaults)
  }

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        onClose()
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onSave = handleSubmit( async(input: TaskTypeInput) => {
    await createTaskType({
      variables: {
        tenantId: tenant_id,
        input: input
      },
      onCompleted: () => {
        showSuccessAlert("Task type has been saved.")
        onClose()
      },
      onError: (error) => {
        if (error?.graphQLErrors?.length > 0 && error?.graphQLErrors[0]?.extensions?.userError === true) {
          showErrorAlert(error.graphQLErrors[0].message)
        } else {
          showErrorAlert("Task type couldn't be created.")
        }
      },
      update: async (cache, { data }) => {
        const newTaskType = _get(data, 'tenant.task.createTaskType', null)
        await cache.updateQuery({
          query: LIST_TASK_TYPES,
          variables: {
            tenantId: tenant_id
          }
        }, (data) => {
          const existingTaskTypes = _get(data, 'tenant.task.listTaskTypes', [])
          return {
            tenant: {
              task: {
                listTaskTypes: _sortBy([...existingTaskTypes, newTaskType], "type")
              }
            }
          }
        })
      }
    })
  })

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      onClose()
    }
  }

  return (
    <DialogV2
      dataTestId={'new-task-type-dialog'}
      formName={FORM_NAME}
      size={'xs'}
      title={'New task type'}
      submitting={isSubmitting}
      onClose={onCancel}
      open={open}
      contentSx={{ overflowY: "visible" }}
    >
      <FormProvider {...formMethods}>
        <TaskTypeForm
          formName={FORM_NAME}
          onSubmit={onSave}
          dataTestId={"new-task-type-form"}
        />
      </FormProvider>
    </DialogV2>
  )
}