import { Pharmacy } from "saga-client/src/types";

export function patientDisplayName(firstName: string, lastName: string) {
  return `${lastName}, ${firstName}`
}

export function userDisplayName(firstName: string, lastName: string) {
  return `${lastName}, ${firstName}`
}

export function referralPractitionerDisplayName(firstName: string, lastName: string) {
  return `${lastName}, ${firstName}`
}

export function practitionerDisplayName(firstName: string, lastName: string) {
  return `${lastName}, ${firstName}`
}

export function practitionerLocationDisplayName(firstName: string, lastName: string, location: string | null) {
  return `${lastName}, ${firstName} (${location})`
}

export function recipientListDisplayName(firstName: string, lastName: string, numOfRecipients: number) {
  const recipientName = `${lastName}, ${firstName}`
  const otherRecipients = numOfRecipients === 2 ? ' and 1 other' : numOfRecipients > 2 ? ` and ${numOfRecipients - 1} others` : ''
  return `${recipientName}${otherRecipients}`
}

export function fullAddress(pharmacy: Pharmacy) {
  const { street1, province, city, postalCode } = pharmacy
  return `${street1 ? street1 + ',' : ''} ${city ? city + ',' : ''} ${province ? province : ''} ${postalCode  ? postalCode : ''}`
}

export const stringToPascalCase = (string) => {
  if(string) {
    return `${string}`
      .toLowerCase()
      .replace(new RegExp(/[-_]+/, 'g'), ' ')
      .replace(new RegExp(/[^\w\s]/, 'g'), '')
      .replace(
        new RegExp(/\s+(.)(\w*)/, 'g'),
        ($1, $2, $3) => `${$2.toUpperCase() + $3}`
      )
      .replace(new RegExp(/\w/), s => s.toUpperCase())
  }
}

export const toHoursAndMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60

  const padToTwoDigits = (num) => {
    return num.toString().padStart(2, '0')
  }

  return `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)}`
}
