import React, { useEffect } from "react"
import Box from '@mui/material/Box'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ChartSettingsLayout, TaskSettingsLayout, TenantSettingsLayout } from "../components/Layouts";
import { General } from '../apps/settings/General'
import { AppSetup } from '../apps/settings/AppSetup'
import { RolesAndPermissions } from '../apps/settings/RolesAndPermissions'
import { Identifiers } from '../apps/settings/Charting/Identifiers'
import { Reports } from '../apps/settings/Reports'
import { Pharmacies } from '../apps/settings/Pharmacies/Pharmacies'
import { User } from '../apps/settings/User'
import { PractitionersRoutes } from './PractitionerRoutes'
import { Billing } from '../apps/settings/Billing'
import { TenantRoleNew } from '../apps/settings/Roles/TenantRoleNew'
import { TenantRoleUpdate } from '../apps/settings/Roles/TenantRoleUpdate'
import { Locations } from '../apps/settings/Locations/Locations'
import { LocationNew } from "../apps/settings/Locations/LocationNew"
import { LocationUpdate } from "../apps/settings/Locations/LocationUpdate"
import { Schedule } from "../apps/settings/Schedule"
import { AppointmentTypes } from "../apps/settings/Schedule/AppointmentType/AppointmentTypes";
import { AppointmentStates } from "../apps/settings/Schedule/AppointmentState/AppointmentStates";
import { AppointmentRooms } from "../apps/settings/Schedule/AppointmentRoom/AppointmentRooms";
import { PharmacyNew } from "../apps/settings/Pharmacies/PharmacyNew";
import { PharmacyUpdate } from "../apps/settings/Pharmacies/PharmacyUpdate";
import { LetterTemplates } from "../apps/settings/Charting/LetterTemplates";
import { TaskTypes } from "../apps/settings/Tasks/Types/TaskTypes";
import { TaskStates } from "../apps/settings/Tasks/TaskStates";
import { TaskTemplates } from "../apps/settings/Tasks/TaskTemplates";
import { usePageTitleContext } from "../providers/PageTitleContextProvider";


export const Settings = () => {
  const { setAreaTitle } = usePageTitleContext()

  useEffect(() => {
    setAreaTitle('Settings')
  }, [setAreaTitle])

  return (
    <Box height={'100%'}>
      <Routes>
        <Route path="/" element={<TenantSettingsLayout />}>
          <Route path="general" element={<General />} />
          <Route path={'locations'}>
            <Route path={'new'} element={<LocationNew />} />
            <Route path={'l/:location_id'} element={<LocationUpdate />} />
            <Route index element={<Locations />} />
          </Route>
          <Route path="setup" element={<AppSetup />} />
          <Route path="roles">
            <Route path={'new'} element={<TenantRoleNew />} />
            <Route path={'r/:role_id'} element={<TenantRoleUpdate />} />
            <Route index element={<RolesAndPermissions />} />
          </Route>
          <Route path={"schedule"}>
            <Route path={'types'} element={<AppointmentTypes />} />
            <Route path={'states'} element={<AppointmentStates />} />
            <Route path={'rooms'} element={<AppointmentRooms />} />
            <Route index element={<Schedule />} />
          </Route>
          <Route path="users" element={<User />} />
          <Route path="practitioners//*" element={<PractitionersRoutes />} />
          <Route path="charting" element={<ChartSettingsLayout />}>
            <Route index element={<Navigate to={'identifiers'} replace={true} />} />
            <Route path={'identifiers'} element={<Identifiers />} />
            <Route path={'letters'} element={<LetterTemplates />} />
          </Route>
          <Route path="tasks" element={<TaskSettingsLayout />}>
            <Route index element={<Navigate to={'types'} replace={true} />} />
            <Route path={'types'} element={<TaskTypes />} />
            <Route path={'states'} element={<TaskStates />} />
            <Route path={'templates'} element={<TaskTemplates />} />
          </Route>
          <Route path="billing" element={<Billing />} />
          <Route path="reports" element={<Reports />} />
          <Route path={'pharmacies'}>
            <Route path={'new'} element={<PharmacyNew />} />
            <Route path={'p/:pharmacy_id'} element={<PharmacyUpdate />} />
            <Route index element={<Pharmacies />} />
          </Route>
        </Route>
      </Routes>
    </Box>
  )
}
