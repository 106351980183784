import React, { useEffect, useRef, useState } from "react";
import { Alert, AlertTitle, Box } from "@mui/material";
import { Section, Tabs, Form } from "saga-library/src";
import { ClaimInputType } from "../../../types/billing";
import { ABClaimActionCode, ABClaimAssessmentOutcome } from "../../../utils/ABClaimConstants";
import { useMutation, useQuery } from "@apollo/client";
import { ClaimOptions } from "./ClaimOptions";
import { AssessedClaimBase } from "./AssessedClaimBase";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { claimDefaults } from "./NewClaimDetails";
import { schema } from "../util/baseClaimValidation";
import { useNavigate, useParams } from "react-router-dom";
import { useAlerts } from "saga-library/src/providers/Alerts";
import _get from "lodash/get";
import { LoadingSpinner } from "../../../components/LoadingScreen";
import { StaticPage } from "../../../components/Layouts";
import { AssessedClaimDialogs } from "./AssessedClaimDialogs";
import { AssessedClaimHeader } from "./AssessedClaimHeader";
import { useAccountContext } from "../../../providers/AccountContext";
import { setAbClaimValues } from "../util/setAbClaimValues";
import { setProblems } from "../util/setProblems";
import { createClaimInputFromFormData } from "../util/createClaimInputFromFormData";
import { ClaimHistory } from "./ClaimHistory";
import { usePrompt } from "../../../providers/NavigationPrompt";
import { ReadOnlyFormActionsMask } from "../../../components/ReadOnlyFormActionsMask";
import { Permission, PermissionType } from "../../../types/settings/Permission";
import {
  ACCEPT_ADJUSTMENT,
  CANCEL_RESUBMISSION,
  CANCEL_WRITEOFF,
  GET_ASSESSEDCLAIM,
  RESUBMIT_CLAIM,
  WRITEOFF_CLAIM
} from "../../../graphql-definitions";
import { AssessedClaimsInfo } from "./AssessedClaimsInfo";
import FormColumn from "./FormColumn";

const FORM_NAME = "assessed_claim_update_details_form"

export const AssessedClaimUpdateDetails = () => {

  const [openResubmitOptions, setOpenResubmitOptions] = React.useState(false)
  const [openWriteoffDialog, setOpenWriteoffDialog] = React.useState(false)
  const [openReassessmentWarningDialog, setOpenReassessmentWarningDialog] = React.useState(false)
  const [openDeletionWarningDialog, setOpenDeletionWarningDialog] = React.useState(false)
  const [openCancelResubmissionDialog, setOpenCancelResubmissionDialog] = React.useState(false)
  const [openCancelWriteoffDialog, setOpenCancelWriteoffDialog] = React.useState(false)
  const [writeoffClaim, { error: writeoffError }] = useMutation(WRITEOFF_CLAIM)
  const [claimHistory, setClaimHistory] = useState([])

  const { tenant_id, claim_id } = useParams()
  const { showErrorAlert, showSuccessAlert, showWarningAlert } = useAlerts()
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()

  const { enableNavigationPrompt } = usePrompt()
  const formRef = useRef<HTMLFormElement>(null)

  const{ loading: initialLoading, data: initialData, error: initialError  } = useQuery(
    GET_ASSESSEDCLAIM,
    {
      variables: {
        claimId: claim_id,
        tenantId: tenant_id,
      },
      onCompleted: (data) => {
        const claim = _get(data, 'tenant.abClaim.abClaim', null)
        const problems = _get(data, 'tenant.abClaim.abClaim.problems', null)
        if(claim.id === claim_id) {
          setAbClaimValues(claim, reset)
          setProblems(problems, setError)
          setClaimHistory(claim.history)
        }
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert("Claim couldn't be retrieved.")
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const [ acceptClaim ] = useMutation(ACCEPT_ADJUSTMENT, {
    variables: {
      claimId: claim_id,
      tenantId: tenant_id,
    },
    onCompleted: (data) => {
      navigate(buildTenantRoute(`billing/assessed`, tenant_id))
      showSuccessAlert("Claim has been accepted.")
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert("Claim couldn't be accepted.")
    },
  })

  const [ cancelResubmission ] = useMutation(CANCEL_RESUBMISSION, {
    variables: {
      claimId: claim_id,
      tenantId: tenant_id,
    },
    onCompleted: (data) => {
      showSuccessAlert("Resubmission has been cancelled.")
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert("Resubmission couldn't be cancelled.")
    },
  })

  const handleWriteoff = (code) => {
    writeoffClaim({
      variables: {
        tenantId: tenant_id,
        id: claim_id,
        code: code
      },
      onCompleted: () => {
        showSuccessAlert('Claim has been written off.')
      },
      onError: () => {
        console.error(JSON.stringify(writeoffError, null, 2))
        showErrorAlert('Claim couldn\'t be written off.')
      },
      update(cache) {
        const recentClaim = _get(initialData, 'tenant.abClaim.abClaim', null)
        cache.modify({
          id: cache.identify(recentClaim),
          fields: {
            acceptCode(recentCode) {
              return code
            },
          },
        })
      },
    })
  }

  const formMethods = useForm<ClaimInputType>({
    defaultValues: claimDefaults,
    schema: schema
  });
  const {
    setValue,
    getValues,
    formState: { dirtyFields, isSubmitSuccessful },
    reset,
    setError,
    handleSubmit,
  } = formMethods

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful])

  const [ cancelWriteOff ] = useMutation(CANCEL_WRITEOFF, {
    variables: {
      claimId: claim_id,
      tenantId: tenant_id,
    },
    onCompleted: (data) => {
      setOpenCancelWriteoffDialog(false)
      const values = getValues()
      values.acceptCode = null
      reset(values)
      showSuccessAlert("Claim can now be resubmitted to ACHIP.")
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert("Write off couldn't be cancelled.")
    },
    update(cache) {
      const recentClaim = _get(initialData, 'tenant.abClaim.abClaim', null)
      cache.modify({
        id: cache.identify(recentClaim),
        fields: {
          acceptCode(recentCode) {
            return null
          }
        },
      })
    }
  })

  const readOnlyOptions = [
    {
      label: 'BASE',
      key: 'BASE',
      content: (
        <fieldset disabled style={{margin: '0px', padding: '0px', border: '0px'}} >
          <AssessedClaimBase />
        </fieldset>
      ),
    },
    {
      label: 'OPTIONS',
      key: 'OPTIONS',
      content: (
        <fieldset disabled style={{margin: '0px', padding: '0px', border: '0px'}} >
          <ClaimOptions.Form />
        </fieldset>
      ),
    },
    {
      label: 'INFO',
      key: 'INFO',
      content: (
        <fieldset disabled style={{margin: '0px', padding: '0px', border: '0px'}} >
          <AssessedClaimsInfo.Form />
        </fieldset>
      )
    },
    {
      label: 'HISTORY',
      key: 'HISTORY',
      content: (
        <fieldset disabled style={{margin: '0px', padding: '0px', border: '0px'}} >
          <ClaimHistory.HistoryTable history={claimHistory} />
        </fieldset>
      )
    }
  ]

  const options = [
    {
      label: 'BASE',
      key: 'BASE',
      content: (
        <AssessedClaimBase />
      ),
    },
    {
      label: 'OPTIONS',
      key: 'OPTIONS',
      content: (
        <ClaimOptions.Form />
      ),
    },
    {
      label: 'INFO',
      key: 'INFO',
      content: (
        <AssessedClaimsInfo.Form />
      )
    },
    {
      label: 'HISTORY',
      key: 'HISTORY',
      content: (
        <ClaimHistory.HistoryTable
          history={claimHistory}
        />
      )
    }
  ]

  const [resubmitClaim, { loading: resubmitLoading }] = useMutation(RESUBMIT_CLAIM, {
      onCompleted: (data) => {
        if (!!data.tenant.assessedAbClaim.resubmitClaim) {
          navigate(buildTenantRoute(`billing/assessed`, tenant_id))
          const problems = _get(data, 'tenant.assessedAbClaim.resubmitClaim.problems', null)
          if (problems?.length) {
            let containsError = problems.some(problem => problem.severity === 'ERROR')
            if (!containsError) {
              showWarningAlert("Claim has been resubmitted but contains warnings. The claim may be be rejected by AHCIP.")
            }
            else {
              showErrorAlert("Claim has been saved but contains errors. It cannot be submitted until all errors are resolved.")
            }
          }
          else {
            showSuccessAlert("Claim has been resubmitted.")
          }
        }
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert("Claim couldn't be resubmitted.")
      }
    }
  )

  const onResubmitWithCode = handleSubmit(async(data) => {
    if (resubmitLoading) {
      return
    }

    const cleanedData = createClaimInputFromFormData(data)
    await resubmitClaim({
      variables: {
        tenantId: tenant_id,
        claimId: claim_id,
        claimData: cleanedData,
        actionCode: getValues('actionCode')
      }
    })
  })

  const resubmitWithCode = (actionCode: ABClaimActionCode) => {
    setValue('actionCode', actionCode)
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      )
    }
  }

  const UpdateAssessedClaimHeader = () => {
    if(initialLoading && !initialData){
      return null
    }

    return <Box
      display={'flex'}
      flexDirection={'row'}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Section.Header>
        {getValues('claimIdentifier')}
      </Section.Header>
      <ReadOnlyFormActionsMask requiredType={PermissionType.Billing} requiredPermission={Permission.READWRITE}>
        <AssessedClaimDialogs
          writeOffProps={{open: openWriteoffDialog, setOpen: setOpenWriteoffDialog, accept: handleWriteoff}}
          reassessmentWarningProps={{open: openReassessmentWarningDialog, setOpen: setOpenReassessmentWarningDialog, accept: () => resubmitWithCode(ABClaimActionCode.REASSESS)}}
          deletionWarningProps={{open: openDeletionWarningDialog, setOpen: setOpenDeletionWarningDialog, accept: () => resubmitWithCode(ABClaimActionCode.DELETE)}}
          cancelResubmissionProps={{open: openCancelResubmissionDialog, setOpen: setOpenCancelResubmissionDialog, accept: cancelResubmission}}
          cancelWriteOffProps={{open: openCancelWriteoffDialog, setOpen: setOpenCancelWriteoffDialog, accept: cancelWriteOff}}
          batchMode={false}
        />
        <AssessedClaimHeader
          getValues={getValues}
          setOpenCancelWriteoffDialog={setOpenCancelWriteoffDialog}
          setOpenResubmitOptions={setOpenResubmitOptions}
          openResubmitOptions={openResubmitOptions}
          setOpenReassessmentWarningDialog={setOpenReassessmentWarningDialog}
          setOpenDeletionWarningDialog={setOpenDeletionWarningDialog}
          setOpenCancelResubmissionDialog={setOpenCancelResubmissionDialog}
          setOpenWriteoffDialog={setOpenWriteoffDialog}
          onAcceptButtonClick={acceptClaim}
          resubmitWithCode={resubmitWithCode}
        />
      </ReadOnlyFormActionsMask>
    </Box>
  }

  return (
    <FormProvider {...formMethods}>
      <FormColumn header={<UpdateAssessedClaimHeader />}>
      {initialLoading && !initialData && (
        <LoadingSpinner />
      )}
      {initialData && (
        <Box display={'flex'} flexDirection={'column'}>
          <Form onSubmit={onResubmitWithCode} ref={formRef}>
            {initialData.tenant.abClaim.abClaim.problems?.some(p => p.field === 'claim' && p.severity === 'WARNING') &&
              <Alert severity="warning">
                <AlertTitle>Warning</AlertTitle>
                {initialData.tenant.abClaim.abClaim.problems.filter(p => p.field === 'claim' && p.severity === 'WARNING').map( p => <>{p.message}<br/></> )}
              </Alert>
            }
            {initialData.tenant.abClaim.abClaim.problems?.some(p => p.field === 'claim' && p.severity === 'ERROR') &&
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {initialData.tenant.abClaim.abClaim.problems.filter(p => p.field === 'claim' && p.severity === 'ERROR').map( p => <>{p.message}<br/></> )}
              </Alert>
            }
            {initialData.tenant.abClaim.abClaim.assessmentOutcome === ABClaimAssessmentOutcome.HELD && <Tabs options={readOnlyOptions}/>}
            {initialData.tenant.abClaim.abClaim.assessmentOutcome !== ABClaimAssessmentOutcome.HELD && <Tabs options={options}/>}
          </Form>
        </Box>
      )}

      {initialError && (
        <StaticPage>
          Sorry, we could not find the requested claim.
        </StaticPage>
      )}
      </FormColumn>
    </FormProvider>
  )
}

