import { SearchControl } from "./index";
import React from "react";
import { SearchControlProps } from "./SearchControl";

export interface SearchBarProps<
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
  > extends Omit<SearchControlProps<
  T,
  Multiple,
  DisableClearable,
  FreeSolo
  >, 'error'> {

}

export const SearchBar = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined = undefined
  >
({
  label,
  inputRef,
  value = undefined,
  ...props
}:SearchBarProps<T, Multiple, DisableClearable, FreeSolo>) => {
  return (
    <SearchControl<T, Multiple, DisableClearable, FreeSolo>
      {...props}
      label={''}
      error={false}
      value={value}
      blurOnSelect
      clearIcon={null}
      autoHighlight
    />
  )

}