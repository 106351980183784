import React, { useEffect, useState } from "react";
import { Fade } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { DialogV2, LoadingButton, Typography } from 'saga-library/src'
import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import _get from 'lodash/get'
import _some from 'lodash/some'
import { useForm, FormProvider } from "saga-library/src/components/Form"
import NewInviteForm from './NewInviteForm'
import { schema } from '../util/inviteValidation'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { GENERATE_INVITES, GET_TENANT_ROLES, GET_TENANT_USERS } from '../../../graphql-definitions'
import { usePrompt } from "../../../providers/NavigationPrompt";

const FORM_NAME = 'new_invite_form'

interface NewInviteModalProps {
  open: boolean
  setOpen: (a: boolean) => void
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Fade ref={ref} {...props} />
})

export const NewInviteModal = ({ open, setOpen }: NewInviteModalProps) => {
  const { tenant_id } = useParams()
  const [roles, setRoles] = useState([])
  const [ openNavigationPrompt, setOpenNavigationPrompt] = useState(false)
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const { enableNavigationPrompt } = usePrompt()

  const [generateInvites, { loading: mutationLoading }] = useMutation(
    GENERATE_INVITES,
    {
      onCompleted: (data) => {
        let results = _get(data, 'tenant.invite.generateInvite', []);
        let successes = _some(results, { sent: true })
        let failures = _some(results, { sent: false })

        if (successes && !failures) {
          showSuccessAlert('Invites have been sent.')
        }
        else if (!successes && failures) {
          showErrorAlert('Invites couldn\'t be sent.')
        }
        else {
          let failures = ""
          results.forEach(invite => {
            if (!invite.success) {
              console.log("building message ", invite)
              failures += invite.emailAddress + "; "
            }
          })
          showErrorAlert('Invites couldn\'t be sent to these email addresses: ' + failures)
        }

        handleClose()
      },
      onError: (error) => {
        console.error(error)
        showErrorAlert('Invites couldn\'t be sent.')
      },
      refetchQueries: [
        {
          query: GET_TENANT_USERS,
          variables: { tenantId: tenant_id },
        },
      ],
    }
  )

  const { error, data } = useQuery(GET_TENANT_ROLES, {
    fetchPolicy: 'cache-first',
    variables: { tenantId: tenant_id },
  })

  const formMethods = useForm({
    defaultValues: {
      question: '',
      passcode: '',
      confirmAnswer: null,
      emailAddresses: [],
      roleIds: []
    },
    schema: schema,
  })
  const {
    handleSubmit,
    formState: { errors, isSubmitSuccessful, dirtyFields },
    reset,
  } = formMethods

  useEffect(() => {
    reset()
  }, [isSubmitSuccessful])

  const handleClose = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      reset()
      setOpen(false)
    }
  }

  const handleNavigationPromptDiscard = (discard : boolean) => {
    if (openNavigationPrompt) {
      if (discard) {
        reset()
        setOpen(false)
      }
      setOpenNavigationPrompt(false)
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  useEffect(() => {
    const tenantRoles = _get(data, 'tenant.role.tenantRoles', null)
    if (error) {
      console.error(JSON.stringify(error, null, 2))
    } else if (data) {
      setRoles(tenantRoles.filter(r => !r.isOwner))
    }
  }, [data, error])

  const onSubmit = handleSubmit(async (data) => {
    await generateInvites({
      variables: {
        tenantId: tenant_id,
        emailAddresses: data.emailAddresses,
        passcode: data.passcode,
        question: data.question,
        roleIds: data.roleIds,
      },
    })
  })

  const renderDialogContent = () => {
    if(error) {
      return <>An error occurred loading available roles</>
    }
    if(data) {
      return <FormProvider {...formMethods}>
        <Typography variant={'body1'}>
          Make sure you give each person you invite the appropriate
          permission level for their clinic role.
        </Typography>
        <NewInviteForm
          onSubmit={onSubmit}
          roles={roles}
          errors={errors}
        />
      </FormProvider>
    }
    return null
  }

  return (
    <DialogV2
      title={"Invite Users"}
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      primaryAction={()=>null}
      overridePrimaryComponent={
        <LoadingButton
          name={'send-invites'}
          onClick={onSubmit}
          type={'button'}
          loading={mutationLoading}
          disabled={!!error}
          variant={'contained'}
          data-testid={"newInviteModal-send-button"}
        >
          Send
        </LoadingButton>
      }
      data-testid={'new-invite-dialog'}
    >
      {renderDialogContent()}
    </DialogV2>
  )
}
