import React, { useEffect, Suspense } from "react";
import { LoadingOverlay } from "../../components/LoadingScreen";
import { Outlet } from "react-router-dom";


export const PatientBase = () => {
  return (
    <Suspense fallback={<LoadingOverlay loading={true} message={"Loading..."}/>}>
      <Outlet/>
    </Suspense>
  )
}