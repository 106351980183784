import * as React from 'react'
import Box from '@mui/material/Box'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import {
  DatePicker,
  DatePickerSlotsComponentsProps,
  LocalizationProvider, PickersDay as MuiPickersDay, PickersDayProps,
  StaticDatePickerSlotsComponentsProps
} from "@mui/x-date-pickers";
import { Controller, useFormContext } from "saga-library/src/components/Form";
import { SxProps } from "@mui/system";
import {
  FormHelperTextProps,
  InputLabelProps,
  TextFieldProps,
  Theme
} from "@mui/material";
import {
  InputProps,
  SlotButtonProps,
  SlotIconProps,
  StaticDatePickerSlot,
  DayCalendarSlotProps,
  useSchemaRequired
} from "saga-library/src";
import { useEffect, useState } from 'react'
import { useGridApiContext } from '@mui/x-data-grid'

export const PickersDay = (props: PickersDayProps<any> & {dataTestId? : string}) => {
  return <MuiPickersDay {...props} data-testid={`${props.dataTestId}-day-${props.day?.date()}`}/>
}

export const staticDatePickerSlotDataTestId : StaticDatePickerSlot = {
    day: PickersDay
}

export const staticDatePickerSlotPropsDataTestId = (dataTestId : string) : StaticDatePickerSlotsComponentsProps<any> => {
  return {
    day: {
      "data-testid": `${dataTestId}`
    } as DayCalendarSlotProps,
    leftArrowIcon: {
      "data-testid": `${dataTestId}-leftArrowIcon`
    } as SlotIconProps,
    nextIconButton: {
      "data-testid": `${dataTestId}-nextIconButton`
    } as SlotButtonProps,
    previousIconButton: {
      "data-testid": `${dataTestId}-previousIconButton`
    } as SlotButtonProps,
    rightArrowIcon: {
      "data-testid": `${dataTestId}-rightArrowIcon`
    } as SlotIconProps,
    switchViewButton: {
      "data-testid": `${dataTestId}-switchViewButton`
    } as SlotButtonProps,
    switchViewIcon: {
      "data-testid": `${dataTestId}-switchViewIcon`
    } as SlotIconProps
  }
}

export interface ControlledDatePickerInterface {
  dataTestId?: string
  label: string
  name: string
  sx?: SxProps<Theme>,
  onChange?: (input) => void
  format?: string
  disabled?: boolean
}

export const ControlledDatePicker = ({
  dataTestId,
  label,
  name,
  sx,
  onChange,
  format = "YYYY/MM/DD",
  disabled = false
}: ControlledDatePickerInterface) => {
  const { control, formState: { isSubmitted } } = useFormContext();
  const required = useSchemaRequired(name)

  return (
    <Box sx={{ m: theme => theme.spacing(1, 0), ...sx }}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange : controlledOnChange, value, ref }, fieldState: {error} }) => {
          return (
            <SimpleDatePicker
              dataTestId={dataTestId}
              label={label}
              value={value || null}
              onChange={(v) => {
                if (onChange) onChange(v)
                controlledOnChange(v)
              }}
              error={error}
              helperText={error?.message}
              required={required}
              sx={sx}
              format={format}
              inputRef={ref}
              isSubmitted={isSubmitted}
              disabled={disabled}
            />
          )}
        }
      />
    </Box>
  )
}

export interface SimpleDatePickerProps {
  dataTestId?: string
  label: string
  value: any
  onChange: (date, state) => void
  helperText?: string
  error?: any
  required?: boolean
  sx?: SxProps<Theme>
  format?: string
  inputRef?: React.Ref<HTMLInputElement>
  isSubmitted?: boolean
  disabled?: boolean
  clearable?: boolean
}

export const SimpleDatePicker = ({
  dataTestId='',
  label,
  value,
  onChange,
  helperText,
  error,
  required,
  sx,
  format = 'YYYY/MM/DD',
  inputRef: _inputRef,
  isSubmitted = false,
  disabled = false,
  clearable = false
}: SimpleDatePickerProps) => {
  const defaultRef = React.useRef<HTMLInputElement>(null)
  let inputRef = _inputRef != null ? _inputRef : defaultRef

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        data-testid={dataTestId}
        label={label}
        value={value && dayjs(value)}
        views={['year', 'month', 'day']}
        onChange={(date, state) => {
          onChange(date, state)
        }}
        disabled={disabled}
        inputRef={inputRef}
        format={format}
        slots={staticDatePickerSlotDataTestId}
        slotProps={{
          ...staticDatePickerSlotPropsDataTestId(dataTestId),
          field: { clearable: clearable },
          textField: {
            "data-testid": `${dataTestId}-textfield`,
            size: 'small',
            helperText: helperText,
            error: isSubmitted && !!error,
            placeholder: format,
            required: required && !value,
            sx: {
              '& .MuiInputBase-root': { height: '40px', borderRadius: '8px' },
              width: "100%",
              minWidth: 160,
              ...sx
            },
            InputLabelProps: {
              "data-testid": `${dataTestId}-label`
            } as InputLabelProps,
            inputProps: {
              "data-testid": `${dataTestId}-inputField`
            },
            InputProps: {
              "data-testid": `${dataTestId}-input`
            } as InputProps,
            FormHelperTextProps: {
              "data-testid": `${dataTestId}-helperText`
            } as FormHelperTextProps
          } as TextFieldProps,
          openPickerButton: {
            "data-testid": `${dataTestId}-openPickerButton`,
            sx: {
              '&:hover': {
                backgroundColor: 'backgrounds.hover',
              },
            }
          } as SlotButtonProps,
          clearIcon: {
            "data-testid": `${dataTestId}-clearIcon`
          } as SlotIconProps,
          openPickerIcon: {
            "data-testid": `${dataTestId}-openPickerIcon`
          } as SlotIconProps,
        } as DatePickerSlotsComponentsProps<any>}
      />
    </LocalizationProvider>
  );
}

export const GridDatePicker = ({
  dataTestId,
  id,
  field,
  value,
  format = 'YYYY/MM/DD',
}) => {
  const apiRef = useGridApiContext();
  const handleChange = (newValue: unknown) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <SimpleDatePicker
      dataTestId={`${dataTestId}-date-picker`}
      label={''}
      format={format}
      value={value}
      onChange={(date, state) => handleChange(date)}
      sx={{
        flexGrow: 1,
        mt: 0,
        '& .MuiInputBase-input': {
          fontSize: '12px'
        },
        '& .MuiOutlinedInput-root': {
          border: 'none',
          '& fieldset': {
            border: 'none',
          },
          '&:hover fieldset': {
            border: 'none',
          },
          '&.Mui-focused fieldset': {
            border: 'none',
          }
        },
      }}
      clearable={true}
    />
  );
}

export const SimpleStartEndDatePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  dataTestId = 'date-picker-',
  dateFormat = "YYYY/MM/DD",
  sx = {}
}) => {
  const [error, setError] = useState<string>('')

  useEffect(() => {
    if (startDate && endDate && endDate.isBefore(startDate)) {
      setError("End date should be greater than start date")
    } else {
      setError('')
    }
  }, [startDate, endDate])

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      gap={1}
      sx={sx}
    >
      <SimpleDatePicker
        dataTestId={`${dataTestId}-start-date`}
        label={'Start date'}
        format={dateFormat}
        value={startDate}
        onChange={(date, state) => setStartDate(date)}
        sx={{flexGrow: 1}}
        clearable={true}
      />
      <SimpleDatePicker
        dataTestId={`${dataTestId}-end-date`}
        label={'End date'}
        format={dateFormat}
        value={endDate}
        error={!!error}
        helperText={error}
        isSubmitted={true}
        onChange={(date, state) => setEndDate(date)}
        sx={{flexGrow: 1}}
        clearable={true}
      />
    </Box>
  )
}

export default ControlledDatePicker
