import React from 'react'
import { Button, Typography } from "saga-library/src";
import { Box, useTheme } from "@mui/material";
import { AbClaimAcceptCode } from "../../../../utils/ABClaimConstants";

export const CancelWriteOffHeader = ({
  claim,
  onClick
}) => {
  const theme = useTheme()

  return (
    <Box sx={{display: 'flex', flexDirection: 'row'}}>
      <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', mr: 1 }}>
        <Typography variant={'body1'} fontWeight={'bold'} color={theme.palette.greys.medium} sx={{m: 'auto'}}>
          {claim.acceptCode === AbClaimAcceptCode.WRITE_OFF_OR_UNCOLLECTABLE? 'UNCOLLECTABLE' : claim.acceptCode.replaceAll('_', ' ')}
        </Typography>
      </Box>
      <Button
        name="cancelWriteoff"
        variant={'outlined'}
        onClick={onClick}
      >
        CANCEL WRITE OFF
      </Button>
    </Box>
  )
}