import { gql } from "@apollo/client";

export const DOSAGE_FRAGMENT = gql`
    fragment DosageFragment on Dosage {
        id
        dosageRange
        dosageUnits
        durationAmount
        durationUnits
        frequency
        prn
        version
        dosageOrder
    }
`

export const PRESCRIPTION_FRAGMENT= gql`
    ${DOSAGE_FRAGMENT}
    fragment PrescriptionFragment on Prescription {
        id
        patientId
        practitionerId
        practitionerName
        drug {
            name
            code
            codeType
        }
        historical
        startDate
        endDate
        quantityAmount
        quantityUnits
        dosageForm
        refills
        refillsExpire
        route
        site
        indication
        additionalInstructions
        status
        link
        dosages {
          ...DosageFragment
        }
        version
        renewedOn
        renewedFrom
        usage
        substitutions
        quantityCalculated
        audit {
          crePit
        }
    }
`


export const GET_PATIENT_PRESCRIPTIONS = gql`
    ${PRESCRIPTION_FRAGMENT}
    query GetPatientPrescriptions($patientId: ID!, $tenantId: ID!) {
      tenant(id: $tenantId) {
        patient(id: $patientId) {
          prescription {
            list {
              ...PrescriptionFragment
            }
          }
        }
      }
    }
`



export const CREATE_PRESCRIPTION = gql`
    ${PRESCRIPTION_FRAGMENT}
    mutation CreatePrescription($tenantId: ID!, $prescriptionInput: PrescriptionInput!) {
        tenant(id: $tenantId) {
            patient {
                prescription {
                    create(input: $prescriptionInput) {
                        ...PrescriptionFragment
                    }
                }
            }
        }
    }`

export const UPDATE_DRAFT_PRESCRIPTION = gql`
    ${PRESCRIPTION_FRAGMENT}
    mutation UpdateDraftPrescription($tenantId: ID!, $prescriptionId: ID!, $prescriptionInput: PrescriptionInput!) {
        tenant(id: $tenantId) {
            patient {
                prescription {
                    update(prescriptionId: $prescriptionId ,input: $prescriptionInput) {
                        ...PrescriptionFragment
                    }
                }
            }
        }
    }`

export const GET_PATIENT_PRESCRIPTION = gql`
    ${PRESCRIPTION_FRAGMENT}
  query GetPatientPrescriptions($tenantId: ID!, $patientId: ID!, $prescriptionId: ID!) {
    tenant(id: $tenantId) {
      patient(id: $patientId,) {
        prescription{
          get(prescriptionId: $prescriptionId) {
            ...PrescriptionFragment
          }
        }
      }
    }
  }
`

export const DELETE_PRESCRIPTION = gql`
  mutation DeleteDraftPrescription($tenantId: ID!, $prescriptionId: ID!) {
      tenant(id: $tenantId) {
          patient {
              prescription {
                  delete(prescriptionId: $prescriptionId)
              }
          }
      }
  }`

export const RENEW_PRESCRIPTION = gql`
  ${PRESCRIPTION_FRAGMENT}
  mutation RenewPrescription($tenantId: ID!, $prescriptionId: ID!) {
      tenant(id: $tenantId) {
          patient {
              prescription {
                  renew(prescriptionId: $prescriptionId){
                      ...PrescriptionFragment
                  }
              }
          }
      }
  }`

export const RENEW_ALL_ACTIVE_CONTINUOUS_PRESCRIPTIONS = gql`
  ${PRESCRIPTION_FRAGMENT}
  mutation RenewAllActiveAndContinuousPrescriptions($tenantId: ID!, $patientId: ID!) {
      tenant(id: $tenantId) {
          patient {
              prescription {
                  renewAllActiveAndContinuousPrescriptions(patientId: $patientId){
                      ...PrescriptionFragment
                  }
              }
          }
      }
  }`

export const RENEW_SELECTED_PRESCRIPTIONS = gql`
  ${PRESCRIPTION_FRAGMENT}
  mutation RenewSelectedPrescriptions($tenantId: ID!, $prescriptionIds: [ID!]!) {
      tenant(id: $tenantId) {
          patient {
              prescription {
                  renewSelectedPrescriptions(prescriptionIds: $prescriptionIds) {
                      ...PrescriptionFragment
                  }
              }
          }
      }
  }`

export const ACTIVATE_PRESCRIPTION = gql`
    ${PRESCRIPTION_FRAGMENT}
  mutation ActivatePrescription($tenantId: ID!, $prescriptionId: ID!, $prescriptionInput: PrescriptionInput!) {
    tenant(id: $tenantId) {
      patient {
        prescription {
          activatePrescription(prescriptionId: $prescriptionId, input: $prescriptionInput) {
            ...PrescriptionFragment
          }
        }
      }
    }            
  }
`

export const ACTIVATE_SELECTED_PRESCRIPTIONS = gql`
    ${PRESCRIPTION_FRAGMENT}
  mutation ActivateSelectedPrescriptions($tenantId: ID!, $prescriptions: [TupleOfObjectIdAndPrescriptionInput!]!) {
    tenant(id: $tenantId) {
      patient {
        prescription {
          activateSelectedPrescriptions(prescriptions: $prescriptions) {
            ...PrescriptionFragment
          }
        }
      }
    }
  }
`

export const INACTIVATE_PRESCRIPTION = gql`
  ${PRESCRIPTION_FRAGMENT}
  mutation InactivatePrescription($tenantId: ID!, $prescriptionId: ID!) {
    tenant(id: $tenantId) {
      patient {
        prescription {
          inactivatePrescription(prescriptionId: $prescriptionId) {
            ...PrescriptionFragment
          }
        }
      }
    }            
  }
`

export const CREATE_ACTIVE_PRESCRIPTION = gql`
    ${PRESCRIPTION_FRAGMENT}
    mutation CreateActivePrescription($tenantId: ID!, $prescriptionInput: PrescriptionInput!) {
        tenant(id: $tenantId) {
            patient {
                prescription {
                    createActivePrescription(input: $prescriptionInput) {
                        ...PrescriptionFragment
                    }
                }
            }
        }            
    }
`

export const ARCHIVE_PRESCRIPTION = gql`
    ${PRESCRIPTION_FRAGMENT}
  mutation ArchivePrescription($tenantId: ID!, $prescriptionId: ID!, $prescriptionInput: PrescriptionInput!) {
    tenant(id: $tenantId) {
      patient {
        prescription {
          archivePrescription(prescriptionId: $prescriptionId, input: $prescriptionInput) {
            ...PrescriptionFragment
          }
        }
      }
    }            
  }
`

export const CREATE_HISTORICAL_PRESCRIPTION = gql`
    ${PRESCRIPTION_FRAGMENT}
    mutation CreateHistoricalPrescription($tenantId: ID!, $prescriptionInput: PrescriptionInput!) {
        tenant(id: $tenantId) {
            patient {
                prescription {
                    createHistoricalPrescription(input: $prescriptionInput) {
                        ...PrescriptionFragment
                    }
                }
            }
        }            
    }
`