import React from 'react'
import Box from '@mui/material/Box'

interface ComingSoonPlaceholderProps {
  message?: string
}

export const ComingSoonPlaceholder = ({
  message = 'Coming Soon!',
}: ComingSoonPlaceholderProps) => {
  return (
    <Box
      sx={(theme) => ({
        height: 300,
        border: '1px dashed' + theme.palette.primary.main,
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      })}
    >
      {message}
    </Box>
  )
}

export default ComingSoonPlaceholder
