import { gql } from "@apollo/client";
import { APPOINTMENT_FRAGMENT } from "../schedule/AppointmentQueries";




export const GET_PATIENT_APPOINTMENTS = gql`
  ${APPOINTMENT_FRAGMENT}
  query GetPatientAppointments($patientId: ID!, $tenantId: ID!) {
    tenant(id: $tenantId) {
      patient(id: $patientId) {
        appointment {
          list {
            ...AppointmentFragment
          }
        }
      }
    }
  }
`