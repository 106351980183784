import { gql } from "@apollo/client";

export const CONDITION_AND_PROCEDURE_FRAGMENT= gql`
    fragment ConditionAndProcedureFragment on ConditionAndProcedure {
        id
        description
        diagnosticCodeId
        diagnosticCode {
            id
            diagnosticCode
            description
        }
        status
        severity
        startDate
        endDate
        notes
        version
        audit {
          crePit
        }
    }
`


export const GET_PATIENT_CONDITION_AND_PROCEDURES = gql`
    ${CONDITION_AND_PROCEDURE_FRAGMENT}
    query GetPatientConditionsAndProcedures($patientId: ID!, $tenantId: ID!) {
        tenant(id: $tenantId) {
            patient(id: $patientId) {
                conditionAndProcedure {
                    list {
                        ...ConditionAndProcedureFragment
                    }
                }
            }
        }
    }
`


export const CREATE_CONDITION_AND_PROCEDURE = gql`
    ${CONDITION_AND_PROCEDURE_FRAGMENT}
    mutation CreateConditionAndProcedure($tenantId: ID!, $input: ConditionAndProcedureInput!) {
        tenant(id: $tenantId) {
            patient {
                conditionAndProcedure {
                    create(input: $input) {
                        ...ConditionAndProcedureFragment
                    }
                }
            }
        }
    }
`

export const UPDATE_CONDITION_AND_PROCEDURE = gql`
    ${CONDITION_AND_PROCEDURE_FRAGMENT}
    mutation UpdateConditionAndProcedure($tenantId: ID!, $id: ID!, $input: ConditionAndProcedureInput!) {
        tenant(id: $tenantId) {
            patient{
                conditionAndProcedure {
                    update(id: $id, input: $input) {
                      ...ConditionAndProcedureFragment
                    }
                }
            }
        }
    }
`

export const DELETE_CONDITION_AND_PROCEDURE = gql`
  mutation DeleteConditionAndProcedure($tenantId: ID!, $conditionAndProcedureId: ID!) {
      tenant(id: $tenantId) {
          patient {
              conditionAndProcedure {
                  delete(id: $conditionAndProcedureId)
              }
          }
      }
  }`