import React from 'react'
import { useParams } from 'react-router-dom'
import { QueryReference, useBackgroundQuery, useMutation } from '@apollo/client'
import _get from 'lodash/get'
import { ImmunizationType } from '../../../types/Immunization'
import { ImmunizationSortPredicate } from '../components/immunizations/ImmunizationUtil'
import { FamilyHistoryType } from '../../../types/FamilyHistory'
import { SocialHistoryType } from '../../../types/SocialHistory'
import {
  GET_PATIENT_FAMILY_HISTORY,
  GET_PATIENT_IMMUNIZATIONS,
  GET_PATIENT_SOCIAL_HISTORY,
  UPDATE_IMMUNIZATIONS,
  UPDATE_SOCIAL_HISTORY
} from '../../../graphql-definitions'
import { useAlerts } from 'saga-library/src/providers/Alerts'

const getImmunizationQueryResults = (data) => {
  return [..._get(data, 'tenant.patient.immunization.list', []) as Array<ImmunizationType>]
}

const getFamilyHistoryQueryResults = (data) => {
  return [..._get(data, 'tenant.patient.familyHistory.list', []) as Array<FamilyHistoryType>]
}

const getSocialHistoryQueryResults = (data) => {
  return _get(data, 'tenant.patient.socialHistory.get', null) as SocialHistoryType
}

const parseImmunizationQueryResults = (data) => {
  return getImmunizationQueryResults(data).sort(ImmunizationSortPredicate)
}

const parseFamilyHistoryQueryResults = (data) => {
  return getFamilyHistoryQueryResults(data)
}

const parseSocialHistoryQueryResults = (data) => {
  return getSocialHistoryQueryResults(data)
}

interface HistoryContextInterface {
  immunizationQueryRef: QueryReference | null
  parseImmunizationQueryResults: (any) => ImmunizationType[]
  familyHistoryQueryRef: QueryReference | null
  parseFamilyHistoryQueryResults: (any) => FamilyHistoryType[]
  socialHistoryQueryRef: QueryReference | null
  parseSocialHistoryQueryResults: (any) => SocialHistoryType
  updateImmunizations: (immunizations: ImmunizationType[], onComplete: () => void) => void
  updateSocialHistory: (socialHistory: SocialHistoryType, onComplete: () => void) => void
}

const defaultHistoryContext: HistoryContextInterface = {
  immunizationQueryRef: null,
  parseImmunizationQueryResults: parseImmunizationQueryResults,
  familyHistoryQueryRef: null,
  parseFamilyHistoryQueryResults: parseFamilyHistoryQueryResults,
  socialHistoryQueryRef: null,
  parseSocialHistoryQueryResults: parseSocialHistoryQueryResults,
  updateImmunizations: (immunizations: ImmunizationType[], onComplete: () => void) => null,
  updateSocialHistory: (socialHistory: SocialHistoryType, onComplete: () => void) => null
}

const HistoryContext = React.createContext(defaultHistoryContext)

export const HistoryProvider = ({ children }) => {
  const { tenant_id, patient_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()

  const [immunizationQueryRef] = useBackgroundQuery(GET_PATIENT_IMMUNIZATIONS, {
    variables: {
      patientId: patient_id,
      tenantId: tenant_id
    }
  })

  const [familyHistoryQueryRef] = useBackgroundQuery(GET_PATIENT_FAMILY_HISTORY, {
    variables: {
      patientId: patient_id,
      tenantId: tenant_id
    }
  })

  const [socialHistoryQueryRef] = useBackgroundQuery(GET_PATIENT_SOCIAL_HISTORY, {
    variables: {
      patientId: patient_id,
      tenantId: tenant_id
    }
  })

  const [updateImmunizationsGQL] = useMutation(UPDATE_IMMUNIZATIONS)

  const [updateSocialHistoryGQL] = useMutation(UPDATE_SOCIAL_HISTORY)


  const updateImmunizations = async(immunizations, onComplete) => {
    await updateImmunizationsGQL({
      variables: {
        tenantId: tenant_id,
        input: immunizations
      },
      onCompleted: (data) => {
        onComplete()
        showSuccessAlert(`Immunizations have been saved.`)
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Immunizations couldn\'t be saved.')
      }
    })
  }

  const updateSocialHistory = async(socialHistory, onComplete) => {
    const hasExistingSocialHistory = socialHistory.hasOwnProperty('patientId')
    if (hasExistingSocialHistory) {
      delete socialHistory.patientId
    }

    await updateSocialHistoryGQL({
      variables: {
        patientId: patient_id,
        tenantId: tenant_id,
        input: socialHistory
      },
      onCompleted: () => {
        onComplete()
        showSuccessAlert('Social history has been saved.')
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Social history couldn\'t be saved.')
      },
      update: (cache, { data }) => {
        if (!hasExistingSocialHistory) {
          // Ensure social history cache does not return null once data exists
          const updatedSocialHistory = _get(data, 'tenant.patient.socialHistory.update', null)
          updatePatientSocialHistoryCache(cache, updatedSocialHistory, tenant_id, patient_id)
        }
      }
    })
  }

  const providerValues = {
    immunizationQueryRef,
    parseImmunizationQueryResults,
    familyHistoryQueryRef,
    parseFamilyHistoryQueryResults,
    socialHistoryQueryRef,
    parseSocialHistoryQueryResults,
    updateImmunizations,
    updateSocialHistory
  }

  return (
    <HistoryContext.Provider value={providerValues}>
      { children }
    </HistoryContext.Provider>
  )
}

export const useHistoryContext = () => {
  return React.useContext(HistoryContext)
}

const updatePatientSocialHistoryCache = async (cache, socialHistory, tenantId, patientId) => {
  await cache.updateQuery({
    query: GET_PATIENT_SOCIAL_HISTORY,
    variables: {
      patientId: patientId,
      tenantId: tenantId
    }
  }, () => {
    return {
      tenant: {
        patient: {
          socialHistory: {
            get: socialHistory
          }
        }
      }
    }
  })
}