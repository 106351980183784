import React from 'react'
import { Button } from "saga-library/src";
import { Box, Tooltip } from "@mui/material";

export const RefusedClaimHeader = ({
                                       onWriteoff,
                                       onResubmit
                                     }) => {

  return (
    <Box sx={{display: 'flex', flexDirection: 'row'}}>
      <Button
        name="writeOff"
        variant={'outlined'}
        onClick={onWriteoff}
        sx={{
          mr: 1,
        }}
      >
        WRITE OFF
      </Button>
      <Tooltip title='This claim will be sent back to AHCIP as a new claim with a new claim number.' placement='bottom'>
        <Button name="resubmit" onClick={onResubmit}>
          RESUBMIT
        </Button>
      </Tooltip>
    </Box>
  )
}