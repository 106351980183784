import React, { useEffect } from 'react'
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  fromPromise,
  InMemoryCache,
  ApolloLink,
  Operation,
  FetchResult,
  Observable,
} from "@apollo/client";
import { getMainDefinition } from '@apollo/client/utilities';
import { useSagaAuthentication } from './Auth'
import { useConfigurationContext } from './Configuration'
import { onError } from "@apollo/client/link/error";
import { useConcurrencyHandler } from "./ConcurrencyConflictProvider";
import { getVersionedEntity } from "../utils/findVersionedObject";
import { print } from 'graphql'
import { createClient, ClientOptions, Client } from 'graphql-sse'
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';



const CONCURRENCY_ERROR_CODE = "CONCURRENCY_ERROR";

/**
 * Apollo graphql client provider.
 * Handles APi authentication via httponly cookie authentication
 * @param children
 * @constructor
 */
export const Apollo = ({ children }) => {
  const { getConfigValue } = useConfigurationContext()
  const authenticated = useSagaAuthentication()
  const { showConcurrencyError } = useConcurrencyHandler()

  const httpLink = createHttpLink({
    uri: getConfigValue('SAGA_GRAPHQL_ENDPOINT'),
    credentials: 'include'
  })

  const concurrencyErrorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      const concurrencyError = graphQLErrors.find(e => e?.extensions?.code === CONCURRENCY_ERROR_CODE)
      if (concurrencyError) {
        const user: string = String(concurrencyError.extensions.username)
        const version: string = String(concurrencyError.extensions.version)
        return fromPromise(showConcurrencyError(user, version))
          .filter(value => {
            return value
          })
          .flatMap(() => {
            const versionedEntity = getVersionedEntity(operation.variables)
            versionedEntity.version = version
            return forward(operation)
          })
      }
    }
  })

  const gql_client = React.useMemo(() => {
    const sseLink = new SSELink({
      url: getConfigValue('SAGA_GRAPHQL_ENDPOINT'),
      credentials: 'include',
      singleConnection: false
    })

    const splitLink = ApolloLink.split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      sseLink,
      httpLink
    )


    const removeTypenameLink = removeTypenameFromVariables()

    const removeAudit = new ApolloLink((operation, forward) => {
      if (operation.variables) {
        const omitAudit = (key, value) => (key === 'audit' ? undefined : value)
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitAudit)
      }
      return forward(operation).map((data) => {
        return data
      })
    })

    const removeLinkedDocument = new ApolloLink((operation, forward) => {
      if (operation.variables) {
        const omitLinkedDocument = (key, value) => (key === 'isLinkedDocument' || key === 'linkedDocument' ? undefined : value)
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitLinkedDocument)
      }
      return forward(operation).map((data) => {
        return data
      })
    })

    const versionMerge = (existing, incoming, { readField, mergeObjects }) => {
        if (existing) {
          const existingVersion = readField("version", existing);
          const incomingVersion = readField("version", incoming);
          const existingRef = readField("__ref", existing);
          const incomingRef = readField("__ref", incoming);
          if (existingRef && incomingRef && existingRef === incomingRef &&
            existingVersion && incomingVersion && incomingVersion <= existingVersion) {
            return existing;
          }
        }
      return mergeObjects(existing, incoming);
    }

    return new ApolloClient({
      connectToDevTools: process.env.NODE_ENV === 'development',
      link: from([concurrencyErrorLink, removeTypenameLink, removeAudit, removeLinkedDocument, splitLink]),
      cache: new InMemoryCache({
        typePolicies: {
          Province: {
            keyFields: ["code"],
          },
          StaticQueries: { merge: true },
          UserQueries: { merge: true },
          TenantQueries: { merge: true },
          RoleQueries: { merge: true },
          ABClaimQueries: { merge: true },
          PractitionerQueries: { merge: true },
          SearchEngine: { merge: true },
          PatientSearchQueries: { merge: true },
          ScheduleQueries: { merge: true },
          AppointmentQueries: { merge: true },
          AppointmentStateQueries: { merge: true },
          AppointmentTypeQueries: { merge: true },
          AppointmentRoomQueries: { merge: true },
          AppointmentRoom: { merge: versionMerge },
          Appointment: { merge: versionMerge },
          ScheduleEvent: { merge: versionMerge },
          BookingPreference: { merge: versionMerge },
          Letter: {
            keyFields: ["id", "__typename", "isLinkedDocument"]
          },
          Form: {
            keyFields: ["id", "__typename", "isLinkedDocument"]
          },
          AbLabResult: {
            keyFields: ["id", "__typename", "isLinkedDocument"]
          },
          EncounterNote: {
            keyFields: ["id", "__typename", "isLinkedDocument"]
          },
          PatientProfile: {
            fields: {
              notes: {
                merge: false,
              },
            }
          },
          PatientQueries: { merge: true },
          PatientTimelineEvent: {
            keyFields: ["id", "__typename", "type"]
          },
          EncounterNoteQueries: { merge: true },
          PrescriptionQueries: { merge: true },
          LabAndInvestigationQueries: { merge: true },
          Report: {
            keyFields: ["id", "__typename", "isReference"]
          },
          SocialHistory: {
            keyFields: ["patientId", "__typename"]
          }
        },
        possibleTypes: {
          Setting: ["IntSetting", "BoolSetting", "StringSetting", "IdSetting"],
          ScheduleItem: ["Appointment", "ScheduleEvent", "BookingPreference", "TemplateBookingPreference", "TemplateEvent"]
        },
      })
    })
  }, [httpLink, concurrencyErrorLink, getConfigValue])

  useEffect(() => {
    if (!authenticated) {
      ; (async () => {
        await gql_client.resetStore() //clear Apollo cache when user logs off
      })()
    }
  }, [authenticated, gql_client])

  return <ApolloProvider client={gql_client}>{children}</ApolloProvider>
}


class SSELink extends ApolloLink {
  private client: Client;

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }

  public dispose() {
    this.client.dispose();
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        },
      );
    });
  }
}