import React from "react";
import { Autocomplete } from "saga-library/src";
import { Controller, useFormContext } from "saga-library/src/components/Form";

export const AllergyReactionInput = ({name, dataTestId}) => {
  const { control } = useFormContext();

  const reactionOptions = [
    {label:"Anaphylaxis"},
    {label:"Angiodema"},
    {label:"Asthma"},
    {label:"Cough"},
    {label:"Diarrhea"},
    {label:"Edema"},
    {label:"Fever"},
    {label:"GI Reactions"},
    {label:"Hives"},
    {label:"Insomnia"},
    {label:"Migraine"},
    {label:"Muscle Cramps"},
    {label:"Rash"},
    {label:"Rhinitis"},
    {label:"Serum Sickness"},
  ]

  return  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value=''}, fieldState: {error} }) => {

      return <Autocomplete
        dataTestId={dataTestId}
        name={'reaction_input'}
        label={"Reaction description"}
        options={reactionOptions}
        onChange={ (e, newValue) => onChange(newValue) }
        freeSolo={true}
        autoSelect={true}
        getOptionLabel={(option) => option?.label || option || ""}
        isOptionEqualToValue={(option, value) => option?.label === value}
        value={value}
      />
    }}
    />
}