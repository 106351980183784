import { MenuAndOutletLayout } from '../../components/Layouts'
import React, { useEffect } from 'react'
import { InboxMenu, InboxSections } from './components/InboxMenu'
import { Role, User } from '../../types/account'
import { useNavigate } from 'react-router-dom'

export enum UserRoleType {
  USER,
  ROLE
}

type UserRole = {
  type: UserRoleType,
  data: User | Role
}

export const InboxSection = () => {
  const navigate = useNavigate()

  const [currentUserRole, setCurrentUserRole] = React.useState<UserRole>()

  useEffect(()=>{
    if(currentUserRole){
      navigate(`${currentUserRole.type === UserRoleType.USER ? "u" : "r"}/${currentUserRole.data.id}/tasks`)
    }
  },[currentUserRole])

  return (
    <MenuAndOutletLayout menu={<InboxMenu value={currentUserRole} setValue={setCurrentUserRole} />} />
  )
}