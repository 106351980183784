import { gql } from "@apollo/client";


export const LOCATION_IDENTIFIER_DETAILS_FRAGMENT = gql`
    fragment LocationIdentifierDetails on Location {
        connectCareSubmitterId
        connectCareDepartmentId
    }
`

export const LOCATION_PARTIAL_ADDRESS_DETAILS_FRAGMENT = gql`
    fragment LocationPartialAddressDetails on Location {
        street1
        city
        province
        postalCode
    }
`

export const LOCATION_ADDRESS_DETAILS_FRAGMENT = gql`
    ${LOCATION_PARTIAL_ADDRESS_DETAILS_FRAGMENT}
    fragment LocationAddressDetails on Location {
        ...LocationPartialAddressDetails
        street2
        street3
    }
`


export const LIST_LOCATIONS = gql`
    ${LOCATION_PARTIAL_ADDRESS_DETAILS_FRAGMENT}
    query ListLocations($tenantId: ID!){
        tenant(id: $tenantId){
            location{
                list{
                    id
                    name
                    ...LocationPartialAddressDetails
                    scheduleIds
                    practitionerIds
                }
            }
        }
    }
`

export const CREATE_LOCATION = gql`
    ${LOCATION_IDENTIFIER_DETAILS_FRAGMENT}
    ${LOCATION_ADDRESS_DETAILS_FRAGMENT}
    mutation CreateLocation($tenantId: ID!, $input: LocationInput!) {
        tenant(id: $tenantId) {
            location {
                create(input: $input) {
                    id
                    name
                    phoneNumber
                    faxNumber
                    ...LocationAddressDetails
                    ...LocationIdentifierDetails
                }
            }
        }
    }
`

export const GET_LOCATION = gql`
    ${LOCATION_IDENTIFIER_DETAILS_FRAGMENT}
    ${LOCATION_ADDRESS_DETAILS_FRAGMENT}
    query GetLocation($tenantId: ID!, $locationId: ID!) {
        tenant(id: $tenantId) {
            location {
                get(id: $locationId) {
                    id
                    version
                    name
                    phoneNumber
                    faxNumber
                    ...LocationAddressDetails
                    ...LocationIdentifierDetails
                }
            }
        }
    }
`

export const UPDATE_LOCATION = gql`
    ${LOCATION_IDENTIFIER_DETAILS_FRAGMENT}
    ${LOCATION_ADDRESS_DETAILS_FRAGMENT}
    mutation UpdateLocation($tenantId: ID!, $id: ID!, $input: LocationInput!) {
        tenant(id: $tenantId) {
            location {
                update(id: $id, input: $input) {
                    id
                    version
                    name
                    phoneNumber
                    faxNumber
                    ...LocationAddressDetails 
                    ...LocationIdentifierDetails
                }
            }
        }
    }
`

export const DEACTIVATE_LOCATION = gql`
    mutation DeactivateLocation($tenantId: ID!, $id: ID!) {
        tenant(id: $tenantId) {
            location {
                deactivate(id: $id)
            }
        }
    }
`
