import { gql } from "@apollo/client";

export const UPDATE_EVENT = gql`
    mutation UpdateEvent($tenantId: ID!, $id: ID! $input: ScheduleBlockInput!) {
        tenant(id: $tenantId) {
            schedule {
                updateEvent(id: $id, input: $input) {
                    id
                    title
                    start
                    end
                    allDay
                    version
                    itemDate
                    scheduleId
                    blockType
                    resource: scheduleId
                }
            }
        }
    }
`

export const DELETE_EVENT = gql`
  mutation DeleteEvent($tenantId: ID!, $id: ID!) {
    tenant(id: $tenantId) {
      schedule {
        deleteEvent(id: $id)
      }
    }
  }
`


export const ADD_NEW_EVENT = gql`
    mutation CreateEvent($tenantId: ID!, $input: ScheduleBlockInput!) {
        tenant(id: $tenantId) {
            schedule {
                createEvent(input: $input)                
            }
        }
    }
`
