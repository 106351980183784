import {
  ReportCategoryType,
  ReportColumn,
  ReportColumnInput,
  ReportColumnType,
  ReportParameter,
  ReportParameterInput,
  ReportParameterType
} from '../../../types/Report'

export const REPORT_COLUMN_GROUPS: { label: string, columns: ReportColumnType[] }[] = [
  {
    label: "Appointment",
    columns: [
      ReportColumnType.DATE,
      ReportColumnType.NOTES,
      ReportColumnType.STATE,
      ReportColumnType.TYPE,
      ReportColumnType.PRACTITIONER
    ]
  },
  {
    label: "Billing",
    columns: [
      ReportColumnType.CALLS,
      ReportColumnType.CLAIMED,
      ReportColumnType.DIAGNOSES,
      ReportColumnType.FACILITY,
      ReportColumnType.OUTCOME,
      ReportColumnType.PAID,
      ReportColumnType.SERVICE_CODE,
      ReportColumnType.SERVICE_DATE,
      ReportColumnType.PRACTITIONER_BILLING_PROFILE,
    ]
  },
  {
    label: "Lab results",
    columns: [
      ReportColumnType.LAB_RESULT_DATE,
      ReportColumnType.LAB_RESULT_DESCRIPTION
    ]
  },
  {
    label: "Linked documents",
    columns: [
      ReportColumnType.LINKED_DOCUMENT_CATEGORY,
      ReportColumnType.LINKED_DOCUMENT_DESCRIPTION
    ]
  },
  {
    label: "Prescriptions",
    columns: [
      ReportColumnType.PRESCRIPTION_DATE,
      ReportColumnType.PRESCRIPTION,
      ReportColumnType.PRESCRIPTION_STATUS
    ]
  },
  {
    label: "Patient",
    columns: [
      ReportColumnType.PATIENT_AGE,
      ReportColumnType.PATIENT_DATE_OF_BIRTH,
      ReportColumnType.PATIENT_GENDER,
      ReportColumnType.PATIENT_IDENTIFIER,
      ReportColumnType.PATIENT_IDENTIFIER_TYPE,
      ReportColumnType.PATIENT_LAST_APPOINTMENT_DATE,
      ReportColumnType.PATIENT,
      ReportColumnType.PATIENT_POSTAL_CODE
    ]
  }
]

export const REPORT_PARAMETER_GROUPS: { category: ReportCategoryType, label: string, parameters: ReportParameterType[] }[] = [
  {
    category: ReportCategoryType.APPOINTMENT,
    label: "Appointment",
    parameters: [
      ReportParameterType.APPOINTMENT_DATE_RANGE,
      ReportParameterType.APPOINTMENT_PRACTITIONERS
    ]
  },
  {
    category: ReportCategoryType.BILLING,
    label: "Billing",
    parameters: [
      ReportParameterType.SERVICE_DATE_RANGE,
      ReportParameterType.PAYMENT_DATE_RANGE,
      ReportParameterType.CLAIM_PRACTITIONERS
    ]
  },
  {
    category: ReportCategoryType.PATIENT,
    label: "Patient",
    parameters: [
      ReportParameterType.PRIMARY_PRACTITIONER
    ]
  }
]

export const convertColumnInputsToColumns = (columns: ReportColumnInput[] = []): ReportColumn[] => {
  return columns.map((column, index) => (
    {
      id: column.id,
      column: column.name,
      order: index,
      version: column.version || "0"
    } as ReportColumn
  ))
}

export const convertParameterInputsToParameters = (parameters: ReportParameterInput[] = []): ReportParameter[] => {
  return parameters.map((parameter, index) => (
    {
      id: parameter.id,
      parameter: parameter.name,
      version: parameter.version || "0"
    } as ReportParameter
  ))
}