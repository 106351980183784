import React from 'react'
import { Button, Typography } from "saga-library/src";
import { Box, useTheme } from "@mui/material";
import { ABClaimActionCode } from "../../../../utils/ABClaimConstants";

export const ResubmittedHeader = ({
 claim,
 onClick
}) => {
  const theme = useTheme()

  let description = 'RESUBMITTED'
  switch(claim.actionCode) {
    case ABClaimActionCode.REASSESS:
      description = description.concat(' FOR REASSESSMENT')
      break
    case ABClaimActionCode.CHANGE:
      description = description.concat(' AS CHANGED CLAIM')
      break
    case ABClaimActionCode.DELETE:
      description = description.concat(' FOR DELETION')
      break
    case ABClaimActionCode.ADD:
      description = description.concat(' AS NEW CLAIM')
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'row'}}>
      {claim.assessmentOutcome === 'APPLIED' && claim.adjusted &&
        <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', mr: 1 }}>
          <Typography variant={'body1'} fontWeight={'bold'} color={theme.palette.greys.medium} sx={{m: 'auto'}}>
            {description}
          </Typography>
        </Box>
      }
      <Button
        name="cancelResubmission"
        variant={'outlined'}
        onClick={onClick}
      >
        Cancel resubmission
      </Button>
    </Box>
  )
}