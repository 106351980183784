import React, { useEffect, useRef, useCallback, useMemo } from 'react'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { LoadingScreen } from '../components/LoadingScreen'
import { v4 as uuidv4 } from 'uuid';
import { useConfigurationContext } from './Configuration'
import { AuthContext, AuthActions, AuthInternalState, AuthStatusResponse, SignInResponse } from '../types/account/Auth'
import { initializeApp } from 'firebase/app'
import { getAuth, signInWithEmailAndPassword, setPersistence, inMemoryPersistence, signOut } from 'firebase/auth'

const AUTH_BROADCASTCHANNEL = "saga_auth_coordination";
const ATTEMPTS_REMAINING_BEFORE_LOCKOUT_MESSAGE = 3;

const defaultSagaAuthentication: AuthContext = {
  performSignIn: async (any): Promise<boolean> => { return false },
  performSignOut: async (): Promise<void> => { },
  performResetPassword: async (any): Promise<boolean> => { return false },
  performForgotPassword: async (any): Promise<boolean> => { return false },
  beginChangeEmail: async (any): Promise<boolean> => { return false },
  performChangeEmail: async (any): Promise<boolean> => { return false },
  clearErrors: () => { },
  isAuthenticated: false,
  friendlyErrorMessage: null,
  friendlyErrorDetails: null
}

const SagaAuthenticationContext = React.createContext<AuthContext>(defaultSagaAuthentication)

function authReducer(state: AuthInternalState, [action, updates = {}]) {
  switch (action) {
    case AuthActions.INIT:
    case AuthActions.UPDATE_ERROR_MESSAGE:
    case AuthActions.REFRESH:
      return { ...state, ...updates }

    case AuthActions.SIGN_IN:
    case AuthActions.ANOTHER_TAB_SIGNED_IN:
      return { ...state, isAuthenticated: true }

    case AuthActions.SIGN_OUT:
    case AuthActions.ANOTHER_TAB_SIGNED_OUT:
      return { ...state, isAuthenticated: false }
  }
  return state
}

export const Auth = ({ children }) => {
  const { getConfigValue } = useConfigurationContext()
  const SAGA_REST_ENDPOINT = getConfigValue('SAGA_REST_ENDPOINT');
  const firebaseTenantId = getConfigValue('FIREBASE_TENANT_ID');
  const firebaseConfig = {
    apiKey:  getConfigValue('FIREBASE_API_KEY'),
    authDomain:  getConfigValue('FIREBASE_AUTH_DOMAIN'),
  }

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  // Initialize Firebase Authentication and get a reference to the service
  const authFB = getAuth(app);
  authFB.tenantId = firebaseTenantId;

  setPersistence(authFB, inMemoryPersistence)

  const timeoutReference = useRef<number>(0)
  const instanceUUID = useRef<string>(uuidv4())
  const refreshTimeoutDelayMs = useMemo((): number => {
    const refresh_token_offest = Number.parseInt(getConfigValue('SAGA_REFRESH_TOKEN_OFFSET_MAX_SECONDS'))
    const refresh_token_lifetime = Number.parseInt(getConfigValue('SAGA_REFRESH_TOKEN_LIFETIME')) * 60

    function getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    return (refresh_token_lifetime - getRndInteger(Number.parseInt(getConfigValue('SAGA_REFRESH_TOKEN_OFFSET_MIN_SECONDS')), refresh_token_offest)) * 1000
  }, [getConfigValue])
  const authChannel = useRef<BroadcastChannel>();
  if (!authChannel.current) {
    authChannel.current = new BroadcastChannel(AUTH_BROADCASTCHANNEL);
  }

  const initialState: AuthInternalState = {
    isAuthenticated: false,
    loading: true,
    refreshDelayMs: refreshTimeoutDelayMs,
    friendlyErrorMessage: null,
    friendlyErrorDetails: null
  }
  const [state, dispatch] = React.useReducer(authReducer, initialState);

  const postMessageToAuthChannel = (message: any) => {
    if (!authChannel.current) return
    try {
      authChannel.current.postMessage(message)
    }
    catch (error) {
      console.log("postMessageToAuthChannel error", error)
    }
  }

  const setupTimeout = useCallback((refreshDelay: number): number => {

    window.clearTimeout(timeoutReference.current || 0)

    return window.setTimeout(
      () => {
        const performRefresh = () => {
          return axios.get(
            SAGA_REST_ENDPOINT + '/identity/refresh',
            {
              withCredentials: true,
              headers: {
                'Content-Type': 'application/json'
              },
              responseType: 'json',
              validateStatus: (status) => {
                return status < 500
              },
            })
            .then(handleResponse)
            .catch(handleError)

          function handleResponse(response: AxiosResponse) {
            if (response.status === 200) {
              postMessageToAuthChannel({ uuid: instanceUUID.current, type: "refresh" })
              timeoutReference.current = setupTimeout(refreshTimeoutDelayMs)
            }
            else {
              dispatch([AuthActions.SIGN_OUT])
              window.clearTimeout(timeoutReference.current || 0)
              window.location.reload()
            }
          }

          function handleError(error: AxiosError) {
            console.error("refresh error", error)
          }
        }

        performRefresh()

      },
      refreshDelay
    )
  }, [SAGA_REST_ENDPOINT, authChannel.current, refreshTimeoutDelayMs])

  const authMessageListener = useCallback((message) => {
    if (message.type !== "message") return
    if (message.data.uuid.toString() === instanceUUID.current.toString()) return

    switch (message.data.type) {
      case "signin":
        timeoutReference.current = setupTimeout(refreshTimeoutDelayMs)
        dispatch([AuthActions.ANOTHER_TAB_SIGNED_IN])
        break
      case "signout":
        dispatch([AuthActions.ANOTHER_TAB_SIGNED_OUT])
        window.clearTimeout(timeoutReference.current || 0)
        window.location.reload()
        break
      case "refresh":
        timeoutReference.current = setupTimeout(refreshTimeoutDelayMs)
        break
    }
  }, [refreshTimeoutDelayMs, setupTimeout])

  useEffect(() => {
    let isAuthenticated = false
    let refreshDelayMs = refreshTimeoutDelayMs

    async function checkAuthStatus() {

      function getAuthStatus() {
        return axios.get(
          SAGA_REST_ENDPOINT + '/identity/auth-status',
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json'
            },
            responseType: 'json',
            validateStatus: (status) => {
              return status < 500
            },
          })
          .then(handleResponse)
          .catch(handleError)

        function handleResponse(response: AxiosResponse<AuthStatusResponse | null>) {
          isAuthenticated = response.data?.isAuthenticated ?? false
          if (isAuthenticated) {
            let seconds = (response.data!.expiresInSeconds > 30) ? response.data!.expiresInSeconds - 30 : 1
            refreshDelayMs = seconds * 1000
          }
        }

        function handleError(error: AxiosError) {
          console.error("AuthStatus", error)
        }

      }

      await getAuthStatus()
    }

    checkAuthStatus()
      .then(() => {

        if (isAuthenticated) {
          timeoutReference.current = setupTimeout(refreshDelayMs)
        }

        dispatch([AuthActions.INIT, {
          isAuthenticated: isAuthenticated,
          refreshDelayMs: refreshDelayMs,
          loading: false,
          authChannel: authChannel.current
        }])

        if (!!authChannel.current) {
          authChannel.current.onmessage = authMessageListener;
        }

      })

    return () => {
      window.clearTimeout(timeoutReference.current || 0)
    }

  }, [SAGA_REST_ENDPOINT, authChannel.current, authMessageListener, refreshTimeoutDelayMs, setupTimeout])

  useEffect(() => () => {
    authChannel.current?.close();
    authChannel.current = undefined;
  }, [])

  function clearErrors(): void {
    dispatch([AuthActions.UPDATE_ERROR_MESSAGE, {
      friendlyErrorMessage: null,
      friendlyErrorDetails: null
    }])
  }

  async function performSignIn(credentials: { email: string; password: string; }): Promise<boolean> {
    let friendlyErrorMessage: string | null = null
    let friendlyErrorDetails: string | null = null

    clearErrors()

    return signInWithEmailAndPassword(authFB, credentials.email, credentials.password)
      .then(async user => {
        if (!user || !user.user) return false

        const idToken = await user.user.getIdToken()
        return axios.post(
          SAGA_REST_ENDPOINT + '/identity/signin',
          JSON.stringify({ idToken: idToken }),
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json'
            },
            responseType: 'json',
            validateStatus: (status_1) => {
              return status_1 < 500
            }
          })
          .then(handleResponse)
          .catch(handleError)
      })
      .catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;

        if (errorCode === "auth/user-not-found" || errorCode === "auth/wrong-password") {
          friendlyErrorMessage = "Wrong email or password."
        }
        else {
          friendlyErrorMessage = "Error signing in."
          console.log('performSignIn error', errorCode, errorMessage)
        }

        dispatch([AuthActions.UPDATE_ERROR_MESSAGE, {
          friendlyErrorMessage: friendlyErrorMessage,
          friendlyErrorDetails: friendlyErrorDetails
        }])
        return false
      })

    function handleResponse(response: AxiosResponse<SignInResponse | null>) {
      if (response.status === 200) {
        postMessageToAuthChannel({ uuid: instanceUUID.current, type: "signin" })
        timeoutReference.current = setupTimeout(refreshTimeoutDelayMs)
        dispatch([AuthActions.SIGN_IN])
        return true
      }
      else if (response.status === 401) {

        //TODO: lockouts are not something we handle anymore
        if (response.data?.lockoutAttemptsRemaining === 0) {
          let sec = response.data!.lockoutEndsInSeconds;
          let min = Math.ceil(sec / 60)
          friendlyErrorMessage = "Your account has been locked out."
          friendlyErrorDetails = `You can try again in ${(sec > 60) ? min + " minutes" : sec + " seconds"} .`
        }
        else if (response.data!.lockoutAttemptsRemaining <= ATTEMPTS_REMAINING_BEFORE_LOCKOUT_MESSAGE) {
          friendlyErrorMessage = "Wrong email or password."
          friendlyErrorDetails = `You have ${response.data!.lockoutAttemptsRemaining} more ${((response.data!.lockoutAttemptsRemaining === 1) ? "attempt" : "attempts")} before you are temporarily locked out`
        }
        else {
          friendlyErrorMessage = "Wrong email or password."
        }
      }
      else {
        friendlyErrorMessage = "Error signing in."
      }

      dispatch([AuthActions.UPDATE_ERROR_MESSAGE, {
        friendlyErrorMessage: friendlyErrorMessage,
        friendlyErrorDetails: friendlyErrorDetails
      }])
      return false
    }

    function handleError(error: AxiosError) {
      console.error(error)
      dispatch([AuthActions.UPDATE_ERROR_MESSAGE, {
        friendlyErrorMessage: "Error signing in"
      }])
      return false
    }

  }

  async function performSignOut(): Promise<void> {

    return await signOut(authFB)
      .then(async () => {
        await axios.get(SAGA_REST_ENDPOINT + '/identity/signout',
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json'
            },
            validateStatus: (status_1) => {
              return status_1 < 500
            }
          })
          .then(handleResponse)
          .catch(handleError)
      })
      .catch((error) => {
        console.error("sign out error", error)
      })

    function handleResponse(response: AxiosResponse) {
      postMessageToAuthChannel({ uuid: instanceUUID.current, type: "signout" })
      window.clearTimeout(timeoutReference.current || 0)
      dispatch([AuthActions.SIGN_OUT])

      if (response.status !== 200 && response.status !== 401) {
        console.error("sign out - unexpected response error", response)
      }
    }

    function handleError(error: AxiosError) {
      console.error("sign out error", error)
      postMessageToAuthChannel({ uuid: instanceUUID.current, type: "signout" })
      window.clearTimeout(timeoutReference.current || 0)
      dispatch([AuthActions.SIGN_OUT])
    }
  }

  function performForgotPassword(postData): Promise<boolean> {
    return axios.post(
      SAGA_REST_ENDPOINT + '/identity/forgotpassword',
      JSON.stringify(postData),
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'json',
        validateStatus: (status) => {
          return status < 500
        },
      })
      .then(handleResponse)
      .catch(handleError)

    function handleResponse(response: AxiosResponse) {
      if (response.status === 400) {
        dispatch([AuthActions.UPDATE_ERROR_MESSAGE, {
          friendlyErrorMessage: "There was a problem with the request. The email address is not valid."
        }])
        return false
      }

      return true
    }

    function handleError(error: AxiosError) {
      console.error(error)
      dispatch([AuthActions.UPDATE_ERROR_MESSAGE, {
        friendlyErrorMessage: "There was an error requesting a password reset."
      }])
      return false
    }

  }

  function performResetPassword(postData): Promise<boolean> {
    clearErrors()

    return axios.post(
      SAGA_REST_ENDPOINT + '/identity/resetpassword',
      JSON.stringify(postData),
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'json',
        validateStatus: (status) => {
          return status < 500
        },
      })
      .then(handleResponse)
      .catch(handleError)

    function handleResponse(response: AxiosResponse) {
      if (response.status === 400) {
        dispatch([AuthActions.UPDATE_ERROR_MESSAGE, {
          friendlyErrorMessage: "There was a problem with the request."
        }])
        return false
      } else if (response.status === 401) {
        dispatch([AuthActions.UPDATE_ERROR_MESSAGE, {
          friendlyErrorMessage: "Password reset has failed. The link may have expired."
        }])
        return false
      }

      return true
    }

    function handleError(error: AxiosError) {
      console.error(error)
      dispatch([AuthActions.UPDATE_ERROR_MESSAGE, {
        friendlyErrorMessage: "There was an error resetting the password."
      }])
      return false
    }

  }

  const beginChangeEmail = async (postData): Promise<boolean> => {
    clearErrors()

    return axios.post(
      SAGA_REST_ENDPOINT + '/identity/beginemailchange',
      JSON.stringify(postData),
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'json',
        validateStatus: (status) => {
          return status < 500
        },
      })
      .then(handleResponse)
      .catch(handleError)

    function handleResponse(response: AxiosResponse) {
      if (response.status === 400) {
        dispatch([AuthActions.UPDATE_ERROR_MESSAGE, {
          friendlyErrorMessage: "There was a problem with the request."
        }])
        return false
      } else if (response.status === 401) {
        dispatch([AuthActions.UPDATE_ERROR_MESSAGE, {
          friendlyErrorMessage: "Email change has failed. Unauthorized request made."
        }])
        return false
      }

      return true
    }

    function handleError(error: AxiosError) {
      console.error(error)
      dispatch([AuthActions.UPDATE_ERROR_MESSAGE, {
        friendlyErrorMessage: "There was an error changing the email."
      }])
      return false
    }
  }

  const performChangeEmail = async (postData): Promise<boolean> => {
    clearErrors()

    return axios.post(
      SAGA_REST_ENDPOINT + '/identity/changeemail',
      JSON.stringify(postData),
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'json',
        validateStatus: (status) => {
          return status < 500
        },
      })
      .then(handleResponse)
      .catch(handleError)

    function handleResponse(response: AxiosResponse) {
      if (response.status === 400) {
        dispatch([AuthActions.UPDATE_ERROR_MESSAGE, {
          friendlyErrorMessage: "There was a problem with the request."
        }])
        return false
      } else if (response.status === 401) {
        dispatch([AuthActions.UPDATE_ERROR_MESSAGE, {
          friendlyErrorMessage: "Email change has failed. The link may have expired."
        }])
        return false
      }

      return true
    }

    function handleError(error: AxiosError) {
      console.error(error)
      dispatch([AuthActions.UPDATE_ERROR_MESSAGE, {
        friendlyErrorMessage: "There was an error changing the email."
      }])
      return false
    }
  }

  const value: AuthContext = {
    performSignIn: performSignIn,
    performSignOut: performSignOut,
    performForgotPassword: performForgotPassword,
    beginChangeEmail: beginChangeEmail,
    performResetPassword: performResetPassword,
    performChangeEmail: performChangeEmail,
    clearErrors: clearErrors,
    isAuthenticated: state.isAuthenticated,
    friendlyErrorMessage: state.friendlyErrorMessage,
    friendlyErrorDetails: state.friendlyErrorDetails
  }

  return state.loading ? (
    <LoadingScreen message={'One moment please'} />
  ) : (
    <SagaAuthenticationContext.Provider value={value}>
      {children}
    </SagaAuthenticationContext.Provider>
  )
}

export const  useSagaAuthentication = () => {
  return React.useContext(SagaAuthenticationContext)
}




