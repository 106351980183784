import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { useMutation } from "@apollo/client";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { ConfirmationDialog, DialogV2, RemoveButton } from "saga-library/src";
import {
  UPDATE_TENANT_LETTER_TEMPLATE,
  LIST_TENANT_LETTER_TEMPLATES,
  DELETE_TENANT_LETTER_TEMPLATE,
} from "../../../../graphql-definitions";
import { LetterTemplateType, LetterTemplateInputType } from "../../../../types/patients/Letters";
import { schema } from "./LetterTemplateFormValidation";
import { LetterTemplateForm } from "./LetterTemplateForm";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { HasPermissionTemplate } from "../../../../components/HasPermissionTemplate";
import { Permission, PermissionType } from "../../../../types/settings/Permission";
import PermissionButton from "../../../../components/PermissionButton";
import _get from "lodash/get";
import { DocumentProvider } from '../../../../providers/DocumentProvider'

const FORM_NAME = "edit_letter_template_form"

interface EditLetterTemplateModalTempProps {
  open: boolean
  onClose: () => void
  letterTemplate: LetterTemplateType | null
}

export const EditLetterTemplateModal = ({
  open,
  onClose,
  letterTemplate
}: EditLetterTemplateModalTempProps) => {
  const { tenant_id } = useParams()
  const { showSuccessAlert, showErrorAlert } = useAlerts()
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)
  const { enableNavigationPrompt } = usePrompt()

  const [editLetterTemplate, {loading, error}] =
    useMutation(UPDATE_TENANT_LETTER_TEMPLATE, {
      onCompleted: (data) => {
        showSuccessAlert('Letter template has been saved.')
        reset(editLetterTemplateDefaultValues)
        onClose()
      },
      onError: (error) => {
        showErrorAlert("Letter template couldn't be saved.")
        console.error(JSON.stringify(error, null, 2))
      }
    })

  const [deleteLetterTemplate] =
    useMutation(DELETE_TENANT_LETTER_TEMPLATE, {
      onCompleted: () => {
        showSuccessAlert('Letter template has been deleted.')
        reset(editLetterTemplateDefaultValues)
        onClose()
      },
      onError: (error) => {
        showErrorAlert("Letter template couldn't be deleted.")
        console.error(JSON.stringify(error, null, 2))
      }
    })

  const editLetterTemplateDefaultValues = {
    id: letterTemplate?.id,
    name: letterTemplate?.name,
    title: letterTemplate?.title,
    content: letterTemplate?.content,
    practitionerId: letterTemplate?.practitioner?.id,
    version: letterTemplate?.version,
  }

  const formMethods = useForm<LetterTemplateInputType>({
    defaultValues: editLetterTemplateDefaultValues,
    schema: schema
  })

  const {
    handleSubmit,
    formState: {dirtyFields, isSubmitting},
    reset,
  } = formMethods

  const handleClose = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      reset(editLetterTemplateDefaultValues)
      onClose()
    }
  }

  const handleNavigationPromptDiscard = (discard : boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        reset(editLetterTemplateDefaultValues)
        onClose()
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onDelete = () => {
    deleteLetterTemplate({
      variables: {
        tenantId: tenant_id,
        letterTemplateId: letterTemplate?.id,
      },
      update(cache) {
        const data = cache.readQuery({
          query: LIST_TENANT_LETTER_TEMPLATES,
          variables: {
            tenantId: tenant_id,
          },
        })

        const tenantLetterTemplates = _get(data, 'tenant.letterTemplate.list', []) as LetterTemplateType[]
        cache.writeQuery({
          query: LIST_TENANT_LETTER_TEMPLATES,
          variables: { tenantId: tenant_id },
          data: {
            tenant: {
              letterTemplate: {
                list: tenantLetterTemplates.filter((l) => l.id !== letterTemplate?.id),
              },
            },
          },
        })
      },
    })
  }

  const onSubmit = handleSubmit(async (data) => {
    await editLetterTemplate({
      variables: {
        tenantId: tenant_id,
        letterTemplateId: data.id,
        input: data,
      },
      update(cache, returnedData) {
        cache.updateQuery({
            query: LIST_TENANT_LETTER_TEMPLATES,
            variables: { tenantId: tenant_id },
          },
          (data) => {
            return data
          }
        )
      },
    })
  })

  return (
    <DialogV2
      title={letterTemplate?.name || 'Edit letter template'}
      open={open != null}
      onClose={handleClose}
      fullWidth
      maxWidth={'md'}
      primaryAction={()=>null}
      overridePrimaryComponent={
        <PermissionButton
          name={"saveLetterTemplate"}
          type={"submit"}
          form={FORM_NAME}
          requiredType={PermissionType.Chart}
          requiredPermission={Permission.READWRITE}
          loading={isSubmitting}
        >
          Save
        </PermissionButton>
      }
      preventScroll={true}
      headerActions=
        {[
          <DeleteLetterTemplateConfirmation onDelete={onDelete} />
        ]}
      PaperProps={{ sx: { overflowY: "clip" } }}
      contentSx={{ overflowY: "clip" }}
      dataTestId={"editLetterTemplateModal"}
    >
      <DocumentProvider>
        <FormProvider {...formMethods}>
            <LetterTemplateForm onSubmit={onSubmit} formName={FORM_NAME}/>
        </FormProvider>
      </DocumentProvider>
    </DialogV2>
  )
}

const DeleteLetterTemplateConfirmation = ({onDelete}) => {
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)

  return (
    <HasPermissionTemplate requiredType={PermissionType.Chart} requiredPermission={Permission.READWRITE}>
      <RemoveButton
        dataTestId={"editLetterTemplateModal"}
        onClick={() => setOpenDeleteConfirmation(true)}
      />
      <ConfirmationDialog
        open={openDeleteConfirmation}
        title={"Delete letter template?"}
        message={"This action cannot be undone."}
        primaryAction={() => {
          setOpenDeleteConfirmation(false)
          onDelete()
        }}
        primaryLabel={"delete"}
        onClose={() => setOpenDeleteConfirmation(false)}
      />
    </HasPermissionTemplate>
  )
}
