import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { useMutation } from "@apollo/client";
import _get from "lodash/get";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { DialogV2 } from "saga-library/src";
import {
  CREATE_TENANT_LETTER_TEMPLATE,
  LIST_TENANT_LETTER_TEMPLATES
} from "../../../../graphql-definitions";
import { LetterTemplateInputType } from "../../../../types/patients/Letters";
import { schema } from "./LetterTemplateFormValidation";
import { LetterTemplateForm } from "./LetterTemplateForm";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { Permission, PermissionType } from "../../../../types/settings/Permission";
import PermissionButton from "../../../../components/PermissionButton";
import { DocumentProvider } from '../../../../providers/DocumentProvider'

const FORM_NAME = 'new_letter_template_form'

interface NewLetterTemplateModalTempProps {
  open: boolean
  setOpen: (a: boolean) => void
}

const newLetterTemplateDefaultValues = {
  name: '',
  title: '',
  content: '',
  version: "0",
}

export const NewLetterTemplateModal = ({ open, setOpen }: NewLetterTemplateModalTempProps) => {
  const { tenant_id } = useParams()
  const { showSuccessAlert, showErrorAlert } = useAlerts()
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)
  const { enableNavigationPrompt } = usePrompt()

  const [addNewLetterTemplate, {loading, error}] =
    useMutation(CREATE_TENANT_LETTER_TEMPLATE, {
      onCompleted: (data) => {
        showSuccessAlert('Letter template has been saved.')
        reset()
        setOpen(false)
      },
      onError: (error) => {
        showErrorAlert("Letter template couldn't be saved.")
        console.error(JSON.stringify(error, null, 2))
      }
    })

  const formMethods = useForm<LetterTemplateInputType>({
    defaultValues: newLetterTemplateDefaultValues,
    schema: schema
  })

  const {
    handleSubmit,
    formState: {dirtyFields, isSubmitting},
    reset,
  } = formMethods

  const handleClose = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      reset()
      setOpen(false)
    }
  }

  const handleNavigationPromptDiscard = (discard : boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        reset()
        setOpen(false)
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onSubmit = handleSubmit(async (data) => {
    await addNewLetterTemplate({
      variables: {
        tenantId: tenant_id,
        input: data,
      },
      update(cache, returnedData) {
        const newLetterTemplate = _get(returnedData, 'data.tenant.letterTemplate.create')
        cache.updateQuery({
            query: LIST_TENANT_LETTER_TEMPLATES,
            variables: { tenantId: tenant_id },
          },
          (data) => {
            let list = [...data.tenant.letterTemplate.list, newLetterTemplate]
            return {
              tenant: {
                letterTemplate: {
                  list
                }
              }
            }
          }
        )
      },
    })
  })

  return (
    <DialogV2
      title={'New letter template'}
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={'md'}
      primaryAction={()=>null}
      overridePrimaryComponent={
        <PermissionButton
          name={"saveNewLetterTemplate"}
          type={"submit"}
          form={FORM_NAME}
          requiredType={PermissionType.Chart}
          requiredPermission={Permission.READWRITE}
          loading={isSubmitting}
        >
          Save
        </PermissionButton>
      }
      preventScroll={true}
      PaperProps={{ sx: { overflowY: "clip" } }}
      contentSx={{ overflowY: "clip" }}
      dataTestId={'newLetterTemplateModal'}
    >
      <DocumentProvider>
        <FormProvider {...formMethods}>
          <LetterTemplateForm formName={FORM_NAME} onSubmit={onSubmit}/>
        </FormProvider>
      </DocumentProvider>
    </DialogV2>
  )
}
