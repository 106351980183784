import { Typography } from "@mui/material";
import React from "react";
import { TableListCellConfig, TableListRowConfig } from "saga-library/src";
import { LetterTemplateType } from "../../../../types/patients/Letters";
import SettingsRow from "../../components/SettingsRow";

function FormatRowData(letterTemplate: any): TableListCellConfig[] {
  const { id, name, practitioner, title } = letterTemplate;

  return [
    {
      children: [
        <SettingsRow key={"name" + id}>
          <Typography>
            {name ? name : ""}
          </Typography>
        </SettingsRow>
      ]
    },
    {
      children: [
        <SettingsRow key={"title" + id}>
          <Typography>
            {title ? title : ""}
          </Typography>
        </SettingsRow>
      ]
    },
    {
      children: [
        <SettingsRow key={"practitioner" + id}>
          <Typography>
            {practitioner ? practitioner?.lastName + ", " + practitioner?.firstName : ""}
          </Typography>
        </SettingsRow>
      ]
    }
  ];
}


export const LetterTemplateRow = (letterTemplate: LetterTemplateType, setEditLetterTemplate): TableListRowConfig => {
  if (letterTemplate) {
    const onRowClicked = () => {
      setEditLetterTemplate(letterTemplate)
    };

    return ({
      onClick: onRowClicked,
      rowData: FormatRowData(letterTemplate),
      key: letterTemplate.id!
    });
  }

  return { rowData: [], key: "" };
};
