import React, { useEffect } from "react"
import { Route, Routes } from "react-router-dom";
import { Tasks as TasksSection } from "../apps/tasks/Tasks";
import { usePageTitleContext } from "../providers/PageTitleContextProvider";

export const Tasks = () => {
  const { setAreaTitle } = usePageTitleContext()

  useEffect(() => {
    setAreaTitle('Tasks')
  }, [setAreaTitle])

  return (
    <Routes>
      <Route path="/" element={<TasksSection />} />
    </Routes>
  )
}