import React, { useState } from "react";
import { Section, EmptyList } from 'saga-library/src'
import { useNavigate, useParams } from "react-router-dom";
import { AssessedClaimUpdateDetails } from "./AssessedClaimUpdateDetails";
import { useAccountContext } from "../../../providers/AccountContext";
import { useAlerts } from "saga-library/src/providers/Alerts";
import AssessedClaimsList from "./AssessedClaimsList";
import { useQuery } from "@apollo/client";
import { AssessedAbClaimSortColumn } from "../../../utils/ABClaimConstants";
import { SortOrder } from "../../../utils/GeneralEnums";
import { AbClaim, AssessedAbClaimFilterInput, AssessedAbClaimSorterInput } from "../../../types/billing";
import { AssessedMultipleSelected } from "./AssessedMultipleSelected";
import { BatchUpdateAssessedDetails } from "./BatchUpdate/AssessedClaims/BatchUpdateAssessedDetails";
import { PageInfo } from "saga-library/src/components/TableList/PagedTableList";
import { LIST_ASSESSEDCLAIMS } from "../../../graphql-definitions";
import FormColumn from "./FormColumn";

const DEFAULT_PAGE_SIZE = 50

interface AssessedClaimsParamsProps {
  tenantId: string
  filters: AssessedAbClaimFilterInput
  sorter: AssessedAbClaimSorterInput
  first: number
  after: string | null
}

export const AssessedClaims = () => {
  const navigate = useNavigate()
  const { tenant_id, claim_id } = useParams()
  const { showErrorAlert } = useAlerts()
  const [selectedClaims, setSelectedClaims] = useState<AbClaim[]>([])
  const [allAssessedClaims, setAllAssessedClaims] = useState<AbClaim[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo>()
  const [updateBatch, setUpdateBatch] = useState(false)
  const { buildTenantRoute } = useAccountContext()

  const defaultParams: AssessedClaimsParamsProps = {
    tenantId: tenant_id!,
    filters:  {
      assessmentOutcomeFilter: null,
      practitionerId: null,
      searchTerm: null,
      adjusted: null,
      resubmittedOnly: null,
    },
    sorter:{
      sortColumn: AssessedAbClaimSortColumn.CLAIM_IDENTIFIER,
      sortOrder: SortOrder.ASCENDING
    },
    first: DEFAULT_PAGE_SIZE,
    after: null
  }

  const assessedClaimsParams: AssessedClaimsParamsProps = defaultParams
  const { loading, data, refetch } = useQuery( LIST_ASSESSEDCLAIMS, {
    variables: assessedClaimsParams,
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert("Assessed claims list couldn't be retrieved.")
    },
    onCompleted: (data) => {
      let claimsList = data.tenant.assessedAbClaim.assessedClaims.edges.map(e => e.node)
      let newPageInfo = data.tenant.assessedAbClaim.assessedClaims.pageInfo
      setAllAssessedClaims([...claimsList])
      setPageInfo(newPageInfo)
    },
    fetchPolicy: 'cache-and-network',
  })

  const onRequestSort = (key, order) => {
    assessedClaimsParams.sorter = {
      sortOrder: order === "asc" ? SortOrder.ASCENDING : SortOrder.DESCENDING,
      sortColumn: key === "Claim" ? AssessedAbClaimSortColumn.CLAIM_IDENTIFIER : AssessedAbClaimSortColumn.SERVICE_DATE
    }

    refetch(assessedClaimsParams)
  }

  const onRequestNext = (cursor) => {
    const previouslyLoadedAssessedClaims = allAssessedClaims
    assessedClaimsParams.after = cursor
    refetch(assessedClaimsParams).then((data) => {
      const claimsList = data.data.tenant.assessedAbClaim.assessedClaims.edges.map(e => e.node)
      setAllAssessedClaims([...previouslyLoadedAssessedClaims, ...claimsList])
    })
  }

  const onSelectAll = (event: React.ChangeEvent<HTMLInputElement>, claims: AbClaim[]) => {
    if (event.target.checked) {
      setSelectedClaims(claims)
    } else {
      setSelectedClaims([])
    }
  }

  const onFiltersChanged = (filters) => {
    assessedClaimsParams.filters = filters
    refetch(assessedClaimsParams)
  }

  const handleSelectClaim = (claim_id) => {
    //TODO: Handle filter changing
    let newClaimList: AbClaim[] = [...selectedClaims]
    if (!selectedClaims.some((c) => c.id === claim_id)) {
      const claim = allAssessedClaims.find(ac => ac.id === claim_id)
      if (claim) newClaimList.push(claim)
    } else {
      newClaimList = newClaimList.filter((c) => c.id !== claim_id)
    }
    setSelectedClaims(newClaimList)
  }

  const handleRowChange = (claim: AbClaim) => {
    navigate(buildTenantRoute(`billing/assessed/c/${claim.id}`, tenant_id))
  }

  return (
    <Section.Container
      sx={{
        width: '100%',
      }}
    >
      <AssessedClaimState
        selectedClaims={selectedClaims}
        claim_id={claim_id}
        refetch={refetch}
        clearSelected={() => setSelectedClaims([])}
        updateBatch={updateBatch}
        setUpdateBatch={setUpdateBatch}
        closeBatchUpdate={() => setUpdateBatch(false)}
        allClaims={allAssessedClaims}
        setSelectedClaims={setSelectedClaims}
      />
      <Section.Column
        width={'auto'}
        sx={{
          overflow: 'hidden',
          minWidth: '880px',
          width: '100%'
        }}
      >
        <AssessedClaimsList
          loading={loading}
          nodes={allAssessedClaims}
          pageInfo={pageInfo}
          onFiltersChanged={onFiltersChanged}
          onRequestSort={onRequestSort}
          onRequestNext={onRequestNext}
          onCheckboxClick={handleSelectClaim}
          selectedClaims={selectedClaims}
          onSelectAll={onSelectAll}
          onRowClick={handleRowChange}
          setSelectedClaims={setSelectedClaims}
        />
      </Section.Column>
    </Section.Container>
  )
}

const AssessedClaimState = ({selectedClaims, claim_id, refetch, clearSelected, updateBatch, setUpdateBatch, closeBatchUpdate, allClaims, setSelectedClaims}) => {
  if (updateBatch) {
    return (
      <BatchUpdateAssessedDetails
        selectedClaims={selectedClaims}
        closeBatchUpdate={closeBatchUpdate}
      />
    )
  }
  else if (selectedClaims.length > 0) {
    return (
      <AssessedMultipleSelected
        selectedClaims={selectedClaims}
        refetch={refetch}
        clearSelected={clearSelected}
        setUpdateBatch={setUpdateBatch}
        allClaims={allClaims}
        setSelectedClaims={(claims) => setSelectedClaims(claims)}
      />
    )
  }
  else {
    if (!claim_id) {
      return <FormColumn>
        <EmptyList message={'Select a claim to view it'}/>
      </FormColumn>
    }
    else {
      return <AssessedClaimUpdateDetails />
    }
  }
}

export default AssessedClaims
