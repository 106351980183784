import React, { useState } from "react";
import { Box, SxProps } from "@mui/material";
import { Theme } from "@mui/system";

export interface OptionsProps {
  label: string
  content: React.ReactNode
}

export interface TabsProps {
  options: OptionsProps[]
  sx?: SxProps<Theme>
  header?: React.ReactNode
  variant?: 'default' | 'contained'
  centered?: boolean
  dataTestId?: string
}

export const Tabs = ({ options, sx = {}, header, variant = 'default', centered = false, dataTestId }: TabsProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const defaultStyles = (theme, label, index) => ({
    textDecoration: index === activeTabIndex ? 'underline' : '',
    textDecorationSkipInk: 'none',
    textDecorationThickness: index === activeTabIndex ? '2px' : '',
    textUnderlineOffset: '2px',
    alignItems: 'center',
    borderRadius: '8px',
    display: 'inline-flex',
    color:
      index === activeTabIndex
        ? theme.palette.primary.main
        : theme.palette.greys.medium,
    cursor: 'pointer',
    flexDirection: 'column',
    fontWeight: index === activeTabIndex ? 'bold' : '',
    mr: 3,
    px: 1,
    py: '4px',
    '&:hover': {
      backgroundColor: index === activeTabIndex ? '' : theme.palette.backgrounds.hover,
    },
    '&::after': {
      content: `"${label}"`,
      fontWeight: 'bold',
      height: 0,
      overflow: 'hidden',
      pointerEvents: 'none',
      userSelect: 'none',
      visibility: 'hidden',
    },
  })

  const containedStyles = (theme, index) => ({
    width: '100%',
    backgroundColor:
      index === activeTabIndex
        ? theme.palette.primary.main
        : 'white',
    color:
      index === activeTabIndex
        ? 'white'
        : theme.palette.greys.light,
    cursor: 'pointer',
    border: '1px solid',
    borderColor: theme.palette.greys.light,
    textAlign: 'center',
    padding: theme.spacing(1),
    '&:first-of-type': {
      borderRadius: '8px 0px 0px 8px',
    },
    '&:last-child': {
      borderRadius: '0px 8px 8px 0px',
    },
    '&:hover': {
      backgroundColor: index === activeTabIndex
        ? theme.palette.primary.main
        : theme.palette.backgrounds.hover
    },
  })

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow:"auto"
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: '40px',
          justifyContent: centered ? 'center' : 'flex-start',
          ...sx,
        }}
      >
        {header}
        {options.map((option, index) => {
          return (
            <Box
              data-testid={`${dataTestId}-option-${index}`}
              key={index}
              sx={(theme) => {
                switch(variant) {
                  case "contained":
                    return containedStyles(theme, index)
                  default:
                    return defaultStyles(theme, option.label, index)
                }
              }}
              onClick={() => setActiveTabIndex(index)}
            >
              {option.label}
            </Box>
          )
        })}
      </Box>
      {options[activeTabIndex].content}
    </Box>
  )
}

export default Tabs
