import React from 'react'
import { Box } from '@mui/material'
import { List } from './components/Tasks/List'
import { FormSection } from './components/Tasks/FormSection'

export const Tasks = () =>{
  return (
    <Box display={'flex'} width={"100%"}>
      <List />
      <FormSection />
    </Box>
  )
}

