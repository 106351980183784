import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Button, RemoveButton, PrintIconButton, SplitButton, ConfirmationDialog, IconButton, SimpleTooltip } from "saga-library/src";
import { SplitButtonOptionProps } from "saga-library/src/components/SplitButton/SplitButton";
import { PrescriptionStatus, PrescriptionType } from "../../../../../types/Prescription";
import { Permission, PermissionType } from "../../../../../types/settings/Permission";
import { HasPermissionTemplate } from "../../../../../components/HasPermissionTemplate";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import {ReactComponent as CreateFavouriteIcon} from "../../../../../assets/CreateFavouriteIcon.svg";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { useMutation } from "@apollo/client";
import { CREATE_FAVOURITE_PRESCRIPTION } from "../../../../../graphql-definitions/tenant/users/TenantUserFavouriteQueries";
import { cleanPrescriptionInput, usePrescriptionUtils } from "./PrescriptionUtil";
import { useParams } from "react-router-dom";
import { UseFormHandleSubmit } from "react-hook-form";

interface PrescriptionActionsProps {
  prescription?: PrescriptionType,
  formName: string,
  onSaveAsActive: (any)=>void
  onSaveAsDraft: (any)=>void
  onNew?: (any)=>void
  onSetAsInactive?: (string)=>void
  onDelete?: (string)=>void
  onSetAsHistorical?: (any)=>void
  getValues: (any)=>any
  onPrintComplete?: (any)=>void
  handleSubmit: UseFormHandleSubmit<PrescriptionType>
}

interface SavePrescriptionButtonProps {
  prescription?: PrescriptionType,
  onSetAsHistorical?: (any)=>void,
  getValues: (any)=>any,
  onSetAsInactive?: (string)=>void,
  formName: string,
  onSaveAsDraft: (any)=>void,
  splitButtonPrimaryAction?: ()=>void
}

export const PrescriptionActions = ({
  formName,
  onSaveAsActive,
  onSaveAsDraft,
  prescription,
  onNew,
  onDelete = ()=>{},
  onSetAsHistorical,
  getValues,
  onSetAsInactive = ()=>{},
  onPrintComplete = ()=>{},
  handleSubmit
}: PrescriptionActionsProps) => {

  return <Box display={"flex"} gap={1} alignItems={'center'}>
    <HasPermissionTemplate requiredType={PermissionType.Chart} requiredPermission={Permission.READWRITE} >
      <>
        <Box display={"flex"} flexDirection={"row"} alignItems={'center'}>
          <CreateFavouriteButton prescription={prescription} handleSubmit={handleSubmit}/>
          <PrintPrescriptionButton prescription={prescription} onSaveAsActive={onSaveAsActive} onPrint={onPrintComplete}/>
          <DeletePrescriptionButton prescription={prescription} onDelete={onDelete}/>
        </Box>

        {onNew && <Button
          dataTestId={`${formName}-save-and-new`}
          name={'saveAndNew'}
          variant={'outlined'}
          onClick={onNew}
          >
            new
          </Button>
        }

        <SavePrescriptionButton
          prescription={prescription}
          onSetAsHistorical={onSetAsHistorical}
          getValues={getValues}
          onSetAsInactive={onSetAsInactive}
          formName={formName}
          onSaveAsDraft={onSaveAsDraft}
        />
      </>
    </HasPermissionTemplate>
  </Box>
}

export const SavePrescriptionButton = ({
  prescription,
  onSetAsHistorical,
  getValues,
  onSetAsInactive = ()=>{},
  formName,
  onSaveAsDraft,
  splitButtonPrimaryAction = ()=>{}
}: SavePrescriptionButtonProps) => {
  const [isHistorical, setIsHistorical] = useState(prescription?.historical)

  useEffect(() => {
    setIsHistorical(getValues('historical'))
  }, [getValues('historical')]);

  if(prescription?.status === PrescriptionStatus.INACTIVE){
    return null
  }

  if(prescription?.status === PrescriptionStatus.ACTIVE){
    return <DeactivateButton onClick={()=> {
      onSetAsInactive(prescription)
    }}/>
  }

  if(isHistorical){
    return <Button
      dataTestId={`${formName}-save-as-inactive`}
      name={'save_as_inactive'}
      sx={{width:'190px'}}
      onClick={onSetAsHistorical}
    >
      Save as inactive
    </Button>
  }

  return <PrescriptionSplitButton onSaveAsDraft={onSaveAsDraft} formName={formName} primaryAction={splitButtonPrimaryAction} />
}

export const PrescriptionSplitButton = ({onSaveAsDraft, formName, primaryAction=(prescription)=>{}})=> {

  const saveOptions:SplitButtonOptionProps[] = [
    {
      label: 'AS DRAFT',
      fx: onSaveAsDraft
    }
  ]

  return <HasPermissionTemplate requiredType={PermissionType.Chart} requiredPermission={Permission.READWRITE} >
    <SplitButton
    dataTestId={`${formName}-save-prescription-button`}
    primaryOption={{
      label: 'SAVE',
      type: 'submit',
      formName: formName,
      fx:primaryAction
    }}
    options={saveOptions} />
    </HasPermissionTemplate>
}

export const DeletePrescriptionButton = ({prescription, onDelete}) => {
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)

  if (prescription?.status === PrescriptionStatus.DRAFT) {
    return <HasPermissionTemplate requiredType={PermissionType.Chart} requiredPermission={Permission.READWRITE} >
      <RemoveButton
        dataTestId={'prescription'}
        onClick={() => setOpenDeleteConfirmation(true)}
      />
      <ConfirmationDialog
        open={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation(false)}
        title={'Delete prescription'}
        message={'This action cannot be undone.'}
        primaryAction={() => {
          setOpenDeleteConfirmation(false)
          onDelete(prescription?.id)
        }}
        primaryLabel={'Delete'}
        dataTestId={'delete-prescription-dialog'}
      />
    </HasPermissionTemplate>
  }

  return null
}

export const PrintPrescriptionButton = ({prescription, onSaveAsActive, onPrint}) => {
  const [openPrintDialog, setOpenPrintDialog] = useState(false)
  const [savedDraftPrescriptionId, setSavedDraftPrescriptionId] = useState(null)

  const handleClick = () => {
    const prescriptionStatus = prescription?.status ?? PrescriptionStatus.DRAFT

    if (prescriptionStatus === PrescriptionStatus.DRAFT) {
      onSaveAsActive((prescription) => {
        setSavedDraftPrescriptionId(prescription)
        setOpenPrintDialog(true)
      })
    } else if (prescriptionStatus === PrescriptionStatus.ACTIVE) {
      setOpenPrintDialog(true)
    }
  }

  if (prescription?.status !== PrescriptionStatus.INACTIVE) {
    return <HasPermissionTemplate requiredType={PermissionType.Chart} requiredPermission={Permission.READWRITE} >
      <PrintIconButton
        dataTestId={'prescription'}
        onClick={handleClick}
      />
      <ConfirmationDialog
        open={openPrintDialog}
        title={'Prescription printed'}
        message={'Prescription printed.'}
        primaryAction={() => {
          setOpenPrintDialog(false)
          if (savedDraftPrescriptionId) {
            onPrint(savedDraftPrescriptionId)
          }
        }}
        dataTestId={'print-prescription-dialog'}
      />
    </HasPermissionTemplate>
  }

  return null
}

export const CreateFavouriteButton = ({prescription, handleSubmit}) => {
  const [favourited, setFavourited] = useState<boolean>(false)

  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const { tenant_id } = useParams()
  const { processPrescription } = usePrescriptionUtils()

  useEffect(() => {
    setFavourited(false)
  }, [prescription?.id])

  const [createFavourite] = useMutation(
    CREATE_FAVOURITE_PRESCRIPTION,
    {
      onCompleted: (data) => {
        setFavourited(true)
        showSuccessAlert('Favourite prescription has been created.')
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert("Favourite prescription couldn't be created.")
      },
    }
  )

  const CreateFavourite = () => {
    const onClick = () => {
      handleSubmit(async (data) => {
        const prescriptionData = cleanPrescriptionInput(processPrescription(data))
        await createFavourite({
          variables: {
            prescriptionInput: {
              ...prescriptionData,
              version: "0"
            },
            tenantId: tenant_id
          }
        })
      })()
    }
    return <IconButton
      dataTestId={'create-favourite-button'}
      onClick={onClick}
      icon={<CreateFavouriteIcon />}
    />
  }

  const FavouriteCreated = () => {
    return <IconButton
      dataTestId={'favourite-created-button'}
      onClick={() => {}}
      icon={<SimpleTooltip title={"A favourite prescription has already been created."}>
              <FavoriteOutlinedIcon  sx={{color: 'greys.medium', height: '24px'}}/>
            </SimpleTooltip>}
    />
  }

  return <HasPermissionTemplate requiredType={PermissionType.Chart} requiredPermission={Permission.READWRITE} >
    {favourited ? <FavouriteCreated /> : <CreateFavourite />}
  </HasPermissionTemplate>
}

export const DeactivateButton = ({onClick}) => {
    return <Button dataTestId={"set-inactive-button"} name={"set_inactive_button"} variant={"outlined"} onClick={onClick} sx={{width:'168px'}}>
      Set as inactive
    </Button>
}