import React from 'react'
import { EncounterNoteTemplate, EncounterNoteTemplateFieldType } from '../../../../types/patients'
import { Box, SxProps, useTheme } from '@mui/material'
import { Theme } from '@mui/system'
import { EmptyList, Typography, TextField } from 'saga-library/src'
import { buildNewEncounterNote } from './EncounterNoteUtil'

interface EncounterNoteTemplateFieldsProps {
  template?: EncounterNoteTemplate | undefined,
  isSigned?: boolean,
  disabled?: boolean,
  sx?: SxProps<Theme>
}

export const EncounterNoteTemplateFields = ({
  template,
  isSigned,
  disabled,
  sx
}: EncounterNoteTemplateFieldsProps) => {
  const theme = useTheme()

  if (!template) {
    return <EmptyList message={"Select an encounter note template"} />
  }

  const renderField = (templateField, index: number) => {
    switch (templateField.type) {
      case EncounterNoteTemplateFieldType.TEXTBOX:
        const name = `textbox-${templateField.id}`

        return (
          <Box
            key={templateField.id}
            display="flex"
            flexDirection="column"
            minHeight={'56px'}
            marginBottom={'16px'}
            border={'1px solid #C4C4C4'}
            borderRadius={'4px'}
          >
            <Box
              key={`${templateField.id}-field-name-box`}
              data-testid={`field-name-box-${index}`}
              display="flex"
              flexDirection="row"
              minHeight={'40px'}
              bgcolor={theme.palette.tertiary.main}
              alignItems="center"
              paddingLeft={'8px'}
            >
              <Typography
                key={`${templateField.id}-field-name`}
                variant="h5"
                dataTestId={`field-name-${index}`}
              >
                {templateField.name}
              </Typography>
            </Box>
            <TextField
              label={''}
              name={name}
              dataTestId={`text-area-${index}`}
              variant="standard"
              hideHelperText={true}
              multiline
              disabled={disabled}
              InputProps={{
                readOnly: isSigned,
                disableUnderline: true
              }}
              sx={{
                marginTop: '0px',
                paddingX: '8px'
              }}
            />
          </Box>
        )
      default:
        console.warn("Unknown field type: ", templateField.type)
    }
  }

  return (
    <Box
      key={'encounter-note-template-fields'}
      display="flex"
      flexDirection="column"
      flex={'1 1 100%'}
      sx={{...sx}}
    >
      {
        buildNewEncounterNote(template)?.template?.fieldTree?.map((templateField, index) => {
          return renderField(templateField, index)
        })
      }
    </Box>
  )
}