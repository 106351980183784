import { ComingSoonPlaceholder, Section, Button } from 'saga-library/src'
import React from 'react'
import { Box } from '@mui/material'

export const List = () => {
  const TaskListHeader = () => {
    return <Box display={'flex'} mb={2} alignItems={'center'}>
      <Box flex={"1 1 100%"}> Filter Placeholder</Box>
      <Button dataTestId={"new_task_button"} name={"new"} onClick={() => console.log("new task")}> new </Button>
    </Box>
  }
  return (
    <Section.Column width={"50%"} sx={{pr:1}} header={<TaskListHeader />}>
      <ComingSoonPlaceholder message={"Task List Table"} />
    </Section.Column>
  )
}