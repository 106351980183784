import React from "react";
import { Section, Typography } from "saga-library/src";
import { Box } from "@mui/material";
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { PrimaryPhoneText, PhoneTypeChip } from "saga-library/src/components/Phone/PhoneTypeLabel";
import { phoneNumberMask } from "saga-library/src/components/PhoneField/PhoneField";
import moment from "moment-timezone";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { IdentifierLabel } from "saga-library/src/components/SearchControl/blocks";
import {
  GET_PATIENT_APPOINTMENT_DATES,
  GET_PATIENT_SCHEDULE_LABEL
} from "../../../graphql-definitions";
import { patientDisplayName } from "saga-library/src/util";
import _get from "lodash/get";
import { practitionerDisplayName } from "saga-library/src/util/formatting";
import { PractitionerIcon } from "../../patients/components/ChartIcons";
import { FormattedAge } from '../../../components/FormattedAge'
import { PatientAddressRow } from '../../patients/PatientLayout'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import { useFormattedDate } from '../../../hooks/FormattedDate'

export const PatientInfoPanel = ({patientId}) => {
  if (!patientId) {
    return null
  }
  return <PatientInfoPanelContent patientId={patientId} />
}

export const formatNextAppointmentDate = (date) => {
  const dateMoment = moment(date, "YYYY-MM-DD")
  return dateMoment.isValid() && date !== '0001-01-01' ? dateMoment.format("dddd, MMMM DD, YYYY") : "N/A"
}

const PatientInfoPanelContent = ({patientId}) => {
  const { tenant_id } = useParams()

  const { data: patientScheduleData } = useQuery(GET_PATIENT_SCHEDULE_LABEL, {
    variables: {
      tenantId: tenant_id,
      patientId: patientId
    }
  })

  const { data : appointmentDatesData } = useQuery(GET_PATIENT_APPOINTMENT_DATES, {
    variables: {
      tenantId: tenant_id,
      patientId: patientId
    }
  })

  const PatientInfoPanelRow = ({children, address=false}) => {
    return (
      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: address? '' : 'center', mb: 1, '& > *:not(:last-child)': {mr: 1}}}>
        {children}
      </Box>
    )
  }

  const PatientPractitionerDisplay = ({primaryPractitioner}) => {
    if (primaryPractitioner) {
      return <Typography variant={'body1'}>{practitionerDisplayName(primaryPractitioner.firstName, primaryPractitioner.lastName)}</Typography>
    }
    return <Typography variant={'body1'} sx={theme => ({ color: theme.palette.greys.medium })}>No practitioner selected</Typography>
  }

  const PatientPhoneRows = ({phones}) => {
    return phones.map((phone, index) => {
      return (
        <PatientInfoPanelRow key={index}>
          <PhoneOutlinedIcon/>
          <Typography variant={'body1'}>
            {phoneNumberMask(phone.number)}
          </Typography>
          <PhoneTypeChip type={phone.type}/>
          {phone.isPrimary && <PrimaryPhoneText bold={false} size={'small'}/>}
        </PatientInfoPanelRow>
      )
    })
  }

  const PatientDetails = ({patientDetails}) => {
    const formattedDate = useFormattedDate(patientDetails.dob)
    return (
      <>
        <PatientInfoPanelRow>
          <Typography variant={'body1'} fontWeight={'bold'}>{patientDisplayName(patientDetails.firstName, patientDetails.lastName)}</Typography>
          <IdentifierLabel label={patientDetails.primaryIdentifierLabel || "ID"} />
          <Typography variant={'body2'}>{patientDetails.primaryIdentifier || "None"}</Typography>
        </PatientInfoPanelRow>
        <PatientInfoPanelRow>
          {patientDetails.dob && <>
              <CakeOutlinedIcon/>
              <Typography variant={'body1'}>{formattedDate}</Typography>
              <FormattedAge dateOfBirth={patientDetails.dob} dataTestId={'patient-date-of-birth'}/>
            </>
          }
          <Typography variant={'body1'}>{patientDetails.gender?.name}</Typography>
        </PatientInfoPanelRow>
        { patientDetails.email && <PatientInfoPanelRow>
            <MailOutlineIcon/>
            <Typography variant={'body1'}>{patientDetails.email}</Typography>
          </PatientInfoPanelRow>
        }
        <PatientPhoneRows phones={patientDetails.phones}/>
        <PatientInfoPanelRow address={true}>
          <HomeOutlinedIcon />
          <PatientAddressRow variant={'body1'} street={patientDetails.street} street2={patientDetails.street2} street3={patientDetails.street3} city={patientDetails.city} province={patientDetails.province} postalCode={patientDetails.postalCode}/>
        </PatientInfoPanelRow>
        <PatientInfoPanelRow>
          <PractitionerIcon/>
          <PatientPractitionerDisplay primaryPractitioner={patientDetails.primaryPractitioner} />
        </PatientInfoPanelRow>
      </>
    )
  }

  if (!patientId || !patientScheduleData || !appointmentDatesData) {
    return (
      <Section.FormSection sx={{ maxWidth: "48%" }}>
      </Section.FormSection>
    )
  }

  return (
    <Section.FormSection sx={{ maxWidth: "48%", mt: 2 }}>
      <PatientDetails patientDetails={_get(patientScheduleData, 'tenant.patient.label', null)}/>
      <Box sx={{mt: 1}}>
        <Typography variant={'body2'}>Last visit</Typography>
        <Typography variant={'body1'} fontWeight={'bold'}>{formatNextAppointmentDate(appointmentDatesData.tenant.schedule.appointment.patientAppointmentDates.item1)}</Typography>
      </Box>
      <Box sx={{mt: 2}}>
        <Typography variant={'body2'}>Next visit</Typography>
        <Typography variant={'body1'} fontWeight={'bold'}>{formatNextAppointmentDate(appointmentDatesData.tenant.schedule.appointment.patientAppointmentDates.item2)}</Typography>
      </Box>
    </Section.FormSection>
  )
}
