

export const capitalizedWord = (word: string): string => {
  if (!word) return word;
  return word[0].toUpperCase() + word.substring(1).toLowerCase();
}

export const lowerCaseWord = (word: string|undefined|null): string => {
  if (!word) return "";
  return word.toLowerCase();
}