import React, { useEffect, useState } from 'react'
import { Box, Menu, MenuItem, Tooltip } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { styled } from '@mui/system'
import { ConfirmationDialog, Typography, TextHighlighting, TableListRowConfig, IconButton } from "saga-library/src";
import { useAccountContext } from '../../../providers/AccountContext'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { LoadingSpinner } from "../../../components/LoadingScreen";
import { useAlerts } from 'saga-library/src/providers/Alerts'
import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'
import { Chip } from "saga-library/src/components/Chip/Chip";
import moment from 'moment-timezone'
import { AccountCircleOutlinedStyledIcon } from "./TenantInviteRow";
import { RESTORE_ACCESS_MUTATION, REVOKE_ACCESS_MUTATION, GET_TENANT_USERS } from "../../../graphql-definitions";
import { useFormattedDate } from '../../../hooks/FormattedDate'

const AccountIcon = styled(AccountCircleIcon)(({ theme }) => ({
  color: theme.palette.greys.light,
  fontSize: 'xxx-large',
}))

export const TenantUserRow = (user, practitioner, roles, searchText, allowActions, users, currentUserId): TableListRowConfig => {
  const ownerRoleIds = roles.filter(r => r.isOwner).map(o => o.id)
  const isRowForOwner = user.roleIds.some(r => ownerRoleIds.includes(r))

  const ownerUserIds = users.filter(u => u.roleIds.some(r => ownerRoleIds.includes(r))).map(o => o.id)
  const currentUserIsOwner: boolean = ownerUserIds.includes(currentUserId)
  const adminRoles: string[] = roles.filter(r => r.isAdmin).map(r => r.id)
  const isRowForAdmin: boolean = user.roleIds.some(r => adminRoles.includes(r))

  const AllowedActions = () => {
    if (currentUserId === user.id || isRowForOwner) {
      return <></>
    } else if (currentUserIsOwner && isRowForAdmin) {
      return <TenantUserRowAction user={user} allowActions={allowActions} />
    } else if (!currentUserIsOwner && isRowForAdmin) {
      return <></>
    } else {
      return <TenantUserRowAction user={user} allowActions={allowActions} />
    }
  }
  return ({
    key: user.id,
    rowData: [
      {
        children:
          <Box
            display={'flex'}
            alignItems={'center'}
        >
            {user.active ? <AccountIcon /> : <AccountCircleOutlinedStyledIcon/>}
          </Box>,
      },
      {
        data: `${user.lastName} ${user.firstName}`,
        children: [
          <Box key={'name-'+user.id} display={'flex'} flexDirection={'row'} sx={theme => ({color: !user.active ? theme.palette.greys.light : ''})}>
            <Typography variant={'p4'}>
              <TextHighlighting text={`${user.lastName}, ${user.firstName} ${currentUserId === user.id ? '(You)' : ''}`} highlight={searchText} />
            </Typography>
          </Box>,
          <Box key={'email-'+user.id} sx={theme => ({color: theme.palette.greys.light})}>
            <Typography variant={'p4'}>
              <TextHighlighting text={user.emailAddress} highlight={searchText} />
            </Typography>
          </Box>,
        ],
      },
      {
        children: <TenantUserRoles user={user} roles={roles} />,
      },
      {
        children: practitioner &&
          <Typography variant={'body1'}> {practitioner.lastName}, {practitioner.firstName} </Typography>
        ,
      },
      {
        children: <LastSignIn user={user}/>
      },
      {
        children:
          <Typography variant={'p4'} sx={theme => ({color: !user.active ? theme.palette.greys.light : ''})}>
            {user.active ? 'Has access' : 'No access'}
          </Typography>
      },
      {
        children: <AllowedActions />
      },
    ]
  })
}

const TenantUserRoles = ({ user, roles }) => {
  const { userId } = useAccountContext()
  return user.roleIds.map((roleId) => {
    let role = roles.find((r) => r.id === roleId)
    return (
      <Chip
        key={user.id+'-'+role.id}
        label={role.name}
        variant={user.id === userId ? 'filled' : 'outlined'}
        sx={theme => ({
          color: !user.active ? theme.palette.greys.light : '',
          borderColor: !user.active ? theme.palette.greys.light : '',
        })}
      />
    )
  })
}

const LastSignIn = ({user}) => {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
    >
      <Tooltip title={useFormattedDate(user.lastSignIn)} placement={'right'}>
        <div>
          <Typography
            variant={'p4'}
            sx={theme => ({
              color: !user.active ? theme.palette.greys.light : ''
            })}
          >
            {user.lastSignIn ? moment(user.lastSignIn).fromNow() : 'Never'}
          </Typography>
        </div>
      </Tooltip>
    </Box>
  )
}

const TenantUserRowAction = ({ user, allowActions = true }) => {
  const { tenant_id } = useParams()
  const { getTenantName } = useAccountContext()
  const tenantName = getTenantName(tenant_id)
  const { showSuccessAlert, showErrorAlert } = useAlerts()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openRevokeAccessDialog, setOpenRevokeAccessDialog] = useState<boolean>(false)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const [revokeAccess, revokeProps] = useMutation(REVOKE_ACCESS_MUTATION)

  const [restoreAccess, restoreProps] = useMutation(RESTORE_ACCESS_MUTATION)

  const revokeClicked = () => {
    revokeAccess({
      variables: {
        tenantId: tenant_id,
        userId: user.id,
      },
      update: (cache) => {
        toggleUserAccessCache(cache)
      },
    })
    handleClose()
  }

  const restoreClicked = () => {
    restoreAccess({
      variables: {
        tenantId: tenant_id,
        userId: user.id,
      },
      update: (cache) => {
        toggleUserAccessCache(cache)
      },
    })
    handleClose()
  }

  const toggleUserAccessCache = (cache) => {
    const data = cache.readQuery({
      query: GET_TENANT_USERS,
      variables: { tenantId: tenant_id },
    })
    const userCopy = _cloneDeep(_get(data, 'tenant.user.allTenantUsers'))
    let updatedUser = userCopy.find((u) => u.id === user.id)
    updatedUser.active = !updatedUser.active
    cache.writeQuery({
      query: GET_TENANT_USERS,
      variables: { tenantId: tenant_id },
      data: {
        tenant: { ...data.tenant, user: { activeTenantUsers: userCopy } },
      },
    })
  }

  useEffect(() => {
    if (revokeProps.error) {
      showErrorAlert(revokeProps.error.message)
      revokeProps.reset()
    }

    if (restoreProps.error) {
      showErrorAlert(restoreProps.error.message)
      restoreProps.reset()
    }

    if (revokeProps.data) {
      showSuccessAlert('User access has been revoked.')
    }

    if (restoreProps.data) {
      showSuccessAlert('User access successfully restored')
    }
  }, [
    revokeProps.error,
    revokeProps.data,
    restoreProps.error,
    restoreProps.data,
  ])

  const loading = restoreProps.loading || revokeProps.loading
  return (
    <>
      <IconButton onClick={handleClick} disabled={!allowActions} icon={<MoreHorizIcon />}/>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {user.active ? (
          <MenuItem onClick={() => setOpenRevokeAccessDialog(true)}>Revoke access</MenuItem>
        ) : (
          <MenuItem onClick={restoreClicked}>Restore access</MenuItem>
        )}
        {loading && (
          <MenuItem>
            <LoadingSpinner />
          </MenuItem>
        )}
      </Menu>
      <ConfirmationDialog
        open={openRevokeAccessDialog}
        title={'Revoke access?'}
        message={`The selected user will no longer have access to ${tenantName}. Their access can be restored at any point.`}
        primaryAction={revokeClicked}
        onClose={() => setOpenRevokeAccessDialog(false)}
        dataTestId={'tenantUser-revokeAccess-confirmation-dialog'}
      />
    </>
  )
}
