import React, { useEffect } from "react";
import { Section } from "saga-library/src";
import { PatientSectionHeader } from "../PatientSectionHeader";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { PrescriptionForm } from "./components/PrescriptionForm";
import { PrescriptionActions } from "./components/PrescriptionActions";
import { PrescriptionStatus, PrescriptionType } from "../../../../types/Prescription";
import { schema, requiredSchema } from "./components/PrescriptionFormValidationsSchema";
import { useQuery } from "@apollo/client";
import { GET_PATIENT_PRESCRIPTION } from "../../../../graphql-definitions";
import { useNavigate, useParams } from "react-router-dom";
import _get from "lodash/get";
import omitDeep from "omit-deep-lodash";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { Box } from "@mui/material";
import { PrescriptionStatusTag } from "./components/PrescriptionStatusTag";
import { RenewBanner } from "./components/RenewBanner";
import { usePrescriptionUtils } from "./components/PrescriptionUtil";
import { LoadingOverlay } from "../../../../components/LoadingScreen";
import { prescriptionFormDefaultValues } from "./components/PrescriptionFormDefaultValues";
import { useAccountContext } from "../../../../providers/AccountContext";
import { usePrescriptionContext } from "../../providers/PrescriptionProvider";
import { flushSync } from "react-dom";

const FORM_NAME = "edit_prescription_form"
export const EditPrescription= ({}) =>{
  const { tenant_id, patient_id, prescription_id } = useParams()
  const { buildTenantRoute } = useAccountContext()
  const {
    deleteDraftPrescription,
    inactivatePrescription,
    renewPrescription,
  } = usePrescriptionContext()
  const { enableNavigationPrompt, clearNavigationPrompt } = usePrompt()
  const navigate = useNavigate()
  const [ prescription, setPrescription ] = React.useState<PrescriptionType>()
  const { setAsHistorical, saveAsActive, saveAsDraft } = usePrescriptionUtils()

  const formMethods = useForm<PrescriptionType>({
    schema: schema,
    requiredSchema: requiredSchema,
    defaultValues: prescriptionFormDefaultValues()
  })

  const {
    handleSubmit,
    formState: { dirtyFields },
    reset,
    setValue,
    getValues
  } = formMethods

  useEffect(() => {
    reset(prescriptionFormDefaultValues())
  },[prescription_id]);

  const { loading } = useQuery( GET_PATIENT_PRESCRIPTION, {
    variables:{
      tenantId: tenant_id,
      patientId: patient_id,
      prescriptionId: prescription_id
    },
    onCompleted: (data) => {
      let prescriptionIn = _get(
        data,
        'tenant.patient.prescription.get',
        null
      )
      prescriptionIn = omitDeep(prescriptionIn, '__typename')

      if(prescriptionIn.dosages.length === 0){
        prescriptionIn.dosages = prescriptionFormDefaultValues().dosages
      }

      setPrescription( Object.assign({}, prescriptionIn) )
      delete prescriptionIn.renewedOn
      delete prescriptionIn.renewedFrom
      reset(prescriptionIn)
    }
  })

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, `prescriptions/p/${prescription_id}`);
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length]);


  const onDelete = (prescriptionId) => {
    deleteDraftPrescription(prescriptionId, () => {
      flushSync(() => {
        clearNavigationPrompt(FORM_NAME)
      })
      navigate(buildTenantRoute(`patients/p/${patient_id}/prescriptions`, tenant_id), {replace: true})
    })
  }

  const onSaveAsDraft = (onComplete = (any)=>{}) => {
    saveAsDraft(
      handleSubmit,
      (updatedPrescription) => {
        setPrescription(updatedPrescription)
        onComplete(updatedPrescription)
      }
    )
  }

  const onSaveAsActive = (onComplete = (any)=>{}) => {
    saveAsActive(
      setValue,
      handleSubmit,
      (updatedPrescription) => {
        setPrescription(updatedPrescription)
        onComplete(updatedPrescription)
      }
    )
  }

  const onSubmit = (e, onComplete) => {
    e.preventDefault()
    onSaveAsActive(onComplete)
  }

  const onSetAsInactive = (prescriptionId) => {
    inactivatePrescription(prescriptionId, ()=>null)
  }

  const onSetAsHistorical = () => {
    setAsHistorical(
      handleSubmit,
      (updatedPrescription) => setPrescription(updatedPrescription))
  }

  const onNew = () => {
    navigate(buildTenantRoute(`patients/p/${patient_id}/prescriptions/new`, tenant_id))
  }

  const onRenew = (prescriptionId) => {
    renewPrescription(
      prescriptionId,
      (newPrescription)=>{
      navigate(buildTenantRoute(`patients/p/${patient_id}/prescriptions/p/${newPrescription.id}`, tenant_id))
    })
  }

  const onBack = () => {
    navigate(buildTenantRoute(`patients/p/${patient_id}/prescriptions`, tenant_id), {replace: true})
  }

  const formDisabled = prescription?.status === PrescriptionStatus.ACTIVE || prescription?.status === PrescriptionStatus.INACTIVE

  const onSaveComplete = () => {
    reset({}, {keepValues: true})
  }

  return <Section.Column
    sx={{ flex: "1 1 100%"}}
    header={
      <PatientSectionHeader
        dataTestId={'edit-prescription'}
        sectionLabel={prescription?.drug.name}
        showBackButton={true}
        onBack={onBack}
        showSave={false}
        actions={prescription ?
          <PrescriptionActions
            formName={FORM_NAME}
            onSaveAsActive={()=>{
              onSaveAsActive(onSaveComplete)
            }}
            onSaveAsDraft={()=>onSaveAsDraft(onSaveComplete)}
            onSetAsHistorical={onSetAsHistorical}
            onDelete={onDelete}
            onNew={onNew}
            getValues={getValues}
            prescription={prescription}
            onSetAsInactive={onSetAsInactive}
            handleSubmit={handleSubmit}
          /> : <></>
        }
        lineclamp={1}
        sx={{mb:0}}
      />} >
    <Box display={'flex'} flexDirection={'column'} gap={1} mb={1}>
      <PrescriptionStatusTag prescriptionStatus={prescription?.status} />
      <RenewBanner prescription={prescription} onRenew={ ()=>onRenew(prescription?.id)} />
    </Box>
    <FormProvider {...formMethods}>
      <LoadingOverlay loading={loading} message={"Loading prescription"}/>
      <PrescriptionForm
        formName={FORM_NAME}
        onSubmit={(e)=>onSubmit(e, onSaveComplete)}
        formDisabled={formDisabled}
        autoFocus={false}
      />
    </FormProvider>
  </Section.Column>
}