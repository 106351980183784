import React from 'react'
import { useParams } from 'react-router-dom'
import { ControlledRichTextEditor } from 'saga-library/src'
import { useConfigurationContext } from '../../providers/Configuration'
import { useDocumentContext } from '../../providers/DocumentProvider'
import { ControlledRichTextEditorProps } from 'saga-library/src/components/RichTextEditor/RichTextEditor'
import { GET_FILE_DETAILS_FROM_URL } from '../../graphql-definitions'
import { DocumentNode } from 'graphql/language'

interface RichTextEditorClientProps extends Omit<ControlledRichTextEditorProps,
  'froalaKey' | 'uploadFiles' | 'deleteFile' |
  'getFileTypeUriWithToken' | 'tenantId' | 'getFileDetailsCall'> {
  getFileDetailsCall?: DocumentNode
}

export const RichTextEditorClient = ({
  getFileDetailsCall = GET_FILE_DETAILS_FROM_URL,
  ...props
}: RichTextEditorClientProps) => {
  const { getConfigValue } = useConfigurationContext()
  const { tenant_id } = useParams()
  const froalaKey = getConfigValue('FROALA_CLIENT_TOKEN')
  const { uploadFiles, deleteFile, getFileTypeUriWithToken } = useDocumentContext()

  return (
    <ControlledRichTextEditor
      froalaKey={froalaKey}
      tenantId={tenant_id}
      uploadFiles={uploadFiles}
      deleteFile={deleteFile}
      getFileTypeUriWithToken={getFileTypeUriWithToken}
      getFileDetailsCall={getFileDetailsCall}
      {...props}
    />
  )
}