import React from "react";
import SettingsHeader from "../components/SettingsHeader";
import { SettingsSectionColumn } from "../components/SettingsSectionColumn";
import { ComingSoonPlaceholder } from "saga-library/src";

export const TaskTemplates = () => {
  return (
    <SettingsSectionColumn header={
      <SettingsHeader title={'Task templates'} dataTestId={'task-templates'} />
    }>
      <ComingSoonPlaceholder />
    </SettingsSectionColumn>
  )
}