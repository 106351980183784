import { gql } from "@apollo/client";

export const LIST_TASK_TYPES = gql`
  query ListTaskTypes($tenantId: ID!) {
    tenant(id: $tenantId) {
      task {
        listTaskTypes {
          id
          type
          version
        }
      }
    }
  }
`

export const CREATE_TASK_TYPE = gql`
  mutation CreateTaskType($tenantId: ID!, $input: TaskTypeInput!) {
    tenant(id: $tenantId) {
      task {
        createTaskType(input: $input) {
          id
          type
          version
        }
      }
    }
  }
`

export const LIST_TASK_STATES = gql`
    query ListTaskStates($tenantId: ID!) {
        tenant(id: $tenantId) {
            task {
                listTaskStates {
                    id
                    name
                    category
                    order
                    version
                }
            }
        }
    }
`

export const UPDATE_TASK_STATE_SORT = gql`
    mutation UpdateTaskState($tenantId: ID!, $taskStateId: ID!, $index: Short!) {
        tenant(id: $tenantId) {
            task {
                updateTaskStateSort(id: $taskStateId, index: $index)
            }
        }
    }
`