import React from 'react'
import { styled } from '@mui/material/styles'
import MuiButton from '@mui/material/Button'
import MuiIconButton from '@mui/material/IconButton'
import MuiLoadingButton from '@mui/lab/LoadingButton'
import { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import { IconButtonProps as MuiIconButtonProps } from "@mui/material/IconButton";
import { LoadingButtonProps as MuiLoadingButtonProps } from "@mui/lab/LoadingButton";

const insertDataTestId = (icon: React.ReactNode, dataTestId: string = ''):React.ReactNode => {
  return React.isValidElement(icon)
    ? React.cloneElement(icon, {"data-testid": dataTestId, ...icon.props})
    : icon
}

export interface ButtonProps extends MuiButtonProps {
  name: string
  type?: 'button' | 'reset' | 'submit'
  variant?: 'outlined' | 'text' | 'contained'
  size?: 'small' | 'large'
  form?: string
  dataTestId?: string
  fullWidth?: boolean
}

const StyledButton = styled(MuiButton)({
  lineHeight: '16px',
  fontWeight: 700,
  borderRadius: '8px',
})

export const Button = ({
  type = 'button',
  name,
  variant = 'contained',
  form,
  sx,
  size,
  fullWidth = false,
  dataTestId,
  startIcon,
  endIcon,
  ...props
}: ButtonProps) => {
  return (
    <StyledButton
      data-testid={dataTestId}
      name={name}
      sx={{
        border: variant === 'outlined' ? '3px solid' : '',
        color: variant === 'outlined' ? 'primary.main' : '',
        "&:disabled":{
          border: variant === 'outlined' ? '3px solid' : '',
          color: variant === 'outlined' ? 'greys.medium' : '',
        },
        "&:hover": {
          border: variant === 'outlined' ? '3px solid' : '',
          backgroundColor: variant === 'contained' ? 'primary.dark' : 'backgrounds.hover',
        },
        height: size === 'small' ? "28px": '40px',
        textTransform: size === 'small' ? 'none' : 'uppercase',
        padding: size === 'small' ? '0 8px' : '0 24px',
        ...sx,
      }}
      fullWidth = {fullWidth}
      variant={variant}
      color="primary"
      type={type}
      form={form}
      size={size}
      startIcon={insertDataTestId(startIcon, `${dataTestId}-startIcon`)}
      endIcon={insertDataTestId(endIcon, `${dataTestId}-endIcon`)}
      {...props}
    />
  )
}

const StyledIconButton = styled(MuiIconButton)({
  borderRadius: '20px',
  padding: '8px',
})

export interface IconButtonProps extends MuiIconButtonProps {
  icon: React.ReactNode
  onClick: ((event?:any) => void) | ((event?:any) => Promise<void>) | (undefined) | (React.MouseEventHandler<HTMLButtonElement>)
  name?: string
  dataTestId?: string
  color?: 'primary' | 'default'
}

export const IconButton = ({
  icon,
  name,
  dataTestId,
  sx,
  color = 'default',
  size = 'small',
  ...props
}: IconButtonProps) => {
  return (
    <StyledIconButton
      name={name}
      data-testid={dataTestId}
      size={size}
      sx={{
        color: color === "primary" ? 'primary.main' : 'greys.medium',
        "&:hover": {
          backgroundColor: 'backgrounds.hover'
        },
        ...sx
      }}
      {...props}
    >
      {insertDataTestId(icon, `${dataTestId}-icon`)}
    </StyledIconButton>
  )
}

export interface LoadingButtonProps extends MuiLoadingButtonProps {
  display?: string
  type?: 'button' | 'reset' | 'submit'
  name: string
  variant?: 'text' | 'outlined' | 'contained'
  form?: string
  dataTestId?: string,
  buttonRef?: React.Ref<HTMLButtonElement>,
}

const StyledLoadingButton = styled(MuiLoadingButton)({
  lineHeight: '16px',
  fontWeight: 700,
  borderRadius: '8px',
  paddingLeft: '24px',
  paddingRight: '24px',
  height: '40px',
  flexShrink: 0,
})

export const LoadingButton = ({
  display = 'inline-block',
  type = 'button',
  name,
  sx,
  variant = 'outlined',
  form,
  dataTestId,
  startIcon,
  endIcon,
  buttonRef,
  ...props
}: LoadingButtonProps) => {
  return (
    <StyledLoadingButton
      data-testid={dataTestId}
      name={name}
      sx={{
        display: display,
        border: variant === 'outlined' ? '3px solid' : '',
        "&:hover": {
          border: variant === 'outlined' ? '3px solid' : '',
          backgroundColor: variant === 'contained' ? 'primary.dark' : 'backgrounds.hover',
        },
        "&:focus": {
          backgroundColor: variant === 'contained' ? '' : 'backgrounds.hover',
        },
        ...sx,
      }}
      variant={variant}
      color={'primary'}
      type={type}
      form={form}
      startIcon={insertDataTestId(startIcon, `${dataTestId}-startIcon`)}
      endIcon={insertDataTestId(endIcon, `${dataTestId}-endIcon`)}
      ref={buttonRef}
      {...props}
    />
  )
}

export interface SaveButtonProps {
  form: string
  submitting: boolean
  disabled?: boolean
  dataTestId: string
}
export const SaveButton = ({form, submitting, disabled, dataTestId} : SaveButtonProps) => {
  return (
    <LoadingButton
      data-testid={`${dataTestId}-save-button`}
      name={'SaveButton'}
      type={'submit'}
      loading={submitting}
      disabled={disabled}
      variant={'contained'}
      form={form}
    >
      Save
    </LoadingButton>
  )
}

export default Button
