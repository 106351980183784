import React, { useCallback, useEffect, useMemo } from 'react'
import { PDFUIInstanceContext } from './FoxitWebPDFContexts'
import { FoxitWebPDFApp } from './FoxitWebPDFApp'
import { PDFViewerRenderer } from './PDFViewerRenderer'
import { PDFViewer, PDFUI, ViewerEvents } from "./FoxitTypes";
import { LoadingSpinner } from "../LoadingScreen";
import { EmptyList } from "saga-library/src";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { FileType } from "../../types/tasks";
import { useAccountContext } from "../../providers/AccountContext";
import { DATE_FORMAT } from "../../utils/SettingsConstants";
import { usePDFViewerEvent } from "./UsePDFViewerEvent";

export interface FoxitPDFViewerProps {
  pdfuiRef?: React.MutableRefObject<PDFUI | null>
  pdfFunctionRef?: React.MutableRefObject<((pdfui: PDFUI) => void)>
  pdfViewerRef?: React.MutableRefObject<PDFViewer | null>,
  loading?: boolean,
  selectedFile?: FileType | boolean | null
  variant?: "document" | "forms" | "labs" | "no_ui"
}

export const FoxitPDFViewer = ({pdfuiRef, pdfFunctionRef, pdfViewerRef, loading, selectedFile, variant} : FoxitPDFViewerProps) => {
  const { documentViewerSettings: { zoom, sidebarWidth, set} } = useAccountContext()
  const { userFirstName, userLastName, getUserSetting } = useAccountContext()
  const properties = useMemo(() => {
    if (pdfFunctionRef?.current) {
      return {}
    }
    return {
      sidebarWidth: sidebarWidth,
      viewChanged: set,
      zoom: zoom
    }
  }, [])

  const documentOpenedCallback = useCallback((pdfViewer : PDFViewer, ...args : any[]) => {
    pdfViewer.setUserName(`${userLastName}, ${userFirstName}`)

    let dateFormat = getUserSetting(DATE_FORMAT) as string
    dateFormat += ' · h:mm A'
    pdfViewer.setFormatOfDynamicStamp('  ', dateFormat)
  }, [getUserSetting, userFirstName, userLastName])

  usePDFViewerEvent(ViewerEvents.openFileSuccess, documentOpenedCallback, pdfViewerRef)

  useEffect(() => {
    if (loading) return

    pdfViewerRef?.current?.redraw()
  }, [loading, pdfViewerRef])

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        if (entry.target.getAttribute('name') === 'sidebar' && entry.contentRect.width !== 0){
          set({
            sidebarWidth: entry.contentRect.width,
            zoom: zoom
          })
        }
      }
    })

    const viewerButtons = document.querySelectorAll('.fv__ui-button')
    viewerButtons.forEach((btn) => {
      const btnName = btn.getAttribute('name')
      btn.setAttribute('data-testid', `pdf-viewer-${btnName}`)
    })

    const toolbar = document.querySelector('[name="toolbar"]')
    if (toolbar) {
      const toolbarItems = toolbar.querySelectorAll('.fx-ribbon-item')
      toolbarItems.forEach((item) => {
        const itemName = item.getAttribute('name')
        item.setAttribute('data-testid', `pdf-viewer-${itemName}`)
      })
    }

    const sidebar = document.querySelector('[name="sidebar"]')
    if (sidebar) {
      let match
      const tabRegex = new RegExp('fv__icon-sidebar-(\\w*)', 'g')
      const sidebarTabs = sidebar.querySelectorAll('.fv__ui-sidebar-nav-ctrl')
      sidebarTabs.forEach((tab) => {
        const tabIcon = tab.querySelector('i')
        if (tabIcon) {
          while ((match = tabRegex.exec(tabIcon.className)) !== null) {
            const tabName = match[1]
            tab.setAttribute('data-testid', `pdf-viewer-sidebar-${tabName}`)
          }
        }
      })

      const dragger = sidebar.querySelector('.fv__ui-sidebar-dragger')
      if (dragger) {
        dragger.setAttribute('data-testid', 'pdf-viewer-sidebar-dragger')
      }

      resizeObserver.observe(sidebar as Element)

      return () => {
        resizeObserver.disconnect()
      }
    }
  },[pdfViewerRef?.current])

  const view = () => {
    if (loading) {
      return <LoadingSpinner sx={{ flexGrow: 'inherit' }} size={"lg"} />
    }
    if (!selectedFile) {
      return <EmptyList icon={DescriptionOutlinedIcon} size={"md"} message={"Select a document to view it."} />
    }
    return <PDFViewerRenderer />
  }

  return (
    <FoxitWebPDFApp variant={variant} {...properties}>
      {view()}
      <PDFUIInstanceContext.Consumer>
        {(pdfui : PDFUI) => {
          if(pdfui){
            if(pdfFunctionRef && pdfFunctionRef?.current){
              pdfFunctionRef.current(pdfui)
            }
            pdfuiRef!.current = pdfui

            pdfui.getPDFViewer()
              .then((viewer : PDFViewer) => {
                // @ts-ignore
                viewer.i18n.addResources('en-US', 'ui_', {
                  'toolbar.alert.openFailed': 'Document has been deleted by another user.',
                })
                pdfViewerRef!.current = viewer
            });
          }
          return <></>;
        }}
      </PDFUIInstanceContext.Consumer>
    </FoxitWebPDFApp>
  );
};
