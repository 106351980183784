import * as UIExtension from '@foxitsoftware/foxit-pdf-sdk-for-web-library/lib/UIExtension.full.js';

export const NoUiTemplate = UIExtension.appearances.Appearance.extend({
  getLayoutTemplate: function() {
    return `<div class="fv__ui-body" style="height:100%;">
      <viewer></viewer>
    </div>`
  }
})

const DefaultTemplate = (uiFragment =[]) =>  {
  const defaultFragments = [
    {
      target: 'contextmenu-item-thumbnail-add-blank-page',
      action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE
    },
    {
      target: 'fv--contextmenu-item-exit-full-screen',
      actions: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE
    },
    {
      target: 'create-polygon',
      action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE
    },
    {
      target: 'create-cloud',
      action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE
    },
    {
      target: 'create-polyline',
      action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE
    }
  ]

  const fragments = defaultFragments.concat(uiFragment)

  return UIExtension.appearances.Appearance.extend({
    getLayoutTemplate: function() {
      return `
      <webpdf @aria:circular-focus>
        <toolbar name="toolbar" class="fv__ui-toolbar-scrollable" @aria:role="navigation" @aria:label="aria:labels.toolbar.nav-title">
          <div class="fv__ui-toolbar-tab-bodies" name="toolbar-tab-bodies">
              <group-list>
                <group name="group-nav" retain-count="3">
                  <hand-ribbon-button @aria:auto-focus></hand-ribbon-button>
                  <selection-ribbon-dropdown></selection-ribbon-dropdown>
                  <zoom-ribbon-dropdown></zoom-ribbon-dropdown>
                </group>
                <group name="group-edit">
                  <create-typewriter-ribbon-button></create-typewriter-ribbon-button>
                  <create-callout-ribbon-button></create-callout-ribbon-button>
                  <create-textbox-ribbon-button></create-textbox-ribbon-button>
                </group>
                <group name="group-text" class="group-text">
                  <div class="fx-ribbon-items-sm">
                    <xbutton name="create-highlight" class="small-button" @tooltip tooltip-title="toolbar.tooltip.highlight.title" @controller="states:CreateHighlightController" icon-class="fv__icon-toolbar-text-highlight"></xbutton>
                    <xbutton name="create-strikeout" class="small-button" @tooltip tooltip="toolbar.tooltip.strikeout.title" @controller="states:CreateStrikeoutController" icon-class="fv__icon-toolbar-strikeout"></xbutton>
                  </div>
                  <div class="fx-ribbon-items-sm">
                    <xbutton name="create-underline" class="small-button" @tooltip tooltip-title="toolbar.tooltip.underline.title" @controller="states:CreateUnderlineController" icon-class="fv__icon-toolbar-underline"></xbutton>
                    <xbutton name="create-squiggly" class="small-button" @tooltip tooltip="toolbar.tooltip.squiggly.title" @controller="states:CreateSquigglyController" icon-class="fv__icon-toolbar-squiggly"></xbutton>
                  </div>
                  <div class="fx-ribbon-items-sm">
                    <xbutton name="create-replace" class="small-button" @tooltip tooltip="toolbar.tooltip.replace.title" @controller="states:CreateReplaceController" icon-class="fv__icon-toolbar-replace"></xbutton>
                    <xbutton name="create-caret" class="small-button" @tooltip tooltip-title="toolbar.tooltip.caret.title" @controller="states:CreateCaretController" icon-class="fv__icon-toolbar-insert"></xbutton>
                  </div>
                </group>  
                      
                <group name="group-drawing" retain-count="2">
                  <stamp-ribbon-dropdown></stamp-ribbon-dropdown>
                  <create-drawings-ribbon-dropdown></create-drawings-ribbon-dropdown>
                  <create-area-highlight-ribbon-button></create-area-highlight-ribbon-button>
                </group>
                
                <group name="home-tab-group-io" retain-count="1" shrink-title="toolbar.more.document.title">
                  <print:print-ribbon-button></print:print-ribbon-button>
                  <download-file-ribbon-button></download-file-ribbon-button>
                </group>
                                 
              </group-list>
          </div>
        </toolbar>
        
        <div class="fv__ui-body">
          <sidebar name="sidebar" @controller="sidebar:SidebarController" @portfolio-layout="cover" @sidebar:limit-size.viewer="300" open>
            <bookmark-sidebar-panel></bookmark-sidebar-panel>
            <thumbnail-sidebar-panel @require-modules="thumbnail" active></thumbnail-sidebar-panel>      
          </sidebar>
          <portfolio-container name="portfolio-container">
            <portfolio-sidebar @portfolio-layout="portfolio,details" @lazy-content="portfolio-layout-shown"
                               name="portfolio-sidebar">
              <portfolio-sidebar-header name="portfolio-sidebar-header">
                <portfolio-path name="portfolio-path"></portfolio-path>
                <portfolio-toolbar name="portfolio-toolbar">
                  <portfolio-extract-selected-item-button
                    name="portfolio-extract-selected-item-button"></portfolio-extract-selected-item-button>
                  <portfolio-show-node-properties-button
                    name="portfolio-show-node-properties-button"></portfolio-show-node-properties-button>
                </portfolio-toolbar>
              </portfolio-sidebar-header>
              <portfolio-node-container name="portfolio-node-container">
                <div class="fv__ui-portfolio-node-tree" @controller="PortfolioNodeTreeController as tree">
                  <portfolio-details-header @portfolio-layout="details" @sync.columns="tree.columns"
                                            name="portfolio-details-header"></portfolio-details-header>
                  <portfolio-node-list name="portfolio-node-list">
                    <portfolio-node @foreach.cached="fileNode in tree.fileNodes track by handle" @sync.display="tree.display"
                                    @sync.data="fileNode" @sync.columns="tree.columns"
                                    @contextmenu="fv--portfolio-file-item-contextmenu" name="portfolio-node"></portfolio-node>
                  </portfolio-node-list>
                </div>
              </portfolio-node-container>
            </portfolio-sidebar>
            <div name="fv--portfolio-previewer-container" class="fv__ui-portfolio-previewer-container"><!-- previewers -->
              <distance:ruler-container name="pdf-viewer-container-with-ruler" @portfolio-cover-sheet>
                <slot>
                  <viewer @zoom-on-pinch @zoom-on-doubletap @zoom-on-wheel @touch-to-scroll
                          @alt.main=":::css(\`.fv__pdf-viewer-container\`)"></viewer>
                </slot>
              </distance:ruler-container>
              <portfolio-previewer-group @portfolio-layout="portfolio,details" @lazy-content="portfolio-layout-shown">
                <portfolio-nochoose-previewer></portfolio-nochoose-previewer>
                <portfolio-unsupported-previewer></portfolio-unsupported-previewer>
                <portfolio-pdf-previewer></portfolio-pdf-previewer>
                <portfolio-video-previewer>
                </portfolio-video-previewer>
                <portfolio-audio-previewer></portfolio-audio-previewer>
                <portfolio-image-previewer></portfolio-image-previewer>
                <portfolio-plain-text-previewer>
                </portfolio-plain-text-previewer>
              </portfolio-previewer-group>
              <portfolio-loading-layer></portfolio-loading-layer>
            </div>
          </portfolio-container>
        </div>
        
        <template name="template-container">
          <print:print-dialog @lazy></print:print-dialog>
          
          <page-template:page-template-dialog @lazy></page-template:page-template-dialog>
          
          <loupe-tool-dialog @lazy></loupe-tool-dialog>
          <create-ink-sign-dialog @lazy></create-ink-sign-dialog>
          <distance:measurement-popup @lazy></distance:measurement-popup>
          <fpmodule:file-property-dialog @lazy></fpmodule:file-property-dialog>
          <redaction:redaction-page-dialog @hide-on-sr @lazy></redaction:redaction-page-dialog>
          
          <!-- contextmenus -->
          <page-contextmenu></page-contextmenu>
          <default-annot-contextmenu @lazy></default-annot-contextmenu>
          <markup-contextmenu @lazy></markup-contextmenu>
          <markup-contextmenu @lazy name="fv--line-contextmenu"></markup-contextmenu>
          <markup-contextmenu @lazy name="fv--linearrow-contextmenu"></markup-contextmenu>
          <markup-contextmenu @lazy name="fv--linedimension-contextmenu"></markup-contextmenu>
          <markup-contextmenu @lazy name="fv--circle-contextmenu"></markup-contextmenu>
          <markup-contextmenu @lazy name="fv--square-contextmenu"></markup-contextmenu>
          <markup-contextmenu @lazy name="fv--ink-contextmenu"></markup-contextmenu>
          <markup-contextmenu @lazy name="fv--stamp-contextmenu"></markup-contextmenu>
          <markup-contextmenu @lazy name="fv--text-contextmenu"></markup-contextmenu>
          <caret-contextmenu name="fv--areahighlight-contextmenu" @lazy></caret-contextmenu>
          <caret-contextmenu name="fv--replace-contextmenu" @lazy></caret-contextmenu>
          <measurement-contextmenu @lazy></measurement-contextmenu>
          <caret-contextmenu name="fv--caret-contextmenu"></caret-contextmenu>
          <textmarkup-contextmenu @lazy name="fv--highlight-contextmenu"></textmarkup-contextmenu>
          <textmarkup-contextmenu @lazy name="fv--strikeout-contextmenu"></textmarkup-contextmenu>
          <textmarkup-contextmenu @lazy name="fv--underline-contextmenu"></textmarkup-contextmenu>
          <textmarkup-contextmenu @lazy name="fv--squiggly-contextmenu"></textmarkup-contextmenu>
          <freetext-contextmenu @lazy name="fv--typewriter-contextmenu"></freetext-contextmenu>
          <freetext-contextmenu @lazy name="fv--callout-contextmenu"></freetext-contextmenu>
          <freetext-contextmenu @lazy name="fv--textbox-contextmenu"></freetext-contextmenu>
          <action-annot-contextmenu @lazy name="fv--image-contextmenu"></action-annot-contextmenu>
          <action-annot-contextmenu @lazy name="fv--link-contextmenu"></action-annot-contextmenu>
          <comment-card-contextmenu @lazy></comment-card-contextmenu>
          <fileattachment-contextmenu @lazy></fileattachment-contextmenu>
          <media-contextmenu @lazy></media-contextmenu>
          <sound-contextmenu @lazy></sound-contextmenu>
          <redact-contextmenu @lazy></redact-contextmenu>
          <edit-graphics:image-contextmenu @lazy></edit-graphics:image-contextmenu>
          <edit-pageobjects:path-contextmenu @lazy></edit-pageobjects:path-contextmenu>
          <field-signature-contextmenu @lazy name="fv--field-signature-contextmenu"></field-signature-contextmenu>
          <text-sel:text-selection-tooltip @lazy></text-sel:text-selection-tooltip>
          <annottext name="fv--annottext-tooltip" @lazy></annottext><!-- Field panel contentmenu -->
          <field-contextmenu @lazy></field-contextmenu>
          <comment-list:filter-dialog name="fv--commentlist-filter-comment-dialog"></comment-list:filter-dialog>
          <selected-text-contextmenu @lazy></selected-text-contextmenu>
          <selected-image-contextmenu @lazy></selected-image-contextmenu>
          <selected-rectangle-contextmenu @lazy></selected-rectangle-contextmenu>
          <search:basic-search-options name="fv--search-basic-options"></search:basic-search-options>
          <search:choose-search-type name="fv--search-choose-search-type"></search:choose-search-type>
          <portfolio-node-properties-dialog @lazy></portfolio-node-properties-dialog>
          <portfolio-node-contextmenu name="fv--portfolio-file-item-contextmenu" @lazy>
            <portfolio-extract-node-contextmenu-item
              name="fv--portfolio-extract-node-contextmenu-item"></portfolio-extract-node-contextmenu-item>
            <portfolio-show-selected-node-information-contextmenu-item
              name="fv--portfolio-show-selected-node-information-contextmenu-item"></portfolio-show-selected-node-information-contextmenu-item>
          </portfolio-node-contextmenu>
          <bookmark-contextmenu @lazy></bookmark-contextmenu>
          <signature:signed-property-dialog name="signed-property-dialog" @lazy></signature:signed-property-dialog>
          <thumbnail:thumbnail-contextmenu name="fv--thumbnail-contextmenu" @lazy></thumbnail:thumbnail-contextmenu>
          <edit-graphics:graphic-object-properties-dialog name="fv--graphics-object-properties-dialog" @lazy></edit-graphics:graphic-object-properties-dialog>
          <digital-stamp:identity-info-dialog name="fv--identity-info-dialog" @lazy></digital-stamp:identity-info-dialog>
        </template>
      </webpdf>
`},
    getDefaultFragments: function() {
      return fragments
    }
  })
}

export default DefaultTemplate