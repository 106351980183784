import React, { useCallback } from "react";
import { useConfigurationContext } from "./Configuration";
import { useApolloClient, useMutation } from "@apollo/client";
import _get from "lodash/get";
import { useParams } from "react-router-dom";
import {
  CREATE_FILE,
  DELETE_FILE,
  FILE_UPLOADED,
  FILE_UPLOADED_AND_LINK_DOCUMENT,
  GET_READ_SAS_TOKEN,
  GET_WRITE_SAS_TOKEN,
  UPDATE_FILE
} from "../graphql-definitions";
import { FileCreateInput, FileType, FileWithProgress } from "../types/tasks";
import { FileUpdateInput } from "../types/tasks/File";
import { formatFileSizeInMB as formatFileSize, uploadToAzure as upload } from "saga-library/src/util";
import { LinkDocumentInput } from "../types/Document";
import { GET_SHARED_FILE_READ_SAS_TOKEN } from "../graphql-definitions/tenant/file/SharedFileQueries";

interface DocumentContextType {
  uploadFiles: (files: FileWithProgress[], isDocumentImported: boolean) => Promise<FileType[] | null>,
  uploadAndLinkFile: (file: FileWithProgress, linkDocumentInput: LinkDocumentInput) => Promise<FileType | null>,
  getFileTypeUriWithToken: (file: FileType | null) => Promise<string | null>,
  getSharedFileTypeUriWithToken: (file: FileType | null) => Promise<string | null>,
  formatFileSizeInMB: (size: number) => string,
  deleteFile: (file: FileType) => void,
  uploadToAzure: (file: FileWithProgress, fileRecord: FileType) => Promise<void>,
  updateFile: (id: string, size: number, version: string) => Promise<FileType | null>,
}

const defaultDocumentContext: DocumentContextType = {
  uploadFiles: () => Promise.resolve(null),
  uploadAndLinkFile: () => Promise.resolve(null),
  getFileTypeUriWithToken: (file: FileType | null) => Promise.resolve(null),
  getSharedFileTypeUriWithToken: (file: FileType | null) => Promise.resolve(null),
  formatFileSizeInMB: (size: number) => '',
  deleteFile: (file: FileType) => null,
  uploadToAzure: () => Promise.resolve(),
  updateFile: () => Promise.resolve(null),
}

const DocumentContext = React.createContext(defaultDocumentContext)

export const DocumentProvider = ({ children }) => {
  const { tenant_id } = useParams()
  const { getConfigValue } = useConfigurationContext()
  const azureStorageBlobEndpoint = getConfigValue('SAGA_AZURE_STORAGE_BLOB_ENDPOINT')
  const apolloClient = useApolloClient()

  const [createFileRecord] = useMutation(CREATE_FILE)
  const [fileUploaded] = useMutation(FILE_UPLOADED)
  const [deleteFileRecord] = useMutation(DELETE_FILE)
  const [updateFileRecord] = useMutation(UPDATE_FILE)
  const [fileUploadedAndLinkDocument] = useMutation(FILE_UPLOADED_AND_LINK_DOCUMENT)

  const uploadToAzure = useCallback(async (file: FileWithProgress, fileRecord: FileType) : Promise<void> => {
    if (!file || !fileRecord) throw Error("Error uploading file to Azure")

    try {
      const sasTokenResponse = await apolloClient.query({
        query: GET_WRITE_SAS_TOKEN,
        variables: {
          tenantId: tenant_id,
          blobName: fileRecord.blobName,
        },
        fetchPolicy: 'no-cache',
      })
      const sasToken = _get(sasTokenResponse, 'data.tenant.file.writeSasToken', undefined)
      await upload(azureStorageBlobEndpoint, sasToken, file, fileRecord)
    } catch (error) {
      console.error('error uploading file to azure', error)
      throw Error("Error uploading file to Azure");
    }
  }, [azureStorageBlobEndpoint, apolloClient, tenant_id]);

  const createAndUploadFile = useCallback(async (file: FileWithProgress) : Promise<FileType> => {
    try {
      const fileInput: FileCreateInput = {
        originalFileName: file.name,
        size: file.size,
      }
      const createResult = await createFileRecord({
        variables: {
          tenantId: tenant_id,
          file: fileInput
        }
      })
      const fileRecord = _get(createResult.data, 'tenant.file.createFile', undefined)

      if (!fileRecord || !fileRecord.id) {
        console.error('error creating file record', createResult.errors)
        throw Error("Error uploading file to Azure")
      }

      await uploadToAzure(file, fileRecord)

      return fileRecord

    } catch (error) {
      console.error('error uploading file', error)
      throw Error("Error uploading file to Azure");
    }
  }, [createFileRecord, tenant_id, uploadToAzure])

  const uploadFiles = useCallback(async (files: FileWithProgress[], isDocumentImported: boolean) => {
    return await Promise.all(files.map(async (file) => {
      const fileRecord = await createAndUploadFile(file)

      const uploadedResult = await fileUploaded({
        variables: {
          tenantId: tenant_id,
          id: fileRecord.id,
          isDocumentImported: isDocumentImported
        }
      })

      const uploadedFile = _get(uploadedResult.data, 'tenant.file.fileUploaded', undefined)

      if (!uploadedFile) {
        console.error('error marking file as uploaded', uploadedResult.errors)
        throw Error("Error uploading file to Azure")
      }
      return uploadedFile
    }));
  }, [createAndUploadFile, fileUploaded, tenant_id])

  const uploadAndLinkFile = useCallback(async (file: FileWithProgress, linkDocumentInput: LinkDocumentInput) : Promise<FileType> => {
    const fileRecord = await createAndUploadFile(file)
    
    const uploadedResult = await fileUploadedAndLinkDocument({
      variables: {
        tenantId: tenant_id,
        id: fileRecord.id,
        linkDocumentInput: {...linkDocumentInput, fileId: fileRecord.id}
      }
    })
    
    const uploadedFile = _get(uploadedResult.data, 'tenant.file.fileUploadedAndLinkDocument', undefined)

    if (!uploadedFile) {
      console.error('error marking file as uploaded', uploadedResult.errors)
      throw Error("Error uploading file to Azure")
    }
    return uploadedFile
  }, [createAndUploadFile, fileUploadedAndLinkDocument, tenant_id])

  const deleteFile = useCallback(async (file: FileType) => {
    await deleteFileRecord({
      variables: {
        tenantId: tenant_id,
        id: file.id
      },
      onError: (error) => {
        throw Error("Error deleting file record");
      },
      onCompleted: async (data) => {
      }
    })
  }, [deleteFileRecord, tenant_id])

  const getFileTypeUriWithToken = useCallback(async (fileRecord: FileType | null) => {
    if (!fileRecord) return null

    const sasTokenResponse = await apolloClient.query({
      query: GET_READ_SAS_TOKEN,
      variables: {
        tenantId: tenant_id,
        blobName: fileRecord.blobName,
      },
      fetchPolicy: 'no-cache',
    })
    const sasToken = _get(sasTokenResponse, 'data.tenant.file.readSasToken', undefined)

    return fileRecord.fileUri + '?' + sasToken;
  }, [apolloClient, tenant_id])

  const getSharedFileTypeUriWithToken = useCallback(async (fileRecord: FileType | null) => {
    if (!fileRecord) return null

    const sasTokenResponse = await apolloClient.query({
      query: GET_SHARED_FILE_READ_SAS_TOKEN,
      variables: {
        tenantId: tenant_id,
        blobName: fileRecord.blobName,
      },
      fetchPolicy: 'no-cache',
    })
    const sasToken = _get(sasTokenResponse, 'data.tenant.sharedFile.readSasToken', undefined)

    return fileRecord.fileUri + '?' + sasToken;
  }, [apolloClient, tenant_id])

  const updateFile = useCallback(async (id: string, size: number, version: string) => {
    const fileInput: FileUpdateInput = {
      id: id,
      size: size,
      version: version,
    }

    const updateResult = await updateFileRecord({
      variables: {
        tenantId: tenant_id,
        file: fileInput
      }
    })
    const updatedFile = _get(updateResult.data, 'tenant.file.updateFile', undefined)

    if (!updatedFile || !updatedFile.id) {
      console.error('error updating file record', updateResult.errors)
      return null
    }

    return updatedFile
  }, [tenant_id, updateFileRecord]);

  const formatFileSizeInMB = formatFileSize

  const providerValue: DocumentContextType = {
    uploadFiles,
    uploadAndLinkFile,
    getFileTypeUriWithToken,
    getSharedFileTypeUriWithToken,
    formatFileSizeInMB,
    deleteFile,
    uploadToAzure,
    updateFile,
  }

  return (
    <DocumentContext.Provider value={providerValue}>
      {children}
    </DocumentContext.Provider>
  )
}

export const useDocumentContext = () => {
  return React.useContext(DocumentContext)
}
