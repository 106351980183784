import {
  AbLabResult,
  AbLabResultNoteAndComment,
  AbLabResultObservationResult,
  AbLabResultSegment
} from '../../../../../types/patients'
import { Box, Chip, useTheme } from '@mui/material'
import { IconButton, Typography } from 'saga-library/src'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { LabAndInvestigationResultValue } from './LabAndInvestigatioResultValue'
import React, { ReactNode } from 'react'
import { ReactComponent as GraphIcon} from "../../../../../assets/GraphIcon.svg";
import { PrintOnly } from '../../../../../components/PrintOnly'
import DOMPurify from 'dompurify'

const SegmentSortPredicate = (a: AbLabResultSegment, b: AbLabResultSegment) => a.segmentOrder - b.segmentOrder

export interface LabAndInvestigationDetailsProps {
  lab: AbLabResult
  segments: AbLabResultSegment[]
  dataTestId: string
  onGraphClick: (result: AbLabResultObservationResult) => void
  header?: ReactNode
  headerOnClick?: () => void
  isRow?: boolean
}

export interface LabAndInvestigationObservationResultDetailProps {
  result: AbLabResultObservationResult
  dataTestId: string
  onGraphClick: (result: AbLabResultObservationResult) => void
}

export interface LabAndInvestigationNoteAndCommentDetailProps {
  noteAndComment: AbLabResultNoteAndComment
  dataTestId: string
}

export const LabAndInvestigationDetails = ({
  lab,
  segments,
  dataTestId,
  onGraphClick,
  header,
  headerOnClick,
  isRow
}:LabAndInvestigationDetailsProps) => {
  const theme = useTheme()

  const hasSegments = segments?.length > 0

  const baseSx = {
    border: '1px rgba(32, 41, 49, 0.32) solid',
    padding: '4px 8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: '40px'
  }

  const headerSx = {
    ...baseSx,
    background: theme.palette.greys.extraLight,
    borderRadius: hasSegments ? '4px 4px 0px 0px' : '4px',
    color: lab.isAbnormal ? theme.palette.error.main : theme.palette.greys.dark
  }

  const headerOnClickSx = {
    ...headerSx,
    '&:hover': {
      background: theme.palette.backgrounds.hover,
    },
    cursor: 'pointer'
  }

  const resultSx = {
    ...baseSx,
    background: 'white',
    alignItems: 'center',
    borderRadius: 0,
  }

  const getSegmentComponent = (segment: AbLabResultSegment, index: number) => {
    switch (segment?.__typename) {
      case "AbLabResultObservationResult":
        return <LabAndInvestigationObservationResultDetail result={segment} dataTestId={`${dataTestId}-${index}`} onGraphClick={onGraphClick} />
      case "AbLabResultNoteAndComment":
        return <LabAndInvestigationNoteAndCommentDetail noteAndComment={segment} dataTestId={`${dataTestId}-${index}`} />
    }
    return <></>
  }

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      fontFamily={!isRow ? "Roboto Mono, monospace" : ""}
    >
      {header && (
        <Box
          sx={{...(headerOnClick ? headerOnClickSx : headerSx)}}
          {...(headerOnClick && {onClick: headerOnClick})}
        >
          {header}
        </Box>
      )}
      {segments.sort(SegmentSortPredicate).map((segment, resultIndex) => {

        const isFirstSegment = !header && resultIndex === 0
        const isLastSegment = resultIndex === segments?.length - 1

        return (
          <Box
            sx={{
              ...resultSx,
              ...(isFirstSegment
                  ? {borderTopLeftRadius: '4px', borderTopRightRadius: '4px'}
                  : {borderTop: 0}
              ),
              ...(isLastSegment
                  ? {borderBottomRightRadius: '4px', borderBottomLeftRadius: '4px'}
                  : {}
              )
            }}
            key={`${segment?.__typename}-${segment.id}`}
          >
            {getSegmentComponent(segment, resultIndex)}
          </Box>
        )
      })}
    </Box>
  )
}

const sanitizeComment = (noteAndComment) => {
  if(!noteAndComment?.comment){
    return ""
  }
  return DOMPurify.sanitize(noteAndComment?.comment, {FORBID_TAGS: ['a']});
}

const LabAndInvestigationNoteAndCommentDetail = ({noteAndComment, dataTestId}:LabAndInvestigationNoteAndCommentDetailProps) => {
  return (
    <Box>
      <Typography variant={"h5"} fontFamily={"inherit"}>Comment</Typography>
      <Typography variant={"body1"} fontFamily={"inherit"} whiteSpace={"break-spaces"} dangerouslySetInnerHTML={{__html: sanitizeComment(noteAndComment)}} />
    </Box>
  )
}

const LabAndInvestigationObservationResultDetail = ({result, dataTestId, onGraphClick}:LabAndInvestigationObservationResultDetailProps) => {
  const theme = useTheme()

  const abnormal = (result.abLabResultObservationResultAbnormals?.length ?? 0) > 0
  const color = abnormal ? theme.palette.error.main : theme.palette.greys.dark

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      flex={"1 1 auto"}
      alignItems={"start"}
    >
      <Typography
        variant={"h5"}
        fontFamily={"inherit"}
        dataTestId={`${dataTestId}-description`}
        color={color}
        alignContent={"center"}
        minHeight={"24px"}
        paddingY={1}
      >
        {result?.observationDescription}
      </Typography>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-evenly"}
        alignItems={"center"}
        gap={1}
        color={color}
      >
        {abnormal &&
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-evenly"}
            alignSelf={"start"}
            gap={1}
            paddingY={1}
          >
            <WarningAmberIcon />
            {(result.abLabResultObservationResultAbnormals?.filter(a => a.flag).map(a =>
              <PrintOnly componentKey={`${a.id}-print-only`}>
                <Chip
                  data-testid={'lab-and-investigation-abnormal-chip'}
                  size={"small"}
                  sx={{
                    color: "white",
                    backgroundColor: theme.palette.error.main
                  }}
                  label={a.flag?.display}
                  key={a.id}
                />
              </PrintOnly>
            ))}
          </Box>
        }
        <LabAndInvestigationResultValue result={result} dataTestId={dataTestId}/>
        {result?.valueType?.code === "NM" &&
          <IconButton
            name={"lab-and-investigation-graphButton"}
            dataTestId={`${dataTestId}-graphButton`}
            onClick={() => onGraphClick(result)}
            icon={<GraphIcon />}
          />
        }
      </Box>
    </Box>
  )
}