import React, { useEffect, useState } from "react";
import { Box } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, FormProvider } from "saga-library/src/components/Form"
import {
  ConfirmationDialog,
  DeleteButton,
  SaveButton,
  Typography
} from "saga-library/src";
import { useMutation, useQuery } from '@apollo/client'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import omitDeep from 'omit-deep-lodash'
import {
  DELETE_TENANT_ROLE,
  GET_TENANT_ROLE,
  GET_TENANT_ROLES,
  UPDATE_TENANT_ROLE,
} from '../../../graphql-definitions'
import TenantRoleForm, { getPermissionForReportingField } from '../components/TenantRole/TenantRoleForm'
import { Permission, PermissionMap, PermissionType } from "../../../types/settings/Permission";
import { getPermissionForField } from "../components/TenantRole/TenantRoleForm";
import { LoadingSpinner } from "../../../components/LoadingScreen";
import { ReadOnlyFormActionsMask } from "../../../components/ReadOnlyFormActionsMask";
import SettingsHeader from "../components/SettingsHeader";
import { SettingsSectionColumn } from "../components/SettingsSectionColumn";
import {
  getTenantRoleFormDefaultValues,
  TenantRoleFormType
} from "../components/TenantRole/TenantRoleFormDefaultValues";
import { schema } from "../Locations/util/validation";
import { Role } from "../../../types/account";
import { usePrompt } from "../../../providers/NavigationPrompt";

const FORM_NAME = 'updateRoleForm'

export const TenantRoleUpdate = () => {
  const navigate = useNavigate()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const { tenant_id, role_id } = useParams()
  const { enableNavigationPrompt } = usePrompt()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openDeleteErrorDialog, setOpenDeleteErrorDialog] = useState(false)
  const [modifiedPermissions, setModifiedPermissions] = useState<
    { name: string; state: string; label: string }[]
  >([])
  const [role, setRole] = useState<Role>()

  const formMethods = useForm<TenantRoleFormType>({
    defaultValues: getTenantRoleFormDefaultValues(),
    schema: schema,
  })

  const {
    reset,
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
  } = formMethods

  const { loading, error } = useQuery(GET_TENANT_ROLE, {
    variables: { roleId: role_id, tenantId: tenant_id },
    onCompleted: (data) => {
      const tenantRole = _get(data, 'tenant.role.tenantRoleById', null)
      if (tenantRole) {
        reset(getTenantRoleFormDefaultValues(tenantRole))
        setRole(tenantRole)
      }
    },
  })

  const [updateRole] = useMutation(UPDATE_TENANT_ROLE, {
    onError: (error) => {
      showErrorAlert('Changes couldn\'t be saved.')
      console.error(JSON.stringify(error, null, 2))
    },
    onCompleted: (data) => {
      const savedRole = _get(data, 'tenant.role.updateRole')
      reset(getTenantRoleFormDefaultValues(savedRole))
      setRole(savedRole)
      showSuccessAlert(`${savedRole.name} role has been saved.`)
    },
  })

  const [deleteRole] = useMutation(DELETE_TENANT_ROLE, {
    onError: (error) => {
      showErrorAlert("Role couldn't be deleted.")
      console.error('Unable to delete role: ' + JSON.stringify(error, null, 2))
    },
    onCompleted: (data) => {
      showSuccessAlert(`${role ? role.name : 'The '} role has been deleted`)
      setOpenDeleteDialog(false)
      navigate(`../`, { replace: true })
    },
  })

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length]);

  const onSubmit = handleSubmit(async (data) => {
    const permissionChanges = getPermissionChanges(
      data,
      dirtyFields
    )

    if (permissionChanges.length > 0) {
      setModifiedPermissions(permissionChanges)
    } else {
      await updateTenantRole(data)
    }
  })

  const getUpdatedPermissions = (permissionList, dirtyFields, data) => {
    const updatedPermissions: { name: string; state: string; label: string }[] =
      []

    permissionList.forEach((permission) => {
      if (_get(dirtyFields, permission.name)) {
        updatedPermissions.push({
          name: permission.name,
          state: _get(data, permission.name) ? 'added' : 'removed',
          label: permission.label,
        })
      }
    })

    return updatedPermissions
  }

  const getPermissionChanges = (data, dirtyFields) => {
    if (!_isEmpty(dirtyFields)) {
      const permissionCheckList = [
        { name: 'isAdmin', label: 'Administrative access' },
        { name: 'billing.readonly', label: 'View claims' },
        { name: 'billing.readwrite', label: 'Manage claims' },
        { name: 'schedule.readwrite', label: 'Manage schedule' },
        { name: 'practitioner.readwrite', label: 'Manage clinic practitioners' },
        { name: 'chart.readwrite', label: 'Manage chart' },
        { name: 'reporting.readwrite', label: 'Manage reports' },
        { name: 'reporting.billing', label: 'View/run billing reports' },
        { name: 'reporting.patientAndAppointment', label: 'View/run patient and appointment reports' },
      ]
      return getUpdatedPermissions(
        permissionCheckList,
        dirtyFields,
        data
      )
    }
    return []
  }

  const updateTenantRole = async (updatedRole) => {
    const userIds = updatedRole.roleUsers?.map((ru) => ru.id)
    const permBilling = getPermissionForField(!!updatedRole.billing?.readonly, !!updatedRole.billing?.readwrite)
    const permSchedule = getPermissionForField(false, !!updatedRole.schedule?.readwrite)
    const permPractitioner = getPermissionForField(false, !!updatedRole.practitioner?.readwrite)
    const permChart = getPermissionForField(false, !!updatedRole.chart?.readwrite)
    const permReportingBilling = getPermissionForReportingField(!!updatedRole.reporting?.billing, !!updatedRole.reporting?.readwrite)
    const permReportingPatientAndAppointment = getPermissionForReportingField(!!updatedRole.reporting?.patientAndAppointment, !!updatedRole.reporting?.readwrite)
    const permLabsReview = getPermissionForField(false, !!updatedRole.labs?.review)

    delete updatedRole.userChangeList
    delete updatedRole.roleUsers
    delete updatedRole.billing
    delete updatedRole.schedule
    delete updatedRole.practitioner
    delete updatedRole.chart
    delete updatedRole.reporting
    delete updatedRole.labs

    const cleanedData = omitDeep(updatedRole, '__typename')

    cleanedData.roleUserIds = userIds
    cleanedData.permBilling = PermissionMap.get(permBilling)
    cleanedData.permSchedule = PermissionMap.get(permSchedule)
    cleanedData.permPractitioner = PermissionMap.get(permPractitioner)
    cleanedData.permChart = PermissionMap.get(permChart)
    cleanedData.permReportingBilling = PermissionMap.get(permReportingBilling)
    cleanedData.permReportingPatientAndAppointment = PermissionMap.get(permReportingPatientAndAppointment)
    cleanedData.permLabsReview = PermissionMap.get(permLabsReview)

    await updateRole({
      variables: {
        tenantId: tenant_id,
        roleId: role_id,
        updatedRole: cleanedData,
      },
    })
  }

  const deleteTenantRole = () => {
    deleteRole({
      variables: {
        tenantId: tenant_id,
        roleId: role_id,
      },
      update(cache) {
        const tenantRolesData = cache.readQuery<any>({
          query: GET_TENANT_ROLES,
          variables: {
            tenantId: tenant_id,
          },
        })

        const tenantRoles = _get<any, string, any[]>(tenantRolesData, 'tenant.role.tenantRoles', [])
        cache.writeQuery({
          query: GET_TENANT_ROLES,
          data: {
            tenant: {
              role: {
                tenantRoles: tenantRoles.filter((r) => r.id !== role_id),
              },
            },
          },
          variables: {
            tenantId: tenant_id,
          },
        })
      },
    })
  }

  const checkDelete = () => {
    role?.roleUsers?.length
      ? setOpenDeleteErrorDialog(true)
      : setOpenDeleteDialog(true)
  }

  if (error) {
    showErrorAlert('Role couldn\'t be retrieved.')
    console.error('Unable to retrieve role: ' + JSON.stringify(error, null, 2))
  }

  if (loading) {
    return (
      <SettingsSectionColumn>
        <LoadingSpinner />
      </SettingsSectionColumn>
    )
  }

  return (
    <SettingsSectionColumn header={
      <SettingsHeader
        title={'Edit role'}
        onBack={() => navigate(`../`)}
        actions={
          <ReadOnlyFormActionsMask requiredType={PermissionType.Admin} requiredPermission={Permission.READONLY}>
            <DeleteButton onClick={checkDelete} dataTestId={'rolesAndPermissions-updateRole-dialog-delete-button'} />
            <SaveButton
              form={FORM_NAME}
              submitting={isSubmitting}
              dataTestId={'rolesAndPermissions-updateRole-dialog'}
            />
          </ReadOnlyFormActionsMask>
        }
        dataTestId={'rolesAndPermissions-updateRole'}
      />
    }>
      <ConfirmationDialog
        open={modifiedPermissions.length > 0}
        onClose={() => setModifiedPermissions([])}
        title={'Save role?'}
        message={
          <ModifiedPermissions modifiedPermissions={modifiedPermissions} />
        }
        primaryAction={() => {
          setModifiedPermissions([])
          handleSubmit(updateTenantRole)()
        }}
        primaryLabel={'Save'}
        primaryVariant={'contained'}
        dataTestId={'rolesAndPermissions-updateRole-save-dialog'}
      />
      <ConfirmationDialog
        open={openDeleteDialog}
        title={'Delete role?'}
        message={"This action cannot be undone."}
        primaryAction={deleteTenantRole}
        primaryLabel={'Delete'}
        onClose={() => setOpenDeleteDialog(false)}
        dataTestId={'rolesAndPermissions-updateRole-delete-dialog'}
      />
      <ConfirmationDialog
        open={openDeleteErrorDialog}
        title={'This role can\'t be deleted'}
        message={'This role is assigned to users and cannot be deleted.'}
        primaryAction={() => setOpenDeleteErrorDialog(false)}
        dataTestId={'rolesAndPermissions-updateRole-error-dialog'}
      />
      <FormProvider {...formMethods}>
        {!!role && (
          <TenantRoleForm
            onSubmit={onSubmit}
            name={FORM_NAME}
            role={role}
          />
        )}
      </FormProvider>
    </SettingsSectionColumn>
  )
}

const ModifiedPermissions = ({ modifiedPermissions }) => {
  const added = modifiedPermissions.filter((p) => p.state === 'added')
  const removed = modifiedPermissions.filter((p) => p.state === 'removed')
  return (
    <Box>
      The following permissions have been changed for this role:
      <ul>
        {added.map((a) => (
          <li key={a.name}>
            <Typography sx={{mb: '4px'}} dataTestId={'rolesAndPermissions-updateRole-modifiedPermissions-added'}>
              <b>{a.label}</b> has been <span style={{color: 'green'}}>added</span>
            </Typography>
          </li>
        ))}
        {removed.map((a) => (
          <li key={a.name}>
            <Typography sx={{mb: '4px'}} dataTestId={'rolesAndPermissions-updateRole-modifiedPermissions-removed'}>
              <b>{a.label}</b> has been <span style={{color: 'red'}}>removed</span>
            </Typography>
          </li>
        ))}
      </ul>
      Access for team members assigned this role will be affected
    </Box>
  )
}
