import React, { useEffect } from "react";
import { DocumentCategory, LinkedDocumentType } from "../../../../types/Document";
import { useParams } from "react-router-dom";
import { LinkedDocumentEditor } from "../LinkedDocumentForm/LinkedDocumentEditor";
import { getLabAndInvestigationDocumentLabel } from "./LabAndInvestigationsUtil";
import { useLabAndInvestigationContext } from "../../providers/LabAndInvestigationProvider";
import { useReadQuery } from "@apollo/client";
import _get from "lodash/get";
import { AbLabResult } from "../../../../types/patients";

const FORM_NAME = "labs_and_investigations_document_form"

export const LabAndInvestigationDocumentEditor = () => {
  const { labAndInvestigationQueryRef } = useLabAndInvestigationContext()
  const { data } = useReadQuery(labAndInvestigationQueryRef!)
  const labAndInvestigations = [..._get(data, 'tenant.patient.labAndInvestigation.list', []) as Array<AbLabResult>]

  const { document_id } = useParams()
  const [ document, setDocument] = React.useState<LinkedDocumentType|undefined>(undefined)

  useEffect(() => {
    if(document_id){
      setDocument(labAndInvestigations.find(lab => lab.isLinkedDocument && lab?.linkedDocument?.id === document_id)?.linkedDocument || undefined)
    }
  }, [document_id, labAndInvestigations]);

  return (
    <LinkedDocumentEditor
      pathName={'labs-investigations'}
      formName={FORM_NAME}
      document={document}
      category={document?.category || DocumentCategory.LAB_RESULT}
      sectionLabel={document ? getLabAndInvestigationDocumentLabel(document) : ""}
      showPrintButton={true}
      dataTestId={"labsInvestigationsEditor"}
      variant={"labs"}
    />
  )
}