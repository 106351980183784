import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@mui/material";
import {
  ControlledSearch,
  TextField,
  Typography,
  ToggleButtonGroup,
  Form,
  ResultOptionsWrapper,
  EntryTitle
} from 'saga-library/src'
import { ReactionType, AllergySeverity } from "../../../../../types/Allergy";
import { GET_SEARCH_ALLERGENS } from "../../../../../graphql-definitions";
import _get from "lodash/get";
import { AllergenSearchResult } from "../../../../../types/Allergen";
import { useParams } from "react-router-dom";
import { AllergyReactionInput } from "./AllergyReactionInput";
import TabFocusInterceptor from "saga-library/src/hooks/tabFocusInterceptor";
import { HighIcon, LowIcon } from "../../ChartIcons";

export const ALLERGY_FORM_ID = 'allergy-form'
interface AllergyFormProps {
  onSubmit: () => void
  autoFocus: boolean
}

export const AllergyForm = ({
  onSubmit,
  autoFocus = false
} : AllergyFormProps) => {
  const { tenant_id } = useParams()
  const firstFieldRef = useRef<any>(null);
  const [hasFocus, setHasFocus] = useState(autoFocus);
  const setFocus = (focus) => {
    setHasFocus(focus)
  }
  TabFocusInterceptor(firstFieldRef, hasFocus, setFocus)

  useEffect(() => {
    if (firstFieldRef && !autoFocus) {
      setHasFocus(false)
    }
  }, [firstFieldRef]);

  const queryVariables = useMemo(() => ({
    tenantId: tenant_id
  }), [tenant_id])

  return (
    <Form onSubmit={onSubmit} id={ALLERGY_FORM_ID}>
      <ControlledSearch
        dataTestId={`${ALLERGY_FORM_ID}-allergen-search`}
        name={'allergen'}
        label={'Allergen'}
        freeSolo
        autoSelect
        autoFocus={autoFocus}
        inputRef={firstFieldRef}
        initialOptionsArray={[{display: 'no known allergy', code: 1}]}
        queries={{
          search: {
            gql: GET_SEARCH_ALLERGENS,
            get: (data) => _get(data, 'tenant.search.allergens.searchResults', []) as AllergenSearchResult[],
            variables: queryVariables,
            fetchPolicy: 'network-only'
          },
        }}
        render={{
          value: (result) => {
            return `${result?.display || result}`
          },
          option: (result, state) => {
            return (
              <ResultOptionsWrapper>
                <EntryTitle
                  title={result?.display}
                  searchText={state.inputValue}
                />
              </ResultOptionsWrapper>
            )
          }
        }}
      />
      <Typography variant={'body1'} fontWeight={'bold'}>Type</Typography>
      <Box
        sx={{
          mb: 2,
          mt: 1
      }}
      >
        <ToggleButtonGroup
          dataTestId={`${ALLERGY_FORM_ID}-reaction-type`}
          name={'reactionType'}
          buttons={[
            { value: ReactionType.ALLERGY, children: 'Allergy' },
            { value: ReactionType.INTOLERANCE, children: 'Intolerance' }
          ]}
          fullWidth={true}
        />
      </Box>
      <TextField
        dataTestId={`${ALLERGY_FORM_ID}-onset-date`}
        name={'onsetDate'}
        label={'Onset date'}
        fullWidth={true}
      />
      <AllergyReactionInput
        dataTestId={`${ALLERGY_FORM_ID}-reaction-description`}
        name={'reactionDescription'}
      />
      <Typography
        variant={'body1'}
        fontWeight={'bold'}
      >
        Reaction severity
      </Typography>
      <Box
        sx={{
          mb: 2,
          mt: 1
      }}
      >
        <ToggleButtonGroup
          dataTestId={`${ALLERGY_FORM_ID}-reaction-severity`}
          name={"severity"}
          buttons={[
            { value: AllergySeverity.HIGH, icon: <HighIcon />, children: "High" },
            { value: AllergySeverity.LOW, icon: <LowIcon />, children: "Low" },
            { value: AllergySeverity.UNABLE_TO_ASSESS, children: "Unable to Assess" }
          ]}
          fullWidth={true}
        />
      </Box>
    </Form>
  )
}