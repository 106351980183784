import { useAccountContext } from '../../../providers/AccountContext'
import { NavLink, useParams } from 'react-router-dom'
import React, { ReactNode, Suspense, useCallback } from 'react'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import DocumentsIcon from '@mui/icons-material/DescriptionOutlined'
import { ErrorBoundary, Section, SubsectionMenu, SubsectionMenuItem } from 'saga-library/src'
import { Box } from '@mui/material'
import { LoadingSpinner } from '../../../components/LoadingScreen'
import UploadFileIcon from '@mui/icons-material/UploadFileOutlined'
import { ReactComponent as LabsImagingIcon } from '../../../assets/LabsImagingIcon.svg'
import { UserRoleSelect } from './UserRoleSelect'
import { UserRoleType } from '../Inbox'

export enum InboxSections {
  TASKS = "Tasks",
  DOCUMENTS = "Documents",
  LABS = "Labs and investigations"
}

export const InboxMenu = ({value, setValue}) => {
  const { userId, buildTenantRoute } = useAccountContext()
  const { user_id, role_id, tenant_id } = useParams()

  const getRoutes = useCallback(():{route:string, text:string, icon:ReactNode}[] => {
    var route = ""
    if(user_id || role_id){
      route = route + (user_id ? `u/${user_id}/` : `r/${role_id}/`)
    } else {
      route = buildTenantRoute(route + `inbox/u/${userId}/`, tenant_id)
    }

    const routes = [
      {
        route: route + 'tasks',
        text: InboxSections.TASKS,
        icon: <TaskAltIcon />
      }
    ]

    if(user_id){
      if(value?.data?.isPractitioner){
        routes.push(
          {
            route: route + `labs`,
            text: InboxSections.LABS,
            icon: <LabsImagingIcon />
          }
        )
      }
      routes.push(
        {
          route: route + 'documents',
          text: InboxSections.DOCUMENTS,
          icon:<DocumentsIcon />
        }
      )
    }

    return routes
  },[user_id, role_id, value])

  const InboxRoute = ({ route, text, icon }) => {
    return (
      <NavLink to={route}>
        {({ isActive }) => (
          <SubsectionMenuItem
            dataTestId={`inboxMenu-menuItem-${text}`}
            isActive={isActive}
            startIcon={icon}
            primaryText={text}
          />
        )}
      </NavLink>
    )
  }

  const onUserRoleChange = (option) => {
    setValue({
        type: option.value && 'emailAddress' in option.value ? UserRoleType.USER : UserRoleType.ROLE,
        data: option.value
    })
  }

  return (
    <>
      <ErrorBoundary
        fallback={
          <FallbackMenu>
            <Box color={(theme) => theme.palette.error.main}>Failed to load</Box>
          </FallbackMenu>
        }
      >
        <Suspense
          fallback={
            <FallbackMenu>
              <LoadingSpinner label={null} />
            </FallbackMenu>
          }
        >
          <SubsectionMenu
            header={<UserRoleSelect onChange={onUserRoleChange}/>}
            routes={getRoutes().map(r => <InboxRoute route={r.route} text={r.text} icon={r.icon}/>)}
            footer={<InboxRoute route={"import"} text={"Import documents"} icon={ <UploadFileIcon />}/>}
          />
        </Suspense>
      </ErrorBoundary>
    </>
  )
}

const FallbackMenu = ({ children }) => {
  return (
    <Section.Column
      rightPadding={1}
    >
      {children}
    </Section.Column>
  )
}