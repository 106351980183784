import React, { useEffect, useState } from 'react'
import { Box, MenuItem } from "@mui/material";
import { Section, SimpleSelect, AddButton, RemoveButton } from 'saga-library/src'
import { useFieldArray, useFormContext } from "saga-library/src/components/Form";
import { TextFieldMask } from 'saga-library/src'
import { useTenantContext } from "../../../../../providers/TenantContextProvider";

export type TenantIdentifierType = {
  id: string
  name: string
  isPrimary: boolean
  alwaysDisplayField: boolean
}

export type IdentifierDetailsType = {
  identifiers: Array<IdentifierType>
}

export type IdentifierType = {
  id?: string
  typeId?: string
  value?: string
  index?: number
}

const defaults: IdentifierDetailsType = {
  identifiers: [],
}
const Form = (props) => {
  const { control, setValue, getValues } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name: 'identifiers',
    control,
  })
  const { tenantIdentifiers } = useTenantContext()

  const [typeSelectorShown, setTypeSelectorShown] = useState(false)
  const [newFields, setNewFields] = useState([] as string[])

  useEffect(() => {
    const existingTypeIds = fields.map((field: IdentifierType) => field.typeId);

    // Add any "always display" identifiers that are missing.
    const identifiersToAdd = tenantIdentifiers.filter(identifier => {
      return identifier.alwaysDisplayField && !existingTypeIds.includes(identifier.id);
    });

    if (identifiersToAdd.length > 0) {
      const updatedIdentifiers = [
        ...fields,
        ...identifiersToAdd.map(identifier => ({ typeId: identifier.id, value: '' }))
      ];

      setValue('identifiers', updatedIdentifiers, { shouldDirty: false });
    }
  }, [fields, setValue, tenantIdentifiers]);

  const fieldSortingPredicate = (first, second) => {
    const firstId = tenantIdentifiers.find(
      (identifier) => identifier.id === (first as IdentifierType).typeId
    )
    const secondId = tenantIdentifiers.find(
      (identifier) => identifier.id === (second as IdentifierType).typeId
    )
    // Always show new fields at the bottom
    if (newFields.includes(firstId?.id)) {
      return 1
    }
    if (newFields.includes(secondId?.id)) {
      return -1
    }
    // Primary first
    if (firstId.isPrimary && !secondId.isPrimary) {
      return -1
    } else if (!firstId.isPrimary && secondId.isPrimary) {
      return 1
    }
    // Always display second
    if (firstId.alwaysDisplayField && !secondId.alwaysDisplayField) {
      return -1
    } else if (!firstId.alwaysDisplayField && secondId.alwaysDisplayField) {
      return 1
    }
    // Otherwise, sort by name
    if (firstId.name < secondId.name) {
      return -1
    } else if (firstId.name > secondId.name) {
      return 1
    } else {
      return 0
    }
  }

  const identifierSortingPredicate = (first, second) => {
    if (first.name < second.name) {
      return -1
    } else if (second.name < first.name) {
      return 1
    } else {
      return 0
    }
  }

  const showTypeSelector = () => {
    setTypeSelectorShown(true)
  }

  const addIdentifierRow = (typeId) => {
    setNewFields([...newFields, typeId])
    append({ typeId: typeId, value: '' })
    setTypeSelectorShown(false)
  }

  const removeIdentifierRow = (typeId, index) => {
    setNewFields(newFields.filter((field) => field !== typeId))
    remove(index)
    setTypeSelectorShown(false)
  }

  const renderIdentifierRows = () => {
    const displayFields = fields
      .map((item, index) => {
        const newItem = item as IdentifierType
        newItem.index = index
        return newItem
      })
      .sort(fieldSortingPredicate)

    const existingIdentifierTypes = displayFields.map(
      (identifier) => identifier.typeId
    )

    const unusedIdentifierTypes = tenantIdentifiers.filter(
      (identifier) => !existingIdentifierTypes.includes(identifier.id)
    )

    unusedIdentifierTypes.sort(identifierSortingPredicate)

    const dataTestId = props?.dataTestId

    return (
      <Box display={'flex'} flexDirection={'column'} gap={'4px'}>
        <Section.SubHeader>Identifiers</Section.SubHeader>
        {displayFields.map((item) => {
          const index = item.index
          const field = item as IdentifierType
          const identifier = tenantIdentifiers.find(
            (identifier) => identifier.id === field.typeId
          )

          if (identifier == null) {
            console.error("No tenant identifier found for identifier type: " + field.typeId)
            console.log(field)
            return null
          }

          const isRemovable =
            !identifier.isPrimary && !identifier.alwaysDisplayField

          const label = !!identifier.province ? identifier.name + ' (' + identifier.province + ')' : identifier.name

          return (
            <Box key={item.id} display={'flex'} flexDirection={'row'} gap={'4px'}>
              <TextFieldMask
                dataTestId={`${dataTestId}-identifiers-${index}-value`}
                formatMask={identifier.formatMask}
                maskChar={''}
                name={`identifiers.${index}.value`}
                label={label}
                sx={{
                  flex: '1 1 auto',
                }}
              />
              {isRemovable && (
                <RemoveButton
                  dataTestId={`${dataTestId}-identifiers-${index}`}
                  sx={{my:1}}
                  onClick={() => removeIdentifierRow(identifier.id, index)}
                />
              )}
            </Box>
          )
        })}
        {typeSelectorShown && unusedIdentifierTypes.length > 0 && (
          <Box
            display={'flex'}
            flexDirection={'column'}
            sx={{ mt: 1 }}
          >
            <SimpleSelect
              dataTestId={`${dataTestId}-identifiers-new-type-select`}
              name={`new_identifier_type`}
              label={'Select an identifier'}
              open={true}
              onClose={() => setTypeSelectorShown(false)}
              onChange={(e) => {
                if (e.target.value === '') {
                  setTypeSelectorShown(false)
                } else {
                  addIdentifierRow(e.target.value)
                }
              }}
              value={''}
            >
              {unusedIdentifierTypes.map((identifier, index) =>
                <MenuItem data-testid={`${dataTestId}-identifiers-new-type-select-${index}`} value={identifier.id}>{ `${identifier.name} ${identifier.province ? "("+identifier.province+")": ''}`}</MenuItem>
              )}
            </SimpleSelect>
          </Box>
        )}
        {!typeSelectorShown && unusedIdentifierTypes.length > 0 && (
          <AddButton
            dataTestId={`${dataTestId}-identifiers`}
            label={'Add an identifier'}
            onClick={showTypeSelector}
          />
        )}
      </Box>
    )
  }

  return <>{renderIdentifierRows()}</>
}

export const IdentifierDetails = {
  Form: Form,
  defaults: defaults,
}
