import _get from "lodash/get";
import { ControlledSearch, OpenInNewTab } from "saga-library/src";
import {
  ControlledSearchControlProps
} from "saga-library/src/components/SearchControl/ControlledSearchControl";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAccountContext } from "../../../providers/AccountContext";
import { ReferralPractitionerResult } from "./ReferralPractitionerResult";
import { ReferralPractitioner } from "../../../types/settings";
import { REFERRAL_PRACTITIONER_SEARCH } from "../../../graphql-definitions";
import { practitionerDisplayName } from "saga-library/src/util/formatting";

export interface ReferralPractitionerSearchProps<
  T = ReferralPractitioner,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
  > extends Omit<ControlledSearchControlProps<
  T,
  Multiple,
  DisableClearable,
  FreeSolo
  >, 'queries'|'render'|'transform'|'isOptionEqualToValue'> {
  disabled?: boolean
  selectedOptions?: any[]
  autoFocusOnOpen?: boolean
  dataTestId: string
}

export const ReferralPractitionerSearch = ({
  name = 'patientId',
  label = 'patient',
  disabled = false,
  selectedOptions,
  autoFocusOnOpen,
  dataTestId,
  ...props
} :ReferralPractitionerSearchProps) => {

  const { tenant_id } = useParams()
  const { buildTenantRoute } = useAccountContext()

  const queryVariables = useMemo(() => ({
    tenantId: tenant_id
  }), [tenant_id])

  const openRefDocInNewTab = (refdoc) => {
    window.open(buildTenantRoute(
      `settings/practitioners/referral/r/${refdoc.id}`, tenant_id
    ))
  }

  return (
    <ControlledSearch<ReferralPractitioner, false, false, false>
      {...props}
      dataTestId={`${dataTestId}-search`}
      autoFocus={autoFocusOnOpen}
      name={name}
      label={label}
      disabled={disabled}
      queries={{
        search: {
          gql: REFERRAL_PRACTITIONER_SEARCH,
          get: (data) => _get(data, 'tenant.search.referralPractitioner.searchResults', []) as ReferralPractitioner[],
          variables: queryVariables,
          fetchPolicy: 'network-only'
        }
      }}
      render={{
        value: (referralPractitioner) => practitionerDisplayName(referralPractitioner.firstName, referralPractitioner.lastName),
        option: (referralPractitioner, state) => (
          <ReferralPractitionerResult
            referralPractitioner={referralPractitioner}
            searchText={state.inputValue}
            dataTestId={`${dataTestId}-result`}
          />
        ),
        endAdornment: (referralPractitioner) => (
          <RefDocEndAdornments
            referralPractitioner={referralPractitioner}
            openRefDocInNewTab={openRefDocInNewTab}
          />
        )
      }}
      getOptionDisabled={ (option) => {
        if (!selectedOptions) {
          return false
        }
        return selectedOptions.some((selectedOption) => option.id === selectedOption.id)
      }
      }
    />
  )
}

export const RefDocEndAdornments = ({referralPractitioner, openRefDocInNewTab}) => {
  return (
    <>
      {
        referralPractitioner &&
        <OpenInNewTab
          onOpenInNewTab={() => openRefDocInNewTab(referralPractitioner)}
        />
      }
    </>
  )
}