import { AddButton } from "saga-library/src";
import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "saga-library/src/components/Form";
import { Box } from "@mui/material";
import { PractitionerBlock, PractitionerRelationshipType } from "./PractitionerBlock";
import { ReferralPractitionerSearch } from "./ReferralPractitionerSearch";
import { PractitionerSelect } from "../../PractitionersSelect";

export type PractitionerArrayProps = {
  name: string
  label: PractitionerRelationshipType
  max?: number
  dataTestId: string
}

export const PractitionerArray = ({name, label, max, dataTestId}: PractitionerArrayProps) => {
  const [autoFocusOnOpen, setAutoFocusOnOpen] = useState<boolean>(false)
  const [displayPractitioners, setDisplayPractitioners] = useState<JSX.Element[]>([])
  const [forcePrimary, setForcePrimary] = useState<boolean>(false)
  const {
    control,
    getValues,
    watch,
    setValue,
  } = useFormContext()
  const { fields, update, append, remove } = useFieldArray({
    name: name,
    control,
  })
  const watchFieldArray = watch(name);

  const practitioners = useWatch({
    control,
    name: name
  });

  useEffect(() => {
    if(practitioners === undefined || fields.length === 0) {
      setValue(name, [''], { shouldDirty: false });
      if (label === PractitionerRelationshipType.CLINIC) {
        setForcePrimary(true)
      }
    }
  }, [fields, practitioners])

  const removePrimary = (practitioner, index) => {
    practitioner.isPrimaryPractitioner = false
    update(index, practitioner)
  }

  const setAsPrimary = (primaryPractitionerIndex) => {
    fields.map((item, index) => {
      let practitioner = getValues(`${name}[${index}]`)
      practitioner = {...practitioner, isPrimaryPractitioner: index === primaryPractitionerIndex}
      update(index, practitioner)
    })
  }

  const onMoreOptions = (practitioner, index) => {
    let options = [{label: 'Delete', onClick: () => remove(index)}]
    if (practitioner.isPrimaryPractitioner) {
      options.push({label: 'Remove primary', onClick: () => removePrimary(practitioner, index)})
    } else {
      options.push({label: 'Set as primary', onClick: () => setAsPrimary(index)})
    }
    return options
  }

  useEffect(() => {
    setDisplayPractitioners(fields.map((item, index) => {
      let practitioner = getValues(`${name}[${index}]`)
      if (forcePrimary && practitioner) {
        practitioner = {...practitioner, isPrimaryPractitioner: true}
        update(index, practitioner)
        setForcePrimary(false)
      }
      if (practitioner) {
        return (
          <PractitionerBlock
            key={`${item.id}-box`}
            blockKey={`${item.id}-box`}
            dataTestId={`${dataTestId}-${index}`}
            practitioner={practitioner}
            onDelete={PractitionerRelationshipType.CLINIC === label ? undefined : (() => remove(index))}
            onMore={PractitionerRelationshipType.CLINIC === label ? (onMoreOptions(practitioner, index)) : undefined}
            type={label}
          />
        )
      }
      return (
        <Box key={`${item.id}-box`}>
          { label === PractitionerRelationshipType.CLINIC ?
            <PractitionerSelect
              key={item.id}
              label={label}
              name={`${name}.${index}`}
              getOptionDisabled={(option) => {
                if (!watchFieldArray) {
                  return false
                }
                return watchFieldArray.some((selectedOption) => option.value === (selectedOption.id || selectedOption.practitionerId))
              }}
              setValueAsPractitioner={true}
              dataTestId={`${dataTestId}-${index}`}
            />
            :
            <ReferralPractitionerSearch
              dataTestId={`${dataTestId}-${index}`}
              key={item.id}
              label={label}
              name={`${name}.${index}`}
              selectedOptions={watchFieldArray}
              autoFocusOnOpen={autoFocusOnOpen}
            />
          }
        </Box>
      )
    }))
  }, [JSON.stringify(watchFieldArray), JSON.stringify(fields)])

  return (
    <>
      {displayPractitioners}
      {(!max || fields.length < max) && <AddButton
        dataTestId={dataTestId}
        label={`Add ${label.toLowerCase()}`}
        onClick={() => {
          append("");
          setAutoFocusOnOpen(true);
        }}
      />}
    </>
  )
}