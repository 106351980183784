import React from "react";
import { TextField } from "saga-library/src";
import { useFormContext } from "saga-library/src/components/Form";
import { PractitionerSelect } from "../../../../components/PractitionersSelect";
import { getErrorMessage } from "./LetterTemplateFormValidation";
import { FormHelperTextWithWarning } from "saga-library/src/components/FormHelperTextWithWarning";
import { FieldError } from "react-hook-form";
import { FormRow } from "../../../../components/FormRow";
import { PermissionType } from "../../../../types/settings/Permission";
import { PermissionForm } from "../../../../components/PermissionForm";
import { RichTextEditorClient } from '../../../../components/RichTextEditorClient'

interface LetterTemplateFormProps {
  onSubmit: (data: any) => void
  formName: string
}

export const LetterTemplateForm = ({
  onSubmit,
  formName,
}: LetterTemplateFormProps) => {
  const { formState: {errors} } = useFormContext()

  return (
    <PermissionForm
      name={formName}
      onSubmit={onSubmit}
      id={formName}
      requiredPermissionType={PermissionType.Chart}
      readOnlyOverride={true}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      <FormRow>
        <TextField
          name={'name'}
          label={'Template name'}
          fullWidth={true}
          dataTestId={"letterTemplateForm-name"}
        />
        <TextField
          name={'title'}
          label={'Title'}
          fullWidth={true}
          dataTestId={"letterTemplateForm-title"}
        />
        <PractitionerSelect
          label={'Practitioner'}
          name={'practitionerId'}
          dataTestId={"letterTemplateForm-practitioner-select"}/>
      </FormRow>
      <FormHelperTextWithWarning
        error={errors && errors.content as FieldError}
        helperText={getErrorMessage(errors, "content")}
        dataTestId={"letterTemplateForm-helper-text"}
      />
      <RichTextEditorClient
        name={"content"}
        showTemplateToolbar={true}
        placeholderText={"??"}
        textEditorHeight={'500px'}
        loopPlaceholders={true}
        dataTestId={"letterTemplateForm-content"}
      />
    </PermissionForm>
  )
}