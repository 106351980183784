import { PatientSearchResult } from "../../../types/patients";
import _get from "lodash/get";
import {
  ControlledSearch,
} from "saga-library/src";
import {
  ControlledSearchControlProps
} from 'saga-library/src/components/SearchControl/ControlledSearchControl'
import React, { useMemo, useState } from 'react'
import { useParams } from "react-router-dom";
import PatientResult from "./PatientResult";
import { useAccountContext } from "../../../providers/AccountContext";
import {
  GET_PATIENT_LABEL,
  GET_RECENT_PATIENTS,
  GET_SEARCH_PATIENTS
} from '../../../graphql-definitions'
import { PatientModal } from "./PatientModal";
import { useFormContext, useWatch } from "saga-library/src/components/Form";
import { useLazyQuery } from "@apollo/client";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { PatientLabel } from "./PatientLabel";
import { SxProps, Theme } from "@mui/material";
import { AddressType } from '../../Address'

export interface PatientSearchProps<
  T = PatientSearchResult,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
  > extends Omit<ControlledSearchControlProps<
  T,
  Multiple,
  DisableClearable,
  FreeSolo
  >, 'queries'|'render'|'transform'|'isOptionEqualToValue'> {
  patientLabelVariant?: 'md' | 'sm'
  patientLabelSx?: SxProps<Theme>,
  defaultTValue?: any
  selectedOptions?: any[]
  autoFocusOnOpen?: boolean
  relationshipAddress?: AddressType
  includeInactive?: boolean
}

export const PatientSearch = ({
  name = 'patientId',
  label = 'patient',
  disabled = false,
  autoSelect = false,
  patientLabelVariant = 'md',
  dataTestId,
  patientLabelSx = {},
  defaultTValue,
  selectedOptions,
  autoFocusOnOpen,
  relationshipAddress,
  includeInactive = false,
  ...props
} :PatientSearchProps) => {

  const { tenant_id } = useParams()
  const { setValue, control } = useFormContext()
  const { buildTenantRoute } = useAccountContext()
  const { showErrorAlert } = useAlerts()
  const [ openPatientModal, setOpenPatientModal ] = useState<boolean>(false)

  const patientSelected = useWatch({
    control,
    name: name
  })

  const [ getPatientDetails ] = useLazyQuery(GET_PATIENT_LABEL, {
    onError: (error) => {
      console.error('Error occurred retrieving user: ' + error)
      showErrorAlert("New patient couldn't be added.")
    },
    onCompleted: (data) => {
      setValue(name, _get(data,'tenant.patient.label', null))
    },
    fetchPolicy: 'cache-first',
  })

  const queryVariables = useMemo(() => ({
    tenantId: tenant_id,
    includeInactive: includeInactive
  }), [tenant_id])

  const openPatientInNewTab = (patient) => {
    window.open(buildTenantRoute(
      `patients/p/${patient.id}`, tenant_id
    ))
  }

  const setSelectedPatient = async (newPatientId: string) => {
    if (newPatientId) {
      await getPatientDetails({
        variables: {
          patientId: newPatientId,
          tenantId: tenant_id,
        }
      })
    }
  }

  return (
    <>
      { patientSelected ?
          <PatientLabel
            dataTestId={`${dataTestId}-label`}
            patient={patientSelected}
            name={name}
            disabled={disabled}
            variant={patientLabelVariant}
            sx={patientLabelSx}
          />
        :
          <>
            <ControlledSearch<PatientSearchResult, false, false, false>
              {...props}
              autoFocus={autoFocusOnOpen}
              dataTestId={`${dataTestId}-search`}
              name={name}
              label={label}
              defaultTValue={defaultTValue}
              disabled={disabled}
              autoSelect={autoSelect && !openPatientModal}
              queries={{
                search: {
                  gql: GET_SEARCH_PATIENTS,
                  get: (data) => _get(data, 'tenant.search.patients.searchResults', []) as PatientSearchResult[],
                  variables: queryVariables,
                  fetchPolicy: 'network-only'
                },
                initial: {
                  gql: GET_RECENT_PATIENTS,
                  get: (data) => (_get(data, 'tenant.search.patients.recent', []) as PatientSearchResult[]).filter(p => p.inactiveDate == null),
                  variables: queryVariables,
                  fetchPolicy: 'cache-first'
                }
              }}
              render={{
                value: (patient) => {
                  if(defaultTValue){
                    return `${patient.primaryIdentifierLabel}`
                  }
                  else{
                    return `${patient.lastName}, ${patient.firstName}`
                  }
                },
                option: (patient, state) => (
                  <PatientResult
                    dataTestId={`${dataTestId}-result-${state.index}`}
                    patient={patient}
                    openInTab={openPatientInNewTab}
                    searchText={state.inputValue}
                  />
                )
              }}
              addNewName={'patient'}
              addNewOnClick={() => {
                setOpenPatientModal(true)
              }}
              getOptionDisabled={ (option) => {
                  if (!selectedOptions) {
                    return false
                  }
                  return selectedOptions.some((selectedOption) => option.id === (selectedOption.relatedPatientId || selectedOption.id))
                }
              }
            />
            <PatientModal
              open={openPatientModal}
              onModalClose={() => setOpenPatientModal(false)}
              setSelectedPatient={setSelectedPatient}
              relationshipAddress={relationshipAddress}
            />
          </>
      }
    </>
  )
}