import React, { useEffect, useState } from 'react'
import { Box, useTheme } from "@mui/material";
import { NewClaimDetails } from './NewClaimDetails'
import { UnsubmittedClaimList } from './UnsubmittedClaimList'
import { PatientClaimsList } from './PatientClaimsList'
import { Section, EmptyList } from 'saga-library/src'
import { useNavigate, useParams } from "react-router-dom";
import { AbClaim } from "../../../types/billing"
import { useApolloClient, useQuery } from "@apollo/client";
import _get from "lodash/get";
import { useAlerts } from "saga-library/src/providers/Alerts";
import UnsubmittedMultipleSelected from "./UnsubmittedMultipleSelected";
import { UnsubmittedClaimUpdateDetails } from "./UnsubmittedClaimUpdateDetails";
import { BatchUpdateDetails } from "./BatchUpdate/UnsubmittedClaims/BatchUpdateDetails";
import { useAccountContext } from "../../../providers/AccountContext";
import { GET_CLAIM, LIST_UNSUBMITTEDCLAIMS } from "../../../graphql-definitions";
import { Permission, PermissionType } from "../../../types/settings/Permission";
import FormColumn from "./FormColumn";

export const UnsubmittedForm = () => {
  const navigate = useNavigate()
  const client = useApolloClient()
  const { tenant_id, claim_id } = useParams()
  const { showErrorAlert } = useAlerts()
  const theme = useTheme()

  const [allUnsubmittedClaims, setAllUnsubmittedClaims] = useState<AbClaim[]>([])
  const [selectedClaims, setSelectedClaims] = useState<AbClaim[]>([])
  const [updateBatch, setUpdateBatch] = useState(false)
  const [batchUpdateClaims, setBatchUpdateClaims] = useState<AbClaim[]>([])
  const [currentPatientId, setCurrentPatientId] = React.useState('')
  const [clearSelectedRows, setClearSelectedRows] = useState(false)
  const [redirectToNewlyCreatedClaim, setRedirectToNewlyCreatedClaim] = useState(false)

  useEffect(() => {
    const claimIds = selectedClaims.map(sc => sc.id)
    const claims = claimIds.map((id) => {
      return client.query({
        query: GET_CLAIM,
        variables: {
          claimId: id,
          tenantId: tenant_id,
        }
      })
    })
    Promise.all(claims).then((result) => {
      const data = result.map(r => r.data.tenant.abClaim.abClaim)
      setBatchUpdateClaims(data)
    })
  }, [selectedClaims])

  const { loading, refetch } = useQuery(LIST_UNSUBMITTEDCLAIMS, {
    variables: { tenantId: tenant_id },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert("Unsubmitted claims couldn't be retrieved.")
    },
    onCompleted: (data) => {
      let unSubmittedClaims = _get(data, 'tenant.abClaim.listUnsubmittedAbClaim', null)
      setAllUnsubmittedClaims(unSubmittedClaims)
    },
    fetchPolicy: 'cache-and-network',
  })

  const handleSelectedClaimIdChange = (claim: AbClaim) => {
    let newClaimList: AbClaim[] = [...selectedClaims]
    if (!selectedClaims.some((c) => c.id === claim.id)) {
      newClaimList.push(claim)
    } else {
      newClaimList = newClaimList.filter((c) => c.id !== claim.id)
    }
    setSelectedClaims(newClaimList)
    setClearSelectedRows(false)
  }

  const handleRowChange = (claim: AbClaim) => {
    setRedirectToNewlyCreatedClaim(false)
    navigate(`../c/${claim.id}`)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>, claims: AbClaim[]) => {
    if (event.target.checked) {
      setSelectedClaims(claims)
    } else {
      setSelectedClaims([])
    }
  };

  const handleButtonSelectAllClick = () => {
    if (selectedClaims.length !== allUnsubmittedClaims.length) {
      setSelectedClaims(allUnsubmittedClaims)
    }
  }

  const handleClearAllClick = () => {
    setSelectedClaims([])
    setClearSelectedRows(true)
  }

  return (
    <Section.Container
      sx={ theme => ({
        width: '100%',
        mr: theme.spacing(2)
      })}
    >

      <UnsubmittedFormStates
        selectedClaims={selectedClaims}
        claim_id={claim_id}
        setCurrentPatientId={setCurrentPatientId}
        updateBatch={updateBatch}
        batchUpdateClaims={batchUpdateClaims}
        setUpdateBatch={setUpdateBatch}
        handleButtonSelectAllClick={handleButtonSelectAllClick}
        refetch={refetch}
        handleClearAllClick={handleClearAllClick}
        redirectToNewlyCreatedClaim={redirectToNewlyCreatedClaim}
        setRedirectToNewlyCreatedClaim={setRedirectToNewlyCreatedClaim}
      />

      <Box
        sx={{
          height: 'auto',
          flex: '1 1 auto',
          minWidth: '880px',
          minHeight: '632px',
          overflow: "hidden",
          display:"flex",
          flexDirection: "column",
          mr: 1,
          pb: "3px"
        }}
      >
        <Section.Column
          width={'auto'}
          sx={{
            flex:"1 1 auto",
            minHeight: '65%',
            maxHeight: '65%',
            m: theme.spacing(0,0,1)
          }}
          rightPadding={1}
        >
          <UnsubmittedClaimList
            loading={loading}
            allUnsubmittedClaims={allUnsubmittedClaims}
            onCheckboxClick={handleSelectedClaimIdChange}
            onRowClick={handleRowChange}
            selectAll={handleSelectAllClick}
            selectedClaims={selectedClaims}
            onRowSelectionChange={handleSelectedClaimIdChange}
            clearSelectedRows={clearSelectedRows}
          />
        </Section.Column>
        <Section.Column
          width={'auto'}
          height={"60%"}
          sx={{
            overflowY:"auto",
            mr: 0,
          }}
          rightPadding={1}
        >
          <PatientClaimsList
            patientId={currentPatientId}
          />
        </Section.Column>
      </Box>
    </Section.Container>
  )
}

const UnsubmittedFormStates = ({
  selectedClaims,
  claim_id,
  setCurrentPatientId,
  updateBatch,
  batchUpdateClaims,
  setUpdateBatch,
  handleButtonSelectAllClick,
  refetch,
  handleClearAllClick,
  redirectToNewlyCreatedClaim,
  setRedirectToNewlyCreatedClaim
}) => {
  const { tenant_id } = useParams()
  const { userHasPermission } = useAccountContext()
  const userHasNewClaimPermission = (tenant_id && userHasPermission(tenant_id, PermissionType.Billing, Permission.READWRITE))

  if (updateBatch) {
    return (
      <BatchUpdateDetails
        selectedClaims={batchUpdateClaims}
        setUpdateBatch={setUpdateBatch}
      />
    )
  } else if (selectedClaims.length > 0) {
    return (
      <UnsubmittedMultipleSelected
        selectedClaims={selectedClaims}
        handleButtonSelectAllClick={handleButtonSelectAllClick}
        refetch={refetch}
        clearAllClick={handleClearAllClick}
        setUpdateBatch={setUpdateBatch}
      />
    )
  } else {
    if (!claim_id) {
      if (userHasNewClaimPermission) {
        return (
          <NewClaimDetails
            setCurrentPatientId={setCurrentPatientId}
            setRedirectToNewlyCreatedClaim={setRedirectToNewlyCreatedClaim}
          />
        )
      }
      return (
        <FormColumn>
          <EmptyList message={'Select a claim to view it.'}/>
        </FormColumn>
      )
    } else {
      return (
        <UnsubmittedClaimUpdateDetails setCurrentPatientId={setCurrentPatientId} redirectToNewlyCreatedClaim={redirectToNewlyCreatedClaim} setRedirectToNewlyCreatedClaim={setRedirectToNewlyCreatedClaim} />
      )
    }
  }
}

export default UnsubmittedForm