import { HistoryIcon } from '../ChartIcons'
import { TableList, TableListCellConfig, TableListHeaderConfig, TableListRowConfig, Typography } from 'saga-library/src'
import React, { useMemo, useState } from 'react'
import Widget from '../Widget'
import { useReadQuery } from '@apollo/client'
import { useHistoryContext } from '../../providers/HistoryProvider'
import moment from 'moment-timezone'
import ImmunizationsDialog from '../immunizations/ImmunizationsDialog'
import FamilyHistoryDialog from '../familyHistory/FamilyHistoryDialog'
import SocialHistoryDialog from '../socialHistory/SocialHistoryDialog'
import { HISTORY_FREQUENCIES, HistoryFrequency, SocialHistoryType } from '../../../../types/SocialHistory'

const columns: TableListHeaderConfig[] = [
  { name: '' },
  { name: '' },
]

enum HistoryOption {
  FAMILY = 'FAMILY',
  SOCIAL = 'SOCIAL',
  IMMUNIZATION = 'IMMUNIZATION'
}

export const HistoryWidget = () => {
  const [ openDialog, setOpenDialog]  = useState<HistoryOption | null>(null)

  return <Widget title={'History'} WidgetIcon={HistoryIcon} >
    <HistoryWidgetBody onRowClick={setOpenDialog} />
    <ImmunizationsDialog open={openDialog === HistoryOption.IMMUNIZATION} setOpen={setOpenDialog} />
    <FamilyHistoryDialog open={openDialog === HistoryOption.FAMILY} setOpen={setOpenDialog} />
    <SocialHistoryDialog open={openDialog === HistoryOption.SOCIAL} setOpen={setOpenDialog} />
  </Widget>
}


const HistoryWidgetBody = ({ onRowClick }) => {
  const {
    immunizationQueryRef,
    parseImmunizationQueryResults,
    familyHistoryQueryRef,
    parseFamilyHistoryQueryResults,
    socialHistoryQueryRef,
    parseSocialHistoryQueryResults
  } = useHistoryContext()

  const { data: immunizationData } = useReadQuery(immunizationQueryRef!)
  const immunizationHistory = useMemo(()=> parseImmunizationQueryResults(immunizationData), [immunizationData, parseImmunizationQueryResults])

  const { data: familyHistoryData } = useReadQuery(familyHistoryQueryRef!)
  const familyHistory = useMemo(()=> parseFamilyHistoryQueryResults(familyHistoryData), [familyHistoryData, parseFamilyHistoryQueryResults])

  const { data: socialHistoryData } = useReadQuery(socialHistoryQueryRef!)
  const socialHistory = useMemo(() => parseSocialHistoryQueryResults(socialHistoryData), [socialHistoryData, parseSocialHistoryQueryResults])

  return (
    <TableList
      dataTestId={'history-widget-table'}
      columns={columns}
      showHeader={false}
      rows={[
        HistoryRow(HistoryOption.FAMILY, familyHistory, () => onRowClick(HistoryOption.FAMILY), 'No historical records'),
        HistoryRow(HistoryOption.SOCIAL, socialHistory, () => onRowClick(HistoryOption.SOCIAL), 'Unknown'),
        HistoryRow(HistoryOption.IMMUNIZATION, immunizationHistory, () => onRowClick(HistoryOption.IMMUNIZATION), 'No vaccination records'),
      ]}
      sx={{ height: '100%', "&.MuiTableCell-root": { borderBottom: null } }}
      tableSx={{ tableLayout: 'fixed' }}
      isWidget={true}
    />
  )
}

function hasData(title: string, data) {
  switch (title) {
    case HistoryOption.SOCIAL:
      return !!data
    case HistoryOption.IMMUNIZATION:
    case HistoryOption.FAMILY:
      return data && data.length > 0
  }
}

function formatHistoryFrequency(label: string, frequency: HistoryFrequency): string {
  if (frequency === HistoryFrequency.UNKNOWN) {
    return ""
  }
  return `${label} (${HISTORY_FREQUENCIES[frequency]})`
}

function summarizeData(title: string, data) {
  let summary: string[] = []
  switch (title) {
    case HistoryOption.IMMUNIZATION:
      summary = data.map((immunization) => immunization.date ? `${immunization.name} (${moment(immunization.date).year()})` : immunization.name)
      break
    case HistoryOption.FAMILY:
      summary = data.map((familyHistory) => `${familyHistory.condition}`)
      break
    case HistoryOption.SOCIAL:
      const socialHistory = data as SocialHistoryType
      summary.push(formatHistoryFrequency('Exercise', socialHistory.exerciseFrequency))
      summary.push(formatHistoryFrequency('Alcohol', socialHistory.alcoholFrequency))
      summary.push(formatHistoryFrequency('Smoking', socialHistory.smokingFrequency))
      summary.push(formatHistoryFrequency('Vaping', socialHistory.vapingFrequency))
      summary.push(formatHistoryFrequency('Caffeine', socialHistory.caffeineFrequency))
      summary.push(formatHistoryFrequency('Cannabis', socialHistory.cannabisFrequency))
      summary.push(formatHistoryFrequency('Other drugs', socialHistory.otherDrugsFrequency))
      summary = summary.filter((s) => !!s)
      break
  }
  return summary.join(' · ')
}

function HistoryRow(title: string, data, onRowClick, emptyMessage) : TableListRowConfig {
  const dataIsNotEmpty = hasData(title, data)
  const summarizedData = dataIsNotEmpty && summarizeData(title, data)

  const rowData = [
    {
      children: <Typography variant={'p2'} dataTestId={`${title}-title`}>{title}</Typography>,
      sx: { width: '95px'}
    },
    {
      children:
      <>
        {summarizedData ?
          <Typography variant={'p2'} lineclamp={2} dataTestId={`${title}-summary`}>{summarizedData}</Typography>
          :
          <Typography variant={'p2'} color={'greys.medium'} dataTestId={`${title}-emptyMessage`}>{emptyMessage}</Typography>
        }
      </>
    },
  ] as TableListCellConfig[];

  return {
    onClick: () => onRowClick(),
    rowData,
    key: title
  }
}

