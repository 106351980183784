import * as React from 'react'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material'
import Box from '@mui/material/Box'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormHelperText from '@mui/material/FormHelperText'
import { Controller, FieldError, useFormContext } from 'saga-library/src/components/Form'
import { Typography } from 'saga-library/src/components/Typography'
import { SimpleCheckbox } from './Checkbox'

interface CheckboxItem {
  name: string
  label: string
}

interface CheckBoxGroupProps<Type> {
  name: string
  label?: string
  error?: FieldError
  items: Type[]
  row?: boolean
  sx?: SxProps<Theme>
  dataTestId: string
}

export default function CheckboxGroup<Type extends CheckboxItem>({
  name,
  label,
  error,
  items,
  row = false,
  sx,
  dataTestId
}: CheckBoxGroupProps<Type>) {
  const { control } = useFormContext()

  return (
    <Box display={"flex"}>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange, ref } }) => (
          <FormControl error={!!error} component="fieldset" sx={{ gap: "8px", ...sx }}>
            {label && (
              <FormLabel
                component={({ children }) => <Typography variant={"h5"}>{children}</Typography>}
                sx={{ ...sx }}
              >
                {label}
              </FormLabel>
            )}
            <FormGroup row={row} sx={{ ml: 2 }}>
              {items.map((item, index) => {
                const itemChecked = (e) => {
                  if (e.target.checked) {
                    value.push(item)
                  } else {
                    value.splice(value.findIndex((v) => v.name === item.name), 1)
                  }
                  onChange(value)
                }
                return (
                  <SimpleCheckbox
                    key={item.name}
                    label={item.label}
                    value={!!value.find((v) => v.name === item.name)}
                    onChange={itemChecked}
                    dataTestId={`${dataTestId}-checkbox-${index}`}
                  />
                )
              })}
            </FormGroup>
            {error && <FormHelperText>{error.toString()}</FormHelperText>}
          </FormControl>
        )}
      />
    </Box>
  )
}
