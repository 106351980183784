import React, { useEffect, useState } from "react";
import { ConfirmationDialog, DeleteButton, NavigationHeader, SaveButton, TextField } from "saga-library/src";
import { Box } from "@mui/material";
import { useFormContext } from "saga-library/src/components/Form";
import { ScheduleTimeSelect } from "../../../components/ScheduleTimeSelect";
import {
  Eventcalendar,
  MbscCalendarEvent,
  MbscCellClickEvent,
  MbscEventClickEvent,
  MbscEventCreateEvent,
  momentTimezone,
} from "@mobiscroll/react";
import { default as momentTZ } from "moment-timezone";
import { ReadOnlyFormActionsMask } from "../../../components/ReadOnlyFormActionsMask";
import { Permission, PermissionType } from "../../../types/settings/Permission";
import { useMutation } from "@apollo/client";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DELETE_TEMPLATE, LIST_TEMPLATES } from "../../../graphql-definitions";
import { formatMomentDateTimeToUTC } from "../util/scheduleFunctions";
import { NewTemplateEventDialog } from "./NewTemplateEventDialog";
import moment from "moment-timezone";
import { EditTemplateEventDialog } from "./EditTemplateEventDialog";
import { EditTemplateBookingPreference } from "./BookingPreference/EditTemplateBookingPreference";
import { TemplateCalendar } from "./components/TemplateCalendar";
import { WeekAvailability } from "../components/WeekAvailability";
import {
  createBookingPreference,
  updateBookingPreference,
  deleteBookingPreference,
  createEvent,
  updateEvent,
  deleteEvent,
  separateIntoIntervals,
  combineFromIntervals,
  getDayBlockedTimes,
  updateDragEvent
} from "../util/templateFunctions";
import { ScheduleLength } from "../../../types/schedule/Schedule";
import { TemplateMbscCalendarColor, TemplateMbscCalendarEvent } from "../../../types/schedule/Template";
import { TemplateDataProps } from "./EditTemplate";
import { useTenantContext } from "../../../providers/TenantContextProvider";
import { usePrompt } from "../../../providers/NavigationPrompt";
import { flushSync } from "react-dom";

const defaultNoHours = {
  isOpen: false,
  startTime: "PT8H",
  endTime: "PT17H"
}

const defaultDailyHours = {
  isOpen: true,
  startTime: "PT8H",
  endTime: "PT17H"
}

export const defaultSingleDay = (day : number = 0) => {
  return {
      ...defaultDailyHours,
      day: day,
      templateBlocks: []
    }
}

export const defaultClearDay = (day : number = 0) => {
  return {
    ...defaultNoHours,
    day: day,
    templateBlocks: []
  }
}

export const defaultFullWeek = [
  {
    ...defaultNoHours,
    day: 0,
    templateBlocks: []
  },
  {
    ...defaultDailyHours,
    day: 1,
    templateBlocks: []
  },
  {
    ...defaultDailyHours,
    day: 2,
    templateBlocks: []
  },
  {
    ...defaultDailyHours,
    day: 3,
    templateBlocks: []
  },
  {
    ...defaultDailyHours,
    day: 4,
    templateBlocks: []
  }
  , {
    ...defaultDailyHours,
    day: 5,
    templateBlocks: []
  },
  {
    ...defaultNoHours,
    day: 6,
    templateBlocks: []
  },
]


interface TemplateFormProps {
  title: string
  templateData?: TemplateDataProps
  showDelete?: boolean
  formName: string
}

export interface TemplateData {
  eventData: any
  day: number
  showBookingPreference: boolean
}

export const TemplateForm = ({
  title,
  showDelete = true,
  formName,
  templateData: initialTemplateData
}: TemplateFormProps) => {
  const { getTenantSettings, tenantSettingsKeys } = useTenantContext()
  const { timezone } = getTenantSettings([tenantSettingsKeys.TIMEZONE])
  const { getValues, formState: { isSubmitting }, setValue, handleSubmit } = useFormContext()
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const { tenant_id, template_id } = useParams()
  const { clearNavigationPrompt } = usePrompt()

  momentTimezone.moment = momentTZ

  const [createOpen, setCreateOpen] = useState<boolean>(false)
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const [templateData, setTemplateData] = useState<TemplateData | null>(null)
  const [editOpen, setEditOpen] = useState<boolean>(false)
  const [editBookingPreferenceOpen, setEditBookingPreferenceOpen] = useState<boolean>(false)
  const [editEventData, setEditEventData] = useState<any>(null)
  const [blockedTimes, setBlockedTimes] = useState<MbscCalendarEvent[]>(initialTemplateData?.blockedTimes || [])
  const [anchorEl, setAnchorEl] = useState<any | HTMLElement>(undefined)
  const [templateLength, setTemplateLength] = useState<ScheduleLength>(ScheduleLength.DAY)
  const [templateEvents, setTemplateEvents] = useState<TemplateMbscCalendarEvent[]>(initialTemplateData?.events || [])
  const [templateBookingPreferences, setTemplateBookingPreferences] = useState<TemplateMbscCalendarColor[]>(initialTemplateData?.bookingPreferences || [])

  useEffect(() => {
    //new templates have the "length" query param set to "day" or "week"
    if (location.search) {
      const tempTemplateLength = queryParams.get("length") === ScheduleLength.WEEK ? ScheduleLength.WEEK : ScheduleLength.DAY
      const tempTemplateDays = (tempTemplateLength === ScheduleLength.WEEK) ? defaultFullWeek : [defaultSingleDay()]
      const tempBlockedTimes: MbscCalendarEvent[] = []

      tempTemplateDays.forEach(templateDay => {
        tempBlockedTimes.push(...getDayBlockedTimes(templateDay, tempTemplateLength, timezone as string))
      })
      setTemplateLength(tempTemplateLength)
      setValue("templateDays", tempTemplateDays)
      setBlockedTimes(tempBlockedTimes)
    }
  }, [location.search])

  useEffect(() => {
    if (!!initialTemplateData) {
      setTemplateEvents(initialTemplateData?.events || [])
      setTemplateBookingPreferences(bookingPreferenceIntervals(initialTemplateData?.bookingPreferences || []))
      setBlockedTimes(initialTemplateData?.blockedTimes || [])
      setTemplateLength(initialTemplateData?.length || ScheduleLength.DAY)
    }
  }, [JSON.stringify(initialTemplateData)])

  const onDeleteCancel = () => setOpenDelete(false)

  const [deleteTemplate] = useMutation(DELETE_TEMPLATE, {
    onError: (error) => {
      showErrorAlert("Template couldn't be deleted.")
      console.error(JSON.stringify(error, null, 2))
    },
    onCompleted: (data) => {
      flushSync(() => {
        clearNavigationPrompt(formName)
      })
      showSuccessAlert('Template has been deleted.')
      navigate(`..`)
      setOpenDelete(false)
    }
  })

  const onDeleteTemplate = handleSubmit(async returnedData => {
    await deleteTemplate({
      variables: {
        tenantId: tenant_id,
        id: template_id
      }, update(cache) {
        cache.updateQuery({
            query: LIST_TEMPLATES,
            variables: { tenantId: tenant_id }},
          (data) => {
            return {
              tenant: {
                schedule: {
                  listTemplates: [...data.tenant.schedule.listTemplates.filter(at => at.id !== template_id)]
                }
              }
            }
          }
        )
      }
    })
  })

  const bookingPreferenceIntervals = (bookingPreferences): TemplateMbscCalendarColor[] => {
    if(bookingPreferences) {
      return bookingPreferences.flatMap((bp) => {
        return separateIntoIntervals(bp, 60)
      })
    }
    return []
  }

  const onEventCreated = (event: MbscEventCreateEvent, data: Eventcalendar) => {
    const startDate = moment(event.event.start)
    const startUTC = formatMomentDateTimeToUTC(startDate)
    const day = startDate.day()
    const bookingPreference = templateBookingPreferences?.find((bp) => startUTC >= bp.start! && startUTC < bp.end!)

    setTemplateData({
      eventData: event.event,
      day: templateLength === ScheduleLength.DAY ? 0 : day,
      showBookingPreference: (!bookingPreference)
    })

    setCreateOpen(true)
  }

  const handleEventClicked = (event: MbscEventClickEvent, data: Eventcalendar) => {
    setEditEventData(event.event)
    setEditOpen(true)
  }

  const onCellRightClicked = (clickEvent: MbscCellClickEvent, inst: Eventcalendar) => {
    clickEvent.domEvent.preventDefault()
    const date = formatMomentDateTimeToUTC(clickEvent.date)
    const bookingPreference = templateBookingPreferences?.find((bp) => date >= bp.start! && date < bp.end!)

    if (bookingPreference) {
      const combinedBookingPreference = combineFromIntervals(templateBookingPreferences, bookingPreference)
      setAnchorEl(clickEvent.domEvent.currentTarget)
      setEditEventData(combinedBookingPreference)
      setEditBookingPreferenceOpen(true)
    }
  }

  const onEventCreate = (event: TemplateMbscCalendarEvent) => {
    const { events, templateDayIndex, templateBlocks } = createEvent(
      getValues(`templateDays`),
      templateEvents,
      event)
    if (templateBlocks) {
      setValue(`templateDays.${templateDayIndex}.templateBlocks`, templateBlocks, { shouldDirty: true })
    }
    setTemplateEvents(events)
  }

  const onEventUpdate = (event) => {
    const { events, templateDayIndex, templateBlocks } = updateEvent(
      getValues(`templateDays`),
      templateEvents,
      event,
      editEventData)
    if (templateBlocks) {
      setValue(`templateDays.${templateDayIndex}.templateBlocks`, templateBlocks, { shouldDirty: true })
    }
    setTemplateEvents(events)
  }

  const onDragEventUpdate = (event) => {
    const { events, templateDayIndex, templateBlocks, newTemplateDayIndex, newTemplateBlocks } = updateDragEvent(
      getValues(`templateDays`),
      templateEvents,
      event)
    if (templateBlocks) {
      setValue(`templateDays.${templateDayIndex}.templateBlocks`, templateBlocks, { shouldDirty: true })
    }
    if (newTemplateDayIndex !== -1 && newTemplateBlocks) {
      setValue(`templateDays.${newTemplateDayIndex}.templateBlocks`, newTemplateBlocks, { shouldDirty: true })
    }
    setTemplateEvents(events)
  }

  const onEventDelete = (event: TemplateMbscCalendarEvent) => {
    const { events, templateDayIndex, templateBlocks } = deleteEvent(
      getValues(`templateDays`),
      templateEvents,
      event
    )
    if (templateBlocks) {
      setValue(`templateDays.${templateDayIndex}.templateBlocks`, templateBlocks, { shouldDirty: true })
    }
    setTemplateEvents(events)
  }

  const onBookingPreferenceCreate = (bookingPreference: TemplateMbscCalendarColor) => {
    const { bookingPreferences, templateDayIndex, templateBlocks, errorMessage } = createBookingPreference(
      getValues(`templateDays`),
      templateBookingPreferences,
      bookingPreference
    )
    if (!!errorMessage) {
      showErrorAlert(errorMessage)
      return;
    }
    if (templateBlocks) {
      setValue(`templateDays.${templateDayIndex}.templateBlocks`, templateBlocks, { shouldDirty: true })
    }
    setTemplateBookingPreferences(bookingPreferenceIntervals(bookingPreferences))
  }

  const onBookingPreferenceUpdate = (bookingPreference: TemplateMbscCalendarColor) => {
    const { bookingPreferences, templateDayIndex, templateBlocks, errorMessage } = updateBookingPreference(
      getValues(`templateDays`),
      templateBookingPreferences,
      bookingPreference,
      editEventData
    )
    if (!!errorMessage) {
      showErrorAlert(errorMessage)
      return
    }
    if (templateBlocks) {
      setValue(`templateDays.${templateDayIndex}.templateBlocks`, templateBlocks, { shouldDirty: true })
    }
    setTemplateBookingPreferences(bookingPreferenceIntervals(bookingPreferences))
  }

  const onBookingPreferenceDelete = (bookingPreference: TemplateMbscCalendarColor) => {
    const { bookingPreferences, templateDayIndex, templateBlocks } = deleteBookingPreference(
      getValues(`templateDays`),
      templateBookingPreferences,
      bookingPreference
    )
    if (templateBlocks) {
      setValue(`templateDays.${templateDayIndex}.templateBlocks`, templateBlocks, { shouldDirty: true })
    }
    setTemplateBookingPreferences(bookingPreferenceIntervals(bookingPreferences))
  }

  const updateBlockedTimesDisplay = (isOpen: boolean, start: string, end: string, day: number) => {
    const tempTemplateDays = getValues(`templateDays`)
    const tempBlockedTimes: MbscCalendarEvent[] = []
    tempTemplateDays[day] = {
      ...tempTemplateDays[day],
      isOpen: isOpen,
      day: day,
      startTime: start,
      endTime: end
    }
    tempTemplateDays.forEach(templateDay => {
      tempBlockedTimes.push(...getDayBlockedTimes(templateDay, templateLength, timezone as string))
    })

    setValue("templateDays", tempTemplateDays)
    setBlockedTimes(tempBlockedTimes)
  }

  const updateDayBlockedTimesDisplay = () => {
    updateBlockedTimesDisplay(true, getValues(`templateDays.0.startTime`), getValues(`templateDays.0.endTime`), 0)
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      sx={{ height: '100%', overflow: 'hidden' }}
    >
      <NavigationHeader
        title={`${title.split(' ')[0]} ${templateLength} ${title.split(' ').slice(1).join(' ')}`}
        titleVariant={'h2'}
        actions={
          <ReadOnlyFormActionsMask requiredType={PermissionType.Schedule} requiredPermission={Permission.READWRITE}>
            <>{showDelete && <DeleteButton dataTestId={"template-delete"} onClick={() => setOpenDelete(true)} />}</>
            <SaveButton
              form={formName}
              dataTestId={"template"}
              submitting={isSubmitting}
            />
          </ReadOnlyFormActionsMask>
        }
        actionSx={{ paddingRight: 1 }}
      />
      <Box
        display={'flex'}
        gap={1}
        width={'100%'}
        overflow={'hidden'}
      >
        <Box
          sx={{ flex: "0 0 200px" }}
        >
          <TextField dataTestId={"template-name"} name={'name'} label={'Name'} fullWidth={true} />
          {templateLength === ScheduleLength.DAY &&
            <>
              <ScheduleTimeSelect dataTestId={"template-startTime"} name={'templateDays.0.startTime'} label={'Start time'} defaultValue={'PT8H'} onChange={updateDayBlockedTimesDisplay} />
              <ScheduleTimeSelect dataTestId={"template-endTime"} name={'templateDays.0.endTime'} label={'End time'} defaultValue={'PT17H'} onChange={updateDayBlockedTimesDisplay} />
            </>
          }
        </Box>
        <Box flex={"1 1 auto"} sx={{ display: 'flex', flexDirection: "column", overflowX: "auto" }}>
          {templateLength === ScheduleLength.WEEK && <Box pl={"68px"} pr={"17px"} height={"185px"} sx={{ flex: '0 0 auto' }}>
            <WeekAvailability dataTestId={"template-week"} updateBlockedTimes={updateBlockedTimesDisplay} />
          </Box>}
          <Box
            overflow={'hidden'}
            minWidth={'865px'}
            data-testid={'template-area'}
          >
            <TemplateCalendar
              scheduleType={templateLength}
              bookingPreferences={templateBookingPreferences}
              events={templateEvents}
              onEventCreated={onEventCreated}
              onEventClicked={handleEventClicked}
              onEventUpdated={onDragEventUpdate}
              onCellRightClicked={onCellRightClicked}
              closedTimes={blockedTimes}
            />
          </Box>
        </Box>
      </Box>

      <ConfirmationDialog
        open={openDelete}
        onClose={onDeleteCancel}
        title={'Delete template?'}
        message={'This action cannot be undone. Dates on which this template has been applied won’t be affected by deleting this template.'}
        primaryAction={onDeleteTemplate}
        primaryLabel={'Delete'}
        isSubmitting={isSubmitting}
        dataTestId={'template-delete-dialog'}
      />

      <NewTemplateEventDialog
        open={createOpen}
        onClose={() => {
          setCreateOpen(false)
        }}
        onBookingPreferenceCreate={onBookingPreferenceCreate}
        onEventCreate={onEventCreate}
        templateData={templateData}
        scheduleLength={templateLength}
      />

      {editEventData && <EditTemplateEventDialog
        open={editOpen}
        onClose={() => {
          setEditOpen(false)
          setEditEventData(null)
        }}
        eventData={editEventData}
        onEventDelete={onEventDelete}
        onEventUpdate={onEventUpdate}
        scheduleLength={templateLength}
      />}

      {editEventData && <EditTemplateBookingPreference
        open={editBookingPreferenceOpen}
        onClose={() => {
          setEditBookingPreferenceOpen(false)
          setEditEventData(null)
        }}
        eventData={editEventData}
        day={templateLength === ScheduleLength.DAY ? 0 : moment(editEventData.start).day()}
        onBookingPreferenceDelete={onBookingPreferenceDelete}
        onBookingPreferenceUpdate={onBookingPreferenceUpdate}
        scheduleLength={templateLength}
      />}
    </Box>
  )
}

