import { PrintIconButton, Section } from "saga-library/src";
import { PatientSectionHeader } from "../PatientSectionHeader";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAccountContext } from "../../../../providers/AccountContext";
import { Permission, PermissionType } from "../../../../types/settings/Permission";
import { DocumentCategory, LinkedDocumentType, UpdateLinkedDocumentInput } from "../../../../types/Document";
import { LinkedDocumentForm } from "./LinkedDocumentForm";
import { MoreDocumentOptions } from "./MoreDocumentOptions";
import { linkedDocumentFormDefaultValues } from "./LinkedDocumentFormDefaultValues";
import { schema } from "./LinkedDocumentFormValidationSchema";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { usePatientDocumentContext } from "../../providers/PatientDocumentProvider";
import { flushSync } from "react-dom";

interface LinkedDocumentEditorProps {
  pathName: string
  formName: string
  document?: LinkedDocumentType
  category: DocumentCategory
  sectionLabel: string
  showPrintButton?: boolean
  dataTestId: string
  variant?: "document" | "forms" | "labs"
}

export const LinkedDocumentEditor = ({
  pathName,
  formName,
  category,
  document,
  sectionLabel,
  showPrintButton,
  dataTestId,
  variant
}:LinkedDocumentEditorProps) => {
  const navigate = useNavigate()
  const { tenant_id } = useParams()
  const { enableNavigationPrompt, clearNavigationPrompt } = usePrompt()
  const { userHasPermission } = useAccountContext()
  const { updateLinkedDocument } = usePatientDocumentContext()
  const [ showPatient, setShowPatient ] = useState<boolean>(false);
  const [ categoryIsChanged, setCategoryIsChanged ] = useState<boolean>(false)

  const hasEditPermission = useMemo(()=>tenant_id && userHasPermission(tenant_id, PermissionType.Chart, Permission.READWRITE), [userHasPermission])

  const formMethods = useForm<UpdateLinkedDocumentInput>({
    defaultValues: linkedDocumentFormDefaultValues,
    schema: schema(showPatient)
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting }
  } = formMethods

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, formName, `${pathName}/`)
    return () => enableNavigationPrompt(false, formName)
  }, [Object.keys(dirtyFields).length]);

  const onSubmit = handleSubmit( async (data) => {
    await updateLinkedDocument(document?.id!, data, category, categoryIsChanged, showPatient, () => {
      flushSync(() => {
        clearNavigationPrompt(formName)
      })
      if(categoryIsChanged || showPatient){
        navigate(-1)
      }
    })
  })

  const onChangePatient = () => {
    setShowPatient(true)
  }

  return <Section.Column
    sx={{ flex: "1 1 100%" }}
    preventScroll={true}
    header={
      <PatientSectionHeader
        sectionLabel={sectionLabel}
        showBackButton={true}
        showSave={hasEditPermission as boolean}
        submitting={isSubmitting}
        onBack={() => navigate(-1)}
        formName={formName}
        sx={{ gap: 1 }}
        actions={
          <>
            {showPrintButton &&
              <PrintIconButton
                onClick={undefined}
                dataTestId={dataTestId}
              />
            }
            <MoreDocumentOptions onChangePatient={onChangePatient} />
          </>
        }
        dataTestId={`${dataTestId}-header`}
      />
    } >
    <FormProvider {...formMethods}>
      <LinkedDocumentForm
        formName={formName}
        document={document}
        showPatient={showPatient}
        onSubmit={onSubmit}
        sx={{mr: 1}}
        viewerSx={{overflow:"hidden"}}
        setCategoryIsChanged={setCategoryIsChanged}
        dataTestId={`${dataTestId}-document-form`}
        variant={variant}
      />
    </FormProvider>
  </Section.Column>
}
