import React from 'react'
import './App.css'
import { AppSettingsContextProvider } from './providers/AppSettingsContext'
import { Route, Routes, Navigate, useParams } from "react-router-dom";
import { SignIn } from 'routes/SignIn'
import { ForgotPassword } from 'routes/ForgotPassword'
import { ResetPassword } from 'routes/ResetPassword'
import { Dashboard } from './routes/Dashboard'
import { Patients } from './apps/patients/PatientRoutes'
import { Billing } from './routes/Billing'
import { ReportRoutes } from './routes/ReportRoutes'
import { Settings } from './routes/Settings'
import { Tasks } from './routes/Tasks'
import { Inbox } from './routes/Inbox'
import { SettingsLayout, BasicLayout, UserLayout, RootLayout } from "./components/Layouts";
import { InviteRedemption } from './routes/InviteRedemption'
import { Home } from './routes/Home'
import { PermissionDenied, ResourceNotFound } from "./routes/static/ErrorPages";
import { PrivacyPolicy } from './routes/static/PrivacyPolicy'
import { TermsOfService } from './routes/static/TermsOfService'
import { BrowserRouter } from 'react-router-dom'
import { AccountContextProvider, useAccountContext } from "./providers/AccountContext";
import { AlertsProvider } from 'saga-library/src/providers/Alerts'
import { NavigationPromptProvider } from "./providers/NavigationPrompt";
import { PageTitleContextProvider } from "./providers/PageTitleContextProvider";
import PublicOnlyRoutes from './routes/util/PublicOnlyRoutes'
import PrivateRoutes from './routes/util/PrivateRoutes'
import { Schedule } from './routes/Schedule'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TenantContextProvider } from "./providers/TenantContextProvider";
import { LocationContextProvider } from "./providers/LocationContextProvider";
import { StaticDataContextProvider } from "./providers/StaticDataContext";
import { SupportSignIn } from "./routes/SupportSignIn";
import { MainAppBar } from "./components/MainAppBar";
import useTenantDataLoader from "./utils/useTenantDataLoader";
import { EncounterNoteTemplateProvider } from './providers/EncounterNoteTemplateProvider'
import { UserInteractionProvider } from './providers/UserInteractionContext'
import { ResetEmail } from 'routes/ResetEmail';


const App = () => {
  return (
    <div className="App">
      <BrowserRouter>

        <AlertsProvider>
          <NavigationPromptProvider>
            <PageTitleContextProvider>
              <UserInteractionProvider>

                <Routes>
                  <Route path="privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="terms-of-service" element={<TermsOfService />} />
                  <Route path="invitation//*" element={<InviteRedemption />} />
                  <Route path="reset-password" element={<ResetPassword />} />
                  <Route path="change-email-verification" element={<ResetEmail />} />
                </Routes>

                  <PublicOnlyRoutes>
                    <Routes>
                      <Route path="signin" element={<SignIn />} />
                      <Route path="support-signin" element={<SupportSignIn />} />
                      <Route path="forgot-password" element={<ForgotPassword />} />
                      <Route path="/" element={<Navigate to="/signin" replace />} />
                    </Routes>
                  </PublicOnlyRoutes>

                  <PrivateRoutes>
                    <AppSettingsContextProvider>
                      <StaticDataContextProvider>
                        <AccountContextProvider>
                          <DndProvider backend={HTML5Backend}>
                            <Routes>
                              <Route element={<SettingsLayout />}>
                                <Route path="home" element={<Home />} />
                                <Route index element={<Home />} />
                              </Route>
                              <Route path="/t/:tenant_id" element={TenantRoutesHOC(UserLayout)}>
                                <Route path="patients//*" element={<Patients />} />
                                <Route path="billing//*" element={<Billing />} />
                                <Route path="reports//*" element={<ReportRoutes />} />
                                <Route path="schedule//*" element={<Schedule />} />
                                <Route path="settings//*" element={<Settings />} />
                                <Route path="tasks//*" element={<Tasks />} />
                                <Route path={"inbox//*"} element={<Inbox />} />
                                <Route index element={<Dashboard />} />
                              </Route>
                              <Route path="invitation//*" element={<></>} />
                              <Route path="reset-password" element={<></>} />
                              <Route path="change-email-verification" element={<></>} />
                              <Route path="privacy-policy" element={<></>} />
                              <Route path="terms-of-service" element={<></>} />
                              <Route element={<BasicLayout />}>
                                <Route path="*" element={<ResourceNotFound />} />
                              </Route>
                            </Routes>
                          </DndProvider>
                        </AccountContextProvider>
                      </StaticDataContextProvider>
                    </AppSettingsContextProvider>
                  </PrivateRoutes>
              </UserInteractionProvider>

            </PageTitleContextProvider>
          </NavigationPromptProvider>
        </AlertsProvider>

      </BrowserRouter>
    </div>
  )
}


const TenantRoutesHOC = (Component) => {
  return (
    <TenantRequired>
      <TenantContextProvider>
        <LocationContextProvider>
          <EncounterNoteTemplateProvider>
            <Component />
          </EncounterNoteTemplateProvider>
        </LocationContextProvider>
      </TenantContextProvider>
    </TenantRequired>
  )
}

export const TenantRequired = ({ children }) => {
  const { tenant_id } = useParams()
  const { userHasTenant } = useAccountContext()
  useTenantDataLoader()

  if (userHasTenant(tenant_id)) {
    return <>{children}</>
  }
  return (
    <RootLayout>
      <MainAppBar rightMenu displayName />
      <PermissionDenied />
    </RootLayout>
  )
}




export default App
