import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { IconButton } from "../Button";
import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from '@mui/icons-material/Search'
import { SxProps } from '@mui/material'
import { Theme } from '@mui/system'

interface OpenInNewTabProps {
  onOpenInNewTab: () => void
  dataTestId?: string
  sx?: SxProps<Theme>
}
export const OpenInNewTab = ({
  onOpenInNewTab,
  dataTestId,
  sx
}: OpenInNewTabProps) => {
  return (
    <InputAdornment data-testid={`${dataTestId}-adornment`} position={'end'} sx={sx}>
      <IconButton
        dataTestId={dataTestId}
        edge={'end'}
        onClick={onOpenInNewTab}
        icon={<OpenInNewIcon />}
      />
    </InputAdornment>
  )
}

export const SearchIconAdornment = ({dataTestId = ''}) => {
  return (
    <InputAdornment data-testid={`${dataTestId}-adornment`} position={'start'}>
      <SearchIcon data-testid={dataTestId}/>
    </InputAdornment>
  )
}