import React, { useState } from "react";
import { TableList } from "saga-library/src";
import { useNavigate, useParams } from "react-router-dom";
import { TableListHeaderConfig, DraggableTableList } from "saga-library/src";
import { useMutation, useQuery } from "@apollo/client";
import { AppointmentStateRow } from "./AppointmentStateRow";
import { NewAppointmentStateModal } from "./NewAppointmentStateModal";
import PermissionButton from "../../../../components/PermissionButton";
import { Permission, PermissionType } from "../../../../types/settings/Permission";
import { EditAppointmentStateModal } from "./EditAppointmentStateModal";
import { useAlerts } from "saga-library/src/providers/Alerts";
import _get from "lodash/get";
import { LIST_APPOINTMENT_STATES, UPDATE_APPOINTMENT_STATE_SORT } from "../../../../graphql-definitions";
import { useAccountContext } from "../../../../providers/AccountContext";
import { AppointmentState } from "../../../../types/schedule";
import SettingsHeader from "../../components/SettingsHeader";
import { SettingsSectionColumn } from "../../components/SettingsSectionColumn";

const columns: TableListHeaderConfig[] = [
  { name: 'Name' },
  { name: 'Colour' },
  { name: 'Can be placed in an exam room' },
  { name: 'Blocks schedule availability' }
]

export const AppointmentStates = () => {
  const navigate = useNavigate()
  const { userHasPermission } = useAccountContext()
  const { tenant_id } = useParams()
  const [newAppointmentStateOpen, setNewAppointmentStateOpen] = useState<boolean>(false)
  const [editDefaultAppointmentState, setEditDefaultAppointmentState] = useState<AppointmentState | null>(null)
  const [editCustomAppointmentState, setEditCustomAppointmentState] = useState<AppointmentState | null>(null)
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const [statesList, setStatesList] = useState<AppointmentState[] | null>(null)
  const hasReadWrite = tenant_id && userHasPermission(tenant_id, PermissionType.Schedule, Permission.READWRITE)

  const { loading } = useQuery(LIST_APPOINTMENT_STATES, {
    variables: { tenantId: tenant_id },
    onCompleted: (data) => {
      setStatesList(_get(data, 'tenant.schedule.state.listStates'))
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
    fetchPolicy: 'cache-and-network'
  })

  const [updateAppointmentState, { loading: sortLoading }] =
    useMutation(UPDATE_APPOINTMENT_STATE_SORT, {
      onCompleted: (data) => {
        showSuccessAlert('Appointment state order has been updated.')
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert("Appointment state order couldn't be updated.")
      }
    })

  const updateSort = async(appointmentState, index, newList) => {
    await updateAppointmentState({
      variables: {
        tenantId: tenant_id,
        appointmentStateId: appointmentState.id,
        index: index
      },
      update(cache) {
        cache.writeQuery({
          query: LIST_APPOINTMENT_STATES,
          data: {
            tenant: {
              schedule: {
                state: {
                  listStates: newList
                }
              }
            }
          },
          variables: {
            tenantId: tenant_id,
          },
        })
      }
    })
  }

  return (
    <SettingsSectionColumn header={
      <SettingsHeader
        title={'Appointment states'}
        onBack={() => navigate(-1)}
        dataTestId={'appointmentStates'}
        actions={
        <PermissionButton
          name={'newAppointmentState'}
          onClick={() => setNewAppointmentStateOpen(true)}
          requiredType={PermissionType.Schedule}
          requiredPermission={Permission.READWRITE}
          dataTestId={'appointmentStates-newState-button'}
          >
         NEW STATE
        </PermissionButton>
        }
      />
    }>
    { hasReadWrite ?
      <DraggableTableList<AppointmentState>
        size={'small'}
        loading={loading}
        columns={columns}
        updateListItems={setStatesList}
        onDragEnd={updateSort}
        sx={{ mt: 2, height: "calc(100vh - 216px)" }}
        listItems={statesList}
        configureRow = {(appointmentState) => {
          return AppointmentStateRow(appointmentState, setEditDefaultAppointmentState, hasReadWrite)
        }}
        dataTestId={'appointmentStates-draggable-list'}
      />
      :
      <TableList
        size={'small'}
        loading={loading}
        columns={columns}
        rows={
          statesList ? statesList.map((state) => AppointmentStateRow(state, setEditDefaultAppointmentState, hasReadWrite)) : []
        }
        sx={{ mt: 2, height: "calc(100vh - 216px)" }}
        dataTestId={'appointmentStates-list'}
      />
    }
      {newAppointmentStateOpen &&
        <NewAppointmentStateModal
          open={newAppointmentStateOpen}
          setOpen={setNewAppointmentStateOpen}
        />}
      {editDefaultAppointmentState &&
        <EditAppointmentStateModal
          open={!!editDefaultAppointmentState}
          onClose={() => setEditDefaultAppointmentState(null)}
          appointmentState={editDefaultAppointmentState}
        />}
      {editCustomAppointmentState &&
        <EditAppointmentStateModal
          open={!!editCustomAppointmentState}
          onClose={() => setEditCustomAppointmentState(null)}
          appointmentState={editCustomAppointmentState}
        />}
    </SettingsSectionColumn>
  )
}

