import React, { useEffect } from "react";
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import Checkbox from '@mui/material/Checkbox'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { CircularProgress, SxProps, TableBody, TableFooter, TablePagination } from "@mui/material";
import { TableListRow } from './TableListRow'
import { TableListHeaderCell } from './TableListHeaderCell'
import type { Order } from './TableSortUtils'
import { EmptyOrLoading, TableListHeaderConfig, TableListRowConfig } from "./TableList";
import { Button } from "../Button";
import { StyledTableContainer } from "./TableContainer"
import { Theme } from "@mui/system";
import { EmptyListConfig } from "../EmptyList/EmptyList";

export interface Connection {
  edges: Edge[],
  pageInfo: PageInfo
}

export interface Edge {
  cursor: string,
  node: any
}

export interface PageInfo {
  hasNextPage: boolean
  hasPreviousPage: boolean
  startCursor: string | null
  endCursor: string | null
}

export interface PagedTableListProps {
  columns: TableListHeaderConfig[]
  rows?: TableListRowConfig[]
  emptyListComponent?: EmptyListConfig
  size?: 'medium' | 'small'
  showHeader?: boolean
  sx?: SxProps<Theme>
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  onRequestSort: (key: string, order: string) => void,
  initialSortKey: string,
  initialSortOrder?: Order,
  pagingMode: 'paged' | 'continuous'
  pageInfo?: PageInfo
  onRequestNext: (cursor: string|null|undefined) => void,
  onRequestPrevious?: (cursor: string|null|undefined) => void,
  pageLoading?: boolean
  loading?: boolean
  showCheckbox?: boolean
  onCheckboxSelectionChanged?: (keys: any[]) => void
}

export const PagedTableList = ({
  columns,
  rows,
  emptyListComponent,
  size = 'medium',
  showHeader = true,
  sx = {},
  onSelectAllClick,
  onRequestSort,
  initialSortKey,
  initialSortOrder = 'asc',
  pagingMode,
  pageInfo,
  onRequestNext,
  onRequestPrevious,
  pageLoading = false,
  loading = false,
  showCheckbox = false,
}: PagedTableListProps) => {
  const [selectAll, setSelectAll] = React.useState<boolean>(false)
  const [order, setOrder] = React.useState<Order>(initialSortOrder)
  const [orderBy, setOrderBy] = React.useState<string>(initialSortKey)
  const [page, setPage] = React.useState<number>(0)

  useEffect(() => {
    if (rows && rows?.length > 0 && rows?.filter(row => row.isRowSelected)?.length === rows?.length) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }
  }, [rows])

  const handleRequestSort = (event: React.MouseEvent<unknown>, key: string) => {
    const isAsc = orderBy === key && order === 'asc'
    const newOrder = isAsc ? 'desc' : 'asc'
    setOrder(newOrder)
    setOrderBy(key)
    onRequestSort(key, newOrder)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if(newPage > page) {
      onRequestNext(pageInfo?.endCursor)
    }
    else if(newPage < page && onRequestPrevious) {
      onRequestPrevious(pageInfo?.startCursor)
    }
    setPage(newPage)
  }

  const showTableContent = rows && rows.length > 0 && pageInfo
  return (
      <StyledTableContainer sx={sx}>
        <Table size={size} sx={{flexShrink: 0}} stickyHeader >
          {showHeader && (
            <TableHead key={'tableListHeader'}>
              <TableRow>
                {showCheckbox && (
                  <TableListHeaderCell
                    lastCol={false}
                    key={'header-checkbox'}
                    name={'checkbox'}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    sx={{p: '0 !important'}}
                  >
                    <Checkbox
                      color={"primary"}
                      onChange={(e) => {
                        setSelectAll(!selectAll)
                        onSelectAllClick(e)
                      }}
                      checked={selectAll}
                    />
                  </TableListHeaderCell>
                )}
                {columns.map((c, i) => (
                  <TableListHeaderCell
                    key={'header-' + i}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    sx={{pl: 1, pr: 1}}
                    lastCol={i === columns.length - 1}
                    {...c}
                  />
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody key={'tableListBody'}>
            {showTableContent &&
              rows.map((row, i) => (
                <TableListRow
                  showCheckbox={showCheckbox}
                  cells={row.rowData}
                  key={'row-' + row.key}
                  onClick={row.onClick}
                  onCheckboxSelect={() => {
                    if (row.onRowSelectionChange) {
                      row.onRowSelectionChange()
                    }
                  }}
                  checked={row.isRowSelected}
                />
            ))}
            {showTableContent && pagingMode === 'continuous' && pageInfo.hasNextPage &&
              <LoadMoreButton
                onClick={() => onRequestNext(pageInfo.endCursor)}
                columns={columns.length}
                pageLoading={pageLoading}
              />
            }
          </TableBody>
          {showTableContent && pagingMode === 'paged' && pageInfo.hasNextPage &&
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={-1}
                  onPageChange={handleChangePage}
                  page={page}
                  rowsPerPage={-1}
                />
              </TableRow>
            </TableFooter>
          }
        </Table>

        <EmptyOrLoading
          dataTestId={'pagedTableList'}
          showTableContent={showTableContent}
          emptyListComponent={emptyListComponent}
          loading={loading}
        />
      </StyledTableContainer>
  )
}

const LoadMoreButton = ({onClick, columns, pageLoading}) => {
  return (
    <TableRow>
      <TableCell colSpan={columns} align={'center'}>
        {pageLoading ?
          <CircularProgress />
          :
          <Button
            name={'loadMoreButton'}
            variant={'text'}
            onClick={onClick}
          >
            load more
          </Button>
        }
      </TableCell>
    </TableRow>
  )
}

export default PagedTableList
