import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { DialogV2, LoadingButton } from "saga-library/src";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { ImmunizationType } from "../../../../types/Immunization";
import { useHistoryContext } from '../../providers/HistoryProvider'
import * as yup from 'yup'
import { ImmunizationsForm } from './ImmunizationsForm'
import { useReadQuery } from '@apollo/client'

const FORM_NAME = "edit_immunization_form"

export type ImmunizationFormType = {
  immunizations: ImmunizationType[]
}

const immunizationDefaults: ImmunizationFormType = {
  immunizations: [],
}

const immunizationRecordSchema = yup.object({
  vaccination: yup.string().required('Vaccination is required'),
  date: yup.date().nullable(),
  vaccinationId: yup.string().nullable(),
});

const schema = yup.object({
  immunizations: yup.array().of(immunizationRecordSchema),
});

const ImmunizationDialog = ({ open, setOpen }) => {
  const { parseImmunizationQueryResults, immunizationQueryRef } = useHistoryContext()
  const { enableNavigationPrompt } = usePrompt()
  const { data } = useReadQuery(immunizationQueryRef!)
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean | string>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const formMethods = useForm<ImmunizationFormType>({
    defaultValues: immunizationDefaults,
    schema: schema,
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset,
  } = formMethods

  useEffect(() => {
    if (open) {
      const immunizationData = parseImmunizationQueryResults(data)
      if (immunizationData.length === 0) {
        reset({ immunizations: [
            {
              id: crypto.randomUUID(),
              name: '',
              date: null,
            }
          ]
        })
      } else {
        reset({ immunizations: immunizationData })
      }
    }
  }, [open, reset, data])

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        setOpen(null)
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, !!openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onSave = () => {
    console.log('TODO: SAGA-3097 IMPLEMENT THE SAVE')
  }

  const onCancel = () => {
    const immunizationData = parseImmunizationQueryResults(data)
    if (!!Object.keys(dirtyFields).length || (isEditing && immunizationData.length > 0)) {
      setOpenNavigationPrompt(true)
    } else {
      setOpen(null)
    }
  }

  return <DialogV2
    dataTestId={'edit-immunization-dialog'}
    title={"Immunization history"}
    open={!!open}
    onClose={onCancel}
    primaryAction={()=> null}
    overridePrimaryComponent={
      <LoadingButton
        name={'saveImmunization'}
        form={FORM_NAME}
        dataTestId={"edit-immunizations-dialog-primary-button"}
        loading={isSubmitting}
        onClick={onSave}
        variant={'contained'}
      >
        Save
      </LoadingButton>
    }
  >
    <FormProvider {...formMethods}>
      <ImmunizationsForm
        onSubmit={()=> {}}
        setIsEditing={setIsEditing}
      />
    </FormProvider>
  </DialogV2>
}

export default ImmunizationDialog