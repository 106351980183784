import { useMutation } from '@apollo/client'
import { LOG_PRINT_OPERATIONS } from '../graphql-definitions/tenant/ActivityTracking'
import { useParams } from 'react-router-dom'

export const useActivityTracking = () => {
  const { tenant_id } = useParams()
  const [logPrintOperation] = useMutation(LOG_PRINT_OPERATIONS)

  const logPrintActivity = (objectType: UserActivityObjectType, objectId: string) => {
    logPrintOperation({
      variables: {
        tenantId: tenant_id,
        objectType,
        objectId
      }
    })
  }

  return {
    logPrintActivity
  }
}

export enum UserActivityObjectType{
  PATIENT= 'PATIENT',
  CLAIM = 'CLAIM',
  PRACTITIONER = 'PRACTITIONER',
  REFERRALPRACTITIONER = 'REFERRALPRACTITIONER',
}