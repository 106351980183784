import { PatientSectionHeader } from "../PatientSectionHeader";
import React, { useCallback, useEffect, useState } from "react";
import { ConfirmationDialog, PrintIconButton, RemoveButton, Section } from "saga-library/src";
import { FormType } from "../../../../types/patients";
import { useNavigate, useParams } from "react-router-dom";
import { useAccountContext } from "../../../../providers/AccountContext";
import { FormsForm } from "./components/FormsForm";
import { FormsStateProvider } from "./components/FormsStateProvider";
import { HasPermissionTemplate } from "../../../../components/HasPermissionTemplate";
import { Permission, PermissionType } from "../../../../types/settings/Permission";
import { formsFormDefaultValues } from "./components/FormsFormDefaultValues";
import { schema } from "./components/FormsFormValidationSchema";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { usePatientFormContext } from "../../providers/PatientFormProvider";
import { useReadQuery } from "@apollo/client";
import _get from "lodash/get";
import { flushSync } from "react-dom";


const FORM_NAME = "patient_form_form"

export const FormsEditor = () => {
  const navigate = useNavigate()
  const { enableNavigationPrompt, clearNavigationPrompt } = usePrompt()
  const { patient_id, tenant_id, form_id } = useParams()
  const { createForm, updateForm, deleteForm, patientFormQueryRef } = usePatientFormContext()
  const [form, setForm] = React.useState<FormType|null>(null)
  const { buildTenantRoute, userHasPermission } = useAccountContext()
  const { data } = useReadQuery(patientFormQueryRef!)

  const hasChartPermissions = (tenant_id && userHasPermission(tenant_id, PermissionType.Chart, Permission.READWRITE))

  const formMethods = useForm<FormType>({
    defaultValues: formsFormDefaultValues,
    schema: schema,
  })

  const {
    reset,
    formState: {
      dirtyFields,
      isSubmitting
    }
  } = formMethods

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length])

  useEffect(() => {
    if(form_id){
      setForm([..._get(data, 'tenant.patient.form.list', []) as Array<FormType>].find(form => form.id === form_id && !form.isLinkedDocument) || null)
    } else {
      setForm(null)
    }
  }, [form_id, patientFormQueryRef]);

  const onSuccess = useCallback((formResult) => {
    flushSync(() => {
      clearNavigationPrompt(FORM_NAME)
    })
    navigate(buildTenantRoute(`patients/p/${patient_id}/forms/f/${formResult.id}`, tenant_id), {replace: true})
  },[form_id, form, patient_id])

  const onSubmitCreate = async (data) => {
    await createForm(data, onSuccess)
  }

  const onSubmitEdit = async (data) => {
    await updateForm(data, () => reset({},{keepValues: true}))
  }

  const onDelete = async () => {
    await deleteForm(form_id!, () => {
      flushSync(() => {
        clearNavigationPrompt(FORM_NAME)
      })
      navigate(buildTenantRoute(`patients/p/${patient_id}/forms`, tenant_id))
    })
  }

  const getHeaderActions = () => {
    return (
      <>
        {
          form && <DeleteFormConfirmation onDelete={onDelete} />
        }
        <PrintIconButton onClick={()=>{console.log("todo SAGA-2557")}} dataTestId={"form"} />
      </>
    )
  }

  const onBack = () => {
    navigate(buildTenantRoute(`patients/p/${patient_id}/forms`, tenant_id), {replace: true})
  }

  return <Section.Column
    sx={{ flex: "1 1 100%" }}
    header={
      <PatientSectionHeader
        dataTestId={'forms-editor'}
        sectionLabel={form ? form.name : "New form"}
        showBackButton={true}
        onBack={onBack}
        formName={FORM_NAME}
        showSave={!!hasChartPermissions as boolean}
        submitting={isSubmitting}
        actions={getHeaderActions()}
      />
    } >
    <FormProvider {...formMethods}>
      <FormsStateProvider>
        <FormsForm
          onSubmit={form ? onSubmitEdit : onSubmitCreate}
          formName={FORM_NAME}
          formDisabled={!hasChartPermissions}
          form={form}
        />
      </FormsStateProvider>
    </FormProvider>
  </Section.Column>
};

export const DeleteFormConfirmation = ({onDelete}) => {
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)

  return (
    <HasPermissionTemplate requiredType={PermissionType.Chart} requiredPermission={Permission.READWRITE}>
      <RemoveButton onClick={() => setOpenDeleteConfirmation(true)} dataTestId={'form'} />
      <ConfirmationDialog
        open={openDeleteConfirmation}
        title={'Delete form?'}
        message={"This action cannot be undone."}
        primaryAction={() => {
          setOpenDeleteConfirmation(false)
          onDelete()
        }}
        primaryLabel={'delete'}
        onClose={() => setOpenDeleteConfirmation(false)}
        dataTestId={'deleteForm-dialog'}
      />
    </HasPermissionTemplate>
  )
}