import { FileType } from "./tasks";
import { Audit } from './Audit'

export enum DocumentCategory {
  CHART_NOTE = "CHART_NOTE",
  FORM = "FORM",
  INVESTIGATION = "INVESTIGATION",
  LAB_RESULT = "LAB_RESULT",
  LETTER = "LETTER",
  OTHER_DOCUMENT = "OTHER_DOCUMENT",
}

export enum PagesToInclude {
  ALL= "All",
  CURRENT = "Current",
  RANGE = "Range"
}

export interface LinkDocumentInput {
  fileId: string
  patientId: string
  category: DocumentCategory | null
  description: string
  documentDate: string
}

export interface UpdateLinkedDocumentInput {
  fileId: string
  patientId: string | null
  category: DocumentCategory | null
  description: string
  documentDate: string
}

export interface LinkDocumentDetails extends LinkDocumentInput {
  pagesToInclude?: PagesToInclude
  pageRange?: number[][]
}

export interface LinkedDocumentType {
  id: string,
  fileId: string,
  category: DocumentCategory,
  documentDate: moment.Moment,
  description?: string,
  file: FileType,
  audit?: Audit
}