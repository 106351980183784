import { PrescriptionDosageLink, PrescriptionStatus, PrescriptionUsage } from "../../../../../types/Prescription";

export const prescriptionFormDefaultValues = (): any => {
  return {
    practitionerId: '',
    startDate: new Date(),
    historical: false,
    status: PrescriptionStatus.DRAFT,
    dosages: [
      {
        id: 'new_dosage_0',
        dosageRange: '',
        dosageUnits: '',
        frequency: '',
        durationAmount: '',
        durationUnits: '',
        prn: null,
        version: "0",
        dosageOrder: 0
      }
    ],
    quantityAmount: '',
    quantityUnits: '',
    dosageForm: '',
    refills: '',
    route: '',
    site: '',
    indication: '',
    additionalInstructions: '',
    substitutions: 'GENERIC',
    link: PrescriptionDosageLink.THEN,
    usage: PrescriptionUsage.SHORT_TERM,
    drug: {'name': '', 'code': null, 'codeType': null},
    quantityCalculated: null
  }
}
