import { gql } from "@apollo/client";

export const ADD_NEW_APPOINTMENT_STATE = gql`
  mutation CreateAppointmentState($tenantId: ID!, $appointmentStateInput: AppointmentStateInput!) {
    tenant(id: $tenantId) {
      schedule {
        state {
          create(newAppointmentState: $appointmentStateInput)
        }
      }
    }
  }
  `


export const LIST_APPOINTMENT_STATES = gql`
  query ListAppointmentStates($tenantId: ID!) {
    tenant(id: $tenantId) {
      schedule {
        state {
          listStates {
            id
            name
            color
            canEnterExamRoom
            blocksSchedule
            isDefault
            version
          }
        }
      }
    }
  }
 `

export const UPDATE_APPOINTMENT_STATE_SORT = gql`
  mutation UpdateAppointmentStateSort($tenantId: ID!, $appointmentStateId: ID!, $index: Short!) {
    tenant(id: $tenantId) {
      schedule {
        state {
          updateSort(id: $appointmentStateId, index: $index)
        }
      }
    }
  }
`


export const GET_APPOINTMENT_STATE = gql`
  query GetAppointmentState($tenantId: ID!, $appointmentStateId: ID!) {
    tenant(id: $tenantId) {
      schedule {
        state {
          state(id: $appointmentStateId) {
            id
            name
            color
            canEnterExamRoom
            blocksSchedule
            isDefault
            isDeleted
            version
          }
        }
      }
    }
  }
`

export const UPDATE_APPOINTMENT_STATE = gql`
  mutation UpdateAppointmentState($tenantId: ID!, $appointmentStateId: ID!, $appointmentStateInput: AppointmentStateInput!) {
    tenant(id: $tenantId) {
      schedule {
        state {
          update(id: $appointmentStateId, updatedAppointmentState: $appointmentStateInput) {
            id
            name
            color
            canEnterExamRoom
            blocksSchedule
            isDefault
            isDeleted
            version
          }
        }
      }
    }
  }
`

export const DELETE_APPOINTMENT_STATE = gql`
  mutation DeleteAppointmentState($tenantId: ID!, $appointmentStateId: ID!) {
    tenant(id: $tenantId) {
      schedule {
        state {
          delete(id: $appointmentStateId)
        }
      }
    }
  }
`