import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { DialogV2, LoadingButton } from "saga-library/src";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { FamilyHistoryType } from "../../../../types/FamilyHistory";
import { useHistoryContext } from '../../providers/HistoryProvider'
import * as yup from 'yup'
import { FamilyHistoryForm } from './FamilyHistoryForm'
import { useReadQuery } from '@apollo/client'

const FORM_NAME = "edit_family_history_form"

type FamilyHistoryFormType = {
  familyHistory: FamilyHistoryType[]
}

const familyHistoryDefaults: FamilyHistoryFormType = {
  familyHistory: [],
}

const familyHistoryRecordSchema = yup.object({
  condition: yup.string().required('Condition is required'),
  relationship: yup.string().required('Relationship is required'),
  notes: yup.string(),
  diagnosticCodeId: yup.string(),
});

const schema = yup.object({
  familyHistory: yup.array().of(familyHistoryRecordSchema),
});

const FamilyHistoryDialog = ({ open, setOpen }) => {
  const { parseFamilyHistoryQueryResults, familyHistoryQueryRef } = useHistoryContext()
  const { enableNavigationPrompt } = usePrompt()
  const { data } = useReadQuery(familyHistoryQueryRef!)
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean | string>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const formMethods = useForm<FamilyHistoryFormType>({
    defaultValues: familyHistoryDefaults,
    schema: schema,
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset,
  } = formMethods

  useEffect(() => {
    if (open) {
      const familyHistoryData = parseFamilyHistoryQueryResults(data)
      if (familyHistoryData.length === 0) {
        reset({ familyHistory: [
            {
              id: crypto.randomUUID(),
              condition: '',
              relationship: '',
              notes: '',
            }
          ]
        })
      } else {
        reset({ familyHistory: familyHistoryData })
      }
    }
  }, [open, reset, data])

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        setOpen(null)
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, !!openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onSave = () => {
    console.log('TODO: SAGA-3085 IMPLEMENT THE SAVE')
  }

  const onCancel = () => {
    const familyHistoryData = parseFamilyHistoryQueryResults(data)
    if (!!Object.keys(dirtyFields).length || (isEditing && familyHistoryData.length > 0)) {
      setOpenNavigationPrompt(true)
    } else {
      setOpen(null)
    }
  }

  return <DialogV2
    dataTestId={'edit-family-history-dialog'}
    title={"Family history"}
    open={!!open}
    onClose={onCancel}
    primaryAction={()=> null}
    overridePrimaryComponent={
      <LoadingButton
        name={'saveFamilyHistory'}
        form={FORM_NAME}
        dataTestId={"edit-family-history-dialog-primary-button"}
        loading={isSubmitting}
        onClick={onSave}
        variant={'contained'}
      >
        Save
      </LoadingButton>
    }
  >
    <FormProvider {...formMethods}>
      <FamilyHistoryForm
        onSubmit={()=> {}}
        setIsEditing={setIsEditing}
      />
    </FormProvider>
  </DialogV2>
}

export default FamilyHistoryDialog