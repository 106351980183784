import React from 'react'
import { TextField } from 'saga-library/src'
import { PermissionType } from '../../../types/settings/Permission'
import { PermissionForm } from '../../../components/PermissionForm'
import { FormRow } from '../../../components/FormRow'
import { ReportFormColumns } from './ReportFormColumns'
import { ReportFormCriteria } from './ReportFormCriteria'
import { ReportFormParameters } from './ReportFormParameters'

type ReportFormType = {
  formName: string
  permissionType: PermissionType
  onSubmit: () => void
  disabled?: boolean
  dataTestId: string
}

export const ReportForm = ({
  formName,
  permissionType,
  onSubmit,
  disabled,
  dataTestId
}: ReportFormType) => {
  return (
    <PermissionForm
      name={`${formName}_report_form`}
      onSubmit={onSubmit}
      id={formName}
      requiredPermissionType={permissionType}
      formDisabled={disabled}
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        gap: "8px",
        overflow: "hidden"
      }}
    >
      <FormRow>
        <TextField
          name={"name"}
          label={"Name"}
          sx={{ flex: "0 0 auto", width: "400px" }}
          dataTestId={`${dataTestId}-name`}
        />
        <TextField
          name={"description"}
          label={"Description"}
          fullWidth={true}
          dataTestId={`${dataTestId}-description`}
        />
      </FormRow>
      <FormRow sx={{ flex: "1 1 auto", overflow: "hidden" }}>
        <ReportFormColumns name={"columns"} disabled={disabled} />
        <ReportFormCriteria />
        <ReportFormParameters name={"parameters"} disabled={disabled} />
      </FormRow>
    </PermissionForm>
  )
}