import React, { useMemo } from "react";
import { TableList, TableListHeaderConfig, Section } from "saga-library/src";
import { PatientAppointmentRow } from "../PatientAppointmentRow";
import { useAppointmentContext } from "../../providers/AppointmentProvider";
import { useReadQuery } from "@apollo/client";
import { ChartPanelHOC } from "../../util/ChartPanelHOC";

const columns: TableListHeaderConfig[] = [
  { name: '' },
  { name: 'Date' },
  { name: 'Time' },
  { name: 'Practitioner' },
  { name: 'Type' },
  { name: 'State' },
  { name: 'Reason for visit' },
  { name: 'Notes' },
  { name: 'Last updated' },
  { name: 'Updated by' }
]

const SectionTitle = "Appointments"


export const PatientAppointments = ChartPanelHOC(SectionTitle, () => {
  const { appointmentQueryRef } = useAppointmentContext()

  return appointmentQueryRef && <PatientAppointmentsPanel/>
})


export const PatientAppointmentsPanel = () => {
  const { appointmentQueryRef, getAppointmentQueryResults } = useAppointmentContext()
  const { data } = useReadQuery(appointmentQueryRef)
  const appointments = useMemo(() => getAppointmentQueryResults(data), [data, getAppointmentQueryResults])

  return (
    <Section.Column headerLabel={SectionTitle}>
      <TableList
        columns={columns}
        rows={[
          ...appointments.map((appointment) => PatientAppointmentRow(appointment))
        ]}
        expandable={true}
        expandedTableName={"APPOINTMENT HISTORY"}
        dataTestId={"patient-appointments"}
      />
    </Section.Column>
  )
}

