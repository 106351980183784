import React, { useState } from 'react'
import {
  StyledTooltip,
  TableListCellConfig,
  TableListRowConfig,
  Typography
} from 'saga-library/src'
import { useAccountContext } from '../../../../../providers/AccountContext'
import { Box } from '@mui/material'
import { NavigateFunction, useParams } from 'react-router-dom'
import { useFormattedDate } from '../../../../../hooks/FormattedDate'
import { getLabAndInvestigationLabel } from '../LabAndInvestigationsUtil'
import DescriptionFilledIcon from '@mui/icons-material/Description'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { AbLabResult, AbLabResultObservationResult } from '../../../../../types/patients'
import { LabAndInvestigationDetails } from './LabAndInvestigationDetails'
import { LabAndInvestigationEditorContent } from '../LabAndInvestigationEditor'
import { FileType } from '../../../../../types/tasks'
import { LabAndInvestigationPrintButton } from '../LabAndInvestigationsPrintButton'

interface LabHeaderProps {
  file: FileType | null,
  lab: AbLabResult,
  index: number,
  patientId: string | undefined,
  printComponentRef: React.RefObject<any>,
  onCompletePrint: () => void,
  onBeforePrint: () => void,
}

export const LabAndInvestigationRow = (
  lab: AbLabResult,
  index: number,
  navigate: NavigateFunction,
  onRowClick: () => void,
  onGraphClick: (result) => void,
  formMethods: any,
  file: FileType | null,
  files: FileType[],
  setFile: (file: FileType | null) => void,
  resetFormState: (lab: AbLabResult) => void,
  printComponentRef: React.RefObject<any>,
  labAndInvestigation: AbLabResult | null,
  setLabAndInvestigation: (lab: AbLabResult | null) => void,
  onBeforeGetContentResolve: React.MutableRefObject<any>,
  getLabAndInvestigation: any,
  loading: boolean
): TableListRowConfig => {
  const { buildTenantRoute } = useAccountContext()
  const { tenant_id, patient_id } = useParams()


  if (lab) {
    const onRowClicked = () => {
      onRowClick()
      navigate(buildTenantRoute(`patients/p/${patient_id}/labs-investigations/${lab.isLinkedDocument ? 'd' : 'l'}/${lab.id}`, tenant_id))
    }

    return ({
      rowData: FormatRowData(
        lab, index, onRowClicked, onGraphClick,
        patient_id, file, files, setFile, formMethods, resetFormState,
        printComponentRef, labAndInvestigation, setLabAndInvestigation,
        onBeforeGetContentResolve, getLabAndInvestigation, loading
      ),
      key: `${lab.isLinkedDocument ? 'd' : 'l'}-${lab.id}`
    })
  }

  return { rowData: [], key: '' }
}

interface PrintComponentProps {
  lab: AbLabResult | null
  printComponentRef: React.RefObject<any>
  loading: boolean
  files: FileType[]
  file: FileType | null
  setFile: (file: FileType | null) => void
  isSubmitting?: boolean
  formMethods: any
  resetFormState: (lab: AbLabResult) => void
}

const PrintComponent = ({
  lab,
  printComponentRef,
  loading,
  files,
  file,
  setFile,
  isSubmitting,
  formMethods,
  resetFormState
}
: PrintComponentProps) => {
  if (!lab) {
    return null
  }

  return (
    <Box
      sx={{ display: 'none', displayPrint: 'block' }}
      ref={printComponentRef}
    >
      <LabAndInvestigationEditorContent
        files={files}
        file={file}
        setFile={setFile}
        labAndInvestigation={lab}
        isSubmitting={isSubmitting}
        formMethods={formMethods}
        resetFormState={resetFormState}
        loading={loading}
      />
    </Box>
  )
}

function FormatRowData(
  lab: AbLabResult,
  index: number,
  onClick: () => void,
  onGraphClick: (result: AbLabResultObservationResult) => void,
  patientId: string | undefined,
  file: FileType | null,
  files: FileType[],
  setFile: (file: FileType | null) => void,
  formMethods: any,
  resetFormState: (lab: AbLabResult) => void,
  printComponentRef: React.RefObject<any>,
  labAndInvestigation: AbLabResult | null,
  setLabAndInvestigation: (lab: AbLabResult | null) => void,
  onBeforeGetContentResolve: React.MutableRefObject<any>,
  getLabAndInvestigation: any,
  loading: boolean
): TableListCellConfig[] {
  const { tenant_id, patient_id } = useParams()

  const handleOnBeforeGetContent = async () => {
    return new Promise<void>((resolve, reject) => {
      getLabAndInvestigation({
        variables: {
          tenantId: tenant_id,
          patientId: patient_id,
          id: lab.id
        }
      }).then(() => {
          onBeforeGetContentResolve.current = resolve
      }).catch((error) => {
        reject(error)
      })
    })
  }

  const handleOnCompletePrint = () => {
    setLabAndInvestigation(null)
  }

  return [
    {
      sx: {
        borderBottom: 'none',
        padding: 0
      },
      children: [
        <>
          <PrintComponent
            printComponentRef={printComponentRef}
            lab={labAndInvestigation}
            loading={loading}
            files={files}
            file={file}
            setFile={setFile}
            formMethods={formMethods}
            resetFormState={resetFormState}
          />
          <LabAndInvestigationDetails
            lab={lab}
            segments={lab.abLabResultObservationResults}
            dataTestId={`labsInvestigationsRow-${index}-result`}
            onGraphClick={onGraphClick}
            key={lab.id}
            headerOnClick={onClick}
            header={
              <LabHeader
                file={file}
                lab={lab}
                index={index}
                patientId={patientId}
                printComponentRef={printComponentRef}
                onCompletePrint={handleOnCompletePrint}
                onBeforePrint={handleOnBeforeGetContent}
              />
            }
            isRow={true}
          />
        </>
      ]
    }
  ]
}

const LabHeader = ({
  file,
  lab,
  index,
  patientId,
  printComponentRef,
  onCompletePrint,
  onBeforePrint,
}: LabHeaderProps) => {
  const [openNotes, setOpenNotes] = useState<boolean>(false)
  const formattedDate = useFormattedDate(lab.date, true)
  const hasNotes = !!lab.notes && (lab.notes.trim() !== '')

  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-evenly'}
      >
        <Typography
          variant={'h5'}
          dataTestId={`labsInvestigationsRow-${index}-label`}
        >
          {getLabAndInvestigationLabel(lab)}
        </Typography>
        <Typography
          variant={'body2'}
          dataTestId={`labsInvestigationsRow-${index}-date`}
        >
          {formattedDate}
        </Typography>
      </Box>
      <Box
        display={'inline-flex'}
        alignItems={'center'}
        color={(theme) => theme.palette.greys.medium}
      >
        {hasNotes && (
          <StyledTooltip
            arrow
            open={openNotes}
            placement={'bottom'}
            onOpen={() => setOpenNotes(true)}
            onClose={() => setOpenNotes(false)}
            color={'white'}
            title={
              <Typography variant={'body1'}>{lab.notes}</Typography>
            }
          >
            <Box
              display={'inline-flex'}
              alignItems={'center'}
              m={1}
              sx={{
                '&:hover': {
                  cursor: 'default'
                }
              }}
              onClick={(e) => {
                e.stopPropagation()
              }}
              data-testid={`labsInvestigationsRow-${index}-notes-icon`}
            >
              {openNotes
                ? <DescriptionFilledIcon />
                : <DescriptionOutlinedIcon />
              }
            </Box>
          </StyledTooltip>
        )}
        <Box
          onClick={(e) => {
            e.stopPropagation()
          }}
          data-testid={`labsInvestigationsRow-${index}-print-icon`}
        >
          <LabAndInvestigationPrintButton
            labAndInvestigation={lab}
            file={file}
            printComponentRef={printComponentRef}
            patientId={patientId}
            onComplete={onCompletePrint}
            onBeforeGetContent={onBeforePrint}
          />
        </Box>
      </Box>
    </>
  )
}