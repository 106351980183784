import React from 'react'
import { Form, DataGrid } from 'saga-library/src'
import { DATE_FORMAT } from '../../../../utils/SettingsConstants'
import { useAccountContext } from '../../../../providers/AccountContext'
import { borderlessFieldsSx, ExtendedGridColDef } from 'saga-library/src/components/DataGrid/DataGrid'
import { usePatientProfileContext } from '../../providers/PatientProfileProvider'
import {
  formatEnumToSentenceCase,
} from '../../../../types/FamilyHistory'
import { ExtendedFamilyRelationshipSearch } from './ExtendedFamilyRelationshipSearch'
import {
  ConditionAndProcedureDescriptionGridSearch
} from '../../../../components/SearchControls/Patient/ConditionAndProcedureDescriptionGridSearch'

export const FAMILY_HISTORY_FORM_ID = 'family-history-form'

interface FamilyHistoryFormProps {
  onSubmit: () => void
  setIsEditing: (isEditing: boolean) => void
}

export const FamilyHistoryForm = ({
  onSubmit,
  setIsEditing
} : FamilyHistoryFormProps) => {
  const { profileData } = usePatientProfileContext()

  const familyHistoryColumns: ExtendedGridColDef[]  = [
    {
      field: 'condition',
      type: 'string',
      headerName: 'Condition',
      editable: true,
      required: true,
      valueGetter: (value: any) => value && value.description ? value.description : value,
      renderCell: params => (params?.value && params?.value.description) ? params?.value.description : params?.value,
      renderEditCell: (params) => (
        <ConditionAndProcedureDescriptionGridSearch
          params={params}
          dataTestId={`${FAMILY_HISTORY_FORM_ID}-condition-search`}
          name={'condition-search'}
          placeholderText={'Condition required'}
          sx={borderlessFieldsSx}
        />
      )
    },
    {
      field: 'relationship',
      headerName: `Relationship to ${profileData.firstName}`,
      editable: true,
      required: true,
      valueGetter: (value: any) => value?.label ? formatEnumToSentenceCase(value.label) : formatEnumToSentenceCase(value),
      renderEditCell: (params) => (
        <ExtendedFamilyRelationshipSearch
          dataTestId={`${FAMILY_HISTORY_FORM_ID}-relationship-search`}
          name={'relationship-search'}
          params={params}
          placeholderText={'Relationship required'}
          sx={borderlessFieldsSx}
        />
      )
    },
    {
      field: 'notes',
      headerName: 'Notes',
      editable: true
    }
  ]

  return (
    <Form onSubmit={onSubmit} id={FAMILY_HISTORY_FORM_ID}>
      <DataGrid
        name={'familyHistory'}
        initialColumns={familyHistoryColumns}
        dataTestId={'familyHistory'}
        addLabel={'condition'}
        height={'662px'}
        emptyListMessage={'Family history appears here once added'}
        disableAdd={(rows) => !rows.every(row => row.condition && row.relationship)}
        setIsEditing={setIsEditing}
      />
    </Form>
  )
}