import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'saga-library/src/components/Form'
import { EncounterNote, EncounterNoteTemplateFieldType } from '../../../../types/patients'
import { encounterNotesFormDefaultValues } from './EncounterNotesFormDefaultValues'
import { schema } from './EncounterNotesFormValidationSchema'
import { EncounterNotesForm } from './EncounterNotesForm'
import { DialogV2 } from 'saga-library/src'
import { usePrompt } from '../../../../providers/NavigationPrompt'
import { cleanEncounterNoteInput, convertEncounterNoteToInput } from './EncounterNoteUtil'
import { useEncounterNoteContext } from '../../providers/EncounterNoteProvider'
import PermissionButton from '../../../../components/PermissionButton'
import { Permission, PermissionType } from '../../../../types/settings/Permission'
import { useQuery } from '@apollo/client'
import { GET_ENCOUNTER_NOTE } from '../../../../graphql-definitions'
import _get from 'lodash/get'
import { useParams } from 'react-router-dom'
import { LoadingSpinner } from '../../../../components/LoadingScreen'
import { DeleteEncounterNoteConfirmation, EditEncounterNoteConfirmation, SignButton } from './EncounterNotesEditor'
import { SignedIcon } from '../ChartIcons'
import { useAccountContext } from '../../../../providers/AccountContext'

const FORM_NAME = 'encounter_notes_form_modal'

interface EncounterNotesModalProps {
  openDialog: boolean,
  selectedEncounterNote?: EncounterNote | null,
  appointment?: any,
  onClose: () => void
}

export const EncounterNotesModal = ({
  openDialog,
  selectedEncounterNote,
  appointment,
  onClose
}: EncounterNotesModalProps) => {
  const { userId } = useAccountContext()
  const { patient_id, tenant_id } = useParams()
  const { enableNavigationPrompt } = usePrompt()
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)
  const [isSigning, setIsSigning] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const { createEncounterNote, deleteEncounterNote, updateEncounterNote } = useEncounterNoteContext()

  const formMethods = useForm<any>({
    defaultValues: encounterNotesFormDefaultValues,
    schema: schema
  })

  const {
    formState: { dirtyFields, isSubmitting },
    handleSubmit,
    reset,
  } = formMethods

  const { data, loading } = useQuery(GET_ENCOUNTER_NOTE, {
    skip: !selectedEncounterNote || isDeleting,
    variables: {
      tenantId: tenant_id,
      patientId: patient_id,
      id: selectedEncounterNote?.id,
    },
    fetchPolicy: 'cache-and-network',
    onError: error => {
      console.error(JSON.stringify(error, null, 2))
    }
  })

  const encounterNote = _get(data, 'tenant.patient.encounterNote.get', null)
  const isSigned = !!encounterNote?.signOffUser?.id

  useEffect(() => {
    if (!encounterNote) {
      return
    }

    const encounterNoteInput = convertEncounterNoteToInput(encounterNote)

    reset(encounterNoteInput)
  }, [encounterNote])

  const handleClose = () => {
    onClose()

    let clearedTemplateFields = {}
    if (!!encounterNote?.template) {
      encounterNote.template.fields.forEach((field) => {
        switch (field.type) {
          case EncounterNoteTemplateFieldType.TEXTBOX:
            clearedTemplateFields[`textbox-${field.id}`] = null
            break
          default:
            console.warn("Unknown field type: ", field.type)
        }
      })
    }
    reset({ ...encounterNotesFormDefaultValues, ...clearedTemplateFields })
    setIsSigning(false)
    setIsDeleting(false)
  }

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
    }
    if (discard) {
      handleClose()
    }
  }

  useEffect(() => {
    if(openDialog) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [openDialog, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onSuccess = ()=> {
    enableNavigationPrompt(false, FORM_NAME)
    handleClose()
  }

  const onSubmitCreate = handleSubmit(async (data) => {
    const encounterNoteData = cleanEncounterNoteInput(data)

    await createEncounterNote(encounterNoteData, onSuccess)
  })

  const onSubmitEdit = handleSubmit(async (data) => {
    const encounterNoteData = cleanEncounterNoteInput(data)

    await updateEncounterNote(encounterNoteData, onSuccess)
  })

  const onSign = handleSubmit(async (data) => {
    setIsSigning(true)

    const message = "Encounter note has been saved and signed"

    let encounterNoteData = cleanEncounterNoteInput(data)
    encounterNoteData.signOffUserId = userId

    if (!encounterNote) {
      await createEncounterNote(encounterNoteData, onSuccess, message)
    } else {
      await updateEncounterNote(encounterNoteData, onSuccess, message)
    }
  })

  const onUnsign = handleSubmit(async (data) => {
    let encounterNoteData = cleanEncounterNoteInput(data)
    encounterNoteData.signOffUserId = null

    await updateEncounterNote(encounterNoteData, () => {
      reset({}, { keepValues: true })
    }, "Encounter note can now be edited")
  })

  const onDelete = async (id) => {
    setIsDeleting(true)
    await deleteEncounterNote(id, onSuccess, () => setIsDeleting(false))
  }

  const onCancelDialog = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      handleClose()
    }
  }

  const view = () => {
    if (loading) {
      return <LoadingSpinner />
    }

    return (
      <EncounterNotesForm
        appointment={appointment}
        onSubmit={encounterNote ? onSubmitEdit : onSubmitCreate}
        formDisabled={isSigned}
        formName={FORM_NAME}
        encounterNote={encounterNote}
        dataTestId={'encounterNotesForm'}
      />
    )
  }
  
  return (
    <DialogV2
      onClose={onCancelDialog}
      maxWidth={'md'}
      open={openDialog}
      title={!!encounterNote || loading
        ? (encounterNote?.template?.name || encounterNote?.description)
        : "New encounter note"
      }
      scroll={'paper'}
      headerActions={encounterNote && !isSigned
        ? [<DeleteEncounterNoteConfirmation key={"delete-confirmation"} onDelete={() => onDelete(encounterNote.id)} />]
        : []
      }
      overridePrimaryComponent={
        !isSigned ?
          <PermissionButton
            name={"saveEncounterNote"}
            type={"submit"}
            form={FORM_NAME}
            requiredType={PermissionType.Chart}
            requiredPermission={Permission.READWRITE}
            loading={isSubmitting && !isSigning}
            disabled={isSigning}
            dataTestId={"encounterNotesModal-save-button"}
          >
            Save
          </PermissionButton>
        : <EditEncounterNoteConfirmation onEdit={onUnsign} isSubmitting={isSubmitting} />
      }
      overrideSecondaryComponent={
        !isSigned &&
          <SignButton
            onSign={onSign}
            isSubmitting={isSubmitting}
            isSigning={isSigning}
          />
      }
      dataTestId={"encounterNotesModal"}
    >
      <FormProvider {...formMethods}>
        {view()}
      </FormProvider>
    </DialogV2>
  )
}