import React from 'react'
import { Box, useTheme } from "@mui/material";
import { Typography } from "saga-library/src";
import { useFormattedDate } from "../../../hooks/FormattedDate";
import { useFormContext } from "saga-library/src/components/Form";

export const Form = () => {
  const { getValues } = useFormContext()
  const theme = useTheme()
  return (
    <Box sx={{display:'flex', flexDirection: 'column', gap: '24px'}} >
      <Box>
        <Typography variant={'body2'} color={theme.palette.greys.medium}>
          Creator
        </Typography>
        <Typography variant={'body1'} fontWeight={'bold'}>
          {getValues('audit.creUserFirstName') + " " + getValues('audit.creUserLastName') || "Unknown"}
        </Typography>
      </Box>
      <Box>
        <Typography variant={'body2'} color={theme.palette.greys.medium}>
          Date and time created
        </Typography>
        <Typography variant={'body1'} fontWeight={'bold'}>
          {useFormattedDate(getValues('audit.crePit'), true)}
        </Typography>
      </Box>
      <Box>
        <Typography variant={'body2'} color={theme.palette.greys.medium}>
          Action
        </Typography>
        <Typography variant={'body1'} fontWeight={'bold'}>
          {getValues('actionCode')}
        </Typography>
      </Box>
      <Box>
        <Typography variant={'body2'} color={theme.palette.greys.medium}>
          Date assessed
        </Typography>
        <Typography variant={'body1'} fontWeight={'bold'}>
          {useFormattedDate(getValues('assessmentPostPit'))}
        </Typography>
      </Box>
      <Box>
        <Typography variant={'body2'} color={theme.palette.greys.medium}>
          Date paid
        </Typography>
        <Typography variant={'body1'} fontWeight={'bold'}>
          {useFormattedDate(getValues('expectedPaymentDate'))}
        </Typography>
      </Box>
    </Box>
  )
}

export const AssessedClaimsInfo = {
  Form: Form,
}