import { gql } from "@apollo/client";

export const AB_LAB_RESULT_PRACTITIONER_IDENTIFIER_FRAGMENT = gql`
    fragment PractitionerIdentifierFragment on AbLabResultPractitionerIdentifier {         
        id
        identifier
        firstName
        lastName
    }
`

export const AB_LAB_RESULT_FRAGMENT = gql`
    fragment AbLabResultFragment on AbLabResult {
        id
        patientId
        labFileId
        gender {
            code
            display
        }
        province {
            code
            display
        }
        patientIdentifiers {
            label
        }
        patientName {
            firstName
            middleName
            lastName
        }
        dob
        practitioners {
            id
        }
        practitionersExceptionCleared
        isLinkedDocument
    }
`

export const GET_AB_LAB_RESULT_DASHBOARD_COUNTS = gql`
    query GetAbLabResultCounts($tenantId: ID!, $startDate: DateTime!, $endDate: DateTime!){
        tenant(id: $tenantId) {
            dashboard {
                abLabResultCounts(startDate: $startDate, endDate: $endDate) {
                    exceptionCount
                    processedCount,
                    lastCheck,
                    nextCheck,
                    lastProcessed
                }
            }
        }
    }
`

export const LIST_AB_LAB_RESULT_EXCEPTIONS = gql`
    ${AB_LAB_RESULT_FRAGMENT}
    query ListAbLabResultExceptions($tenantId: ID!){
        tenant(id: $tenantId) {
            dashboard {
                listAbLabResultExceptions {
                    abLabResult {
                        ...AbLabResultFragment
                    }
                    practitionerException
                    patientException
                }
            }
        }
    }
`


export const LINK_AB_LAB_RESULT = gql`
    ${AB_LAB_RESULT_FRAGMENT}
    mutation LinkAbLabResult($tenantId: ID!, $abLabResultInput: AbLabResultDtoInput!) {
        tenant(id: $tenantId) {
            dashboard {
                linkAbLabResult(input: $abLabResultInput) {
                    abLabResult {
                        ...AbLabResultFragment
                    }
                    practitionerException
                    patientException
                }
            }
        }
    }
`

export const LIST_AB_LAB_RESULT_EXCEPTION_PRACTITIONERS = gql`
    ${AB_LAB_RESULT_PRACTITIONER_IDENTIFIER_FRAGMENT}
    query ListAbLabResultExceptionPractitioners($tenantId: ID!, $abLabResultId: ID!) {
        tenant(id: $tenantId) {
            dashboard {
                listAbLabResultExceptionPractitioners(abLabResultId: $abLabResultId) {
                    ...PractitionerIdentifierFragment
                }
            }
        }
    }
`