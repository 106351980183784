import { gql } from "@apollo/client";
import {
  ASSESSED_AB_CLAIM_INFO_DETAILS_FRAGMENT,
  ASSESSED_CLAIM_DATA_FRAGMENT,
  CLAIM_BASE_DETAILS_FRAGMENT,
  CLAIM_OPTIONS_FRAGMENT
} from "./BillingFragments";

export const ACCEPT_ADJUSTMENT = gql`
    mutation AcceptAdjustment(
        $tenantId: ID!
        $claimId: ID!
    ) {
        tenant(id: $tenantId) {
            assessedAbClaim {
                acceptAdjustment(id: $claimId)
            }
        }
    }
`

export const CANCEL_WRITEOFF = gql`
    mutation CancelWriteOff(
        $tenantId: ID!
        $claimId: ID!
    ) {
        tenant(id: $tenantId) {
            assessedAbClaim {
                cancelWriteOff(id: $claimId)
            }
        }
    }
`

export const CANCEL_RESUBMISSION = gql`
    ${ASSESSED_CLAIM_DATA_FRAGMENT}
    mutation CancelResubmission(
        $tenantId: ID!
        $claimId: ID!
    ) {
        tenant(id: $tenantId) {
            assessedAbClaim {
                cancelResubmission(id: $claimId) {
                    ...AssessedClaimDataFragment
                }
            }
        }
    }
`

export const RESUBMIT_CLAIM = gql`
    ${ASSESSED_CLAIM_DATA_FRAGMENT}
    mutation ResubmitClaim($tenantId: ID!, $claimId: ID!, $claimData: AbClaimInput!, $actionCode: ABClaimActionCode!) {
        tenant(id: $tenantId) {
            assessedAbClaim {
                resubmitClaim(id: $claimId, input: $claimData, action: $actionCode) {
                    ...AssessedClaimDataFragment
                }
            }
        }
    }
`

export const RESUBMIT_CLAIMS = gql`
  ${CLAIM_BASE_DETAILS_FRAGMENT}
  ${CLAIM_OPTIONS_FRAGMENT}
  ${ASSESSED_AB_CLAIM_INFO_DETAILS_FRAGMENT}
    mutation ResubmitClaims($tenantId: ID!, $claimIds: [ID!]!, $claimData: AbBatchClaimInput!, $actionCode: ABClaimActionCode!) {
        tenant(id: $tenantId) {
            assessedAbClaim {
                resubmitClaims(ids: $claimIds, input: $claimData, action: $actionCode) {
                    id
                    version
                    claimIdentifier
                    claimStatus
                    acceptCode
                    assessmentOutcome
                    actionCode
                    ...AbClaimBaseDetails
                    ...AbClaimOptionsDetails
                    explanatoryCodes {
                        id
                        explanatoryCode
                        description
                    }
                    assessmentAmount
                    adjusted
                    ...AssessedAbClaimInfoDetails
                    problems {
                        severity
                        field
                        message
                    }
                }
            }
        }
    }
`


export const WRITEOFF_CLAIM = gql`
    mutation WriteOffClaim($tenantId: ID!, $id: ID!, $code: ABClaimAcceptCode!) {
        tenant(id: $tenantId) {
            assessedAbClaim {
                writeOffClaim(id: $id, code: $code)
            }
        }
    }
`


export const LIST_ASSESSEDCLAIMS = gql`
  ${ASSESSED_CLAIM_DATA_FRAGMENT}
  query assessedClaims($tenantId: ID!, $filters: AssessedAbClaimFilterInput, $sorter: AssessedAbClaimSorterInput!, $first: Int!, $after: String) {
    tenant(id: $tenantId) {
      assessedAbClaim {
        assessedClaims(filters: $filters, sorter: $sorter, first: $first, after: $after) {
          edges {
            cursor
            node {
              ...AssessedClaimDataFragment
              assessmentDate
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
`


export const ACCEPT_ADJUSTMENTS = gql`
  mutation AcceptAssessedAdjustments($tenantId: ID!, $ids: [ID!]!) {
    tenant(id: $tenantId) {
      assessedAbClaim {
        acceptAdjustments(ids: $ids) 
      }
    }
  }
`

export const WRITEOFF_CLAIMS = gql`
  mutation WriteOffClaims($tenantId: ID!, $ids: [ID!]!, $code: ABClaimAcceptCode!) {
    tenant(id: $tenantId) {
      assessedAbClaim {
        writeOffClaims(ids: $ids, code: $code)
      }
    }
  }
`
