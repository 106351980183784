import React from 'react'
import { Typography } from "saga-library/src";
import { Box, useTheme } from "@mui/material";
import { ResubmitOptionsList } from "./ResubmitOptionsList";
import { useFormattedDate } from "../../../../hooks/FormattedDate";

export const AcceptedClaimHeader = ({
    acceptedDate,
    onResubmitButtonClick,
    onReassessOptionClick,
    onDeleteOptionClick,
    onChangeOptionClick,
    expand,
  }) => {

  const theme = useTheme()

  return (
    <Box sx={{display: 'flex', flexDirection: 'row'}}>
      <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', mr: 1 }}>
        <Typography variant={'body1'} fontWeight={'bold'} color={theme.palette.greys.medium} sx={{m: 'auto'}}>
          ACCEPTED ON {useFormattedDate(acceptedDate)}
        </Typography>
      </Box>
      <ResubmitOptionsList
        onResubmitButtonClick={onResubmitButtonClick}
        onReassessOptionClick={onReassessOptionClick}
        onDeleteOptionClick={onDeleteOptionClick}
        onChangeOptionClick={onChangeOptionClick}
        expand={expand}
      />
    </Box>
  )
}