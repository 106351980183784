import React, { useEffect, useState } from "react";
import { ConfirmationDialog, DeleteButton } from "saga-library/src";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import _get from "lodash/get";
import omitDeep from 'omit-deep-lodash'
import { omit } from "lodash";
import { useForm, FormProvider } from "saga-library/src/components/Form";
import { locationDetailsDefaults, LocationDetailsType } from "./LocationNew";
import { schema } from "./util/validation";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { LoadingSpinner } from "../../../components/LoadingScreen";
import { LocationForm } from "./LocationForm";
import { Location } from "../../../types";
import { Permission, PermissionType } from "../../../types/settings/Permission";
import { useLocationContext } from "../../../providers/LocationContextProvider";
import { ReadOnlyFormActionsMask } from "../../../components/ReadOnlyFormActionsMask";
import PermissionButton from "../../../components/PermissionButton";
import { DEACTIVATE_LOCATION, GET_LOCATION, UPDATE_LOCATION } from "../../../graphql-definitions";
import SettingsHeader from "../components/SettingsHeader";
import { SettingsSectionColumn } from "../components/SettingsSectionColumn";
import { usePrompt } from "../../../providers/NavigationPrompt";

const FORM_NAME = 'update_location_form'

export const LocationUpdate = () => {
  const navigate = useNavigate()
  const { tenant_id, location_id } = useParams()
  const [ title, setTitle ] = useState('')
  const [ openDeleteDialog, setOpenDeleteDialog ] = useState(false)
  const [ openDeleteErrorDialog, setOpenDeleteErrorDialog ] = useState(false)
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const { locations } = useLocationContext()
  const { enableNavigationPrompt } = usePrompt()

  const formMethods = useForm<LocationDetailsType>({
    defaultValues: locationDetailsDefaults,
    schema: schema,
  })

  const {
    reset,
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
  } = formMethods

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length])

  const {
    loading: initialLoading,
    data: initialData,
  } = useQuery(GET_LOCATION, {
    variables: {
      locationId: location_id,
      tenantId: tenant_id
    },
    onCompleted: (data) => {
      const location = _get(data,'tenant.location.get',null)
      if (location) {
        setValues(location)
      }
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert('Location couldn\'t be retrieved.')
    },
    fetchPolicy: 'cache-and-network'
  })

  const [updateLocation, { error: updateError, data: updateData }] =
    useMutation(UPDATE_LOCATION, {
      onCompleted: (updateData) => {
        const data = _get(updateData, 'tenant.location.update', null)
        if (data.id === location_id) {
          setValues(data)
        }
        showSuccessAlert(`${data.name} has been saved.`)
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert("Location couldn't be updated.")
      }
    })

  const [deactivateLocation] = useMutation(DEACTIVATE_LOCATION, {
    onCompleted: (data) => {
      showSuccessAlert(`${title} has been deleted.`)
      setOpenDeleteDialog(false)
      navigate(`../`)
    },
    onError: (error) => {
      showErrorAlert("Location couldn't be deleted.")
      console.error(JSON.stringify(error, null, 2))
    }
  })

  const setValues = (data) => {
    let cleanedData = omitDeep(
      omit(data, 'id'),
      '__typename',
      'street1'
    )
    cleanedData = {
      ...cleanedData,
      street: data.street1
    }
    reset(cleanedData)
    setTitle(data.name)
  }

  const SaveButton = () => {
    return <PermissionButton
      requiredType={PermissionType.Admin}
      requiredPermission={Permission.READONLY}
      name={"updateLocation"}
      onClick={onSubmit}
      dataTestId={'locationUpdate-save-button'}
      loading={isSubmitting}
      type={'submit'}
      form={FORM_NAME}
    >
      Save
    </PermissionButton>
  }

  const onSubmit = handleSubmit(async (data) => {
    const { street, ...rest } = data
    const location: Location = {
      ...rest,
      street1: street
    }

    await updateLocation({
      variables: {
        tenantId: tenant_id,
        input: location,
        id: location_id
      },
    })
  })

  const handleDeleteLocation = () => {
    deactivateLocation({
      variables: {
        tenantId: tenant_id,
        id: location_id,
      },
    })
  }

  const getHeader = () => {
    const location = locations.find(l => l.id === location_id)
    const hasSchedule = location?.scheduleIds ? location?.scheduleIds?.length > 0 : false
    if(initialData) {
      return <SettingsHeader
        title={title}
        onBack={() => navigate(`../`)}
        dataTestId={'locationUpdate'}
        actions={
          <ReadOnlyFormActionsMask requiredType={PermissionType.Admin} requiredPermission={Permission.READONLY}>
            { locations.length > 1 ? <DeleteButton
              dataTestId={'locationUpdate-settingsHeader-delete-button'}
              onClick={() => hasSchedule ? setOpenDeleteErrorDialog(true) : setOpenDeleteDialog(true)}
            /> : <></> }
            <SaveButton/>
          </ReadOnlyFormActionsMask>
        }
      />
    }
  }

  return (
    <FormProvider {...formMethods}>
      <SettingsSectionColumn header={getHeader()} >
        {initialLoading && (
          <LoadingSpinner />
        )}
        {openDeleteDialog && (
          <DeleteLocationDialog
            openDeleteDialog={openDeleteDialog}
            closeDeleteDialog={() => setOpenDeleteDialog(false)}
            handleDeleteLocation={handleDeleteLocation}
            />
        )}
        {openDeleteErrorDialog && (
          <DeleteLocationErrorDialog
            openDeleteErrorDialog={openDeleteErrorDialog}
            closeDeleteErrorDialog={() => setOpenDeleteErrorDialog(false)}
          />
        )}
        {(initialData || updateData) && (
          <LocationForm
            onSubmit={onSubmit}
            formName={FORM_NAME}
            dataTestId={'locationUpdate'}
          />
        )}
      </SettingsSectionColumn>
    </FormProvider>
  )
}

const DeleteLocationDialog = ({ openDeleteDialog, closeDeleteDialog, handleDeleteLocation }) => {
  return (
    <ConfirmationDialog
      open={openDeleteDialog}
      onClose={closeDeleteDialog}
      title={'Delete location?'}
      message={'This action cannot be undone.'}
      primaryAction={handleDeleteLocation}
      primaryLabel={'Delete'}
      dataTestId={'locationUpdate-deleteLocationDialog'}
    />
  )
}

const DeleteLocationErrorDialog = ({ openDeleteErrorDialog, closeDeleteErrorDialog }) => {
  return (
    <ConfirmationDialog
      open={openDeleteErrorDialog}
      title={'This location can\'t be deleted'}
      message={'This location contains schedules and cannot be deleted.'}
      primaryAction={closeDeleteErrorDialog}
      dataTestId={'locationUpdate-deleteLocationErrorDialog'}
    />
  )
}