import React, { useCallback } from 'react'
import { PatientSearchResult } from "../../../types/patients";
import moment from "moment-timezone";
import { EntryTitle, IdentifierRow, ResultOptionsWrapper } from "saga-library/src";
import { patientDisplayName } from "saga-library/src/util";
import { useTenantContext } from "../../../providers/TenantContextProvider";
import Box from "@mui/material/Box";
import { DATE_FORMAT } from "../../../utils/SettingsConstants";
import { useAccountContext } from "../../../providers/AccountContext";
import { Typography, useTheme } from '@mui/material'
import { useFormattedDate } from '../../../hooks/FormattedDate'
import { Tag } from '../../../apps/patients/components/Tag'

interface PatientResultProps {
  patient: PatientSearchResult
  searchText: string
  openInTab?: (patient: PatientSearchResult) => void
  dataTestId?: string
}

export const PatientResult = ({
  patient,
  searchText,
  openInTab,
  dataTestId
}: PatientResultProps) => {
  const { formatIdentifier } = useTenantContext()
  const theme = useTheme()

  const {
    firstName,
    lastName,
    identifiers,
    dob,
    inactiveDate
  } = patient

  const formattedDob = useFormattedDate(dob)

  function formatIdentifierRows() {
    if (identifiers && identifiers.length > 0) {
      return identifiers.map((identifier) => {
        return (
          <IdentifierRow
            key={identifier.typeId}
            dataTestId={`${dataTestId}-id`}
            label={identifier.label}
            identifier={formatIdentifier(identifier.value, identifier.typeId)}
            searchText={searchText}
            disabled={!!inactiveDate}
          />
        )
      })
    }

    return <IdentifierRow
      dataTestId={`${dataTestId}-id`}
      label={"ID"}
      identifier={"None"}
      searchText={searchText}
      disabled={!!inactiveDate}
    />
  }

  return (
    <ResultOptionsWrapper
      dataTestId={dataTestId}
      key={dataTestId}
      options={{
        onOpenInNewTab: () => {
          if(openInTab) {
            openInTab(patient)
          }
        }
      }}
    >
      <EntryTitle
        dataTestId={`${dataTestId}-name`}
        title={patientDisplayName(firstName, lastName)}
        searchText={searchText}
        endAdornment={!inactiveDate ? undefined : <Tag label={'End dated'} color={theme.palette.greys.medium} fontWeight={400}/>}
        sx={ {
          mb: 0,
          color: !inactiveDate ? "default" : theme.palette.greys.medium
        }}
      />
      <Typography
        variant={'p2'}
        sx={{ mb: '2px' }}
        color={!inactiveDate ? undefined : theme.palette.greys.medium}
      >
        {formattedDob || 'No date of birth'}
      </Typography>
      <Box
        display={'flex'}
        flexDirection={'row'}
        flexWrap={'wrap'}
      >
        {formatIdentifierRows()}
      </Box>
    </ResultOptionsWrapper>
  )
}

export default PatientResult
