import { gql } from "@apollo/client";

export const GET_PHARMACY = gql`
  query GetPharmacy($tenantId: ID!, $pharmacyId: ID!) {
    tenant(id: $tenantId) {
      pharmacy {
        get(id: $pharmacyId) {
          id
          name
          street1
          street2
          street3
          city
          province
          postalCode
          phoneNumber
          faxNumber
          version
        }
      }
    }
  }
`

export const LIST_PHARMACIES = gql`
    query ListPharmacies($tenantId: ID!){
        tenant(id: $tenantId){
            pharmacy{
                list {
                    id
                    name
                    street1
                    street2
                    street3
                    city
                    province
                    postalCode
                    phoneNumber
                    faxNumber
                }
            }
        }
    }
`


export const UPDATE_PHARMACY = gql`
  mutation UpdatePharmacy($tenantId: ID!, $id: ID!, $input: PharmacyInput!) {
    tenant(id: $tenantId) {
      pharmacy {
        update(id: $id, input: $input) {
          id
          version
          name
          street1
          street2
          street3
          city
          province
          postalCode
          phoneNumber
          faxNumber
        }
      }
    }
  }
`


export const CREATE_PHARMACY = gql`
  mutation CreatePharmacy($tenantId: ID!, $input: PharmacyInput!) {
    tenant(id: $tenantId) {
      pharmacy {
        create(input: $input) {
          id
          name
          street1
          street2
          street3
          city
          province
          postalCode
          phoneNumber
          faxNumber
        }
      }
    }
  }
 `

export const DELETE_PHARMACY = gql`
  mutation DeletePharmacy($tenantId: ID!, $id: ID!) {
    tenant(id: $tenantId) {
      pharmacy {
        delete(id: $id)
      }
    }
  }
`
