import { Button, Section, Tabs, Typography, Form } from "saga-library/src";
import { ABClaimActionCode, PayToCode } from "../../../../../utils/ABClaimConstants";
import { useNavigate, useParams } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import { AbClaimMultiple, BatchClaimInputType } from "../../../../../types/billing/AbClaim/AbClaim";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useForm, FormProvider } from "saga-library/src/components/Form";
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { BatchUpdateAssessedClaimOptions } from "./BatchUpdateAssessedClaimOptions";
import { BatchUpdateAssessedBase } from "./BatchUpdateAssessedBase";
import {
  setAbClaimMultiple,
  setBatchAbClaimValues
} from "../batchUpdateUtil";
import { useApolloClient, useMutation } from "@apollo/client";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { useAccountContext } from "../../../../../providers/AccountContext";
import moment from "moment-timezone";
import { usePrompt } from "../../../../../providers/NavigationPrompt";
import {
  LIST_ASSESSEDCLAIMS,
  RESUBMIT_CLAIMS
} from "../../../../../graphql-definitions";
import FormColumn from "../../FormColumn";

const FORM_NAME = "batch_update_assessed_details"

export const assessedClaimDefaults = {
  version: "0",
  billingProfile: null,
  facility: null,
  functionalCenter: null,
  referralPractitioner: null,
  serviceCode: null,
  diagnosticCodes: [],
  serviceCodeFeeModifiers: [],
  patient: null,
  locationCode: undefined,
  text: undefined,
  serviceDate: moment().format(),
  calls: 1,
  encounter: 1,
  paperDocumentation: false,
  confidential: false,
  originalEncounterDate: null,
  originalFacility: null,
  originalLocationCode: "",
  newBornCode: null,
  guardianUli: null,
  guardianRegNumber: "",
  skillId: undefined,
  payToCode: PayToCode.BAPY,
  payToUli: null,
  interceptReason: "",
  patientFirstName: "",
  patientLastName: "",
  patientPHN: "",
  patientDOB: null,
  patientGenderId: ""
}

export const BatchUpdateAssessedDetails = ({ selectedClaims, closeBatchUpdate }) => {
  const { tenant_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const theme = useTheme()
  const formRef = useRef<HTMLFormElement>(null)
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()
  const client = useApolloClient()
  const { enableNavigationPrompt } = usePrompt()

  useEffect(() => {
    if (selectedClaims.length === 0) {
      closeBatchUpdate()
    }
  }, [selectedClaims])

  const [resubmitClaims] = useMutation(
    RESUBMIT_CLAIMS,
    {
      onCompleted: async (data) => {
        navigate(buildTenantRoute('billing/assessed', tenant_id))
        closeBatchUpdate()
        showSuccessAlert(`${selectedClaims.length} ${selectedClaims.length === 1? 'claim has' : 'claims have'} been queued for resubmission.`)
        await client.refetchQueries({
          include: [LIST_ASSESSEDCLAIMS]
        })
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Claims couldn\'t be resubmitted.')
      }
    }
  )


  const formMethods = useForm<BatchClaimInputType>({
    defaultValues: assessedClaimDefaults
  })
  const {
    getValues,
    setValue,
    formState: {dirtyFields, isSubmitSuccessful},
    setFocus,
    reset,
  } = formMethods

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful])

  const resubmit = async (data) => {
    const claimIds = selectedClaims.map(sc => sc.id)

    // Cannot resubmit assessed claim with different practitioner or patient
    data.abClaimBatchUpdatedFields = {
      billingProfileId: false,
      calls: dirtyFields.calls || false,
      confidential: dirtyFields.confidential || false,
      diagnosticCodeIds: !!dirtyFields.diagnosticCodes,
      encounter: dirtyFields.encounter || false,
      facilityId: !!dirtyFields.facility,
      functionalCenterId: !!dirtyFields.functionalCenter,
      guardianUli: dirtyFields.guardianUli || false,
      interceptReason: dirtyFields.interceptReason || false,
      locationCode: dirtyFields.locationCode || false,
      newbornCode: dirtyFields.newBornCode || false,
      originalEncounterDate: dirtyFields.originalEncounterDate || false,
      originalFacilityId: dirtyFields.originalFacility || false,
      originalLocationCode: dirtyFields.originalLocationCode || false,
      paperDocumentation: dirtyFields.paperDocumentation || false,
      patientId: false,
      payToCode: dirtyFields.payToCode || false,
      payToUli: dirtyFields.payToUli || false,
      referralPractitionerId: !!dirtyFields.referralPractitioner,
      serviceCodeFeeModifierIds: !!dirtyFields.serviceCodeFeeModifiers,
      serviceCodeId: !!dirtyFields.serviceCode,
      serviceDate: !!dirtyFields.serviceDate,
      skillId: dirtyFields.skillId || false,
      text: dirtyFields.text || false,
      feeMod1Units: dirtyFields.feeMod1Units || false,
      feeMod2Units: dirtyFields.feeMod2Units || false,
      feeMod3Units: dirtyFields.feeMod3Units || false,
    }

    data.billingProfileId = data.billingProfile?.id || ''
    delete data.billingProfile

    data.facilityId = data.facility?.id || null
    delete data.facility

    data.functionalCenterId = data.functionalCenter?.id || null
    delete data.functionalCenter

    data.referralPractitionerId = data.referralPractitioner?.id || null
    delete data.referralPractitioner

    data.serviceCodeId = data.serviceCode?.id || ''
    delete data.serviceCode

    data.diagnosticCodeIds = data.diagnosticCodes?.map(dc => dc.id) || null
    delete data.diagnosticCodes

    data.serviceCodeFeeModifierIds = data.serviceCodeFeeModifiers?.map(fm => fm.value) || null
    delete data.serviceCodeFeeModifiers

    data.patientId = data.patient?.id || null
    delete data.patient

    data.originalFacilityId = data.originalFacility?.id || null
    delete data.originalFacility

    data.skillId = data.skillId?.value || null
    data.originalLocationCode = data.originalLocationCode || null
    data.interceptReason = data.interceptReason || null
    data.newBornCode = data.newBornCode || null
    data.payToUli = data.payToUli || null

    await resubmitClaims({
      variables: {
        tenantId: tenant_id,
        claimIds: claimIds,
        claimData: data,
        actionCode: ABClaimActionCode.REASSESS
      },
    })

  }

  const shortcuts = useMemo(() => ({
    "d": () => {
      resubmit(getValues())
    },
    "f": () => {
      setFocus("facility")
    },
    "r": () => {
      setFocus("referralPractitioner")
    },
    "t": () => {
      setFocus("text")
    },
    "p": () => {
      setFocus("patient")
    },
    "e": () => {
      setFocus("encounter")
    }
  }), [setFocus])

  const handleKeyPress = useCallback((event) => {
    if (event.altKey && event.key in shortcuts){
      event.preventDefault()
      shortcuts[event.key]()
    }
  }, [shortcuts])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    };
  }, [handleKeyPress])

  let abClaimMultiple : AbClaimMultiple = setAbClaimMultiple(selectedClaims)

  useEffect(() => {
    setBatchAbClaimValues(abClaimMultiple, selectedClaims, setValue)
  }, [])

  const options = [
    {
      label: 'BASE',
      key: 'BASE',
      content: (
        <BatchUpdateAssessedBase
          batchUpdateMultiple={abClaimMultiple}
          claim={selectedClaims[0]}
          selectedClaims={selectedClaims}
        />
      ),
    },
    {
      label: 'OPTIONS',
      key: 'OPTIONS',
      content: (
        <BatchUpdateAssessedClaimOptions.Form
          batchUpdateMultiple={abClaimMultiple}
        />
      ),
    },
  ]

  if (selectedClaims.length === 0) {
    return null
  }

  const BatchUpdateHeader = () => {
    return <>
      <Box
        display={'flex'}
        flexDirection={'row'}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Section.Header>Updating {selectedClaims.length} {selectedClaims.length === 1? 'claim' : 'claims'}</Section.Header>
        <Box>
          <Button
            name="cancel"
            variant={'outlined'}
            onClick={closeBatchUpdate}
            sx={{
              mr: 1,
            }}
          >
            CANCEL
          </Button>
          <Button name="resubmit" onClick={() => resubmit(getValues())}>
            RESUBMIT
          </Button>
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        color={theme.palette.warning.main}
        sx={{
          alignItems: 'center',
          mt: 1
        }}
      >
        <WarningAmberIcon sx={{ mr: 1 }}/>
        <Typography variant={'subtitle1'}>Only fields with values shared across all selected claims can be updated. New values will replace existing values in all claims.</Typography>
      </Box>
    </>
  }

  return (
    <FormProvider {...formMethods}>
      <FormColumn header={<BatchUpdateHeader />}>
          <Form onSubmit={resubmit} autoComplete={'false'} ref={formRef}>
            <Tabs options={options} />
          </Form>
      </FormColumn>
    </FormProvider>
  )
}