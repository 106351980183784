import React from 'react'
import MuiAppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { AppBarMenu } from '../AppBarMenu'
import { useTheme } from "@mui/material";
import { Typography } from "../Typography";
import CenterMenu from "../CenterMenu/CenterMenu";
import { Box } from "@mui/system";

interface AppBarProps {
  rightMenu?: React.ReactNode
  searchBar?: React.ReactNode
  logo?: string
  logoNav?: () => void
  displayName?: string
  clinicName?: string
  menuOpen: boolean
  setMenuOpen: (boolean) => void,
  routes: Array<any>
  settingsRoute: React.ReactNode
}

export default function AppBar({
  searchBar,
  logo,
  logoNav,
  displayName,
  clinicName,
  menuOpen,
  setMenuOpen,
  rightMenu,
  routes,
  settingsRoute
}: AppBarProps) {

  return (
    <MuiAppBar
      sx={{
        height: 56,
        boxShadow: 'none',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      position="fixed"
      color="primary"
    >
      <Toolbar
        sx={ theme => ({
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
          padding: 0,
          '&.MuiToolbar-root': {
            p: theme.spacing(0 ,2),
            minHeight: 56
          }
        })}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div data-testid={'saga-logo'} onClick={logoNav && (() => logoNav())}>
            {logo && <img alt={'Saga'} height={40} width={'auto'} src={logo} />}
          </div>
          {!!searchBar && searchBar}
        </div>
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-40%)',
          }}
        >
          <CenterMenu routes={routes} />
        </Box>
        <Actions
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          displayName={displayName}
          clinicName={clinicName}
          settingsRoute={settingsRoute}
        >
          {rightMenu}
        </Actions>
      </Toolbar>
    </MuiAppBar>
  )
}

interface ActionsProps {
  children?: React.ReactNode
  displayName?: string
  clinicName?: string
  menuOpen: boolean
  setMenuOpen: (boolean) => void
  settingsRoute: React.ReactNode
}

const Actions = ({
  children,
  displayName,
  clinicName,
  menuOpen,
  setMenuOpen,
  settingsRoute
}: ActionsProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const theme = useTheme()

  const openMenuHandler = (event) => {
    if (menuOpen) {
      handleClose()
    } else {
      setAnchorEl(event.currentTarget)
      setMenuOpen(true)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
    setMenuOpen && setMenuOpen(false)
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {children && (
        <>
          <Typography
            style={{
              paddingRight: theme.spacing(1)
            }}
          >
            {clinicName}
          </Typography>
          {settingsRoute}
          <IconButton
            data-testid={'saga-user-menu'}
            edge="end"
            aria-label="account of current user"
            color="inherit"
            onClick={openMenuHandler}
            sx={{
              maxWidth: '450px',
              borderRadius: 1,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
                borderRadius: 1,
              },
            }}
          >
            <AccountCircle
              sx={{ fontSize: 40 }}
              aria-controls="simple-menu"
              aria-haspopup="true"
            />
            <Typography
              lineclamp={1}
              style={{
                paddingLeft: theme.spacing(1),
                wordBreak: 'break-all',
              }}
            >
              {displayName}
            </Typography>
          </IconButton>

          <AppBarMenu
            open={menuOpen}
            anchorEl={anchorEl}
            handleClose={handleClose}
          >
            {children}
          </AppBarMenu>
        </>
      )}
    </div>
  )
}