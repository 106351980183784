import React from "react";
import { useParams } from "react-router-dom";
import { useBackgroundQuery, QueryReference, useMutation } from "@apollo/client";
import {
  CREATE_FORM,
  DELETE_FORM,
  GET_PATIENT_FORMS,
  UPDATE_FORM
} from "../../../graphql-definitions/tenant/patient/FormQueries";
import { FormType } from '../../../types/patients'
import { useAlerts } from "saga-library/src/providers/Alerts";
import _get from "lodash/get";
import { FormSortPredicate } from '../components/forms/components/FormsUtil'

const getFormQueryResults = (data) => {
  return [..._get(data, 'tenant.patient.form.list', []) as Array<FormType>]
}

const parseFormQueryResults = (data) => {
  return getFormQueryResults(data).sort(FormSortPredicate)
}

interface PatientFormContextInterface {
  createForm: ( form: FormType, onSuccess?: (form:FormType) => void ) => Promise<void>,
  updateForm: ( form: FormType, onSuccess?: (form:FormType) => void ) => Promise<void>,
  deleteForm: ( formId: string, onSuccess?: () => void ) => Promise<void>,
  patientFormQueryRef: QueryReference | null
  getFormQueryResults: (any) => FormType[]
  parseFormQueryResults: (any) => FormType[]
}

const defaultPatientFormContext: PatientFormContextInterface = {
  createForm: (form: FormType, onSuccess?:(form: FormType)=>void) => Promise.resolve(),
  updateForm: (form: FormType, onSuccess?:(form: FormType)=>void) => Promise.resolve(),
  deleteForm: (formId: string, onSuccess?:()=>void) => Promise.resolve(),
  patientFormQueryRef: null,
  getFormQueryResults: getFormQueryResults,
  parseFormQueryResults: parseFormQueryResults
}


const PatientFormContext = React.createContext(defaultPatientFormContext)

export const PatientFormProvider = ({ children }) => {
  const { tenant_id, patient_id } = useParams()
  const [ createFormMutation ] = useMutation(CREATE_FORM)
  const [ updateFormMutation ] = useMutation(UPDATE_FORM)
  const [ deleteFormMutation ] = useMutation(DELETE_FORM)
  const { showErrorAlert, showSuccessAlert } = useAlerts()

  const [patientFormQueryRef] = useBackgroundQuery(GET_PATIENT_FORMS, {
    variables: {
      patientId: patient_id,
      tenantId: tenant_id
    }
  })

  const createForm = async (form, onSuccess) => {
    delete form.practitioner

    await createFormMutation({
      variables: {
        tenantId: tenant_id,
        input: form
      },
      onCompleted: (data) => {
        const formResult = _get(data, 'tenant.patient.form.create', null)
        if(onSuccess) {
          onSuccess(formResult)
        }
        showSuccessAlert("Form has been saved")
      },
      onError: (error) => {
        showErrorAlert("Form couldn\'t be created")
      },
      update: (cache, { data }) => {
        const newForm = _get(data, 'tenant.patient.form.create', null)
        updatePatientFormCache(cache, newForm, tenant_id, patient_id)
      }
    })
  }

  const updateForm = async (form, onSuccess) => {
    const formId = form.id

    delete form.id
    delete form.practitioner

    await updateFormMutation({
      variables:{
        tenantId: tenant_id,
        formId: formId,
        input: form
      },
      onCompleted: (data) => {
        const formResult = _get(data, 'tenant.patient.form.update', null)
        if(onSuccess) {
          onSuccess(formResult)
        }
        showSuccessAlert("Form has been saved")
      },
      onError: (error) => {
        showErrorAlert("Form couldn\'t be saved")
      }
    })
  }

  const deleteForm = async (formId, onSuccess) => {
    await deleteFormMutation({
      variables: {
        tenantId: tenant_id,
        formId: formId,
      },
      onCompleted: (data) => {
        if (onSuccess) {
          onSuccess()
        }
        showSuccessAlert("Form has been deleted.")
      },
      onError: (error) => {
        showErrorAlert("Form couldn't be deleted.")
      },
      update: (cache) => {
        const normalizedId = cache.identify({
          id: formId,
          __typename: 'Form',
          isLinkedDocument: false
        })
        cache.evict({ id: normalizedId })
        cache.gc()
      }
    })
  }

  const providerValues = {
    createForm,
    updateForm,
    deleteForm,
    patientFormQueryRef,
    getFormQueryResults,
    parseFormQueryResults
  }

  return (
    <PatientFormContext.Provider value={providerValues}>
      { children }
    </PatientFormContext.Provider>
  )
}

export const usePatientFormContext = () => {
  return React.useContext(PatientFormContext)
}

const updatePatientFormCache = async(cache, form, tenantId, patientId) => {
  await cache.updateQuery({
    query: GET_PATIENT_FORMS,
    variables:{
      tenantId,
      patientId
    }
  }, (data) => {
    const existingForms = _get(data, 'tenant.patient.form.list', []).filter(d => d.id !== form.id || d.isLinkedDocument !== form.isLinkedDocument)
    return {
      tenant: {
        patient: {
          form: {
            list: [...existingForms, form]
          }
        }
      }
    }
  })
}

const transformLinkedDocument = (document) => {
  const name = !!document.description || document.description.trim().length > 0 ? document.description : "Form"

  return {
    id: document.id,
    name: name,
    formDate: document.documentDate,
    isLinkedDocument: true,
    linkedDocument: document,
    description: null,
    __typename: 'Form',
    csvData: null,
    locationId: null,
    practitioner: null,
    practitionerId: null,
    sharedFormId: null,
    version: null,
    audit: document.audit
  }
}

export const moveDocumentToForm = async(cache, document, tenantId, patientId) => {
  const newForm = transformLinkedDocument(document)
  await updatePatientFormCache(cache, newForm, tenantId, patientId)
}