import { gql } from "@apollo/client";
import { ASSESSED_CLAIM_DATA_FRAGMENT, CLAIM_BASE_DETAILS_FRAGMENT, CLAIM_OPTIONS_FRAGMENT } from "./BillingFragments";

export const GET_CLAIM = gql`
    ${CLAIM_BASE_DETAILS_FRAGMENT}
    ${CLAIM_OPTIONS_FRAGMENT}
    query GetClaim($tenantId: ID!, $claimId: ID!) {
        tenant(id: $tenantId) {
            abClaim {
                abClaim(id: $claimId) {
                    id
                    version
                    claimIdentifier
                    claimStatus
                    ...AbClaimBaseDetails
                    ...AbClaimOptionsDetails
                    problems {
                        severity
                        field
                        message
                    }
                    assessmentOutcome
                    actionCode
                    acceptCode
                    adjusted
                    assessmentAmount
                }
            }
        }
    }
`

export const SAVE_CLAIM = gql`
  mutation AddNewAbClaim($tenantId: ID!, $claimData: AbClaimInput!) {
    tenant(id: $tenantId) {
      abClaim {
        createAbClaim(abClaimInput: $claimData) {
          id
          claimIdentifier
          patient{
            id
            firstName
            lastName
            primaryIdentifier
          }
          serviceDate
          serviceCode {
            id
            serviceCode
          }
          diagnosticCodes {
            id
            diagnosticCode
          }
          claimAmount
          calls
          encounter
          facility {
            id
            facilityNumber
          }
          functionalCenter {
            id
            functionalCenterCode
          }
          claimStatus
          billingProfile {
            id
            name
          }
          problems {
            id
            severity
            field
            message
          }
          customClaimAmount
        }
      }
    }
  }
`

export const GET_ASSESSEDCLAIM = gql`
    ${ASSESSED_CLAIM_DATA_FRAGMENT}
    query GetAssessedClaim($tenantId: ID!, $claimId: ID!) {
        tenant(id: $tenantId) {
            abClaim {
                abClaim(id: $claimId) {
                    ...AssessedClaimDataFragment
                    assessmentDate
                }
            }
        }
    }
`

export const LIST_UNSUBMITTEDCLAIMS = gql`
  query ListUnsubmittedClaims($tenantId: ID!) {
    tenant(id: $tenantId) {
      abClaim {
        listUnsubmittedAbClaim {
          id
          claimIdentifier
          patient{
            id
            firstName
            lastName
            primaryIdentifier
          }
          serviceDate
          serviceCode {
            id
            serviceCode
          }
          diagnosticCodes {
            id
            diagnosticCode
          }
          claimAmount
          calls
          encounter
          facility {
            id
            description
            facilityNumber
          }
          functionalCenter {
            id
            description
            functionalCenterCode
          }
          claimStatus
          billingProfile {
            id
            name
            practitionerLastName
            practitionerFirstName
          }
          problems {
            id
            severity
            field
            message
          }
        }
      }
    }
  }
`


export const UPDATE_CLAIM = gql`
    ${CLAIM_BASE_DETAILS_FRAGMENT}
    ${CLAIM_OPTIONS_FRAGMENT}
    mutation UpdateUnsubmittedClaim(
        $tenantId: ID!
        $claimId: ID!
        $claimData: AbClaimInput!
    ) {
        tenant(id: $tenantId) {
            abClaim {
                updateAbClaim(abClaimId: $claimId, abClaimInput: $claimData) {
                    id
                    version
                    claimIdentifier
                    claimStatus
                    ...AbClaimBaseDetails
                    ...AbClaimOptionsDetails
                    problems {
                        severity
                        field
                        message
                    }
                }
            }
        }
    }
`

export const UPDATE_CLAIMS = gql`
  mutation UpdateAbClaims($tenantId: ID!, $claimData: AbBatchClaimInput!, $claimIds: [ID!]!, $action: ABClaimActionCode!) {
    tenant(id: $tenantId) {
      abClaim {
        updateAbClaims(abClaimIds: $claimIds, abClaimInput: $claimData, action: $action) {
          id
          version
          claimIdentifier
          patient{
            id
            firstName
            lastName
            primaryIdentifier
          }
          serviceDate
          serviceCode {
            serviceCode
          }
          diagnosticCodes {
            diagnosticCode
          }
          claimAmount
          calls
          encounter
          facility {
            facilityNumber
          }
          functionalCenter {
            functionalCenterCode
          }
          claimStatus
          billingProfile {
            name
          }
          problems {
            severity
            field
            message
          }
        }
      }
    }
  }
`

export const HOLD_CLAIM = gql`
    ${CLAIM_BASE_DETAILS_FRAGMENT}
    ${CLAIM_OPTIONS_FRAGMENT}
    mutation HoldClaim(
        $tenantId: ID!
        $claimId: ID!
    ) {
        tenant(id: $tenantId) {
            abClaim {
                holdClaim(abClaimId: $claimId) {
                    id
                    claimIdentifier
                    claimStatus
                    ...AbClaimBaseDetails
                    ...AbClaimOptionsDetails
                }
            }
        }
    }
`

export const UNHOLD_CLAIM = gql`
    ${CLAIM_BASE_DETAILS_FRAGMENT}
    ${CLAIM_OPTIONS_FRAGMENT}
    mutation UnholdClaim(
        $tenantId: ID!
        $claimId: ID!
    ) {
        tenant(id: $tenantId) {
            abClaim {
                unholdClaim(abClaimId: $claimId) {
                    id
                    claimIdentifier
                    claimStatus
                    ...AbClaimBaseDetails
                    ...AbClaimOptionsDetails
                }
            }
        }
    }
`

export const DELETE_CLAIM = gql`
    mutation DeleteClaim(
        $tenantId: ID!
        $claimId: ID!
    ) {
        tenant(id: $tenantId) {
            abClaim {
                deleteAbClaim(id: $claimId)
            }
        }
    }
`

export const GET_SUBMIT_INFO = gql`
    query GetSubmissionInfo ($tenantId: ID!) {
        tenant(id: $tenantId) {
            abClaim {
                submissionInfo {
                    readyClaims
                    resubmittedClaims
                }
            }
        }
    }
`

export const SUBMIT_CLAIMS = gql`
    mutation SubmitClaims ($tenantId: ID!) {
        tenant(id: $tenantId) {
            abClaim {
                submitClaims
            }
        }
    }
`

export const LIST_PATIENTCLAIMS = gql`
  query ListPatientClaims($tenantId: ID!, $selectedPatientId: ID!) {
    tenant(id: $tenantId) {
      abClaim {
        listAbClaimForPatient(patientId: $selectedPatientId) {
          claimIdentifier
          patient {
            id
          }
          serviceDate
          serviceCode {
            serviceCode
          }
          claimAmount
          calls
          actionCode
          claimStatus
          diagnosticCodes {
            diagnosticCode
          }
          assessmentAmount
          billingProfile {
            name
            practitionerFirstName
            practitionerLastName
          }
          referralPractitioner {
            firstName
            lastName
            practitionerId
          }
        }
      }
    }
  }
`

export const HOLD_CLAIMS = gql`
  mutation HoldClaims($tenantId: ID!, $abClaimIds: [ID!]!) {
    tenant(id: $tenantId) {
      abClaim {
        holdClaims(abClaimIds: $abClaimIds)
      }
    }
  }
`

export const UNHOLD_CLAIMS = gql`
  mutation UnholdClaims($tenantId: ID!, $abClaimIds: [ID!]!) {
    tenant(id: $tenantId) {
      abClaim {
        unholdClaims(abClaimIds: $abClaimIds)
      }
    }
  }
`

export const DELETE_CLAIMS = gql`
  mutation DeleteAbClaims($tenantId: ID!, $abClaimIds: [ID!]!) {
    tenant(id: $tenantId) {
      abClaim {
        deleteAbClaims(abClaimIds: $abClaimIds)
      }
    }
  }
`

export const CALCULATE_PRICE = gql`
  query CalculatePrice($tenantId: ID!, $claimData: AbClaimAmountCalculationInput!) {
    tenant(id: $tenantId) {
      abClaim {
        calculatePrice(input: $claimData)
      }
    }
  }
`

export const GET_SERVICECODEFEEMODIFIERS = gql`
  query ListServiceCodeFeeModifiers($tenantId: ID!, $serviceCodeId: ID!) {
    tenant(id: $tenantId) {
      abClaim {
        listAbClaimServiceCodeFeeModifiers(serviceCodeId: $serviceCodeId) {
          id
          feeMod
          feeModType
          description
        }
      }
    }
  }
`
